import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Parser from 'html-react-parser';
import axios from 'axios';
import { servidorBackEnd, servidorFrontEnd, diminuirAlturaBoxFooter } from '../../utils/constantes_em_comum.js';

const setaTituloSistema = () => {
    document.getElementById('nomeSistemaInfoDiv').innerHTML = 'Área do Candidato - FIC Preenchido';

    document.getElementById('boxDiv').style.minHeight = (window.innerHeight - diminuirAlturaBoxFooter)+'px';
}

const Formulario2219Preenchido = () => {
    const navigate = useNavigate();
    const [lCandidato, setLCandidato] = useState([]);
    const [nomeCandidato, setNomeCandidato] = useState('');
    const [cpfCandidato, setCpfCandidato] = useState('');
    const [nrInscricao, setNrInscricao] = useState('');
    const [codigoAutenticacao, setCodigoAutenticacao] = useState('');
    const [qrCode, setQrCode] = useState('');

    useEffect(() => {
        document.title = '[SCIC] Candidato';
        setaTituloSistema();
        validaToken();
    }, []);

    const validaToken = async () => {
        document.getElementById('tokenInvalidoDiv').style.display = 'none';
        document.getElementById('conteudoDiv').style.display = 'none';

        const token = window.sessionStorage.getItem('token');
        const responseInfoToken = await axios.get(servidorBackEnd + '/getInfoTokenCandidato?token=' + token);
        if(responseInfoToken.data.cpf === '' || responseInfoToken.data.cpf === undefined || responseInfoToken.data.nr_inscricao === undefined || responseInfoToken.data === '' || responseInfoToken.data.admin === true){
            document.getElementById('tokenInvalidoDiv').style.display = 'block';
            return false;
        }
        const responseCandidato = await axios.post(servidorBackEnd + '/getCandidato2219', {
            cpf: responseInfoToken.data.cpf,
            nrInscricao: responseInfoToken.data.nr_inscricao
        });

        setCodigoAutenticacao(responseCandidato.data.form_autenticacao);
        gerarQrCode(responseCandidato.data.form_autenticacao);
        document.getElementById('conteudoDiv').style.display = 'block';
    }

    const gerarQrCode = async (codAutenticacao) => {
        const responseQrCode = await axios.get(servidorBackEnd + '/qrCodeImage?stringdata=' + servidorFrontEnd + 'impressao/' + codAutenticacao);
        setQrCode(responseQrCode.data);
    }

    const fic = async () => {
        navigate('/form2219');
    }

    const gerarPdf = async (e) => {
        e.preventDefault();
        window.open('/impressao2219/' + codigoAutenticacao, 'ficImpressao');
    }

    const imprimePagina = (e) => {
        e.preventDefault();
        window.print();
        return false;
    }

    return (
        <div className="container mt-5 box" id='boxDiv'>
            <div className="hero-body" id='tokenInvalidoDiv' style={{ display:'none', margin:'auto' }}>
                <div className="container">
                    <div className="columns is-centered box">
                        <div className="column">
                            <table border={0} style={{ margin:'auto' }}>
                                <tbody>
                                    <tr>
                                        <td width={'65px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnEsquerda.jpeg'/></td>
                                        <td style={{ padding:'10px', fontFamily:'Times New Roman', verticalAlign:'middle', fontWeight:'bold', fontSize:'13px', lineHeight:'14px' }}>
                                            RIO GRANDE DO NORTE<br/>
                                            SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E DA DEFESA SOCIAL<br/>
                                            POLÍCIA MILITAR<br/>
                                            AGÊNCIA CENTRAL DE INTELIGÊNCIA – ACI<br/>
                                            SUBSEÇÃO DE CONTRAINTELIGÊNCIA – SS3
                                        </td>
                                        <td width={'80px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnDireita.jpg'/></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3}>
                                            <div className='notification is-danger'>
                                                <b>Token Inválido!</b><br/>
                                                Por favor, logue novamente
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div id='conteudoDiv' style={{ display:'none' }}>
                <form>
                    <table className='table is-fullwidth'>
                        <tbody>
                            <tr>
                                <td colSpan={2} style={{ borderBottom:'0px solid lightgrey' }}>
                                    <table style={{ margin:'auto'}}>
                                        <tbody>
                                            <tr>
                                                <td width={'95px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='pmrnEsquerda.jpeg'/></td>
                                                <td style={{ padding:'0px', fontFamily:'Times New Roman', verticalAlign:'middle', fontWeight:'bold', fontSize:'15px', lineHeight:'14px' }}>
                                                    RIO GRANDE DO NORTE<br/>
                                                    SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E DA DEFESA SOCIAL<br/>
                                                    POLÍCIA MILITAR<br/>
                                                    AGÊNCIA CENTRAL DE INTELIGÊNCIA – ACI<br/>
                                                    SUBSEÇÃO DE CONTRAINTELIGÊNCIA – SS3
                                                </td>
                                                <td width={'112px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='pmrnDireita.jpg'/></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{ borderBottom:'0px solid lightgrey' }}>&nbsp;</td>
                            </tr>
                            <tr>
                                <td rowSpan={2} width={'48%'} style={{ borderBottom:'0px solid lightgrey', textAlign:'right', paddingRight:'50px' }}>
                                    {qrCode !== '' &&
                                        // <img width={'300px'} src={qrCode} />
                                        <img src={qrCode} />
                                    }
                                </td>
                                <td width={'52%'} style={{ borderBottom:'0px solid lightgrey' }}>
                                    <span style={{ fontWeight:'bold', fontSize:'30px' }}>Código de Autenticação</span><br/>
                                    <span style={{ fontWeight:'bold', fontSize:'20px' }}>{codigoAutenticacao}</span>
                                    <br/><br/>
                                    <button onClick={(e) => imprimePagina(e)} style={{ width:'180px' }} className="button is-info" id="printPageButton"><i className="fa fa-print"></i>&nbsp;Imprimir</button>
                                </td>
                            </tr>
                            <tr>
                                <td width={'52%'} style={{ verticalAlign:'bottom', paddingBottom:'42px' }}>
                                    <div className='columns'>
                                        <div id="printPageButton" className='column has-background-info' style={{ cursor:'pointer', textAlign:'center', fontWeight:'bold', color:'white', marginTop:'20px' }} onClick={(e) => fic()}>ALTERAR FICHA DE<br/>INFORMAÇÕES CONFIDENCIAIS</div>
                                        <div className='column is-narrow'>&nbsp;</div>
                                        <div id="printPageButton" className='column has-background-info' style={{ cursor:'pointer', textAlign:'center', fontWeight:'bold', color:'white', marginTop:'20px' }} onClick={(e) => gerarPdf(e)}>IMPRIMIR FICHA DE<br/>IMPRESSÕES CONFIDENCIAIS</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
        )
    }
     
    export default Formulario2219Preenchido