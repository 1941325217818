import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Parser from 'html-react-parser';
import axios from 'axios';
import { servidorBackEnd, diminuirAlturaBoxFooter } from '../../utils/constantes_em_comum.js';

const setaTituloSistema = () => {
    document.getElementById('nomeSistemaInfoDiv').innerHTML = 'Instrução para o Preenchimento do Formulário';

    document.getElementById('boxDiv').style.minHeight = (window.innerHeight - diminuirAlturaBoxFooter)+'px';
}

const Formulario2219Instrucao = () => {
    const navigate = useNavigate();
    const [lCandidato, setLCandidato] = useState([]);
    const [nomeCandidato, setNomeCandidato] = useState('');
    const [cpfCandidato, setCpfCandidato] = useState('');
    const [nrInscricao, setNrInscricao] = useState('');
    const [codigoAutenticacao, setCodigoAutenticacao] = useState('');

    useEffect(() => {
        document.title = '[SCIC] Instrução';
        setaTituloSistema();
        validaToken();
    }, []);

    const validaToken = async () => {
        document.getElementById('tokenInvalidoDiv').style.display = 'none';
        document.getElementById('conteudoDiv').style.display = 'none';

        const token = window.sessionStorage.getItem('token');
        const responseInfoToken = await axios.get(servidorBackEnd + '/getInfoTokenCandidato?token=' + token);
        if(responseInfoToken.data.cpf === '' || responseInfoToken.data.cpf === undefined || responseInfoToken.data.nr_inscricao === undefined || responseInfoToken.data === '' || responseInfoToken.data.admin === true){
            document.getElementById('tokenInvalidoDiv').style.display = 'block';
            return false;
        }

        document.getElementById('conteudoDiv').style.display = 'block';
    }

    const fic = async () => {
        navigate('/form2219');
    }

    return (
        <div className="container mt-5 box" id='boxDiv'>
            <div className="hero-body" id='tokenInvalidoDiv' style={{ display:'none', margin:'auto' }}>
                <div className="container">
                    <div className="columns is-centered box">
                        <div className="column">
                            <table border={0} style={{ margin:'auto' }}>
                                <tbody>
                                    <tr>
                                        <td width={'65px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnEsquerda.jpeg'/></td>
                                        <td style={{ padding:'10px', fontFamily:'Times New Roman', verticalAlign:'middle', fontWeight:'bold', fontSize:'13px', lineHeight:'14px' }}>
                                            RIO GRANDE DO NORTE<br/>
                                            SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E DA DEFESA SOCIAL<br/>
                                            POLÍCIA MILITAR<br/>
                                            AGÊNCIA CENTRAL DE INTELIGÊNCIA – ACI<br/>
                                            SUBSEÇÃO DE CONTRAINTELIGÊNCIA – SS3
                                        </td>
                                        <td width={'80px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnDireita.jpg'/></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3}>
                                            <div className='notification is-danger'>
                                                <b>Token Inválido!</b><br/>
                                                Por favor, logue novamente
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div id='conteudoDiv' style={{ display:'none' }}>
                <form>
                    <div className='columns box' style={{ maxWidth:'700px', margin:'auto' }}>
                        <div className='column' style={{ width:'100%', textAlign:'justify' }}>
                            <b>INSTRUÇÕES PARA O PREENCHIMENTO:</b><br/><br/>
                            <span style={{marginLeft:'4em'}}></span>Conforme já descrito na folha de rosto do presente Formulário, todos os dados, informações e documentos que compuserem a Ficha de Informações Confidenciais - FIC são identificados como <b>informação pessoal</b>, possuindo assim, <b>restrição de acesso</b>, nos termos do art. 5º, inc. X e XXXIII, da Constituição Federal; art. 2º, parágrafo único, inc V, da Lei n.º 9.784, de 29JAN99; art. 6º, inc. III; art. 23, inc. VIII; art. 31, § 1º, inc. I, tudo da Lei Federal n.º 12.527/11, de 18NOV11 - “Lei de Acesso a Informação”; Inciso III, do Art. 4° da Lei Estadual n°9.963, de 27JUL2015, regulamentada pelo Decreto Estadual n° 25.399, de 31JUL2015.
                            <br/><br/>
                            <span style={{marginLeft:'4em'}}></span>Leia com atenção todas as questões do Formulário antes de preenchê-lo. As perguntas      apresentadas são de seu próprio interesse, portanto, <b>todas elas devem ser respondidas corretamente</b>, fornecendo informações com <b>riqueza de detalhes</b>. Se o espaço for insuficiente para o preenchimento das questões, utilize a área de “<i>Informações Complementares</i>”, ou se preferir, <u>junte folhas para complementação e as anexe no Formulário</u>. Para o preenchimento de toda esta documentação você irá dispor de alguns dias. Não deixe questões em branco. Inutilize com traço os espaços onde não houver resposta.
                            <br/><br/>
                            <span style={{marginLeft:'4em'}}></span><b style={{ fontWeight:'bold', color:'red' }}>ATENÇÃO, TENHA CUIDADO!!!</b><br/>
                            <span style={{marginLeft:'4em'}}></span>Falhas cometidas durante o preenchimento do presente Formulário serão avaliadas quanto à propositura intencional e/ou até mesmo de má fé do candidato. Não deixe de declarar nenhuma informação e/ou dados solicitados nas perguntas. NÃO OMITA NADA NO FORMULÁRIO, pois <u>declarações inexatas, falsas e/ou omissão de informações relevantes e já conhecidas pelo candidato, ainda que verificadas posteriormente</u>, poderão determinar a <b>eliminação</b> do candidato no concurso e a sua consequente <b>exclusão sumária</b> do certame, tudo nos termos do § 6º; artigo 2º, da Lei Complementar n.º 613, de 03JAN18 - “Lei de Ingresso da PMRN”, que modificou o Art. 11 da Lei Estadual n° 4.630, de 16DEZ1976 - “<i>Estatuto dos Militares Estaduais do Rio Grande do Norte</i>” e também conforme especificado no edital do concurso. 
                            <br/><br/>
                            <span style={{marginLeft:'4em'}}></span><b style={{ fontWeight:'bold', color:'red' }}>ATENÇÃO, TENHA CUIDADO!!!</b><br/>
                            <span style={{marginLeft:'4em'}}></span>Após a entrega da sua Ficha de Informações Confidenciais - FIC, para a Comissão do Concurso, caso ocorra QUALQUER ALTERAÇÃO do seu <u>endereço de moradia</u>, envolvimento de sua pessoa em <u>ocorrência policial</u> - de qualquer natureza, seja na condição de autor, indiciado, réu, testemunha ou até mesmo como vítima -, <u>tratamento de saúde</u> grave que você seja submetido, <u>internação</u> hospitalar, <u>punição</u> disciplinar no trabalho e/ou em ambiente escolar, <u>acidente</u> de trânsito, suspensão ou cassação de sua <u>CNH</u>, inclusão de seu nome no <u>SCPC e/ou SERASA</u> , VOCÊ DEVERÁ INFORMAR por escrito à Investigação Social da Polícia Militar e anexar uma cópia, nos casos em que houver, do registro policial elaborado na ocorrência da qual você participou. O não atendimento a qualquer uma destas exigências poderá determinar a sua eliminação no concurso, nos termos da legislação acima especificada.
                        </div>
                    </div>
                    <div style={{textAlign:'center', paddingTop:'15px' }}>
                        <button className="button has-background-info has-text-white" onClick={fic}>PREENCHER FICHA DE INFORMAÇÕES CONFIDENCIAIS</button>
                    </div>
                </form>
            </div>
        </div>
        )
    }
     
    export default Formulario2219Instrucao