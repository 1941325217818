import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { cpfMask, nrInscricaoMask } from '../../utils/mask.js';
import Parser from 'html-react-parser';
import axios from 'axios';
import { servidorBackEnd } from '../../utils/constantes_em_comum.js';

const PaginaInicial2301 = () => {
    const navigate = useNavigate();
    const [cpf, setCpf] = useState('');
    const [nrInscricao, setNrInscricao] = useState('');
    const [msg, setMsg] = useState('');

    useEffect(() => {
        document.title = '[SCIC] Login';
    }, []);

    const consultar = async (e) => {
        e.preventDefault();
        setMsg('');

        const responseCandidato = await axios.post(servidorBackEnd + '/getCandidato2301', {
            cpf: cpf,
            nrInscricao: nrInscricao
        });

        if(responseCandidato.data === ''){
            setMsg('<b>CPF e/ou Número de Inscrição Inválido!</b><br/>Por favor, verifique as informações e tente novamente');
            return false;
        }
        if(responseCandidato.data.status === 'bloqueado'){
            setMsg('<b>Usuário Bloqueado!</b>');
            return false;
        }
        
        const responsePermDataHora = await axios.get(servidorBackEnd + '/getPermissaoAcessoDataHora2301?idCandidato=' + responseCandidato.data.id_candidato);
        if(responsePermDataHora.data === ''){
            let date_format = {};
            date_format.year = 'numeric';
            date_format.month = '2-digit';
            date_format.day = '2-digit';
            date_format.hour = 'numeric';
            date_format.minute = 'numeric';

            var dataHoraFinalFic = new Date(responseCandidato.data.form_data_final);
            dataHoraFinalFic.setMinutes(dataHoraFinalFic.getMinutes() + dataHoraFinalFic.getTimezoneOffset());

            setMsg('<b>Prazo para preenchimento da FIC (Ficha de Informações Confidenciais) finalizado!</b><br/>Prazo: ' + dataHoraFinalFic.toLocaleDateString('pt-BR', date_format) + 'h');
            return false;
        }

        const responseGenerateToken = await axios.post(servidorBackEnd + '/createTokenCandidato', {
            id_candidato: responseCandidato.data.id_candidato,
            cpf: responseCandidato.data.cpf,
            nr_inscricao: responseCandidato.data.nr_inscricao,
            nome: responseCandidato.data.nome,
            status: responseCandidato.data.status,
        });
        const token = responseGenerateToken.data;

        window.sessionStorage.setItem('token', token);

        if(responseCandidato.data.form_finalizou === false)
            navigate('/formInstrucao');
        else
            navigate('/formPreenchido');
    }

    const jahPreencheu = async() => {
        navigate('/formPreenchido');
    }

    return (
        <section className="hero has-background-grey-light is-fullheight is-fullwidth">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-6-desktop">
                            <form onSubmit={consultar} className="box" name='frm' id='frm'>
                                <div className="field mt-5">
                                    <div style={{ textAlign:'center' }}>
                                        <table border={0} width={'100%'}>
                                            <tbody>
                                                <tr>
                                                    <td width={'65px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='pmrnEsquerda.jpeg'/></td>
                                                    <td style={{ padding:'0px', fontFamily:'Times New Roman', verticalAlign:'middle', fontWeight:'bold', fontSize:'13px', lineHeight:'14px' }}>
                                                        RIO GRANDE DO NORTE<br/>
                                                        SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E DA DEFESA SOCIAL<br/>
                                                        POLÍCIA MILITAR<br/>
                                                        AGÊNCIA CENTRAL DE INTELIGÊNCIA – ACI<br/>
                                                        SUBSEÇÃO DE CONTRAINTELIGÊNCIA – SS3
                                                    </td>
                                                    <td width={'80px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='pmrnDireita.jpg'/></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {msg !== '' &&
                                    <div className='notification is-danger'>{Parser(msg)}</div>
                                }
                                <div className='box' id='conteudoFormularioDiv'>
                                    {/* <div className="field mt-5">
                                        <label className="label" htmlFor="name">N° Inscrição ou E-mail</label>
                                        <div className="control has-icons-left">
                                            <div className="controls">
                                                <input type="text" className="input" id="nrInscricao" placeholder="Número de inscrição ou E-mail" autoFocus value={nrInscricao} onChange={(e) => setNrInscricao(nrInscricaoMask(e.target.value))} />
                                            </div>
                                            <span className="icon is-small is-left">
                                                <i className="fa fa-user"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="field mt-5">
                                        <label className="label" htmlFor="password">Senha cadastrada na inscrição</label>
                                        <div className="control has-icons-left">
                                            <div className="controls">
                                                <input type="password" className="input" id="password" placeholder="******" value={password} onChange={(e) => setPassword(e.target.value)} />
                                            </div>
                                            <span className="icon is-small is-left">
                                                <i className="fa fa-lock"></i>
                                            </span>
                                        </div>
                                    </div> */}
                                    <div className="field mt-5">
                                        <label className="label" htmlFor="cpf">CPF</label>
                                        <div className="control has-icons-left">
                                            <div className="controls">
                                                <input type="text" className="input" id="cpf" placeholder="CPF" value={cpf} autoFocus onChange={(e) => setCpf(cpfMask(e.target.value))} />
                                            </div>
                                            <span className="icon is-small is-left">
                                                <i className="fa fa-vcard-o"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="field mt-5">
                                        <label className="label" htmlFor="name">N° Inscrição</label>
                                        <div className="control has-icons-left">
                                            <div className="controls">
                                                <input type="text" className="input" maxLength={9} id="nrInscricao" placeholder="Número de inscrição" value={nrInscricao} onChange={(e) => setNrInscricao(nrInscricaoMask(e.target.value))} />
                                            </div>
                                            <span className="icon is-small is-left">
                                                <i className="fa fa-user"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="field mt-5">
                                        <button className="button is-info is-fullwidth"><i className="fa fa-search"></i>&nbsp;Acessar</button>
                                    </div>
                                    {/* <div style={{ textAlign:'center', fontSize:'12px' }}>
                                        <a onClick={consultar}>Esqueci minha senha</a>
                                    </div> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
 
export default PaginaInicial2301