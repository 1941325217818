import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { servidorBackEnd } from '../../utils/constantes_em_comum.js';
 
const Navbar2219 = () => {
    const [name, setName] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        getUser();
    }, []);
 
    const Logout = async () => {
        try {
            window.sessionStorage.removeItem("token");
            navigate('/');
        } catch (error) {
            console.log(error);
        }
    }

    const getUser = async () => {
        try {
            const tokenNavBar = window.sessionStorage.getItem('token');
            const response = await axios.get(servidorBackEnd + '/getInfoTokenAdmin?token='+tokenNavBar);
            setName(response.data.nome);
        } catch (error) {
            if (error.response) {
                navigate('/');
            }
        }
    }
 
    return (
        <nav className="navbar has-background-info-dark has-text-white" role="navigation" aria-label="main navigation">
            <div className="container">
                <div className="navbar-brand">
                    <a href="/" role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>
 
                <div id="navbarBasicExample" className="navbar-menu">
                    <div className="navbar-start" style={{ fontWeight:'bold', fontSize:'30px', marginTop:'5px' }}>
                         SCIC
                    </div>
 
                    <table>
                        <tbody>
                            <tr style={{ textAlign:'center', fontWeight:'bold', color:'white' }}>
                                <td><div id="nomeSistemaInfoDiv" style={{ marginTop:'15px' }}></div></td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <div className="navbar-end">
                        <div className="navbar-item">
                            <div style={{fontWeight:'bold', color:'white', paddingRight:'10px', fontSize:'18px', fontFamily:'segoe script' }}>
                                {name}
                            </div>
                            <div className="buttons">
                                <button onClick={Logout} style={{ marginTop: '0px' }} className="button">
                                    <i className="fa fa-sign-out"></i> Sair
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}
 
export default Navbar2219