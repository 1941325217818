import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { nrInscricaoMask } from '../../utils/mask.js';
import Parser from 'html-react-parser';
import axios from 'axios';
// import '../../utils/padrao1.css';
import { servidorBackEnd } from '../../utils/constantes_em_comum.js';

const PaginaInicial2219 = () => {
    const navigate = useNavigate();
    const [usuarioAdmin, setUsuarioAdmin] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');

    useEffect(() => {
        document.title = '[SCIC] Admin';
    }, []);

    const consultar = async (e) => {
        e.preventDefault();
        setMsg('');

        const responseAdmin = await axios.post(servidorBackEnd + '/getAdmin2219', {
            usuario: usuarioAdmin,
            senha: password
        });

        if(responseAdmin.data === ''){
            setMsg('<b>Usuário e/ou Senha Inválida!</b><br/>Por favor, verifique as informações e tente novamente');
            return false;
        }
        if(responseAdmin.data.status === 'bloqueado'){
            setMsg('<b>Usuário Bloqueado!</b>');
            return false;
        }

        const responseGenerateToken = await axios.post(servidorBackEnd + '/createTokenAdmin', {
            concurso: '2219',
            usuario: responseAdmin.data.usuario,
            senha: responseAdmin.data.senha,
            nome: responseAdmin.data.nome
        });
        const token = responseGenerateToken.data;

        window.sessionStorage.setItem('token', token);
        
        navigate('/adminBusca2219');
    }

    return (
        <section className="hero has-background-grey-light is-fullheight is-fullwidth">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-6-desktop">
                            <form onSubmit={consultar} className="box" name='frm' id='frm'>
                                <div className="field mt-5">
                                    <div style={{ textAlign:'center' }}>
                                        <table border={0} width={'100%'}>
                                            <tbody>
                                                <tr>
                                                    <td className='has-background-danger-dark has-text-white' colSpan={3} style={{ fontWeight:'bold', fontSize:'30px', padding:'20px' }}>
                                                        SCIC - ADMIN<br/>2219 (Encerrado)
                                                    </td>
                                                </tr>
                                                <tr><td>&nbsp;</td></tr>
                                                <tr>
                                                    <td width={'65px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='pmrnEsquerda.jpeg'/></td>
                                                    <td style={{ padding:'0px', fontFamily:'Times New Roman', verticalAlign:'middle', fontWeight:'bold', fontSize:'13px', lineHeight:'14px' }}>
                                                        RIO GRANDE DO NORTE<br/>
                                                        SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E DA DEFESA SOCIAL<br/>
                                                        POLÍCIA MILITAR<br/>
                                                        AGÊNCIA CENTRAL DE INTELIGÊNCIA – ACI<br/>
                                                        SUBSEÇÃO DE CONTRAINTELIGÊNCIA – SS3
                                                    </td>
                                                    <td width={'80px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='pmrnDireita.jpg'/></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {msg !== '' &&
                                    <div className='notification is-danger'>{Parser(msg)}</div>
                                }
                                <div className='box' id='conteudoFormularioDiv'>
                                    <div className="field mt-5">
                                        <label className="label" htmlFor="usuarioAdmin">Usuário</label>
                                        <div className="control has-icons-left">
                                            <div className="controls">
                                                <input type="text" className="input" id="usuarioAdmin" placeholder="Usuário Administrador" autoFocus value={usuarioAdmin} onChange={(e) => setUsuarioAdmin(e.target.value)} />
                                            </div>
                                            <span className="icon is-small is-left">
                                                <i className="fa fa-user"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="field mt-5">
                                        <label className="label" htmlFor="password">Senha</label>
                                        <div className="control has-icons-left">
                                            <div className="controls">
                                                <input type="password" className="input" id="password" placeholder="******" value={password} onChange={(e) => setPassword(e.target.value)} />
                                            </div>
                                            <span className="icon is-small is-left">
                                                <i className="fa fa-lock"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="field mt-5">
                                        <button className="button is-info is-fullwidth"><i className="fa fa-search"></i>&nbsp;Acessar</button>
                                    </div>
                                    {/* <div style={{ textAlign:'center', fontSize:'12px' }}>
                                        <a onClick={consultar}>Esqueci minha senha</a>
                                    </div> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
 
export default PaginaInicial2219