import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { onlyNumber, nrCepMask, dataBr, dataNascimentoBanco2Html } from '../../utils/mask';
import axios from 'axios';
import { servidorBackEnd, msgInicialErro } from '../../utils/constantes_em_comum.js';
import '../../utils/padrao1.css';

const setaTituloSistema = () => {
    document.title = '[SCIC] FIC - Impressão';
}

const FormularioImpressao2301 = () => {
    const { autenticacao } = useParams();
    const [idCandidato, setIdCandidato] = useState('');
    const [nrInscricao, setNrInscricao] = useState('');
    const [informacaoFoto, setInformacaoFoto] = useState('');
    const [imagemFoto, setImagemFoto] = useState('');
    const [candidato, setCandidato] = useState('');
    const [dadosFormularioSalvo, setDadosFormularioSalvo] = useState(null);

    useEffect(() => {
        setaTituloSistema();
        validaToken();
        window.scroll(0,0);
    }, []);

    const validaToken = async () => {
        document.getElementById('tokenInvalidoDiv').style.display = 'none';
        document.getElementById('conteudoDiv').style.display = 'none';
        document.getElementById('loadingDiv').style.display = 'block';

        const token = window.sessionStorage.getItem('token');
        const responseInfoToken = await axios.get(servidorBackEnd + '/getInfoTokenCandidato?token=' + token);
        if(responseInfoToken.data === ''){
            document.getElementById('tokenInvalidoDiv').style.display = 'block';
            document.getElementById('loadingDiv').style.display = 'none';
            return false;
        }

        const responseCandidato = await axios.get(servidorBackEnd + '/getCandidatoByAutenticacao2301?autenticacao=' + autenticacao);
        if(responseCandidato.data === ''){
            document.getElementById('autenticacaoInvalidoDiv').style.display = 'block';
            document.getElementById('loadingDiv').style.display = 'none';
            return false;
        }
        setCandidato(responseCandidato.data);

        if(responseInfoToken.data.admin === true){
        }
        else{//se for candidato acessando, ele só pode ver o dele
            if(responseInfoToken.data.nr_inscricao === undefined){
                document.getElementById('tokenInvalidoDiv').style.display = 'block';
                document.getElementById('loadingDiv').style.display = 'none';
                return false;
            }
            if(responseCandidato.data.nr_inscricao !== responseInfoToken.data.nr_inscricao){
                document.getElementById('permissaoInvalidoDiv').style.display = 'block';
                document.getElementById('loadingDiv').style.display = 'none';
                return false;
            }
        }
        const responseFormulario = await axios.get(servidorBackEnd + '/getFormulario2301?nrInscricao=' + responseCandidato.data.nr_inscricao);
        setDadosFormularioSalvo(responseFormulario.data);
        setIdCandidato(responseCandidato.data.id_candidato);
        setNrInscricao(responseCandidato.data.nr_inscricao);
        
        document.getElementById('checkTermo1').checked = true;
        document.getElementById('checkTermo2').checked = true;
        document.getElementById('checkTermo3').checked = true;

        validaCarregaFoto(responseCandidato.data.nr_inscricao);

        document.getElementById('loadingDiv').style.display = 'none';
        document.getElementById('conteudoDiv').style.display = 'block';
    }

    const validaCarregaFoto = async(nrInscricao) => {
        const responseInfoFoto = await axios.get(servidorBackEnd + '/getCandidatoFoto2301?nrInscricao=' + nrInscricao);
        if(responseInfoFoto.data !== null){
            setInformacaoFoto(responseInfoFoto.data);

            const responseFoto = await axios.get(servidorBackEnd + '/getCandidatoOnlyFoto2301?nrInscricao=' + nrInscricao);
            setImagemFoto(responseFoto.data);
            console.log(responseFoto)
        }
    }

    const fazNada = (e) => {
        e.preventDefault();
        return false;
    }

    const imprimePagina = (e) => {
        e.preventDefault();
        window.print();
        return false;
    }

    return (
        <section className="hero has-background-grey-light is-fullheight is-fullwidth">
            <div className="container box" id='boxDiv' style={{ width: '842px' }}>
                <div className="hero-body" id='tokenInvalidoDiv' style={{ display:'none', margin:'auto' }}>
                    <div className="container">
                        <div className="columns is-centered box">
                            <div className="column">
                                <table border={0} width={'100%'}>
                                    <tbody>
                                        <tr>
                                            <td width={'65px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnEsquerda.jpeg'/></td>
                                            <td style={{ padding:'10px', fontFamily:'Times New Roman', verticalAlign:'middle', fontWeight:'bold', fontSize:'13px', lineHeight:'14px' }}>
                                                RIO GRANDE DO NORTE<br/>
                                                SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E DA DEFESA SOCIAL<br/>
                                                POLÍCIA MILITAR<br/>
                                                AGÊNCIA CENTRAL DE INTELIGÊNCIA – ACI<br/>
                                                SUBSEÇÃO DE CONTRAINTELIGÊNCIA – SS3
                                            </td>
                                            <td width={'80px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnDireita.jpg'/></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className='notification is-danger'>
                                    <b>Token Inválido!</b><br/>
                                    Por favor, logue novamente
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-body" id='loadingDiv' style={{ margin:'auto' }}>
                    <div className="container">
                        <div className="columns is-centered box">
                            <div className="column">
                                <table border={0} width={'100%'}>
                                    <tbody>
                                        <tr>
                                            <td width={'65px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnEsquerda.jpeg'/></td>
                                            <td style={{ padding:'10px', fontFamily:'Times New Roman', verticalAlign:'middle', fontWeight:'bold', fontSize:'13px', lineHeight:'14px' }}>
                                                RIO GRANDE DO NORTE<br/>
                                                SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E DA DEFESA SOCIAL<br/>
                                                POLÍCIA MILITAR<br/>
                                                AGÊNCIA CENTRAL DE INTELIGÊNCIA – ACI<br/>
                                                SUBSEÇÃO DE CONTRAINTELIGÊNCIA – SS3
                                            </td>
                                            <td width={'80px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnDireita.jpg'/></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className='notification'>
                                    <img src='/loading.gif'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-body" id='permissaoInvalidoDiv' style={{ display:'none', margin:'auto' }}>
                    <div className="container">
                        <div className="columns is-centered box">
                            <div className="column">
                                <table border={0} width={'100%'}>
                                    <tbody>
                                        <tr>
                                            <td width={'65px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnEsquerda.jpeg'/></td>
                                            <td style={{ padding:'10px', fontFamily:'Times New Roman', verticalAlign:'middle', fontWeight:'bold', fontSize:'13px', lineHeight:'14px' }}>
                                                RIO GRANDE DO NORTE<br/>
                                                SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E DA DEFESA SOCIAL<br/>
                                                POLÍCIA MILITAR<br/>
                                                AGÊNCIA CENTRAL DE INTELIGÊNCIA – ACI<br/>
                                                SUBSEÇÃO DE CONTRAINTELIGÊNCIA – SS3
                                            </td>
                                            <td width={'80px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnDireita.jpg'/></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className='notification is-danger'>
                                    <b>Código de Autenticação Inválido!</b><br/>
                                    Por favor, logue novamente e tente novamente
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-body" id='autenticacaoInvalidoDiv' style={{ display:'none', margin:'auto' }}>
                    <div className="container">
                        <div className="columns is-centered box">
                            <div className="column">
                                <table border={0} width={'100%'}>
                                    <tbody>
                                        <tr>
                                            <td width={'65px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnEsquerda.jpeg'/></td>
                                            <td style={{ padding:'10px', fontFamily:'Times New Roman', verticalAlign:'middle', fontWeight:'bold', fontSize:'13px', lineHeight:'14px' }}>
                                                RIO GRANDE DO NORTE<br/>
                                                SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E DA DEFESA SOCIAL<br/>
                                                POLÍCIA MILITAR<br/>
                                                AGÊNCIA CENTRAL DE INTELIGÊNCIA – ACI<br/>
                                                SUBSEÇÃO DE CONTRAINTELIGÊNCIA – SS3
                                            </td>
                                            <td width={'80px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnDireita.jpg'/></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className='notification is-danger'>
                                    <b>Código de Autenticação Inválido!</b><br/>
                                    Por favor, clique no link anterior e tente novamente
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='conteudoDiv' style={{ display:'none' }}>
                    {dadosFormularioSalvo !== null &&
                        <form>
                            <table border={0} style={{ backgroundColor:'white', margin:'auto', marginBottom:'50px' }}>
                                <tbody>
                                    <tr>
                                        <td width={'95px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnEsquerda.jpeg'/></td>
                                        <td style={{ padding:'10px', fontFamily:'Times New Roman', textAlign:'center', verticalAlign:'middle', fontWeight:'bold', fontSize:'17px', lineHeight:'20px' }}>
                                            RIO GRANDE DO NORTE<br/>
                                            SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E DA DEFESA SOCIAL<br/>
                                            POLÍCIA MILITAR<br/>
                                            AGÊNCIA CENTRAL DE INTELIGÊNCIA – ACI<br/>
                                            SUBSEÇÃO DE CONTRAINTELIGÊNCIA – SS3
                                        </td>
                                        <td width={'110px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnDireita.jpg'/></td>
                                    </tr>
                                </tbody>
                            </table>
                            <article className='message is-link' id='divParteInicial'>
                                <div className='message-header'>
                                    <div className="tags are-medium has-addons">
                                        FICHA DE INFORMAÇÕES CONFIDENCIAIS
                                    </div>
                                </div>
                                <div className='message-body'>
                                    <div>
                                        <div className='columns'>
                                            <div className='column'>
                                                <b>Edital Nº 01/2023</b><br/>Concurso Público para ingresso no Curso de Formação de Praças<br/><br/>
                                                <button onClick={(e) => imprimePagina(e)} style={{ width:'180px' }} className="button is-info" id="printPageButton"><i className="fa fa-print"></i>&nbsp;Imprimir</button>
                                            </div>
                                            <div className='column is-narrow' style={{ textAlign:'right' }}>
                                                <table border='1' style={{ width:'150px', height:'190px' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ verticalAlign:'middle', textAlign:'center' }}>
                                                                {imagemFoto !== '' &&
                                                                    <img src={`data:${informacaoFoto.tipo};base64,${imagemFoto}`} />
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className='columns'>
                                            <div className='column'>
                                                <div className='notification is-warning' style={{ textAlign:'center' }}>
                                                    A presente FIC é constituída de dados, informações e documentos, cujo conteúdo é identificado como pertencente à categoria de <b>INFORMAÇÃO PESSOAL</b><br/>
                                                    Todo o conteúdo informado e composto em anexo à presente Ficha, possui <b>RESTRIÇÃO DE ACESSO</b>, nos termos da legislação vigente.
                                                </div>
                                            </div>
                                        </div>
                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label">Nome</label>
                                                {candidato.nome}
                                            </div>
                                        </div>
                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label">Endereço</label>
                                                {candidato.Endereco} {candidato.EnderecoNumero}
                                            </div>
                                        </div>
                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label">Complemento</label>
                                                {candidato.EnderecoComplemento}
                                            </div>
                                            <div className='column'>
                                                <label className="label">Bairro</label>
                                                {candidato.Bairro}
                                            </div>
                                            <div className='column'>
                                                <label className="label">CEP</label>
                                                {candidato.Cep}
                                            </div>
                                        </div>
                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label">Cidade</label>
                                                {candidato.Cidade}
                                            </div>
                                            <div className='column'>
                                                <label className="label">Estado</label>
                                                {candidato.Uf}
                                            </div>
                                            <div className='column'>
                                                <label className="label">Telefone Celular</label>
                                                ({candidato.Ddd}) {candidato.Telefone}
                                            </div>
                                        </div>
                                        <div className='columns' style={{ marginTop:'20px' }}>
                                            <div className='column' style={{ textAlign:'center', color:'black' }}>
                                                ______________________________________________________________________<br/>Assinatura do Candidato
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>

                            <div className="pagebreak"> </div>

                            <article className='message is-link' id='divParteA'>
                                <div className='message-header'>
                                    <div className="tags are-medium has-addons">
                                        PARTE A - INFORMAÇÕES PARTICULARES
                                    </div>
                                </div>
                                <div className='message-body'>
                                    <div>
                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label">Nome</label>
                                                {candidato.nome}
                                            </div>
                                        </div>
                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="nome">Natural de</label>
                                                {candidato.Naturalidade}
                                            </div>
                                            <div className='column'>
                                                <label className="label">Estado</label>
                                                {candidato.UfNaturalidade}
                                            </div>
                                            <div className='column'>
                                                <label className="label" htmlFor="nome">Data de nascimento</label>
                                                {dataNascimentoBanco2Html(candidato.DataNascimento)}
                                            </div>
                                        </div>
                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="nome">CPF</label>
                                                {candidato.cpf}
                                            </div>
                                            <div className='column'>
                                                <label className="label" htmlFor="nome">RG</label>
                                                {candidato.rg}
                                            </div>
                                            <div className='column'>
                                                <label className="label" htmlFor="nome">Expedido por</label>
                                                {candidato.OrgaoEmissor}/{candidato.UfEmissor}
                                            </div>
                                        </div>
                                        <div className='columns'>
                                            <div className='column is-two-thirds'>
                                                <label className="label" htmlFor="rgAnterior">RG anterior (de outro Estado, se possuir)</label>
                                                {dadosFormularioSalvo.rgAnterior}
                                            </div>
                                            <div className='column'>
                                                <label className="label" htmlFor="nome">Estado civil</label>
                                                {candidato.EstadoCivil}
                                            </div>
                                        </div>
                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="profissao">Profissão</label>
                                                {dadosFormularioSalvo.profissao}
                                            </div>
                                            <div className='column'>
                                                <label className="label" htmlFor="empregoAtual">Emprego atual</label>
                                                {dadosFormularioSalvo.empregoAtual}
                                            </div>
                                            <div className='column'>
                                                <label className="label" htmlFor="religiao">Religião</label>
                                                {dadosFormularioSalvo.religiao}
                                            </div>
                                        </div>
                                        <div className='columns'>
                                            <div className='column is-two-thirds'>
                                                <label className="label" htmlFor="apelido">Qualquer outro nome ou apelido pelo qual você é conhecido</label>
                                                {dadosFormularioSalvo.apelido}
                                            </div>
                                            <div className='column'>
                                                <label className="label" htmlFor="comQuemResideAtualmente">Com quem reside atualmente</label>
                                                {dadosFormularioSalvo.comQuemResideAtualmente}
                                            </div>
                                        </div>
                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="todosEmail">Correios eletrônicos (e-mail) particulares e funcionais (infome todos que você possui)</label>
                                                <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.todosEmail.replaceAll('\n', '<br/>'), }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        
                            <article className='message is-link' id='divParteB'>
                                <div className='message-header'>
                                    <div className="tags are-medium has-addons">
                                        PARTE B - INFORMAÇÕES RESIDENCIAIS
                                    </div>
                                </div>
                                <div className='message-body'>
                                    <div>
                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label">Declarar em ordem cronológica todos os lugares onde você <u>residiu e reside atualmente</u>, <u>a partir dos 12 anos de idade</u>. Incluindo alojamentos, pensões, repúblicas e outras formas de moradia. <u>ATENÇÃO:</u> Caso você tenha residido em condomínio fechado ou prédio, deverá indicar um morador do(s) local(is) que resida no endereço e que conheça você:</label>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        {dadosFormularioSalvo.resideResidiuApartir12anos.map((resideResidiu, index) => (
                                                            <div key={index}>
                                                                <article className='message'>
                                                                    <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                        <p>{index+1}. Residiu com: {resideResidiu.residiuCom} ({resideResidiu.mêsInicial}/{resideResidiu.anoInicial} ~ {resideResidiu.mêsFinal}/{resideResidiu.anoFinal})</p>
                                                                    </div>
                                                                    <div className='message-body has-background-white-ter has-text-black'>
                                                                        <p>
                                                                            Endereço: {resideResidiu.endereço} {resideResidiu.número}
                                                                        </p>
                                                                        <p>
                                                                            Complemento: {resideResidiu.complemento};
                                                                            Morador Indicado: {resideResidiu.moradorIndicado}
                                                                        </p>
                                                                        <p>
                                                                            Cidade: {resideResidiu.cidade};
                                                                            Estado: {resideResidiu.estado};
                                                                            Bairro: {resideResidiu.bairro}
                                                                        </p>
                                                                        <p>
                                                                            CEP: {resideResidiu.cep};
                                                                            Ponto de Referência: {resideResidiu.pontoReferência}
                                                                        </p>
                                                                        <p style={{ wordWrap:'break-word', width:'720px' }}>
                                                                            Link do Endereço no Google Maps: {resideResidiu.gMaps}
                                                                        </p>
                                                                    </div>
                                                                </article>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="motivoNResidiuPaiEsposa">Caso você não tenha residido com os seus pais ou esposa durante algum período, explique os motivos:</label>
                                                        <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.motivoNResidiuPaiEsposa.replaceAll('\n', '<br/>'), }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>

                            <article className='message is-link' id='divParteC'>
                                <div className='message-header'>
                                    <div className="tags are-medium has-addons">
                                        PARTE C - INFORMAÇÕES FAMILIARES
                                    </div>
                                </div>
                                <div className='message-body'>
                                    <div>
                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label">Informações completas sobre seus <u>pais</u> e <u>irmãos</u>. Se você foi <u>criado por padrastos</u>, <u>tutores legais</u> ou <u>outras pessoas que não sejam seus pais biológicos</u>, também devem constar nas informações abaixo solicitadas:</label>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        {dadosFormularioSalvo.familiar.map((familia, index) => (
                                                            <div key={index}>
                                                                <article className='message is-dark'>
                                                                    <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                        <p>{index+1}. {familia.nome} ({familia.parentesco})</p>
                                                                    </div>
                                                                    <div className='message-body has-background-white-ter has-text-black'>
                                                                        <p>
                                                                            CPF: {familia.cpf};
                                                                            RG: {familia.rg}
                                                                        </p>
                                                                        <p>
                                                                            Apelido: {familia.apelido};
                                                                            Idade: {familia.idade};
                                                                            Situação: {familia.situação}
                                                                        </p>
                                                                        <p>
                                                                            DataNascimento: {familia.dataNascimento};
                                                                            Naturalidade: {familia.naturalidade};
                                                                            Estado: {familia.naturalEstado}
                                                                        </p>
                                                                        <p>
                                                                            Endereço: {familia.endereço} {familia.número}
                                                                        </p>
                                                                        <p>
                                                                            Complemento: {familia.complemento};
                                                                            CEP: {familia.cep}
                                                                        </p>
                                                                        <p>
                                                                            Cidade: {familia.cidade};
                                                                            Estado: {familia.estado};
                                                                            Bairro: {familia.bairro}
                                                                        </p>
                                                                        <p>
                                                                            Profissão: {familia.profissão};
                                                                            Local Trabalho: {familia.localTrabalho}
                                                                        </p>
                                                                    </div>
                                                                </article>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="separadoDivorciado">É separado (de fato ou de direito) ou divorciado?</label>
                                                        {dadosFormularioSalvo.separadoDivorciado}
                                                    </div>
                                                </div>
                                                {dadosFormularioSalvo.separadoDivorciado === 'sim' &&
                                                    <div id='separadoDivorciadoDiv' className='box'>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label">Dados do <u>ex-esposo(a)</u> ou do <u>ex-companheiro(a)</u>:</label>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoNome">Nome</label>
                                                                        {dadosFormularioSalvo.separadoDivorciadoNome}
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoCpf">CPF</label>
                                                                        {dadosFormularioSalvo.separadoDivorciadoCpf}
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoRg">RG</label>
                                                                        {dadosFormularioSalvo.separadoDivorciadoRg}
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoLocalTrabalho">Local de Trabalho</label>
                                                                        {dadosFormularioSalvo.separadoDivorciadoLocalTrabalho}
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoApelido">Qualquer outro nome ou apelido</label>
                                                                        {dadosFormularioSalvo.separadoDivorciadoApelido}
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label">Endereço(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                        <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.separadoDivorciadoEndereco.replaceAll('\n', '<br/>'), }} />
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoProfissao">Profissão</label>
                                                                        {dadosFormularioSalvo.separadoDivorciadoProfissao}
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoRelacaoAtual">Qual sua relação atual com ele(a)?</label>
                                                                        {dadosFormularioSalvo.separadoDivorciadoRelacaoAtual}
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoNaturalidade">Natural de</label>
                                                                        {dadosFormularioSalvo.separadoDivorciadoNaturalidade}
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoNaturalEstado">Estado Naturalidade</label>
                                                                        {dadosFormularioSalvo.separadoDivorciadoNaturalEstado}
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoRelacionamentoMesInicio">Período do Relacionamento - De</label>
                                                                        <div className='columns'>
                                                                            <div className='column is-narrow'>
                                                                                {dadosFormularioSalvo.separadoDivorciadoRelacionamentoMesInicio}
                                                                            </div>
                                                                            <div className='column is-narrow'>
                                                                                {dadosFormularioSalvo.separadoDivorciadoRelacionamentoAnoInicio}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoRelacionamentoMesFim">Período do Relacionamento - Até</label>
                                                                        <div className='columns'>
                                                                            <div className='column is-narrow'>
                                                                                {dadosFormularioSalvo.separadoDivorciadoRelacionamentoMesFim}
                                                                            </div>
                                                                            <div className='column is-narrow'>
                                                                                {dadosFormularioSalvo.separadoDivorciadoRelacionamentoAnoFim}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="relacionamento">Você possui <u>namorado(a)</u> ou <u>relacionamento informal</u> com alguma pessoa?</label>
                                                        {dadosFormularioSalvo.relacionamento}
                                                    </div>
                                                </div>
                                                {dadosFormularioSalvo.relacionamento === 'sim' &&
                                                    <div id='relacionamentoDiv' className='box'>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label">Dados do <u>namorado(a)</u> ou <u>relacionamento informal</u>:</label>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="relacionamentoNome">Nome</label>
                                                                        {dadosFormularioSalvo.relacionamentoNome}
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="relacionamentoCpf">CPF</label>
                                                                        {dadosFormularioSalvo.relacionamentoCpf}
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="relacionamentoRg">RG</label>
                                                                        {dadosFormularioSalvo.relacionamentoRg}
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="relacionamentoLocalTrabalho">Local de Trabalho</label>
                                                                        {dadosFormularioSalvo.relacionamentoLocalTrabalho}
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="relacionamentoApelido">Qualquer outro nome ou apelido</label>
                                                                        {dadosFormularioSalvo.relacionamentoApelido}
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label">Endereço Completo(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                        <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.relacionamentoEndereco.replaceAll('\n', '<br/>'), }} />
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="relacionamentoProfissao">Profissão</label>
                                                                        {dadosFormularioSalvo.relacionamentoProfissao}
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="relacionamentoDuracao">Já há quanto tempo dura o relacionamento?</label>
                                                                        {dadosFormularioSalvo.relacionamentoDuracao}
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="relacionamentoNaturalidade">Natural de</label>
                                                                        {dadosFormularioSalvo.relacionamentoNaturalidade}
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="relacionamentoNaturalEstado">Estado Naturalidade</label>
                                                                        {dadosFormularioSalvo.relacionamentoNaturalEstado}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="processoPaternidade">Você já esteve envolvido em algum <u>processo de paternidade</u>?</label>
                                                        {dadosFormularioSalvo.processoPaternidade}
                                                    </div>
                                                </div>
                                                {dadosFormularioSalvo.processoPaternidade === 'sim' &&
                                                    <div id='processoPaternidadeDiv' className='box'>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="detalheProcessoPaternidade">Informações detalhadas do processo de paternidade:</label>
                                                                <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.detalheProcessoPaternidade.replaceAll('\n', '<br/>'), }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="filhoEnteado">Você tem <u>filhos</u> e/ou <u>enteados</u>?</label>
                                                        {dadosFormularioSalvo.filhoEnteado}
                                                    </div>
                                                </div>
                                                {dadosFormularioSalvo.filhoEnteado === 'sim' &&
                                                    <div id='filhoEnteadoDiv' className='box'>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                {dadosFormularioSalvo.filhosEnteados.map((fe, index) => (
                                                                    <div key={index}>
                                                                        <article className='message'>
                                                                            <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                                <p>{index+1}. {fe.nome} ({fe.condição})</p>
                                                                            </div>
                                                                            <div className='message-body has-background-white-ter has-text-black'>
                                                                                <p>
                                                                                    CPF: {fe.cpf}; RG: {fe.rg}; Idade: {fe.idade}
                                                                                </p>
                                                                                <p>
                                                                                    Natural de: {fe.naturalidade}; Estado Naturalidade: {fe.naturalEstado}
                                                                                </p>
                                                                                <p>
                                                                                    Endereço: {fe.endereço} {fe.número}
                                                                                </p>
                                                                                <p>
                                                                                    Cidade: {fe.cidade};
                                                                                    Estado: {fe.estado};
                                                                                    Bairro: {fe.bairro}
                                                                                </p>
                                                                                <p>
                                                                                    Complemento: {fe.complemento};
                                                                                    CEP: {fe.cep}
                                                                                </p>
                                                                                <p>
                                                                                    Reside com: {fe.resideCom};
                                                                                    Apelido: {fe.apelido};
                                                                                    profissão: {fe.profissão}
                                                                                </p>
                                                                                <p>
                                                                                    Trabalha/Estuda: {fe.trabalhoEstudo}
                                                                                </p>
                                                                            </div>
                                                                        </article>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>

                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="sustentoFilhoEnteado">Está provendo o <u>sustento</u> dos seus filhos e/ou enteados?</label>
                                                                {dadosFormularioSalvo.sustentoFilhoEnteado}
                                                            </div>
                                                        </div>
                                                        {dadosFormularioSalvo.sustentoFilhoEnteado === 'não' &&
                                                            <div id='sustentoFilhoEnteadoDiv' className='box'>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="detalheSustentoFilhoEnteado">Detalhe/Motivo de não prover o sustento:</label>
                                                                        <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.detalheSustentoFilhoEnteado.replaceAll('\n', '<br/>'), }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }

                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="casamentoUniaoEstavel">Você é <u>casado</u> ou está em uma <u>união estável</u>?</label>
                                                        {dadosFormularioSalvo.casamentoUniaoEstavel}
                                                    </div>
                                                </div>
                                                {dadosFormularioSalvo.casamentoUniaoEstavel === 'sim' &&
                                                    <div id='casamentoUniaoEstavelDiv' className='box'>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                            <label className="label">Informações do <u>casamento/união estável</u>:</label>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelNome">Nome do cônjuge/companheiro(a)</label>
                                                                        {dadosFormularioSalvo.casamentoUniaoEstavelNome}
                                                                    </div>
                                                                    <div className='column is-narrow'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelIdade">Idade</label>
                                                                        {dadosFormularioSalvo.casamentoUniaoEstavelIdade}
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelNaturalidade">Natural de</label>
                                                                        {dadosFormularioSalvo.casamentoUniaoEstavelNaturalidade}
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelNaturalEstado">Estado Naturalidade</label>
                                                                        {dadosFormularioSalvo.casamentoUniaoEstavelNaturalEstado}
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelCpf">CPF</label>
                                                                        {dadosFormularioSalvo.casamentoUniaoEstavelCpf}
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelRg">RG</label>
                                                                        {dadosFormularioSalvo.casamentoUniaoEstavelRg}
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelApelido">Qualquer outro nome ou apelido</label>
                                                                        {dadosFormularioSalvo.casamentoUniaoEstavelApelido}
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelCondicao">Condição do relacionamento</label>
                                                                        {dadosFormularioSalvo.casamentoUniaoEstavelCondicao}
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelDataCasamentoRelacionamento">Data do casamento/Início do relacionamento</label>
                                                                        {dadosFormularioSalvo.casamentoUniaoEstavelDataCasamentoRelacionamento}
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelLocalCasamento">Local do casamento</label>
                                                                        {dadosFormularioSalvo.casamentoUniaoEstavelLocalCasamento}
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelProfissao">Profissão</label>
                                                                        {dadosFormularioSalvo.casamentoUniaoEstavelProfissao}
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelSalario">Salário (R$)</label>
                                                                        {dadosFormularioSalvo.casamentoUniaoEstavelSalario}
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelEmpresa">Nome da empresa onde cônjuge/companheiro(a) trabalha</label>
                                                                        {dadosFormularioSalvo.casamentoUniaoEstavelEmpresa}
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label">Endereço Completo da EMPRESA(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                        <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.casamentoUniaoEstavelEmpresaEndereco.replaceAll('\n', '<br/>'), }} />
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelFuncao">Função que cônjuge/companheiro(a) exerce</label>
                                                                        {dadosFormularioSalvo.casamentoUniaoEstavelFuncao}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            
                            <article className='message is-link' id='dadosConcursoDiv'>
                                <div className='message-header'>
                                    <div className="tags are-medium has-addons">
                                        PARTE D - INFORMAÇÕES DE ORDEM MÉDICA, SÓCIO-COMPORTAMENTAL E PARTIDÁRIA
                                    </div>
                                </div>
                                <div className='message-body'>
                                    <div>
                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="examinadoTratadoInternado">Você ou alguém de sua família já foi <u>examinado</u>, <u>tratado</u> ou <u>já esteve internado</u> em razão de distúrbios nervosos, mentais ou moléstia prolongada?</label>
                                                {dadosFormularioSalvo.examinadoTratadoInternado}
                                            </div>
                                        </div>
                                        {dadosFormularioSalvo.examinadoTratadoInternado === 'sim' &&
                                            <div id='examinadoTratadoInternadoDiv' className='box'>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheExaminadoTratadoInternado">Qual ou quais foram as moléstias, os locais e períodos de tratamento e/ou internação, bem como a situação atual do citado quadro de saúde:</label>
                                                        <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.detalheExaminadoTratadoInternado.replaceAll('\n', '<br/>'), }} />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="tatuagem">Você <u>possui</u> ou <u>já possuiu tatuagem</u>?</label>
                                                {dadosFormularioSalvo.tatuagem}
                                            </div>
                                        </div>
                                        {dadosFormularioSalvo.tatuagem === 'sim' &&
                                            <div id='tatuagemDiv' className='box'>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheTatuagem">Informações detalhadas, relatando sobre a(s) tatuagem(gens):
                                                        <span style={{ backgroundColor:'#F6F6F6', fontWeight:'normal' }}><br/>a. Quais partes do seu corpo são/eram tatuadas?<br/>b. Quais desenhos, símbolos ou dizeres você possui/possuía tatuado? 
                                                        <br/>c. Qual o significado de suas tatuagens?<br/>d. O que lhe motivou fazer e/ou remover as tatuagens?<br/>e. Em qual estabelecimento você realizou e/ou removeu as tatuagens (nome e localização)?</span></label>
                                                        <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.detalheTatuagem.replaceAll('\n', '<br/>'), }} />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="internadoHospital">Você já foi <u>internado</u> em algum hospital?</label>
                                                {dadosFormularioSalvo.internadoHospital}
                                            </div>
                                        </div>
                                        {dadosFormularioSalvo.internadoHospital === 'sim' &&
                                            <div id='internadoHospitalDiv' className='box'>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheInternadoHospital">Qual foi o motivo (acidentes, doenças, etc.), a data e período de internação, bem como o local (hospital) de internação e/ou tratamento:</label>
                                                        <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.detalheInternadoHospital.replaceAll('\n', '<br/>'), }} />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="desmaiou">Você já <u>desmaiou</u> alguma vez?</label>
                                                {dadosFormularioSalvo.desmaiou}
                                            </div>
                                        </div>
                                        {dadosFormularioSalvo.desmaiou === 'sim' &&
                                            <div id='desmaiouDiv' className='box'>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheDesmaiou">Relate sobre o que aconteceu(desmaio):</label>
                                                        <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.detalheDesmaiou.replaceAll('\n', '<br/>'), }} />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="bebidaAlcoolica">Você já fez ou faz <u>uso de bebidas alcóolicas</u>?</label>
                                                {dadosFormularioSalvo.bebidaAlcoolica}
                                            </div>
                                        </div>
                                        {dadosFormularioSalvo.bebidaAlcoolica === 'sim' &&
                                            <div id='bebidaAlcoolicaDiv' className='box'>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheBebidaAlcoolica">Informações detalhadas, relatando sobre bebidas alcóolicas:<span style={{ backgroundColor:'#F6F6F6', fontWeight:'normal' }}><br/>a. Quais tipos de bebidas alcoólicas você consome?<br/>b. Com que frequência você bebe?<br/>c. Quais locais você costuma beber e com quem?</span></label>
                                                        <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.detalheBebidaAlcoolica.replaceAll('\n', '<br/>'), }} />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="fuma">Você <u>fuma</u> ou já fumou?</label>
                                                {dadosFormularioSalvo.fuma}
                                            </div>
                                        </div>
                                        {dadosFormularioSalvo.fuma === 'sim' &&
                                            <div id='fumaDiv' className='box'>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheFuma">Informações detalhadas, relatando sobre o seu uso de cigarro:</label>
                                                        <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.detalheFuma.replaceAll('\n', '<br/>'), }} />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="entorpecente"><u>Você</u> já fez ou faz uso de substância entorpecente ilícita (<u>drogas</u>) ou já experimentou alguma vez?</label>
                                                {dadosFormularioSalvo.entorpecente}
                                            </div>
                                        </div>
                                        {dadosFormularioSalvo.entorpecente === 'sim' &&
                                            <div id='entorpecenteDiv' className='box'>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEntorpecente">Informações detalhadas, relatando sobre substância entorpecente ilícita:<span style={{ backgroundColor:'#F6F6F6', fontWeight:'normal' }}><br/>a. Qual(is) tipo(s) de droga(s) que você utilizou/utiliza?<br/>b. Já há quanto tempo você faz uso ou por quanto tempo você utilizou droga?<br/>c. Qual(is) a(s) maneiras(s) que você fez/faz uso de droga?<br/>d. Você fazia uso ou somente experimentou drogas?<br/>e. Quando você experimentou ou fez uso de drogas?<br/>f. Qual local (estabelecimento) que você usou droga?<br/>g. Com quem você fez uso de droga? (cite nome, se possível completo, apelido e seu endereço)<br/>h. De quem, onde e quando você adquiriu/comprou a droga?<br/>i. Quanto você pagou pela droga?<br/>j. Quais os efeitos que você sentiu com o uso da droga?</span></label>
                                                        <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.detalheEntorpecente.replaceAll('\n', '<br/>'), }} />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="entorpecenteFamilia"><u>Alguém de sua família</u> (pai, mãe, irmãos e outros parentes com quem você se relaciona com frequência) já fez ou faz uso de substância entorpecente ilícita (<u>drogas</u>)?</label>
                                                {dadosFormularioSalvo.entorpecenteFamilia}
                                            </div>
                                        </div>
                                        {dadosFormularioSalvo.entorpecenteFamilia === 'sim' &&
                                            <div id='entorpecenteFamiliaDiv' className='box'>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEntorpecenteFamilia">Informações detalhadas sobre entorpecente na família:</label>
                                                        <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.detalheEntorpecenteFamilia.replaceAll('\n', '<br/>'), }} />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="entorpecenteRelacao">Você conhece alguém, possui/possuiu algum <u>amigo(a)</u>, ou se relaciona/relacionou afetivamente com alguém que fez ou faz uso de substância entorpecente ilícita (<u>drogas</u>)?</label>
                                                {dadosFormularioSalvo.entorpecenteRelacao}
                                            </div>
                                        </div>
                                        {dadosFormularioSalvo.entorpecenteRelacao === 'sim' &&
                                            <div id='entorpecenteRelacaoDiv' className='box'>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEntorpecenteRelacao">Informações detalhadas sobre entorpecente com amigo(a):<span style={{ backgroundColor:'#F6F6F6', fontWeight:'normal' }}><br/>a. Nome da(s) pessoa(s)<br/>b. Grau de amizade ou de relacionamento com aquela(s) pessoa(s)<br/>c. Período de amizade ou de relacionamento com aquela(s) pessoa(s)<br/>d. Endereço completo de moradia da(s) pessoa(s)<br/>e. Qualquer outro nome e/ou apelido pelo qual a pessoa é ou era conhecida</span></label>
                                                        <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.detalheEntorpecenteRelacao.replaceAll('\n', '<br/>'), }} />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="antecedenteCriminalRelacao">Você conhece alguém, possui/possuiu algum <u>amigo(a)</u>, ou se relaciona/relacionou afetivamente com alguém que possui <u>antecedentes criminais</u>?</label>
                                                {dadosFormularioSalvo.antecedenteCriminalRelacao}
                                            </div>
                                        </div>
                                        {dadosFormularioSalvo.antecedenteCriminalRelacao === 'sim' &&
                                            <div id='antecedenteCriminalRelacaoDiv' className='box'>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheAntecedenteCriminalRelacao">Informações detalhadas sobre antecedente criminal com amigo(a):<span style={{ backgroundColor:'#F6F6F6', fontWeight:'normal' }}><br/>a. Nome da(s) pessoa(s)<br/>b. Grau de amizade ou de relacionamento com aquela(s) pessoa(s)<br/>c. Período de amizade ou de relacionamento com aquela(s) pessoa(s)<br/>d. Endereço completo de moradia da(s) pessoa(s)<br/>e. Qualquer outro nome e/ou apelido pelo qual a pessoa é ou era conhecida<br/>f. Motivos que levaram o referido amigo a ser registrado criminalmente</span></label>
                                                        <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.detalheAntecedenteCriminalRelacao.replaceAll('\n', '<br/>'), }} />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label">Informações sobre 4 (quatro) pessoas, que NÃO sejam parentes ou ex-empregadores, que possam <u>testemunhar</u> a seu favor:</label>
                                                <article className='message is-info' id='testemunhaDiv'>
                                                    <div className='message-header'>
                                                        <div className="tags are-medium has-addons">
                                                            TESTEMUNHA 1
                                                        </div>
                                                    </div>
                                                    <div className='message-body' style={{ backgroundColor:'white' }}>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha1Nome">1. Nome</label>
                                                                {dadosFormularioSalvo.testemunha1Nome}
                                                            </div>
                                                            <div className='column is-narrow'>
                                                                <label className="label" htmlFor="testemunha1Idade">1. Idade</label>
                                                                {dadosFormularioSalvo.testemunha1Idade}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha1Naturalidade">1. Natural de</label>
                                                                {dadosFormularioSalvo.testemunha1Naturalidade}
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha1NaturalEstado">1. Estado Naturalidade</label>
                                                                {dadosFormularioSalvo.testemunha1NaturalEstado}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha1Cpf">1. CPF</label>
                                                                {dadosFormularioSalvo.testemunha1Cpf}
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha1Rg">1. RG</label>
                                                                {dadosFormularioSalvo.testemunha1Rg}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha1TempoConhece">1. Há quanto tempo se conhecem?</label>
                                                                {dadosFormularioSalvo.testemunha1TempoConhece}
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha1Apelido">1. Qualquer outro nome ou apelido</label>
                                                                {dadosFormularioSalvo.testemunha1Apelido}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha1EnderecoResidencial">1. Endereço Completo RESIDENCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.testemunha1EnderecoResidencial.replaceAll('\n', '<br/>'), }} />
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha1Profissao">1. Profissão</label>
                                                                {dadosFormularioSalvo.testemunha1Profissao}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha1EnderecoComercial">1. Endereço Completo COMERCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.testemunha1EnderecoComercial.replaceAll('\n', '<br/>'), }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                                <article className='message is-info' id='dadosPessoaisDiv'>
                                                    <div className='message-header'>
                                                        <div className="tags are-medium has-addons">
                                                            TESTEMUNHA 2
                                                        </div>
                                                    </div>
                                                    <div className='message-body' style={{ backgroundColor:'white' }}>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha2Nome">2. Nome</label>
                                                                {dadosFormularioSalvo.testemunha2Nome}
                                                            </div>
                                                            <div className='column is-narrow'>
                                                                <label className="label" htmlFor="testemunha2Idade">2. Idade</label>
                                                                {dadosFormularioSalvo.testemunha2Idade}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha2Naturalidade">2. Natural de</label>
                                                                {dadosFormularioSalvo.testemunha2Naturalidade}
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha2NaturalEstado">2. Estado Naturalidade</label>
                                                                {dadosFormularioSalvo.testemunha2NaturalEstado}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha2Cpf">2. CPF</label>
                                                                {dadosFormularioSalvo.testemunha2Cpf}
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha2Rg">2. RG</label>
                                                                {dadosFormularioSalvo.testemunha2Rg}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha2TempoConhece">2. Há quanto tempo se conhecem?</label>
                                                                {dadosFormularioSalvo.testemunha2TempoConhece}
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha2Apelido">2. Qualquer outro nome ou apelido</label>
                                                                {dadosFormularioSalvo.testemunha2Apelido}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha2EnderecoResidencial">2. Endereço Completo RESIDENCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.testemunha2EnderecoResidencial.replaceAll('\n', '<br/>'), }} />
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha2Profissao">2. Profissão</label>
                                                                {dadosFormularioSalvo.testemunha2Profissao}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha2EnderecoComercial">2. Endereço Completo COMERCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.testemunha2EnderecoComercial.replaceAll('\n', '<br/>'), }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                                <article className='message is-info' id='dadosPessoaisDiv'>
                                                    <div className='message-header'>
                                                        <div className="tags are-medium has-addons">
                                                            TESTEMUNHA 3
                                                        </div>
                                                    </div>
                                                    <div className='message-body' style={{ backgroundColor:'white' }}>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha3Nome">3. Nome</label>
                                                                {dadosFormularioSalvo.testemunha3Nome}
                                                            </div>
                                                            <div className='column is-narrow'>
                                                                <label className="label" htmlFor="testemunha3Idade">3. Idade</label>
                                                                {dadosFormularioSalvo.testemunha3Idade}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha3Naturalidade">3. Natural de</label>
                                                                {dadosFormularioSalvo.testemunha3Naturalidade}
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha3NaturalEstado">3. Estado Naturalidade</label>
                                                                {dadosFormularioSalvo.testemunha3NaturalEstado}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha3Cpf">3. CPF</label>
                                                                {dadosFormularioSalvo.testemunha3Cpf}
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha3Rg">3. RG</label>
                                                                {dadosFormularioSalvo.testemunha3Rg}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha3TempoConhece">3. Há quanto tempo se conhecem?</label>
                                                                {dadosFormularioSalvo.testemunha3TempoConhece}
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha3Apelido">3. Qualquer outro nome ou apelido</label>
                                                                {dadosFormularioSalvo.testemunha3Apelido}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha3EnderecoResidencial">3. Endereço Completo RESIDENCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.testemunha3EnderecoResidencial.replaceAll('\n', '<br/>'), }} />
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha3Profissao">3. Profissão</label>
                                                                {dadosFormularioSalvo.testemunha3Profissao}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha3EnderecoComercial">3. Endereço Completo COMERCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.testemunha3EnderecoComercial.replaceAll('\n', '<br/>'), }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                                <article className='message is-info' id='dadosPessoaisDiv'>
                                                    <div className='message-header'>
                                                        <div className="tags are-medium has-addons">
                                                            TESTEMUNHA 4
                                                        </div>
                                                    </div>
                                                    <div className='message-body' style={{ backgroundColor:'white' }}>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha4Nome">4. Nome</label>
                                                                {dadosFormularioSalvo.testemunha4Nome}
                                                            </div>
                                                            <div className='column is-narrow'>
                                                                <label className="label" htmlFor="testemunha4Idade">4. Idade</label>
                                                                {dadosFormularioSalvo.testemunha4Idade}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha4Naturalidade">4. Natural de</label>
                                                                {dadosFormularioSalvo.testemunha4Naturalidade}
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha4NaturalEstado">4. Estado Naturalidade</label>
                                                                {dadosFormularioSalvo.testemunha4NaturalEstado}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha4Cpf">4. CPF</label>
                                                                {dadosFormularioSalvo.testemunha4Cpf}
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha4Rg">4. RG</label>
                                                                {dadosFormularioSalvo.testemunha4Rg}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha4TempoConhece">4. Há quanto tempo se conhecem?</label>
                                                                {dadosFormularioSalvo.testemunha4TempoConhece}
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha4Apelido">4. Qualquer outro nome ou apelido</label>
                                                                {dadosFormularioSalvo.testemunha4Apelido}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha4EnderecoResidencial">4. Endereço Completo RESIDENCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.testemunha4EnderecoResidencial.replaceAll('\n', '<br/>'), }} />
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha4Profissao">4. Profissão</label>
                                                                {dadosFormularioSalvo.testemunha4Profissao}
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="testemunha4EnderecoComercial">4. Endereço Completo COMERCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.testemunha4EnderecoComercial.replaceAll('\n', '<br/>'), }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                        
                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="faPmGmPcPtcConhecido">Você possui ou já possuiu <u>parentes</u> e/ou <u>amigos pessoais</u> nas Forças Armadas, Polícia Militar, Guarda Municipal, Polícia Civil ou Polícia Técnico-Científica?</label>
                                                {dadosFormularioSalvo.faPmGmPcPtcConhecido}
                                            </div>
                                        </div>
                                        {dadosFormularioSalvo.faPmGmPcPtcConhecido === 'sim' &&
                                            <div id='faPmGmPcPtcConhecidoDiv' className='box'>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                {dadosFormularioSalvo.faPmGmPcPtc.map((parenteAmigo, index) => (
                                                                    <div key={index}>
                                                                        <article className='message is-dark'>
                                                                            <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                                <p>{index+1}. {parenteAmigo.nome} ({parenteAmigo.grauParentesco})</p>
                                                                            </div>
                                                                            <div className='message-body has-background-white-ter has-text-black'>
                                                                                <p>
                                                                                    Situação: {parenteAmigo.situação};
                                                                                    Tempo que se conhecem: {parenteAmigo.tempoConhece}
                                                                                </p>
                                                                                <p>
                                                                                    CPF: {parenteAmigo.cpf};
                                                                                    RG: {parenteAmigo.rg}
                                                                                </p>
                                                                                <p>
                                                                                    Instituição: {parenteAmigo.instituição}
                                                                                </p>
                                                                                <p>
                                                                                    Cargo/Posto/Graduação: {parenteAmigo.cargoPostoGraduação}
                                                                                </p>
                                                                                <p>
                                                                                    Unidade: {parenteAmigo.unidade}
                                                                                </p>
                                                                                <p>
                                                                                    Função: {parenteAmigo.função}
                                                                                </p>
                                                                            </div>
                                                                        </article>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="folga">O que você costuma fazer ou gosta de estar fazendo em suas <u>horas de folga</u>?</label>
                                                <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.folga.replaceAll('\n', '<br/>'), }} />
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label">Quais são seus <u>costumes</u> e <u>locais</u> que por hábito tem frequentado?</label>
                                                <div className='box'>
                                                    <label className="label" htmlFor="costumeLocalDiaSemana">a. Durante a semana (de segunda-feira a sexta-feira)</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.costumeLocalDiaSemana.replaceAll('\n', '<br/>'), }} />
                                                    <label className="label" htmlFor="costumeLocalFdsFeriado">b. Durante o final de semana e feriados</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.costumeLocalFdsFeriado.replaceAll('\n', '<br/>'), }} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="socioClube">Você é ou já foi sócio de algum <u>clube/associação desportiva</u>?</label>
                                                {dadosFormularioSalvo.socioClube}
                                            </div>
                                        </div>
                                        {dadosFormularioSalvo.socioClube === 'sim' &&
                                            <div id='socioClubeDiv' className='box'>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                {dadosFormularioSalvo.socioClubes.map((sc, index) => (
                                                                    <div key={index}>
                                                                        <article className='message'>
                                                                            <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                                <p>{index+1}. {sc.nome} ({sc.mêsInicial}/{sc.anoInicial} ~ {sc.mêsFinal}/{sc.anoFinal})</p>
                                                                            </div>
                                                                            <div className='message-body has-background-white-ter has-text-black'>
                                                                                <p>
                                                                                    Endereço: {sc.endereço} {sc.número}
                                                                                </p>
                                                                                <p>
                                                                                    Complemento: {sc.complemento};
                                                                                    CEP: {sc.cep}
                                                                                </p>
                                                                                <p>
                                                                                    Bairro: {sc.bairro};
                                                                                    Cidade: {sc.cidade};
                                                                                    Estado: {sc.estado}
                                                                                </p>
                                                                                <p>
                                                                                    Ponto de Referência: {sc.pontoReferência}
                                                                                </p>
                                                                                <p>
                                                                                    Atividade(s): {sc.atividade}
                                                                                </p>
                                                                                <p>
                                                                                    Notificação/Punição: {sc.notificaçãoPunição}
                                                                                </p>
                                                                                {sc.notificaçãoPunição === 'sim' &&
                                                                                    <div dangerouslySetInnerHTML={{ __html: sc.detalheNotificaçãoPunição.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                                }
                                                                            </div>
                                                                        </article>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="sindicato">Você pertence ou já pertenceu a qualquer <u>sindicato</u> ou outra <u>associação de classe</u>?</label>
                                                {dadosFormularioSalvo.sindicato}
                                            </div>
                                        </div>
                                        {dadosFormularioSalvo.sindicato === 'sim' &&
                                            <div id='sindicatoDiv' className='box'>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                {dadosFormularioSalvo.sindicatos.map((sind, index) => (
                                                                    <div key={index}>
                                                                        <article className='message'>
                                                                            <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                                <p>{index+1}. {sind.nome} ({sind.mêsInicial}/{sind.anoInicial} ~ {sind.mêsFinal}/{sind.anoFinal})</p>
                                                                            </div>
                                                                            <div className='message-body has-background-white-ter has-text-black'>
                                                                                <p>
                                                                                    Endereço: {sind.endereço} {sind.número}
                                                                                </p>
                                                                                <p>
                                                                                    Complemento: {sind.complemento};
                                                                                    CEP: {sind.cep}
                                                                                </p>
                                                                                <p>
                                                                                    Bairro: {sind.bairro};
                                                                                    Cidade: {sind.cidade};
                                                                                    Estado: {sind.estado}
                                                                                </p>
                                                                                <p>
                                                                                    Ponto de Referência: {sind.pontoReferência}
                                                                                </p>
                                                                                <p>
                                                                                    Notificação/Punição: {sind.notificaçãoPunição}
                                                                                </p>
                                                                                {sind.notificaçãoPunição === 'sim' &&
                                                                                    <div dangerouslySetInnerHTML={{ __html: sind.detalheNotificaçãoPunição.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                                }
                                                                            </div>
                                                                        </article>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="partidoPolitico">Você é ou já foi filiado a algum <u>partido político</u>?</label>
                                                {dadosFormularioSalvo.partidoPolitico}
                                            </div>
                                        </div>
                                        {dadosFormularioSalvo.partidoPolitico === 'sim' &&
                                            <div id='partidoPoliticoDiv' className='box'>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                {dadosFormularioSalvo.partidosPoliticos.map((pp, index) => (
                                                                    <div key={index}>
                                                                        <article className='message'>
                                                                            <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                                <p>{index+1}. {pp.nome} ({pp.mêsInicial}/{pp.anoInicial} ~ {pp.mêsFinal}/{pp.anoFinal})</p>
                                                                            </div>
                                                                            <div className='message-body has-background-white-ter has-text-black'>
                                                                                <p>
                                                                                    Endereço: {pp.endereço} {pp.número}
                                                                                </p>
                                                                                <p>
                                                                                    Complemento: {pp.complemento};
                                                                                    CEP: {pp.cep}
                                                                                </p>
                                                                                <p>
                                                                                    Bairro: {pp.bairro};
                                                                                    Cidade: {pp.cidade};
                                                                                    Estado: {pp.estado}
                                                                                </p>
                                                                                <p>
                                                                                    Ponto de Referência: {pp.pontoReferência}
                                                                                </p>
                                                                                <p>
                                                                                    Filiação/Desfiliação: {pp.filiaçãoDesfiliação}
                                                                                </p>
                                                                                <p>
                                                                                    Notificação/Punição: {pp.notificaçãoPunição}
                                                                                </p>
                                                                                {pp.notificaçãoPunição === 'sim' &&
                                                                                    <div dangerouslySetInnerHTML={{ __html: pp.detalheNotificaçãoPunição.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                                }
                                                                            </div>
                                                                        </article>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </article>
                            
                            <article className='message is-link' id='dadosConcursoDiv'>
                                <div className='message-header'>
                                    <div className="tags are-medium has-addons">
                                        PARTE E - INFORMAÇÕES JUDICIAIS E POLICIAIS
                                    </div>
                                </div>
                                <div className='message-body'>
                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="processoJustica">Você já fez ou faz parte de algum <u>Processo na Justiça</u>, seja na condição de autor, indiciado, réu, testemunha ou até mesmo como vítima?</label>
                                            {dadosFormularioSalvo.processoJustica}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.processoJustica === 'sim' &&
                                        <div id='processoJusticaDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Forneça informações detalhadas:</label>
                                                    <div className="controls">
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>a. Justiça Civil?</td>
                                                                            <td style={{ paddingLeft:'5px', paddingRight:'15px' }}>
                                                                                <div className="controls">
                                                                                    {dadosFormularioSalvo.processoJusticaJC}
                                                                                </div>
                                                                            </td>
                                                                            <td>d. Justiça Militar?</td>
                                                                            <td style={{ paddingLeft:'5px', paddingRight:'15px' }}>
                                                                                <div className="controls">
                                                                                    {dadosFormularioSalvo.processoJusticaJM}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>b. Justiça Trabalhista?</td>
                                                                            <td style={{ paddingLeft:'5px', paddingRight:'15px' }}>
                                                                                <div className="controls">
                                                                                    {dadosFormularioSalvo.processoJusticaJT}
                                                                                </div>
                                                                            </td>
                                                                            <td>e. Vara da Infância e Juventude?</td>
                                                                            <td style={{ paddingLeft:'5px', paddingRight:'15px' }}>
                                                                                <div className="controls">
                                                                                    {dadosFormularioSalvo.processoJusticaVIJ}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>c. Justiça Criminal?</td>
                                                                            <td style={{ paddingLeft:'5px', paddingRight:'15px' }}>
                                                                                <div className="controls">
                                                                                    {dadosFormularioSalvo.processoJusticaJCR}
                                                                                </div>
                                                                            </td>
                                                                            <td>f. Juizado Especial Criminal?</td>
                                                                            <td style={{ paddingLeft:'5px', paddingRight:'15px' }}>
                                                                                <div className="controls">
                                                                                    {dadosFormularioSalvo.processoJusticaJEC}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        {dadosFormularioSalvo.processosJustica.map((pj, index) => (
                                                                            <div key={index}>
                                                                                <article className='message is-dark'>
                                                                                    <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                                        <p>{index+1}. [{pj.númeroAno}] {pj.tipo} ({pj.condição})</p>
                                                                                    </div>
                                                                                    <div className='message-body has-background-white-ter has-text-black'>
                                                                                        <p>
                                                                                            Data do Fato: {pj.dataFato}
                                                                                        </p>
                                                                                        <p>
                                                                                            Bairro: {pj.bairro};
                                                                                            Cidade: {pj.cidade};
                                                                                            Estado: {pj.estado}
                                                                                        </p>
                                                                                        <p>
                                                                                            Fórum: {pj.fórum}
                                                                                        </p>
                                                                                        <p>
                                                                                            Vara: {pj.vara}
                                                                                        </p>
                                                                                        <p>
                                                                                            Solução/Pena: {pj.soluçãoPena}
                                                                                        </p>
                                                                                        Detalhe do fato ocorrido:
                                                                                            <div dangerouslySetInnerHTML={{ __html: pj.detalheFatoOcorrido.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                                    </div>
                                                                                </article>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="ocorrenciaPolicial">Você já esteve envolvido em <u>ocorrência policial</u>? (Informe qualquer tipo de ocorrência, seja quando você era criança, adolescente ou adulto, seja na condição de <u>autor</u>, <u>indiciado</u>, <u>réu</u>, <u>testemunha</u> ou até mesmo como <u>vítima</u>)</label>
                                            {dadosFormularioSalvo.ocorrenciaPolicial}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.ocorrenciaPolicial === 'sim' &&
                                        <div id='ocorrenciaPolicialDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            {dadosFormularioSalvo.ocorrenciasPoliciais.map((op, index) => (
                                                                <div key={index}>
                                                                    <article className='message is-dark'>
                                                                        <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                            <p>{index+1}. {op.rpBo} ({op.condição})</p>
                                                                        </div>
                                                                        <div className='message-body has-background-white-ter has-text-black'>
                                                                            <p>
                                                                                Data do Fato: {op.dataFato}
                                                                            </p>
                                                                            <p>
                                                                                Registro Policial/Boletim Ocorrência: {op.rpBo}
                                                                            </p>
                                                                            <p>
                                                                                Delegacia/Quartel: {op.delegaciaQuartel}
                                                                            </p>
                                                                            <p>
                                                                                Solução/Pena: {op.soluçãoPena}
                                                                            </p>
                                                                            Detalhe do fato ocorrido:
                                                                                <div dangerouslySetInnerHTML={{ __html: op.detalheFatoOcorrido.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                        </div>
                                                                    </article>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="ipSindIpm">Você já esteve envolvido em ou <u>Investigação Preliminar (IP)</u>, <u>Sindicância</u> ou <u>Inquérito Policial Militar (IPM)</u>?</label>
                                            {dadosFormularioSalvo.ipSindIpm}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.ipSindIpm === 'sim' &&
                                        <div id='ipSindIpmDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            {dadosFormularioSalvo.listaIpSindIpm.map((isi, index) => (
                                                                <div key={index}>
                                                                    <article className='message is-dark'>
                                                                        <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                            <p>{index+1}. {isi.nrProcedimento} ({isi.condição})</p>
                                                                        </div>
                                                                        <div className='message-body has-background-white-ter has-text-black'>
                                                                            <p>
                                                                                Data do Fato: {isi.dataFato}
                                                                            </p>
                                                                            <p>
                                                                                Solução: {isi.solução}
                                                                            </p>
                                                                            Detalhe do fato ocorrido:
                                                                                <div dangerouslySetInnerHTML={{ __html: isi.detalheFatoOcorrido.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                        </div>
                                                                    </article>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="padCdCj">Você já esteve alguma vez envolvido em <u>Processo Administrativo Disciplinar (PAD)</u>, <u>Conselho de Disciplina (CD)</u> ou <u>Conselho de Justificação (CJ)</u>?</label>
                                            {dadosFormularioSalvo.padCdCj}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.padCdCj === 'sim' &&
                                        <div id='padCdCjDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            {dadosFormularioSalvo.listaPadCdCj.map((pcc, index) => (
                                                                <div key={index}>
                                                                    <article className='message is-dark'>
                                                                        <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                            <p>{index+1}. {pcc.nrProcedimento} ({pcc.condição})</p>
                                                                        </div>
                                                                        <div className='message-body has-background-white-ter has-text-black'>
                                                                            <p>
                                                                                Data do Fato: {pcc.dataFato}
                                                                            </p>
                                                                            <p>
                                                                                Solução: {pcc.solução}
                                                                            </p>
                                                                            Detalhe do fato ocorrido:
                                                                                <div dangerouslySetInnerHTML={{ __html: pcc.detalheFatoOcorrido.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                        </div>
                                                                    </article>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="familiaOpPj">Algum <u>membro de sua família</u> já esteve ou está envolvido em ocorrência policial ou com algum Processo na Justiça?</label>
                                            {dadosFormularioSalvo.familiaOpPj}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.familiaOpPj === 'sim' &&
                                        <div id='familiaOpPjDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            {dadosFormularioSalvo.familiasOpPj.map((familiar, index) => (
                                                                <div key={index}>
                                                                    <article className='message is-dark'>
                                                                        <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                            <p>{index+1}. {familiar.nome} ({familiar.condição})</p>
                                                                        </div>
                                                                        <div className='message-body has-background-white-ter has-text-black'>
                                                                            <p>
                                                                                Grau de Parentesco: {familiar.grauParentesco}
                                                                            </p>
                                                                            <p>
                                                                                Data do Fato: {familiar.dataFato};
                                                                                Tipo do Processo: {familiar.tipoProcesso}
                                                                            </p>
                                                                            <p>
                                                                                CPF: {familiar.cpf};
                                                                                RG: {familiar.rg}
                                                                            </p>
                                                                            <p>
                                                                                Endereço do familiar: {familiar.endereço} {familiar.número}
                                                                            </p>
                                                                            <p>
                                                                                Bairro: {familiar.bairro};
                                                                                Cidade: {familiar.cidade};
                                                                                Estado: {familiar.estado}
                                                                            </p>
                                                                            <p>
                                                                                Solução/Pena: {familiar.soluçãoPena}
                                                                            </p>
                                                                            Fato ocorrido:
                                                                                <div dangerouslySetInnerHTML={{ __html: familiar.fatoOcorrido.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                        </div>
                                                                    </article>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="armaDeFogo">Você possui ou já possuiu <u>arma de fogo</u>?</label>
                                            {dadosFormularioSalvo.armaDeFogo}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.armaDeFogo === 'sim' &&
                                        <div id='armaDeFogoDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="detalheArmaDeFogo">Informações detalhadas sobre arma de fogo:
                                                    <span style={{ backgroundColor:'#F6F6F6', fontWeight:'normal' }}><br/>a. Espécie do armamento<br/>b. Marca, modelo e calibre da arma<br/>c. Número do armamento<br/>d. Número do registro e data de validade<br/>e. Número do documento de porte e validade<br/>f. De quem você adquiriu a arma ? De um parente, amigo ou conhecido?<br/>g. Nome<br/>h. Número do CPF<br/>i. Número do RG<br/>j. Endereço (Rua, número, bairro, cidade, estado)</span></label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.detalheArmaDeFogo.replaceAll('\n', '<br/>'), }} />
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="armaDeFogoApreendida">Você já teve <u>arma apreendida</u>?</label>
                                                    {dadosFormularioSalvo.armaDeFogoApreendida}
                                                </div>
                                            </div>
                                            <div id='armaDeFogoApreendidaDiv' className='box'>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheArmaDeFogoApreendida">Informações detalhadas sobre arma apreendida:</label>
                                                        <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.detalheArmaDeFogoApreendida.replaceAll('\n', '<br/>'), }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </article>
                            
                            <article className='message is-link' id='dadosConcursoDiv'>
                                <div className='message-header'>
                                    <div className="tags are-medium has-addons">
                                        PARTE F - INFORMAÇÕES PROFISSIONAIS
                                    </div>
                                </div>
                                <div className='message-body'>
                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label">Relacione, a partir das datas mais antigas, todos os <u>lugares em que você esteve empregado, até o atual</u>, registrado ou não, bem como os por conta própria e serviços informais ("bicos"). NÃO DEIXE DE DECLARAR NENHUM EMPREGO. Informe os endereços por completo. Informe também os períodos (datas) de inatividade entre cada emprego. <u>ATENÇÃO</u>: Caso você tenha trabalhado em empresa terceirizada, informe o <u>endereço do escritório</u> da empresa e também o <u>endereço do local onde você prestou serviços</u> para a empresa que o contratou:</label>
                                            <div className='columns'>
                                                <div className='column'>
                                                    {dadosFormularioSalvo.detalheEmprego.map((emprego, index) => (
                                                        <div key={index}>
                                                            <article className='message'>
                                                                <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                    <p>{index+1}. {emprego.empresa} ({emprego.mêsInicial}/{emprego.anoInicial} ~ {emprego.mêsFinal}/{emprego.anoFinal})</p>
                                                                </div>
                                                                <div className='message-body has-background-white-ter has-text-black'>
                                                                    <p>
                                                                        CNPJ: {emprego.cnpj} (Sede: {emprego.sede})
                                                                    </p>
                                                                    <p>
                                                                        Endereço: {emprego.endereço}
                                                                    </p>
                                                                    <p>
                                                                        Bairro: {emprego.bairro}; Cidade: {emprego.cidade}
                                                                    </p>
                                                                    <p>
                                                                        Estado: {emprego.estado}; CEP: {emprego.cep}
                                                                    </p>
                                                                    <p>
                                                                        Batalhão PM: {emprego.batalhaoPm}
                                                                    </p>
                                                                    <p>
                                                                        Função: {emprego.função}; Salário: {emprego.salário}
                                                                    </p>
                                                                    <p>
                                                                        Departamento: {emprego.departamento}; Chefe: {emprego.chefe}
                                                                    </p>
                                                                    <p>
                                                                        Punição: {emprego.puniçãoSofrida}
                                                                    </p>
                                                                    <p>
                                                                        Motivo Demissão: {emprego.demissão}
                                                                    </p>
                                                                    <p>
                                                                        Inatividade: ({emprego.inatividadeMêsInicial}/{emprego.inatividadeAnoInicial} ~ {emprego.inatividadeMêsFinal}/{emprego.inatividadeAnoFinal})
                                                                    </p>
                                                                    <p>
                                                                        fezDuranteDesemprego: {emprego.fezDuranteDesemprego}
                                                                    </p>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="tentouIngressarPm">Você procurou <u>anteriormente ingressar</u> na Polícia Militar?</label>
                                            {dadosFormularioSalvo.tentouIngressarPm}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.tentouIngressarPm === 'sim' &&
                                        <div id='tentouIngressarPmDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="tentouIngressarPmQuantidade">a. Quantas vezes tentou ingressar e quando</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.tentouIngressarPmQuantidade.replaceAll('\n', '<br/>'), }} />

                                                    <label className="label" htmlFor="tentouIngressarPmReprovou">b. Qual(is) a(s) Etapa(s) em que você foi reprovado e o motivo da reprovação</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.tentouIngressarPmReprovou.replaceAll('\n', '<br/>'), }} />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="tentouIngressarOutroEmprego">Você procurou anteriormente <u>ingressar em algum outro emprego</u> público?</label>
                                            {dadosFormularioSalvo.tentouIngressarOutroEmprego}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.tentouIngressarOutroEmprego === 'sim' &&
                                        <div id='tentouIngressarOutroEmpregoDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="tentouIngressarOutroEmpregoA">a. Qual foi a Instituição e o cargo pretendido?</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.tentouIngressarOutroEmpregoA.replaceAll('\n', '<br/>'), }} />

                                                    <label className="label" htmlFor="tentouIngressarOutroEmpregoB">b. Quantas vezes você tentou ingressar?</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.tentouIngressarOutroEmpregoB.replaceAll('\n', '<br/>'), }} />

                                                    <label className="label" htmlFor="tentouIngressarOutroEmpregoC">c. Qual foi o ano e o local do concurso (cidade/Estado)?</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.tentouIngressarOutroEmpregoC.replaceAll('\n', '<br/>'), }} />

                                                    <label className="label" htmlFor="tentouIngressarOutroEmpregoD">d. Qual foi o resultado que você obteve nos exames prestados?</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.tentouIngressarOutroEmpregoD.replaceAll('\n', '<br/>'), }} />

                                                    <label className="label" htmlFor="tentouIngressarOutroEmpregoE">e. Se reprovado, qual foi o motivo e a etapa do concurso em que você foi reprovado?</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.tentouIngressarOutroEmpregoE.replaceAll('\n', '<br/>'), }} />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="servicoMilitar">Você serviu o <u>Serviço Militar Obrigatório</u>?</label>
                                            {dadosFormularioSalvo.servicoMilitar}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.servicoMilitar === 'sim' &&
                                        <div id='servicoMilitarDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label">Informações a respeito de seu Serviço Militar Obrigatório:</label>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="servicoMilitarUnidade">Unidade em que você serviu</label>
                                                            {dadosFormularioSalvo.servicoMilitarUnidade}
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="servicoMilitarCompanhia">Companhia</label>
                                                            {dadosFormularioSalvo.servicoMilitarCompanhia}
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="servicoMilitarPelotao">Pelotão</label>
                                                            {dadosFormularioSalvo.servicoMilitarPelotao}
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="servicoMilitarMesInicial">Período em que serviu - De</label>
                                                            <div className='columns'>
                                                                <div className='column is-narrow'>
                                                                    {dadosFormularioSalvo.servicoMilitarMesInicial}
                                                                </div>
                                                                <div className='column is-narrow'>
                                                                    {dadosFormularioSalvo.servicoMilitarAnoInicial}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="servicoMilitarMesFinal">Período em que serviu - Até</label>
                                                            <div className='columns'>
                                                                <div className='column is-narrow'>
                                                                    {dadosFormularioSalvo.servicoMilitarMesFinal}
                                                                </div>
                                                                <div className='column is-narrow'>
                                                                    {dadosFormularioSalvo.servicoMilitarAnoFinal}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="servicoMilitarEndereco">Endereço(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                            <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.servicoMilitarEndereco.replaceAll('\n', '<br/>'), }} />
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="servicoMilitarPontoReferencia">Ponto de Referência (Batalhão PM da área)</label>
                                                            {dadosFormularioSalvo.servicoMilitarPontoReferencia}
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="servicoMilitarFuncao">Função que desempenhava</label>
                                                            {dadosFormularioSalvo.servicoMilitarFuncao}
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="servicoMilitarPunicao">Relacione TODAS as punições sofridas e os seus motivos, inclusive aquelas punições que são do seu conhecimento e que por ventura, <u>talvez não constem registradas nos seus assentamentos de registro militar</u></label>
                                                            <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.servicoMilitarPunicao.replaceAll('\n', '<br/>'), }} />
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="servicoMilitarMotivoBaixa">Qual foi o motivo da baixa?</label>
                                                            {dadosFormularioSalvo.servicoMilitarMotivoBaixa}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </article>
                            
                            <article className='message is-link' id='dadosConcursoDiv'>
                                <div className='message-header'>
                                    <div className="tags are-medium has-addons">
                                        PARTE G - INFORMAÇÕES ESCOLARES
                                    </div>
                                </div>
                                <div className='message-body'>
                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label">Estabelecimentos de ensino onde você cursou os ensinos fundamental, médio, técnico, superior e de pós-graduação (se houver):</label>
                                            <div className='columns'>
                                                <div className='column'>
                                                    {dadosFormularioSalvo.escolas.map((escola, index) => (
                                                        <div key={index}>
                                                            <article className='message'>
                                                                <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                    <p>{index+1}. {escola.curso} ({escola.mêsInicial}/{escola.anoInicial} ~ {escola.mêsFinal}/{escola.anoFinal})</p>
                                                                </div>
                                                                <div className='message-body has-background-white-ter has-text-black'>
                                                                    <p>
                                                                        Escola: {escola.nome}
                                                                    </p>
                                                                    <p>
                                                                        Séries/Semestres que você cursou: {escola.cursou}; 
                                                                        Tipo: {escola.tipo}
                                                                    </p>
                                                                    <p>
                                                                        Razão EAD: {escola.razãoEad}
                                                                    </p>
                                                                    <p>
                                                                        Endereço: {escola.endereço} {escola.número}
                                                                    </p>
                                                                    <p>
                                                                        Complemento: {escola.complemento};
                                                                        CEP: {escola.cep}
                                                                    </p>
                                                                    <p>
                                                                        Cidade: {escola.cidade};
                                                                        Estado: {escola.estado};
                                                                        Bairro: {escola.bairro}
                                                                    </p>
                                                                    <p>
                                                                        Ponto de Referência: {escola.pontoReferência}
                                                                    </p>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="punicaoEscolar">Você sofreu alguma <u>punição escolar</u> (advertência, suspensão, expulsão etc) em qualquer estabelecimento de ensino?</label>
                                            {dadosFormularioSalvo.punicaoEscolar}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.punicaoEscolar === 'sim' &&
                                        <div id='punicaoEscolarDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="detalhePunicaoEscolar">Detalhes: A data da punição, a sanção aplicada e o fato que causou a punição escolar:</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.detalhePunicaoEscolar.replaceAll('\n', '<br/>'), }} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </article>
                            
                            <article className='message is-link' id='dadosConcursoDiv'>
                                <div className='message-header'>
                                    <div className="tags are-medium has-addons">
                                        PARTE H - INFORMAÇÕES ECONÔMICAS
                                    </div>
                                </div>
                                <div className='message-body'>
                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="protecaoCredito">Está com o nome registrado em algum órgão ou entidade de <u>controle e proteção ao crédito</u> financeiro (SCPC etc)? Possui <u>cheques devolvidos</u>, títulos protestados, prestações ou dívidas em atraso?</label>
                                            {dadosFormularioSalvo.protecaoCredito}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.protecaoCredito === 'sim' &&
                                        <div id='protecaoCreditoDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="protecaoCreditoDivida">a. Valor da(s) dívida(s)</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.protecaoCreditoDivida.replaceAll('\n', '<br/>'), }} />
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="protecaoCreditoDataCompra">b. Data em que as compras foram realizadas</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.protecaoCreditoDataCompra.replaceAll('\n', '<br/>'), }} />
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="protecaoCreditoDataDeixouPagarDivida">c. Data em que você deixou de pagar a(s) dívida(s)</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.protecaoCreditoDataDeixouPagarDivida.replaceAll('\n', '<br/>'), }} />
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="protecaoCreditoInstituicaoCredora">d. Nome da(s) Instituição(ões) credora(s) da(s) sua(s) dívida(s)</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.protecaoCreditoInstituicaoCredora.replaceAll('\n', '<br/>'), }} />
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="protecaoCreditoMotivoDivida">e. MOTIVOS pelos quais você não pagou a(s) dívida(s), tornando-o inadimplente</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.protecaoCreditoMotivoDivida.replaceAll('\n', '<br/>'), }} />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="propriedadeImovel">Você possui ou já possuiu em seu nome, a propriedade de algum bem <u>imóvel</u>?</label>
                                            {dadosFormularioSalvo.propriedadeImovel}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.propriedadeImovel === 'sim' &&
                                        <div id='propriedadeImovelDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="propriedadeImovelTipo">a. Qual tipo de imóvel</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.propriedadeImovelTipo.replaceAll('\n', '<br/>'), }} />
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="propriedadeImovelTamanho">b. Qual tamanho do imóvel (metragem geral)</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.propriedadeImovelTamanho.replaceAll('\n', '<br/>'), }} />
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="propriedadeImovelValorAvaliado">c. Qual valor de mercado em que está avaliado o imóvel</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.propriedadeImovelValorAvaliado.replaceAll('\n', '<br/>'), }} />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="atividadeEmpresarial">Você possui ou já possuiu em seu nome, alguma <u>atividade empresarial</u>, seja na condição de <u>proprietário ou sócio</u>?</label>
                                            {dadosFormularioSalvo.atividadeEmpresarial}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.atividadeEmpresarial === 'sim' &&
                                        <div id='atividadeEmpresarialDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="atividadeEmpresarialTipo">a. Qual tipo de atividade empresarial realizada</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.atividadeEmpresarialTipo.replaceAll('\n', '<br/>'), }} />
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="atividadeEmpresarialNivelParticipacao">b. Qual nível/grau de sua participação na constituição da empresa</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.atividadeEmpresarialNivelParticipacao.replaceAll('\n', '<br/>'), }} />
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="atividadeEmpresarialEmpresa">c. Informe os dados de identificação da empresa (Nome, CNPJ, endereço, sócios, data do contrato etc)</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.atividadeEmpresarialEmpresa.replaceAll('\n', '<br/>'), }} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </article>
                            
                            <article className='message is-link' id='dadosConcursoDiv'>
                                <div className='message-header'>
                                    <div className="tags are-medium has-addons">
                                        PARTE I - INFORMAÇÕES DE TRÂNSITO
                                    </div>
                                </div>
                                <div className='message-body'>
                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="permissaoDirigir">Você possui <u>Carteira Nacional de Habilitação (CNH)</u> ou <u>Permissão Para Dirigir (PPD)</u>?</label>
                                            {dadosFormularioSalvo.permissaoDirigir}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.permissaoDirigir === 'sim' &&
                                        <div id='permissaoDirigirDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="cnh">Nº da CNH ou da PPD</label>
                                                    {dadosFormularioSalvo.cnh}
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="numeroRegistroCnh">Nº do registro</label>
                                                    {dadosFormularioSalvo.numeroRegistroCnh}
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="dataExpedicaoCnh">Data de Expedição</label>
                                                    {dadosFormularioSalvo.dataExpedicaoCnh}
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="localCnh">Local da Expedição</label>
                                                    {dadosFormularioSalvo.localCnh}
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="categoriaCnh">Categoria</label>
                                                    {dadosFormularioSalvo.categoriaCnh}
                                                </div>
                                            </div>

                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="cnhMulta">Sua CNH ou PPD possui pontuação por multas?</label>
                                                    {dadosFormularioSalvo.cnhMulta}
                                                </div>
                                            </div>
                                            {dadosFormularioSalvo.cnhMulta === 'sim' &&
                                                <div id='cnhMultaDiv' className='box'>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className='label' htmlFor='detalheCnhMulta'>Informações detalhadas sobre as infrações cometidas, bem como aquelas que foram registradas em seu nome, ainda que você não estivesse conduzindo o veículo, porém, trata-se de um carro (ou moto) registrado em seu nome:</label>
                                                            <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.detalheCnhMulta.replaceAll('\n', '<br/>'), }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }

                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="cnhApreendidaSuspensaCassada">Você já teve alguma vez a CNH ou PPD <u>apreendida</u>, <u>suspensa</u> ou <u>cassada</u>?</label>
                                            {dadosFormularioSalvo.cnhApreendidaSuspensaCassada}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.cnhApreendidaSuspensaCassada === 'sim' &&
                                        <div id='cnhApreendidaSuspensaCassadaDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="detalheCnhApreendidaSuspensaCassada">Informações detalhadas sobre os motivos da apreensão, suspensão, cassação:</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.detalheCnhApreendidaSuspensaCassada.replaceAll('\n', '<br/>'), }} />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="acidenteTransito">Você já esteve envolvido em <u>acidente de trânsito</u> ao dirigir veículo?</label>
                                            {dadosFormularioSalvo.acidenteTransito}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.acidenteTransito === 'sim' &&
                                        <div id='acidenteTransitoDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="detalheAcidenteTransito">Informações detalhadas sobre o(s) acidente(s):</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.detalheAcidenteTransito.replaceAll('\n', '<br/>'), }} />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="veiculoSeuNomeSuaPosse">Você possui <u>veículo(s) em seu nome</u> e que esteja na <u>sua posse</u>?</label>
                                            {dadosFormularioSalvo.veiculoSeuNomeSuaPosse}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.veiculoSeuNomeSuaPosse === 'sim' &&
                                        <div id='veiculoSeuNomeSuaPosseDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    {dadosFormularioSalvo.lVeiculoSeuNomeSuaPosse.map((veiculo, index) => (
                                                        <div key={index}>
                                                            <article className='message'>
                                                                <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                    <p>{index+1}. [{veiculo.placa}] {veiculo.marcaAnoModelo} ({veiculo.cor})</p>
                                                                </div>
                                                                <div className='message-body has-background-white-ter has-text-black'>
                                                                    <p>
                                                                        Seguro, Licenciamento, IPVA pago: {veiculo.taxaOk}
                                                                    </p>
                                                                    {veiculo.taxaOk === 'não' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheTaxaOk.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                    <p>
                                                                        Multa pendente: {veiculo.multaPendente}
                                                                    </p>
                                                                    {veiculo.multaPendente === 'sim' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheMultaPendente.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                    <p>
                                                                        Dirige este veículo: {veiculo.voceDirige}
                                                                    </p>
                                                                    {veiculo.voceDirige === 'não' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheVoceDirige.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                </div>
                                                            </article>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="veiculoSeuNomeNaoSuaPosse">Você possui <u>veículo(s) em seu nome</u> e que <u>NÃO esteja na sua posse</u>?</label>
                                            {dadosFormularioSalvo.veiculoSeuNomeNaoSuaPosse}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.veiculoSeuNomeNaoSuaPosse === 'sim' &&
                                        <div id='veiculoSeuNomeNaoSuaPosseDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    {dadosFormularioSalvo.lVeiculoSeuNomeNaoSuaPosse.map((veiculo, index) => (
                                                        <div key={index}>
                                                            <article className='message'>
                                                                <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                    <p>{index+1}. [{veiculo.placa}] {veiculo.marcaAnoModelo} ({veiculo.cor})</p>
                                                                </div>
                                                                <div className='message-body has-background-white-ter has-text-black'>
                                                                    <p>
                                                                        Seguro, Licenciamento, IPVA pago: {veiculo.taxaOk}
                                                                    </p>
                                                                    {veiculo.taxaOk === 'não' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheTaxaOk.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                    <p>
                                                                        Multa pendente: {veiculo.multaPendente}
                                                                    </p>
                                                                    {veiculo.multaPendente === 'sim' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheMultaPendente.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                    <p>
                                                                        Dirige este veículo: {veiculo.voceDirige}
                                                                    </p>
                                                                    {veiculo.voceDirige === 'não' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheVoceDirige.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                    <p>
                                                                        Proprietário: {veiculo.proprietario};
                                                                        Parentesco: {veiculo.parentesco}
                                                                    </p>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="veiculoSuaPosseNaoSeuNome">Você <u>tem a posse de veículo(s) que não está(ão) em seu nome</u>?</label>
                                            {dadosFormularioSalvo.veiculoSuaPosseNaoSeuNome}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.veiculoSuaPosseNaoSeuNome === 'sim' &&
                                        <div id='veiculoSuaPosseNaoSeuNomeDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    {dadosFormularioSalvo.lVeiculoSuaPosseNaoSeuNome.map((veiculo, index) => (
                                                        <div key={index}>
                                                            <article className='message'>
                                                                <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                    <p>{index+1}. [{veiculo.placa}] {veiculo.marcaAnoModelo} ({veiculo.cor})</p>
                                                                </div>
                                                                <div className='message-body has-background-white-ter has-text-black'>
                                                                    <p>
                                                                        Seguro, Licenciamento, IPVA pago: {veiculo.taxaOk}
                                                                    </p>
                                                                    {veiculo.taxaOk === 'não' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheTaxaOk.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                    <p>
                                                                        Multa pendente: {veiculo.multaPendente}
                                                                    </p>
                                                                    {veiculo.multaPendente === 'sim' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheMultaPendente.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                    <p>
                                                                        Dirige este veículo: {veiculo.voceDirige}
                                                                    </p>
                                                                    {veiculo.voceDirige === 'não' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheVoceDirige.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                    <p>
                                                                        Proprietário: {veiculo.proprietario};
                                                                        Parentesco: {veiculo.parentesco}
                                                                    </p>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="veiculoNenhumaCondicaoAnterior"><u>Você dirige algum veículo</u> que NÃO esteja enquadrado em nenhuma das condições descritas nas questões anteriores?</label>
                                            {dadosFormularioSalvo.veiculoNenhumaCondicaoAnterior}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.veiculoNenhumaCondicaoAnterior === 'sim' &&
                                        <div id='veiculoNenhumaCondicaoAnteriorDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    {dadosFormularioSalvo.lVeiculoNenhumaCondicaoAnterior.map((veiculo, index) => (
                                                        <div key={index}>
                                                            <article className='message'>
                                                                <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                    <p>{index+1}. [{veiculo.placa}] {veiculo.marcaAnoModelo} ({veiculo.cor})</p>
                                                                </div>
                                                                <div className='message-body has-background-white-ter has-text-black'>
                                                                    <p>
                                                                        Seguro, Licenciamento, IPVA pago: {veiculo.taxaOk}
                                                                    </p>
                                                                    {veiculo.taxaOk === 'não' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheTaxaOk.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                    <p>
                                                                        Multa pendente: {veiculo.multaPendente}
                                                                    </p>
                                                                    {veiculo.multaPendente === 'sim' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheMultaPendente.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                </div>
                                                            </article>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="pagouPrometeuPagamento">Você já <u>pagou ou prometeu qualquer tipo de pagamento</u>, em dinheiro ou não, para obter recomendações ou promessas de auxílio nos exames de seleção para ingresso na Polícia Militar?</label>
                                            {dadosFormularioSalvo.pagouPrometeuPagamento}
                                        </div>
                                    </div>
                                    {dadosFormularioSalvo.pagouPrometeuPagamento === 'sim' &&
                                        <div id='pagouPrometeuPagamentoDiv' className='box'>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="detalhePagouPrometeuPagamento">Informações detalhadas, relatando sobre quem recebeu seu pagamento (Nome), qual foi a <u>recompensa prometida</u> e outros dados e informações importantes:</label>
                                                    <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.detalhePagouPrometeuPagamento.replaceAll('\n', '<br/>'), }} />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className="pagebreak"> </div>
                                    
                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="label" htmlFor="informacoesComplementares">Espaço reservado para esclarecimentos de lacunas no preenchimento deste formulário, bem como para prestar outras informações complementares, julgadas relevantes</label>
                                            <div dangerouslySetInnerHTML={{ __html: dadosFormularioSalvo.informacoesComplementares.replaceAll('\n', '<br/>'), }} />
                                        </div>
                                    </div>
                                </div>
                            </article>

                            <article className='message is-link' id='dadosConcursoDiv'>
                                <div className='message-header'>
                                    <div className="tags are-medium has-addons">
                                        PARTE J - JUNTADA DE DOCUMENTAÇÃO
                                    </div>
                                </div>
                                <div className='message-body'>
                                    {/* <div className='columns'>
                                        <div className='column'>
                                            <label className="label">Anexe o mapa ou desenho ilustrativo de todos os endereços de suas residências (antigas e atual), assinalando os pontos de referência e ruas principais. Se necessário, anexe folhas suplementares.</label>
                                        </div>
                                    </div> */}
                                    <div className='columns' style={{ textAlign:'center' }}>
                                        <div className='column'>
                                            <label className='label'>DOCUMENTAÇÃO NECESSÁRIA PARA A AVALIAÇÃO DA CONDUTA SOCIAL</label>
                                            <b>ATENÇÃO</b>: Deve ser apresentada toda a documentação original para conferência.
                                        </div>
                                    </div>
                                    <div className='columns'>
                                        <div className='column'>
                                            <ol style={{ listStyleType:'lower-alpha', marginLeft:'18px', marginRight:'18px' }}>
                                                <li>01 (uma) foto 5X7 cm colada na capa de cada via do Formulário da Avaliação de Conduta Social, datada e recente em até, no máximo, 6 (seis)  meses. ATENÇÃO: Não será aceita fotografia trajando uniformes militares, escolares, empresariais ou similares;</li>
                                                <li style={{ marginTop:'5px' }}>01 (uma) cópia reprográfica da Certidão de Nascimento ou Casamento;</li>
                                                <li style={{ marginTop:'5px' }}>01 (uma) cópia reprográfica da Cédula de Identidade (RG) ou Registro de Identidade Civil (RIC);</li>
                                                <li style={{ marginTop:'5px' }}>01 (uma) cópia reprográfica da Certidão de Distribuição Criminal, emitida pela Justiça Estadual, das comarcas dos municípios em que residiu a partir dos 18 anos de idade;</li>
                                                <li style={{ marginTop:'5px' }}>01 (uma) cópia do Atestado de Antecedentes Criminais, com emissão de forma imediata e gratuita por meio da rede Mundial de Computadores – Intenet;</li>
                                                <li style={{ marginTop:'5px' }}>01 (uma) cópia reprográfica da Certidão Negativa do Serviço Central de Proteção ao Crédito ou, caso exista débito, 01 (uma) cópia reprográfica do Extrato de Consulta;</li>
                                                <li style={{ marginTop:'5px' }}>01 (uma) cópia reprográfica da Certidão, expedida pelo órgão público em que estiver servindo, informando sua atual situação disciplinar ou comportamento; se responde ou se já respondeu a algum procedimento/processo administrativo; se responde ou se já respondeu a algum procedimento/ processo disciplinar, bem como punições sofridas, se houver. Esta documentação deve ser atendida somente por quem é servidor público ou militar;</li>
                                                <li style={{ marginTop:'5px' }}>01 (uma) cópia reprográfica do Certificado de Dispensa de Incorporação (CDI). Esta documentação deve ser atendida somente para candidatos do sexo masculino;</li>
                                                <li style={{ marginTop:'5px' }}>01 (uma) cópia reprográfica do Certificado de Conclusão do Ensino Superior ou grau equivalente, expedido por estabelecimento de ensino público ou particular, devidamente reconhecido pela legislação vigente;</li>
                                                <li style={{ marginTop:'5px' }}>01 (uma) cópia reprográfica do Histórico Escolar do Ensino Superior.</li>
                                            </ol>
                                        </div>
                                    </div>

                                    <div className='columns' style={{ textAlign:'center' }}>
                                        <div className='column'>
                                            <label className='label'>DOCUMENTAÇÃO NECESSÁRIA PARA QUEM SERVIU AS FORÇAS ARMADAS</label>
                                        </div>
                                    </div>
                                    <div className='columns'>
                                        <div className='column'>
                                            <ol style={{ listStyleType:'lower-alpha', marginLeft:'18px', marginRight:'18px' }}>
                                                <li>01 (uma) cópia reprográfica do Certificado de Reservista;</li>
                                                <li style={{ marginTop:'5px' }}>01 (uma) cópia reprográfica da Declaração de Comportamento ou do Diploma de Honra ao Mérito.</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </article>

                            <div className="pagebreak"> </div>
                            
                            <article className='message is-link' id='dadosConcursoDiv'>
                                <div className='message-header'>
                                    <div className="tags are-medium has-addons">
                                        TERMO, DECLARAÇÃO E AUTORIZAÇÃO
                                    </div>
                                </div>
                                <div className='message-body'>
                                    <label className='label' style={{ textAlign:'center' }}>TERMO DE CIÊNCIA, DECLARAÇÃO E AUTORIZAÇÃO SOBRE AVALIAÇÃO DA CONDUTA SOCIAL, DA REPUTAÇÃO E DA IDONEIDADE </label>
                                    <div className='notification is-warning'>
                                        <div className="control">
                                            <label className="checkbox" onClick={(e) => fazNada(e)} style={{ textAlign:'justify', fontWeight:'bold' }}>
                                                <input type="checkbox" id='checkTermo1'/>&nbsp;DECLARO, na condição de candidato a ingresso na Polícia Militar do Estado do Rio Grande do Norte,       ESTAR CIENTE que a presente documentação é constituída de dados e informações sigilosas, cuja preservação e restrição de acesso devem ser rigorosamente cumpridas pela própria Administração deste Concurso, uma vez que além do sigilo instituído, os dados, informações e documentos apresentados estão identificados como INFORMAÇÕES PESSOAIS, conforme define o inciso IV, do artigo 4º da Lei Federal 12.527, de 18NOV11; Inciso III, do Art. 4° da Lei Estadual n°9.963, de 27JUL2015, regulamentada pelo Decreto Estadual n° 25.399, de 31JUL2015, dispositivos legais instituídos para a devida proteção da intimidade, vida privada, honra e imagem deste próprio candidato.
                                            </label>
                                        </div>
                                    </div>

                                    <div className='notification is-warning'>
                                        <div className="control">
                                            <label className="checkbox" onClick={(e) => fazNada(e)} style={{ textAlign:'justify', fontWeight:'bold' }}>
                                                <input type="checkbox" id='checkTermo2'/>&nbsp;ESTOU CIENTE sobre todas as orientações descritas, bem como li e respondi pessoalmente todas as questões contidas no presente Formulário, bem como AUTORIZO que seja procedida a necessária averiguação sobre a minha vida pregressa e atual, nos aspectos de minha conduta escolar, profissional, ético-social e moral, isentando de qualquer responsabilidade as pessoas, empresas e estabelecimentos de ensino que prestarem informações sobre minha pessoa à Instituição (PMRN), a quem caberá resguardar o SIGILO DA FONTE dos dados, informações e documentos que instruírem a pesquisa sobre meu histórico de conduta e perfil ético-social e moral durante a Etapa da <i>Avaliação da Conduta Social, da Reputação e da Idoneidade</i>, que compõe o processo seletivo do presente certame concursal, em cumprimento ao que está determinado, principalmente, nos incisos X e XXXIII do artigo 5º e inciso II, do parágrafo 3.º, do artigo 37, tudo da Constituição Federal do Brasil.
                                            </label>
                                        </div>
                                    </div>

                                    <div className='notification is-warning'>
                                        <div className="control">
                                            <label className="checkbox" onClick={(e) => fazNada(e)} style={{ textAlign:'justify', fontWeight:'bold' }}>
                                                <input type="checkbox" id='checkTermo3'/>&nbsp;ESTOU CIENTE que após a entrega da minha Ficha de Informações Confidenciais para a Comissão do Concurso, caso ocorra QUALQUER alteração do meu <u>endereço de moradia</u>, envolvimento de minha pessoa em <u>ocorrência policial</u> de qualquer natureza (seja na condição de autor, indiciado, réu, testemunha ou até mesmo como vítima), <u>tratamento de saúde</u> grave que eu seja submetido, <u>internação</u> hospitalar, <u>punição</u> disciplinar no trabalho e/ou em ambiente escolar, <u>acidente</u> de trânsito, suspensão ou cassação de minha <u>CNH</u>, inclusão de um nome no <u>SCPC e/ou SERASA</u>, DEVEREI INFORMAR por escrito à Investigação Social da Polícia Militar e anexar uma cópia, nos casos em que houver, do registro policial elaborado na ocorrência da qual venha participar. Tenho o conhecimento de que o não atendimento a qualquer uma das exigências descritas, poderá determinar a minha eliminação no concurso, tudo nos termos do § 6º; artigo 2º, da Lei Complementar n.º 613, de 03JAN18 – “Lei de Ingresso da PMRN”, que modificou o Art. 11 da Lei Estadual n° 4.630, de 16DEZ1976 – "<i>Estatuto dos Militares Estaduais do Rio Grande do Norte</i>" e também conforme especificado no edital do concurso.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <button onClick={(e) => imprimePagina(e)} style={{ width:'180px' }} className="button is-info" id="printPageButton"><i className="fa fa-print"></i>&nbsp;Imprimir</button>
                                </div>
                            </article>

                        </form>
                    }
                </div>
            </div>
        </section>
        )
    }
     
    export default FormularioImpressao2301