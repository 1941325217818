import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { onlyNumber, nrCepMask, cpfMask, dataBr, dataNascimentoBanco2Html } from '../../utils/mask';
import '../../utils/padrao1.css';
// import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// import '../utils/padrao.css';
import { servidorBackEnd, msgInicialErro } from '../../utils/constantes_em_comum.js';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const setaTituloSistema = () => {
    document.getElementById('nomeSistemaInfoDiv').innerHTML = 'FIC - Ficha de Informações Confidenciais';
}

const Formulario2301 = () => {
    const [show, setShow] = useState(false);
    const [msgErroModalDiv, setMsgErroModalDiv] = useState('');
    const navigate = useNavigate();
    const [file, setFile] = useState();
    const [idCandidato, setIdCandidato] = useState('');
    const [nrInscricao, setNrInscricao] = useState('');
    const [informacaoFoto, setInformacaoFoto] = useState('');
    const [imagemFoto, setImagemFoto] = useState('');
    const [candidato, setCandidato] = useState('');
    const [dadosFormularioSalvo, setDadosFormularioSalvo] = useState(null);
    const [rgAnterior, setRgAnterior] = useState('');
    const [profissao, setProfissao] = useState('');
    const [empregoAtual, setEmpregoAtual] = useState('');
    const [religiao, setReligiao] = useState('');
    const [apelido, setApelido] = useState('');
    const [comQuemResideAtualmente, setComQuemResideAtualmente] = useState('');
    const [todosEmail, setTodosEmail] = useState('');
    let [resideResidiuApartir12anos, setResideResidiuApartir12anos] = useState([]);
        const [resideResidiuApartir12anosMesInicial, setResideResidiuApartir12anosMesInicial] = useState('');
        const [resideResidiuApartir12anosMesFinal, setResideResidiuApartir12anosMesFinal] = useState('');
        const [resideResidiuApartir12anosAnoInicial, setResideResidiuApartir12anosAnoInicial] = useState('');
        const [resideResidiuApartir12anosAnoFinal, setResideResidiuApartir12anosAnoFinal] = useState('');
        const [resideResidiuApartir12anosEndereco, setResideResidiuApartir12anosEndereco] = useState('');
        const [resideResidiuApartir12anosNumero, setResideResidiuApartir12anosNumero] = useState('');
        const [resideResidiuApartir12anosComplemento, setResideResidiuApartir12anosComplemento] = useState('');
        const [resideResidiuApartir12anosPontoReferencia, setResideResidiuApartir12anosPontoReferencia] = useState('');
        const [resideResidiuApartir12anosBairro, setResideResidiuApartir12anosBairro] = useState('');
        const [resideResidiuApartir12anosCidade, setResideResidiuApartir12anosCidade] = useState('');
        const [resideResidiuApartir12anosEstado, setResideResidiuApartir12anosEstado] = useState('');
        const [resideResidiuApartir12anosCep, setResideResidiuApartir12anosCep] = useState('');
        const [resideResidiuApartir12anosResidiuCom, setResideResidiuApartir12anosResidiuCom] = useState('');
        const [resideResidiuApartir12anosMoradorIndicado, setResideResidiuApartir12anosMoradorIndicado] = useState('');
        const [resideResidiuApartir12anosGMaps, setResideResidiuApartir12anosGMaps] = useState('');
    const[motivoNResidiuPaiEsposa, setMotivoNResidiuPaiEsposa] = useState('');
    let [familiar, setFamiliar] = useState([]);
        const [familiarParentesco, setFamiliarParentesco] = useState('');
        const [familiarIdade, setFamiliarIdade] = useState('');
        const [familiarSituacao, setFamiliarSituacao] = useState('');
        const [familiarNome, setFamiliarNome] = useState('');
        const [familiarDataNascimento, setFamiliarDataNascimento] = useState('');
        const [familiarNaturalidade, setFamiliarNaturalidade] = useState('');
        const [familiarNaturalEstado, setFamiliarNaturalEstado] = useState('');
        const [familiarCpf, setFamiliarCpf] = useState('');
        const [familiarRg, setFamiliarRg] = useState('');
        const [familiarEndereco, setFamiliarEndereco] = useState('');
        const [familiarNumero, setFamiliarNumero] = useState('');
        const [familiarComplemento, setFamiliarComplemento] = useState('');
        const [familiarCep, setFamiliarCep] = useState('');
        const [familiarBairro, setFamiliarBairro] = useState('');
        const [familiarCidade, setFamiliarCidade] = useState('');
        const [familiarEstado, setFamiliarEstado] = useState('');
        const [familiarProfissao, setFamiliarProfissao] = useState('');
        const [familiarLocalTrabalho, setFamiliarLocalTrabalho] = useState('');
        const [familiarApelido, setFamiliarApelido] = useState('');
    const [separadoDivorciado, setSeparadoDivorciado] = useState('');
        const [separadoDivorciadoNome, setSeparadoDivorciadoNome] = useState('');
        const [separadoDivorciadoCpf, setSeparadoDivorciadoCpf] = useState('');
        const [separadoDivorciadoRg, setSeparadoDivorciadoRg] = useState('');
        const [separadoDivorciadoLocalTrabalho, setSeparadoDivorciadoLocalTrabalho] = useState('');
        const [separadoDivorciadoApelido, setSeparadoDivorciadoApelido] = useState('');
        const [separadoDivorciadoEndereco, setSeparadoDivorciadoEndereco] = useState('');
        const [separadoDivorciadoProfissao, setSeparadoDivorciadoProfissao] = useState('');
        const [separadoDivorciadoRelacaoAtual, setSeparadoDivorciadoRelacaoAtual] = useState('');
        const [separadoDivorciadoNaturalidade, setSeparadoDivorciadoNaturalidade] = useState('');
        const [separadoDivorciadoNaturalEstado, setSeparadoDivorciadoNaturalEstado] = useState('');
        const [separadoDivorciadoRelacionamentoMesInicio, setSeparadoDivorciadoRelacionamentoMesInicio] = useState('');
        const [separadoDivorciadoRelacionamentoAnoInicio, setSeparadoDivorciadoRelacionamentoAnoInicio] = useState('');
        const [separadoDivorciadoRelacionamentoMesFim, setSeparadoDivorciadoRelacionamentoMesFim] = useState('');
        const [separadoDivorciadoRelacionamentoAnoFim, setSeparadoDivorciadoRelacionamentoAnoFim] = useState('');
    const [relacionamento, setRelacionamento] = useState('');
        const [relacionamentoNome, setRelacionamentoNome] = useState('');
        const [relacionamentoCpf, setRelacionamentoCpf] = useState('');
        const [relacionamentoRg, setRelacionamentoRg] = useState('');
        const [relacionamentoLocalTrabalho, setRelacionamentoLocalTrabalho] = useState('');
        const [relacionamentoApelido, setRelacionamentoApelido] = useState('');
        const [relacionamentoEndereco, setRelacionamentoEndereco] = useState('');
        const [relacionamentoProfissao, setRelacionamentoProfissao] = useState('');
        const [relacionamentoDuracao, setRelacionamentoDuracao] = useState('');
        const [relacionamentoNaturalidade, setRelacionamentoNaturalidade] = useState('');
        const [relacionamentoNaturalEstado, setRelacionamentoNaturalEstado] = useState('');
    const [processoPaternidade, setProcessoPaternidade] = useState('');
    const [detalheProcessoPaternidade, setDetalheProcessoPaternidade] = useState('');
    const [filhoEnteado, setFilhoEnteado] = useState('');
    let [filhosEnteados, setFilhosEnteados] = useState([]);
        const [filhosEnteadosNome, setFilhosEnteadosNome] = useState('');
        const [filhosEnteadosIdade, setFilhosEnteadosIdade] = useState('');
        const [filhosEnteadosNaturalidade, setFilhosEnteadosNaturalidade] = useState('');
        const [filhosEnteadosNaturalEstado, setFilhosEnteadosNaturalEstado] = useState('');
        const [filhosEnteadosCpf, setFilhosEnteadosCpf] = useState('');
        const [filhosEnteadosRg, setFilhosEnteadosRg] = useState('');
        const [filhosEnteadosEndereco, setFilhosEnteadosEndereco] = useState('');
        const [filhosEnteadosNumero, setFilhosEnteadosNumero] = useState('');
        const [filhosEnteadosComplemento, setFilhosEnteadosComplemento] = useState('');
        const [filhosEnteadosCep, setFilhosEnteadosCep] = useState('');
        const [filhosEnteadosBairro, setFilhosEnteadosBairro] = useState('');
        const [filhosEnteadosCidade, setFilhosEnteadosCidade] = useState('');
        const [filhosEnteadosEstado, setFilhosEnteadosEstado] = useState('');
        const [filhosEnteadosResideCom, setFilhosEnteadosResideCom] = useState('');
        const [filhosEnteadosApelido, setFilhosEnteadosApelido] = useState('');
        const [filhosEnteadosProfissao, setFilhosEnteadosProfissao] = useState('');
        const [filhosEnteadosTrabalhoEstudo, setFilhosEnteadosTrabalhoEstudo] = useState('');
        const [filhosEnteadosCondicao, setFilhosEnteadosCondicao] = useState('');
    const [sustentoFilhoEnteado, setSustentoFilhoEnteado] = useState('');
    const [detalheSustentoFilhoEnteado, setDetalheSustentoFilhoEnteado] = useState('');
    const [casamentoUniaoEstavel, setCasamentoUniaoEstavel] = useState('');
        const [casamentoUniaoEstavelNome, setCasamentoUniaoEstavelNome] = useState('');
        const [casamentoUniaoEstavelIdade, setCasamentoUniaoEstavelIdade] = useState('');
        const [casamentoUniaoEstavelNaturalidade, setCasamentoUniaoEstavelNaturalidade] = useState('');
        const [casamentoUniaoEstavelNaturalEstado, setCasamentoUniaoEstavelNaturalEstado] = useState('');
        const [casamentoUniaoEstavelCpf, setCasamentoUniaoEstavelCpf] = useState('');
        const [casamentoUniaoEstavelRg, setCasamentoUniaoEstavelRg] = useState('');
        const [casamentoUniaoEstavelApelido, setCasamentoUniaoEstavelApelido] = useState('');
        const [casamentoUniaoEstavelCondicao, setCasamentoUniaoEstavelCondicao] = useState('');
        const [casamentoUniaoEstavelDataCasamentoRelacionamento, setCasamentoUniaoEstavelDataCasamentoRelacionamento] = useState('');
        const [casamentoUniaoEstavelLocalCasamento, setCasamentoUniaoEstavelLocalCasamento] = useState('');
        const [casamentoUniaoEstavelProfissao, setCasamentoUniaoEstavelProfissao] = useState('');
        const [casamentoUniaoEstavelSalario, setCasamentoUniaoEstavelSalario] = useState('');
        const [casamentoUniaoEstavelEmpresa, setCasamentoUniaoEstavelEmpresa] = useState('');
        const [casamentoUniaoEstavelEmpresaEndereco, setCasamentoUniaoEstavelEmpresaEndereco] = useState('');
        const [casamentoUniaoEstavelFuncao, setCasamentoUniaoEstavelFuncao] = useState('');
    const [examinadoTratadoInternado, setExaminadoTratadoInternado] = useState('');
    const [detalheExaminadoTratadoInternado, setDetalheExaminadoTratadoInternado] = useState('');
    const [tatuagem, setTatuagem] = useState('');
    const [detalheTatuagem, setDetalheTatuagem] = useState('');
    const [internadoHospital, setInternadoHospital] = useState('');
    const [detalheInternadoHospital, setDetalheInternadoHospital] = useState('');
    const [desmaiou, setDesmaiou] = useState('');
    const [detalheDesmaiou, setDetalheDesmaiou] = useState('');
    const [bebidaAlcoolica, setBebidaAlcoolica] = useState('');
    const [detalheBebidaAlcoolica, setDetalheBebidaAlcoolica] = useState('');
    const [fuma, setFuma] = useState('');
    const [detalheFuma, setDetalheFuma] = useState('');
    const [entorpecente, setEntorpecente] = useState('');
    const [detalheEntorpecente, setDetalheEntorpecente] = useState('');
    const [entorpecenteFamilia, setEntorpecenteFamilia] = useState('');
    const [detalheEntorpecenteFamilia, setDetalheEntorpecenteFamilia] = useState('');
    const [entorpecenteRelacao, setEntorpecenteRelacao] = useState('');
    const [detalheEntorpecenteRelacao, setDetalheEntorpecenteRelacao] = useState('');
    const [antecedenteCriminalRelacao, setAntecedenteCriminalRelacao] = useState('');
    const [detalheAntecedenteCriminalRelacao, setDetalheAntecedenteCriminalRelacao] = useState('');
    const [testemunha1Nome, setTestemunha1Nome] = useState('');
    const [testemunha1Idade, setTestemunha1Idade] = useState('');
    const [testemunha1Naturalidade, setTestemunha1Naturalidade] = useState('');
    const [testemunha1NaturalEstado, setTestemunha1NaturalEstado] = useState('');
    const [testemunha1Cpf, setTestemunha1Cpf] = useState('');
    const [testemunha1Rg, setTestemunha1Rg] = useState('');
    const [testemunha1TempoConhece, setTestemunha1TempoConhece] = useState('');
    const [testemunha1Apelido, setTestemunha1Apelido] = useState('');
    const [testemunha1EnderecoResidencial, setTestemunha1EnderecoResidencial] = useState('');
    const [testemunha1Profissao, setTestemunha1Profissao] = useState('');
    const [testemunha1EnderecoComercial, setTestemunha1EnderecoComercial] = useState('');
    const [testemunha2Nome, setTestemunha2Nome] = useState('');
    const [testemunha2Idade, setTestemunha2Idade] = useState('');
    const [testemunha2Naturalidade, setTestemunha2Naturalidade] = useState('');
    const [testemunha2NaturalEstado, setTestemunha2NaturalEstado] = useState('');
    const [testemunha2Cpf, setTestemunha2Cpf] = useState('');
    const [testemunha2Rg, setTestemunha2Rg] = useState('');
    const [testemunha2TempoConhece, setTestemunha2TempoConhece] = useState('');
    const [testemunha2Apelido, setTestemunha2Apelido] = useState('');
    const [testemunha2EnderecoResidencial, setTestemunha2EnderecoResidencial] = useState('');
    const [testemunha2Profissao, setTestemunha2Profissao] = useState('');
    const [testemunha2EnderecoComercial, setTestemunha2EnderecoComercial] = useState('');
    const [testemunha3Nome, setTestemunha3Nome] = useState('');
    const [testemunha3Idade, setTestemunha3Idade] = useState('');
    const [testemunha3Naturalidade, setTestemunha3Naturalidade] = useState('');
    const [testemunha3NaturalEstado, setTestemunha3NaturalEstado] = useState('');
    const [testemunha3Cpf, setTestemunha3Cpf] = useState('');
    const [testemunha3Rg, setTestemunha3Rg] = useState('');
    const [testemunha3TempoConhece, setTestemunha3TempoConhece] = useState('');
    const [testemunha3Apelido, setTestemunha3Apelido] = useState('');
    const [testemunha3EnderecoResidencial, setTestemunha3EnderecoResidencial] = useState('');
    const [testemunha3Profissao, setTestemunha3Profissao] = useState('');
    const [testemunha3EnderecoComercial, setTestemunha3EnderecoComercial] = useState('');
    const [testemunha4Nome, setTestemunha4Nome] = useState('');
    const [testemunha4Idade, setTestemunha4Idade] = useState('');
    const [testemunha4Naturalidade, setTestemunha4Naturalidade] = useState('');
    const [testemunha4NaturalEstado, setTestemunha4NaturalEstado] = useState('');
    const [testemunha4Cpf, setTestemunha4Cpf] = useState('');
    const [testemunha4Rg, setTestemunha4Rg] = useState('');
    const [testemunha4TempoConhece, setTestemunha4TempoConhece] = useState('');
    const [testemunha4Apelido, setTestemunha4Apelido] = useState('');
    const [testemunha4EnderecoResidencial, setTestemunha4EnderecoResidencial] = useState('');
    const [testemunha4Profissao, setTestemunha4Profissao] = useState('');
    const [testemunha4EnderecoComercial, setTestemunha4EnderecoComercial] = useState('');
    const [faPmGmPcPtcConhecido, setFaPmGmPcPtcConhecido] = useState('');
    let [faPmGmPcPtc, setFaPmGmPcPtc] = useState([]);
        const [faPmGmPcPtcNome, setFaPmGmPcPtcNome] = useState('');
        const [faPmGmPcPtcCpf, setFaPmGmPcPtcCpf] = useState('');
        const [faPmGmPcPtcRg, setFaPmGmPcPtcRg] = useState('');
        const [faPmGmPcPtcGrauParentesco, setFaPmGmPcPtcGrauParentesco] = useState('');
        const [faPmGmPcPtcTempoConhece, setFaPmGmPcPtcTempoConhece] = useState('');
        const [faPmGmPcPtcCargoPostoGraduacao, setFaPmGmPcPtcCargoPostoGraduacao] = useState('');
        const [faPmGmPcPtcInstituicao, setFaPmGmPcPtcInstituicao] = useState('');
        const [faPmGmPcPtcUnidade, setFaPmGmPcPtcUnidade] = useState('');
        const [faPmGmPcPtcFuncao, setFaPmGmPcPtcFuncao] = useState('');
        const [faPmGmPcPtcSituacao, setFaPmGmPcPtcSituacao] = useState('');
    const [folga, setFolga] = useState('');
    const [costumeLocalDiaSemana, setCostumeLocalDiaSemana] = useState('');
    const [costumeLocalFdsFeriado, setCostumeLocalFdsFeriado] = useState('');
    const [socioClube, setSocioClube] = useState('');
    let [socioClubes, setSocioClubes] = useState([]);
        const [socioClubeNome, setSocioClubeNome] = useState('');
        const [socioClubeMesInicial, setSocioClubeMesInicial] = useState('');
        const [socioClubeAnoInicial, setSocioClubeAnoInicial] = useState('');
        const [socioClubeMesFinal, setSocioClubeMesFinal] = useState('');
        const [socioClubeAnoFinal, setSocioClubeAnoFinal] = useState('');
        const [socioClubeEndereco, setSocioClubeEndereco] = useState('');
        const [socioClubeNumero, setSocioClubeNumero] = useState('');
        const [socioClubeComplemento, setSocioClubeComplemento] = useState('');
        const [socioClubeCep, setSocioClubeCep] = useState('');
        const [socioClubeBairro, setSocioClubeBairro] = useState('');
        const [socioClubeCidade, setSocioClubeCidade] = useState('');
        const [socioClubeEstado, setSocioClubeEstado] = useState('');
        const [socioClubePontoReferencia, setSocioClubePontoReferencia] = useState('');
        const [socioClubeAtividade, setSocioClubeAtividade] = useState('');
        const [socioClubeNotificacaoPunicao, setSocioClubeNotificacaoPunicao] = useState('');
        const [detalheSocioClubeNotificacaoPunicao, setDetalheSocioClubeNotificacaoPunicao] = useState('');
    const [sindicato, setSindicato] = useState('');
    let [sindicatos, setSindicatos] = useState([]);
        const [sindicatoNome, setSindicatoNome] = useState('');
        const [sindicatoMesInicial, setSindicatoMesInicial] = useState('');
        const [sindicatoAnoInicial, setSindicatoAnoInicial] = useState('');
        const [sindicatoMesFinal, setSindicatoMesFinal] = useState('');
        const [sindicatoAnoFinal, setSindicatoAnoFinal] = useState('');
        const [sindicatoEndereco, setSindicatoEndereco] = useState('');
        const [sindicatoNumero, setSindicatoNumero] = useState('');
        const [sindicatoComplemento, setSindicatoComplemento] = useState('');
        const [sindicatoCep, setSindicatoCep] = useState('');
        const [sindicatoBairro, setSindicatoBairro] = useState('');
        const [sindicatoCidade, setSindicatoCidade] = useState('');
        const [sindicatoEstado, setSindicatoEstado] = useState('');
        const [sindicatoPontoReferencia, setSindicatoPontoReferencia] = useState('');
        const [sindicatoNotificacaoPunicao, setSindicatoNotificacaoPunicao] = useState('');
        const [detalheSindicatoNotificacaoPunicao, setDetalheSindicatoNotificacaoPunicao] = useState('');
    const [partidoPolitico, setPartidoPolitico] = useState('');
    let [partidosPoliticos, setPartidosPoliticos] = useState([]);
        const [partidoPoliticoNome, setPartidoPoliticoNome] = useState('');
        const [partidoPoliticoMesInicial, setPartidoPoliticoMesInicial] = useState('');
        const [partidoPoliticoAnoInicial, setPartidoPoliticoAnoInicial] = useState('');
        const [partidoPoliticoMesFinal, setPartidoPoliticoMesFinal] = useState('');
        const [partidoPoliticoAnoFinal, setPartidoPoliticoAnoFinal] = useState('');
        const [partidoPoliticoEndereco, setPartidoPoliticoEndereco] = useState('');
        const [partidoPoliticoNumero, setPartidoPoliticoNumero] = useState('');
        const [partidoPoliticoComplemento, setPartidoPoliticoComplemento] = useState('');
        const [partidoPoliticoCep, setPartidoPoliticoCep] = useState('');
        const [partidoPoliticoBairro, setPartidoPoliticoBairro] = useState('');
        const [partidoPoliticoCidade, setPartidoPoliticoCidade] = useState('');
        const [partidoPoliticoEstado, setPartidoPoliticoEstado] = useState('');
        const [partidoPoliticoPontoReferencia, setPartidoPoliticoPontoReferencia] = useState('');
        const [partidoPoliticoFiliacaoDesfiliacao, setPartidoPoliticoFiliacaoDesfiliacao] = useState('');
        const [partidoPoliticoNotificacaoPunicao, setPartidoPoliticoNotificacaoPunicao] = useState('');
        const [detalhePartidoPoliticoNotificacaoPunicao, setDetalhePartidoPoliticoNotificacaoPunicao] = useState('');
    const [processoJustica, setProcessoJustica] = useState('');
    let [processosJustica, setProcessosJustica] = useState([]);
        const [processoJusticaJC, setProcessoJusticaJC] = useState('');
        const [processoJusticaJT, setProcessoJusticaJT] = useState('');
        const [processoJusticaJCR, setProcessoJusticaJCR] = useState('');
        const [processoJusticaJM, setProcessoJusticaJM] = useState('');
        const [processoJusticaVIJ, setProcessoJusticaVIJ] = useState('');
        const [processoJusticaJEC, setProcessoJusticaJEC] = useState('');
        const [processoJusticaTipo, setProcessoJusticaTipo] = useState('');
        const [processoJusticaCondicao, setProcessoJusticaCondicao] = useState('');
        const [processoJusticaDataFato, setProcessoJusticaDataFato] = useState('');
        const [processoJusticaNumeroAno, setProcessoJusticaNumeroAno] = useState('');
        const [processoJusticaBairro, setProcessoJusticaBairro] = useState('');
        const [processoJusticaCidade, setProcessoJusticaCidade] = useState('');
        const [processoJusticaEstado, setProcessoJusticaEstado] = useState('');
        const [processoJusticaForum, setProcessoJusticaForum] = useState('');
        const [processoJusticaVara, setProcessoJusticaVara] = useState('');
        const [processoJusticaSolucaoPena, setProcessoJusticaSolucaoPena] = useState('');
        const [processoJusticaDetalheFato, setProcessoJusticaDetalheFato] = useState('');
    const [ocorrenciaPolicial, setOcorrenciaPolicial] = useState('');
    let [ocorrenciasPoliciais, setOcorrenciasPoliciais] = useState([]);
        const [ocorrenciaPolicialDataFato, setOcorrenciaPolicialDataFato] = useState('');
        const [ocorrenciaPolicialCondicao, setOcorrenciaPolicialCondicao] = useState('');
        const [ocorrenciaPolicialDelegaciaQuartel, setOcorrenciaPolicialDelegaciaQuartel] = useState('');
        const [ocorrenciaPolicialRpBo, setOcorrenciaPolicialRpBo] = useState('');
        const [ocorrenciaPolicialSolucaoPena, setOcorrenciaPolicialSolucaoPena] = useState('');
        const [ocorrenciaPolicialDetalheFato, setOcorrenciaPolicialDetalheFato] = useState('');
    const [ipSindIpm, setIpSindIpm] = useState('');
    let [listaIpSindIpm, setListaIpSindIpm] = useState([]);
        const [ipSindIpmDataFato, setIpSindIpmDataFato] = useState('');
        const [ipSindIpmCondicao, setIpSindIpmCondicao] = useState('');
        const [ipSindIpmNrProcedimento, setIpSindIpmNrProcedimento] = useState('');
        const [ipSindIpmSolucao, setIpSindIpmSolucao] = useState('');
        const [ipSindIpmDetalheFato, setIpSindIpmDetalheFato] = useState('');
    const [padCdCj, setPadCdCj] = useState('');
    let [listaPadCdCj, setListaPadCdCj] = useState([]);
        const [padCdCjDataFato, setPadCdCjDataFato] = useState('');
        const [padCdCjCondicao, setPadCdCjCondicao] = useState('');
        const [padCdCjNrProcedimento, setPadCdCjNrProcedimento] = useState('');
        const [padCdCjSolucao, setPadCdCjSolucao] = useState('');
        const [padCdCjDetalheFato, setPadCdCjDetalheFato] = useState('');
    const [familiaOpPj, setFamiliaOpPj] = useState('');
    let [familiasOpPj, setFamiliasOpPj] = useState([]);
        const [familiaOpPjNome, setFamiliaOpPjNome] = useState('');
        const [familiaOpPjGrauParentesco, setFamiliaOpPjGrauParentesco] = useState('');
        const [familiaOpPjCondicao, setFamiliaOpPjCondicao] = useState('');
        const [familiaOpPjDataFato, setFamiliaOpPjDataFato] = useState('');
        const [familiaOpPjTipoProcesso, setFamiliaOpPjTipoProcesso] = useState('');
        const [familiaOpPjCpf, setFamiliaOpPjCpf] = useState('');
        const [familiaOpPjRg, setFamiliaOpPjRg] = useState('');
        const [familiaOpPjEndereco, setFamiliaOpPjEndereco] = useState('');
        const [familiaOpPjNumero, setFamiliaOpPjNumero] = useState('');
        const [familiaOpPjBairro, setFamiliaOpPjBairro] = useState('');
        const [familiaOpPjCidade, setFamiliaOpPjCidade] = useState('');
        const [familiaOpPjEstado, setFamiliaOpPjEstado] = useState('');
        const [familiaOpPjSolucaoPena, setFamiliaOpPjSolucaoPena] = useState('');
        const [familiaOpPjFatoOcorrido, setFamiliaOpPjFatoOcorrido] = useState('');
    const [armaDeFogo, setArmaDeFogo] = useState('');
    const [detalheArmaDeFogo, setDetalheArmaDeFogo] = useState('');
    const [armaDeFogoApreendida, setArmaDeFogoApreendida] = useState('');
    const [detalheArmaDeFogoApreendida, setDetalheArmaDeFogoApreendida] = useState('');
    let [detalheEmprego, setDetalheEmprego] = useState([]);
        const [detalheEmpregoEmpresa, setDetalheEmpregoEmpresa] = useState('');
        const [detalheEmpregoCnpj, setDetalheEmpregoCnpj] = useState('');
        const [detalheEmpregoSede, setDetalheEmpregoSede] = useState('');
        const [detalheEmpregoMesInicial, setDetalheEmpregoMesInicial] = useState('');
        const [detalheEmpregoAnoInicial, setDetalheEmpregoAnoInicial] = useState('');
        const [detalheEmpregoMesFinal, setDetalheEmpregoMesFinal] = useState('');
        const [detalheEmpregoAnoFinal, setDetalheEmpregoAnoFinal] = useState('');
        const [detalheEmpregoEndereco, setDetalheEmpregoEndereco] = useState('');
        const [detalheEmpregoBairro, setDetalheEmpregoBairro] = useState('');
        const [detalheEmpregoCidade, setDetalheEmpregoCidade] = useState('');
        const [detalheEmpregoEstado, setDetalheEmpregoEstado] = useState('');
        const [detalheEmpregoCep, setDetalheEmpregoCep] = useState('');
        const [detalheEmpregoFuncao, setDetalheEmpregoFuncao] = useState('');
        const [detalheEmpregoSalario, setDetalheEmpregoSalario] = useState('');
        const [detalheEmpregoDepartamento, setDetalheEmpregoDepartamento] = useState('');
        const [detalheEmpregoChefe, setDetalheEmpregoChefe] = useState('');
        const [detalheEmpregoPunicaoSofrida, setDetalheEmpregoPunicaoSofrida] = useState('');
        const [detalheEmpregoMotivoDemissao, setDetalheEmpregoMotivoDemissao] = useState('');
        const [detalheEmpregoInatividadeMesInicial, setDetalheEmpregoInatividadeMesInicial] = useState('');
        const [detalheEmpregoInatividadeAnoInicial, setDetalheEmpregoInatividadeAnoInicial] = useState('');
        const [detalheEmpregoInatividadeMesFinal, setDetalheEmpregoInatividadeMesFinal] = useState('');
        const [detalheEmpregoInatividadeAnoFinal, setDetalheEmpregoInatividadeAnoFinal] = useState('');
        const [detalheEmpregoFezDuranteDesemprego, setDetalheEmpregoFezDuranteDesemprego] = useState('');
        const [detalheEmpregoBatalhaoPm, setDetalheEmpregoBatalhaoPm] = useState('');
    const [tentouIngressarPm, setTentouIngressarPm] = useState('');
        const [tentouIngressarPmQuantidade, setTentouIngressarPmQuantidade] = useState('');
        const [tentouIngressarPmReprovou, setTentouIngressarPmReprovou] = useState('');
    const [tentouIngressarOutroEmprego, setTentouIngressarOutroEmprego] = useState('');
        const [tentouIngressarOutroEmpregoA, setTentouIngressarOutroEmpregoA] = useState('');
        const [tentouIngressarOutroEmpregoB, setTentouIngressarOutroEmpregoB] = useState('');
        const [tentouIngressarOutroEmpregoC, setTentouIngressarOutroEmpregoC] = useState('');
        const [tentouIngressarOutroEmpregoD, setTentouIngressarOutroEmpregoD] = useState('');
        const [tentouIngressarOutroEmpregoE, setTentouIngressarOutroEmpregoE] = useState('');
    const [servicoMilitar, setServicoMilitar] = useState('');
    const [servicoMilitarUnidade, setServicoMilitarUnidade] = useState('');
    const [servicoMilitarCompanhia, setServicoMilitarCompanhia] = useState('');
    const [servicoMilitarPelotao, setServicoMilitarPelotao] = useState('');
    const [servicoMilitarMesInicial, setServicoMilitarMesInicial] = useState('');
    const [servicoMilitarAnoInicial, setServicoMilitarAnoInicial] = useState('');
    const [servicoMilitarMesFinal, setServicoMilitarMesFinal] = useState('');
    const [servicoMilitarAnoFinal, setServicoMilitarAnoFinal] = useState('');
    const [servicoMilitarEndereco, setServicoMilitarEndereco] = useState('');
    const [servicoMilitarPontoReferencia, setServicoMilitarPontoReferencia] = useState('');
    const [servicoMilitarFuncao, setServicoMilitarFuncao] = useState('');
    const [servicoMilitarPunicao, setServicoMilitarPunicao] = useState('');
    const [servicoMilitarMotivoBaixa, setServicoMilitarMotivoBaixa] = useState('');
    let [escolas, setEscolas] = useState([]);
        const [escolaNome, setEscolaNome] = useState('');
        const [escolaCurso, setEscolaCurso] = useState('');
        const [escolaCursou, setEscolaCursou] = useState('');
        const [escolaTipo, setEscolaTipo] = useState('');
        const [escolaRazaoEad, setEscolaRazaoEad] = useState('');
        const [escolaMesInicial, setEscolaMesInicial] = useState('');
        const [escolaAnoInicial, setEscolaAnoInicial] = useState('');
        const [escolaMesFinal, setEscolaMesFinal] = useState('');
        const [escolaAnoFinal, setEscolaAnoFinal] = useState('');
        const [escolaEndereco, setEscolaEndereco] = useState('');
        const [escolaNumero, setEscolaNumero] = useState('');
        const [escolaComplemento, setEscolaComplemento] = useState('');
        const [escolaCep, setEscolaCep] = useState('');
        const [escolaBairro, setEscolaBairro] = useState('');
        const [escolaCidade, setEscolaCidade] = useState('');
        const [escolaEstado, setEscolaEstado] = useState('');
        const [escolaPontoReferencia, setEscolaPontoReferencia] = useState('');
    const [punicaoEscolar, setPunicaoEscolar] = useState('');
    const [detalhePunicaoEscolar, setDetalhePunicaoEscolar] = useState('');
    const [protecaoCredito, setProtecaoCredito] = useState('');
    const [protecaoCreditoDivida, setProtecaoCreditoDivida] = useState('');
    const [protecaoCreditoDataCompra, setProtecaoCreditoDataCompra] = useState('');
    const [protecaoCreditoDataDeixouPagarDivida, setProtecaoCreditoDataDeixouPagarDivida] = useState('');
    const [protecaoCreditoInstituicaoCredora, setProtecaoCreditoInstituicaoCredora] = useState('');
    const [protecaoCreditoMotivoDivida, setProtecaoCreditoMotivoDivida] = useState('');
    const [propriedadeImovel, setPropriedadeImovel] = useState('');
    const [propriedadeImovelTipo, setPropriedadeImovelTipo] = useState('');
    const [propriedadeImovelTamanho, setPropriedadeImovelTamanho] = useState('');
    const [propriedadeImovelValorAvaliado, setPropriedadeImovelValorAvaliado] = useState('');
    const [atividadeEmpresarial, setAtividadeEmpresarial] = useState('');
    const [atividadeEmpresarialTipo, setAtividadeEmpresarialTipo] = useState('');
    const [atividadeEmpresarialNivelParticipacao, setAtividadeEmpresarialNivelParticipacao] = useState('');
    const [atividadeEmpresarialEmpresa, setAtividadeEmpresarialEmpresa] = useState('');
    const [permissaoDirigir, setPermissaoDirigir] = useState('');
        const [cnh, setCnh] = useState('');
        const [numeroRegistroCnh, setNumeroRegistroCnh] = useState('');
        const [dataExpedicaoCnh, setDataExpedicaoCnh] = useState('');
        const [localCnh, setLocalCnh] = useState('');
        const [categoriaCnh, setCategoriaCnh] = useState('');
        const [cnhMulta, setCnhMulta] = useState('');
        const [detalheCnhMulta, setDetalheCnhMulta] = useState('');
    const [cnhApreendidaSuspensaCassada, setCnhApreendidaSuspensaCassada] = useState('');
    const [detalheCnhApreendidaSuspensaCassada, setDetalheCnhApreendidaSuspensaCassada] = useState('');
    const [acidenteTransito, setAcidenteTransito] = useState('');
    const [detalheAcidenteTransito, setDetalheAcidenteTransito] = useState('');
    const [veiculoSeuNomeSuaPosse, setVeiculoSeuNomeSuaPosse] = useState('');
    let [lVeiculoSeuNomeSuaPosse, setLVeiculoSeuNomeSuaPosse] = useState([]);
        const [veiculoSeuNomeSuaPossePlaca, setVeiculoSeuNomeSuaPossePlaca] = useState('');
        const [veiculoSeuNomeSuaPosseMarcaAnoModelo, setVeiculoSeuNomeSuaPosseMarcaAnoModelo] = useState('');
        const [veiculoSeuNomeSuaPosseCor, setVeiculoSeuNomeSuaPosseCor] = useState('');
        const [veiculoSeuNomeSuaPosseTaxaOk, setVeiculoSeuNomeSuaPosseTaxaOk] = useState('');
        const [detalheVeiculoSeuNomeSuaPosseTaxaOk, setDetalheVeiculoSeuNomeSuaPosseTaxaOk] = useState('');
        const [veiculoSeuNomeSuaPosseMultaPendente, setVeiculoSeuNomeSuaPosseMultaPendente] = useState('');
        const [detalheVeiculoSeuNomeSuaPosseMultaPendente, setDetalheVeiculoSeuNomeSuaPosseMultaPendente] = useState('');
        const [veiculoSeuNomeSuaPosseVoceDirige, setVeiculoSeuNomeSuaPosseVoceDirige] = useState('');
        const [detalheVeiculoSeuNomeSuaPosseVoceDirige, setDetalheVeiculoSeuNomeSuaPosseVoceDirige] = useState('');
    const [veiculoSeuNomeNaoSuaPosse, setVeiculoSeuNomeNaoSuaPosse] = useState('');
    let [lVeiculoSeuNomeNaoSuaPosse, setLVeiculoSeuNomeNaoSuaPosse] = useState([]);
        const [veiculoSeuNomeNaoSuaPossePlaca, setVeiculoSeuNomeNaoSuaPossePlaca] = useState('');
        const [veiculoSeuNomeNaoSuaPosseMarcaAnoModelo, setVeiculoSeuNomeNaoSuaPosseMarcaAnoModelo] = useState('');
        const [veiculoSeuNomeNaoSuaPosseCor, setVeiculoSeuNomeNaoSuaPosseCor] = useState('');
        const [veiculoSeuNomeNaoSuaPosseTaxaOk, setVeiculoSeuNomeNaoSuaPosseTaxaOk] = useState('');
        const [detalheVeiculoSeuNomeNaoSuaPosseTaxaOk, setDetalheVeiculoSeuNomeNaoSuaPosseTaxaOk] = useState('');
        const [veiculoSeuNomeNaoSuaPosseMultaPendente, setVeiculoSeuNomeNaoSuaPosseMultaPendente] = useState('');
        const [detalheVeiculoSeuNomeNaoSuaPosseMultaPendente, setDetalheVeiculoSeuNomeNaoSuaPosseMultaPendente] = useState('');
        const [veiculoSeuNomeNaoSuaPosseVoceDirige, setVeiculoSeuNomeNaoSuaPosseVoceDirige] = useState('');
        const [detalheVeiculoSeuNomeNaoSuaPosseVoceDirige, setDetalheVeiculoSeuNomeNaoSuaPosseVoceDirige] = useState('');
        const [veiculoSeuNomeNaoSuaPosseProprietario, setVeiculoSeuNomeNaoSuaPosseProprietario] = useState('');
        const [veiculoSeuNomeNaoSuaPosseParentescoProprietario, setVeiculoSeuNomeNaoSuaPosseParentescoProprietario] = useState('');
    const [veiculoSuaPosseNaoSeuNome, setVeiculoSuaPosseNaoSeuNome] = useState('');
    let [lVeiculoSuaPosseNaoSeuNome, setLVeiculoSuaPosseNaoSeuNome] = useState([]);
        const [veiculoSuaPosseNaoSeuNomePlaca, setVeiculoSuaPosseNaoSeuNomePlaca] = useState('');
        const [veiculoSuaPosseNaoSeuNomeMarcaAnoModelo, setVeiculoSuaPosseNaoSeuNomeMarcaAnoModelo] = useState('');
        const [veiculoSuaPosseNaoSeuNomeCor, setVeiculoSuaPosseNaoSeuNomeCor] = useState('');
        const [veiculoSuaPosseNaoSeuNomeTaxaOk, setVeiculoSuaPosseNaoSeuNomeTaxaOk] = useState('');
        const [detalheVeiculoSuaPosseNaoSeuNomeTaxaOk, setDetalheVeiculoSuaPosseNaoSeuNomeTaxaOk] = useState('');
        const [veiculoSuaPosseNaoSeuNomeMultaPendente, setVeiculoSuaPosseNaoSeuNomeMultaPendente] = useState('');
        const [detalheVeiculoSuaPosseNaoSeuNomeMultaPendente, setDetalheVeiculoSuaPosseNaoSeuNomeMultaPendente] = useState('');
        const [veiculoSuaPosseNaoSeuNomeVoceDirige, setVeiculoSuaPosseNaoSeuNomeVoceDirige] = useState('');
        const [detalheVeiculoSuaPosseNaoSeuNomeVoceDirige, setDetalheVeiculoSuaPosseNaoSeuNomeVoceDirige] = useState('');
        const [veiculoSuaPosseNaoSeuNomeProprietario, setVeiculoSuaPosseNaoSeuNomeProprietario] = useState('');
        const [veiculoSuaPosseNaoSeuNomeParentescoProprietario, setVeiculoSuaPosseNaoSeuNomeParentescoProprietario] = useState('');
    const [veiculoNenhumaCondicaoAnterior, setVeiculoNenhumaCondicaoAnterior] = useState('');
    let [lVeiculoNenhumaCondicaoAnterior, setLVeiculoNenhumaCondicaoAnterior] = useState([]);
        const [veiculoNenhumaCondicaoAnteriorPlaca, setVeiculoNenhumaCondicaoAnteriorPlaca] = useState('');
        const [veiculoNenhumaCondicaoAnteriorMarcaAnoModelo, setVeiculoNenhumaCondicaoAnteriorMarcaAnoModelo] = useState('');
        const [veiculoNenhumaCondicaoAnteriorCor, setVeiculoNenhumaCondicaoAnteriorCor] = useState('');
        const [veiculoNenhumaCondicaoAnteriorTaxaOk, setVeiculoNenhumaCondicaoAnteriorTaxaOk] = useState('');
        const [detalheVeiculoNenhumaCondicaoAnteriorTaxaOk, setDetalheVeiculoNenhumaCondicaoAnteriorTaxaOk] = useState('');
        const [veiculoNenhumaCondicaoAnteriorMultaPendente, setVeiculoNenhumaCondicaoAnteriorMultaPendente] = useState('');
        const [detalheVeiculoNenhumaCondicaoAnteriorMultaPendente, setDetalheVeiculoNenhumaCondicaoAnteriorMultaPendente] = useState('');
    const [pagouPrometeuPagamento, setPagouPrometeuPagamento] = useState('');
    const [detalhePagouPrometeuPagamento, setDetalhePagouPrometeuPagamento] = useState('');
    const [informacoesComplementares, setInformacoesComplementares] = useState('');
    
    useEffect(() => {
        document.title = '[SCIC] FIC';
        setaTituloSistema();
        validaToken();
        window.scroll(0,0);
    }, []);

    const preencheDadosFormulario = async (responseFormulario) => {
        setRgAnterior(responseFormulario.data.rgAnterior);
        setProfissao(responseFormulario.data.profissao);
        setEmpregoAtual(responseFormulario.data.empregoAtual);
        setReligiao(responseFormulario.data.religiao);
        setApelido(responseFormulario.data.apelido);
        setComQuemResideAtualmente(responseFormulario.data.comQuemResideAtualmente);
        setTodosEmail(responseFormulario.data.todosEmail);
        setResideResidiuApartir12anos(responseFormulario.data.resideResidiuApartir12anos);
        setMotivoNResidiuPaiEsposa(responseFormulario.data.motivoNResidiuPaiEsposa);
        setFamiliar(responseFormulario.data.familiar);
        setSeparadoDivorciado(responseFormulario.data.separadoDivorciado);
        setSeparadoDivorciadoNome(responseFormulario.data.separadoDivorciadoNome);
        setSeparadoDivorciadoCpf(responseFormulario.data.separadoDivorciadoCpf);
        setSeparadoDivorciadoRg(responseFormulario.data.separadoDivorciadoRg);
        setSeparadoDivorciadoLocalTrabalho(responseFormulario.data.separadoDivorciadoLocalTrabalho);
        setSeparadoDivorciadoApelido(responseFormulario.data.separadoDivorciadoApelido);
        setSeparadoDivorciadoEndereco(responseFormulario.data.separadoDivorciadoEndereco);
        setSeparadoDivorciadoProfissao(responseFormulario.data.separadoDivorciadoProfissao);
        setSeparadoDivorciadoRelacaoAtual(responseFormulario.data.separadoDivorciadoRelacaoAtual);
        setSeparadoDivorciadoNaturalidade(responseFormulario.data.separadoDivorciadoNaturalidade);
        setSeparadoDivorciadoNaturalEstado(responseFormulario.data.separadoDivorciadoNaturalEstado);
        setSeparadoDivorciadoRelacionamentoMesInicio(responseFormulario.data.separadoDivorciadoRelacionamentoMesInicio);
        setSeparadoDivorciadoRelacionamentoAnoInicio(responseFormulario.data.separadoDivorciadoRelacionamentoAnoInicio);
        setSeparadoDivorciadoRelacionamentoMesFim(responseFormulario.data.separadoDivorciadoRelacionamentoMesFim);
        setSeparadoDivorciadoRelacionamentoAnoFim(responseFormulario.data.separadoDivorciadoRelacionamentoAnoFim);
        setRelacionamento(responseFormulario.data.relacionamento);
        setRelacionamentoNome(responseFormulario.data.relacionamentoNome);
        setRelacionamentoCpf(responseFormulario.data.relacionamentoCpf);
        setRelacionamentoRg(responseFormulario.data.relacionamentoRg);
        setRelacionamentoLocalTrabalho(responseFormulario.data.relacionamentoLocalTrabalho);
        setRelacionamentoApelido(responseFormulario.data.relacionamentoApelido);
        setRelacionamentoEndereco(responseFormulario.data.relacionamentoEndereco);
        setRelacionamentoProfissao(responseFormulario.data.relacionamentoProfissao);
        setRelacionamentoDuracao(responseFormulario.data.relacionamentoDuracao);
        setRelacionamentoNaturalidade(responseFormulario.data.relacionamentoNaturalidade);
        setRelacionamentoNaturalEstado(responseFormulario.data.relacionamentoNaturalEstado);
        setProcessoPaternidade(responseFormulario.data.processoPaternidade);
        setDetalheProcessoPaternidade(responseFormulario.data.detalheProcessoPaternidade);
        setFilhoEnteado(responseFormulario.data.filhoEnteado);
        setFilhosEnteados(responseFormulario.data.filhosEnteados);
        setSustentoFilhoEnteado(responseFormulario.data.sustentoFilhoEnteado);
        setDetalheSustentoFilhoEnteado(responseFormulario.data.detalheSustentoFilhoEnteado);
        setCasamentoUniaoEstavel(responseFormulario.data.casamentoUniaoEstavel);
        setCasamentoUniaoEstavelNome(responseFormulario.data.casamentoUniaoEstavelNome);
        setCasamentoUniaoEstavelIdade(responseFormulario.data.casamentoUniaoEstavelIdade);
        setCasamentoUniaoEstavelNaturalidade(responseFormulario.data.casamentoUniaoEstavelNaturalidade);
        setCasamentoUniaoEstavelNaturalEstado(responseFormulario.data.casamentoUniaoEstavelNaturalEstado);
        setCasamentoUniaoEstavelCpf(responseFormulario.data.casamentoUniaoEstavelCpf);
        setCasamentoUniaoEstavelRg(responseFormulario.data.casamentoUniaoEstavelRg);
        setCasamentoUniaoEstavelApelido(responseFormulario.data.casamentoUniaoEstavelApelido);
        setCasamentoUniaoEstavelCondicao(responseFormulario.data.casamentoUniaoEstavelCondicao);
        setCasamentoUniaoEstavelDataCasamentoRelacionamento(responseFormulario.data.casamentoUniaoEstavelDataCasamentoRelacionamento);
        setCasamentoUniaoEstavelLocalCasamento(responseFormulario.data.casamentoUniaoEstavelLocalCasamento);
        setCasamentoUniaoEstavelProfissao(responseFormulario.data.casamentoUniaoEstavelProfissao);
        setCasamentoUniaoEstavelSalario(responseFormulario.data.casamentoUniaoEstavelSalario);
        setCasamentoUniaoEstavelEmpresa(responseFormulario.data.casamentoUniaoEstavelEmpresa);
        setCasamentoUniaoEstavelEmpresaEndereco(responseFormulario.data.casamentoUniaoEstavelEmpresaEndereco);
        setCasamentoUniaoEstavelFuncao(responseFormulario.data.casamentoUniaoEstavelFuncao);
        setExaminadoTratadoInternado(responseFormulario.data.examinadoTratadoInternado);
        setDetalheExaminadoTratadoInternado(responseFormulario.data.detalheExaminadoTratadoInternado);
        setTatuagem(responseFormulario.data.tatuagem);
        setDetalheTatuagem(responseFormulario.data.detalheTatuagem);
        setInternadoHospital(responseFormulario.data.internadoHospital);
        setDetalheInternadoHospital(responseFormulario.data.detalheInternadoHospital);
        setDesmaiou(responseFormulario.data.desmaiou);
        setDetalheDesmaiou(responseFormulario.data.detalheDesmaiou);
        setBebidaAlcoolica(responseFormulario.data.bebidaAlcoolica);
        setDetalheBebidaAlcoolica(responseFormulario.data.detalheBebidaAlcoolica);
        setFuma(responseFormulario.data.fuma);
        setDetalheFuma(responseFormulario.data.detalheFuma);
        setEntorpecente(responseFormulario.data.entorpecente);
        setDetalheEntorpecente(responseFormulario.data.detalheEntorpecente);
        setEntorpecenteFamilia(responseFormulario.data.entorpecenteFamilia);
        setDetalheEntorpecenteFamilia(responseFormulario.data.detalheEntorpecenteFamilia);
        setEntorpecenteRelacao(responseFormulario.data.entorpecenteRelacao);
        setDetalheEntorpecenteRelacao(responseFormulario.data.detalheEntorpecenteRelacao);
        setAntecedenteCriminalRelacao(responseFormulario.data.antecedenteCriminalRelacao);
        setDetalheAntecedenteCriminalRelacao(responseFormulario.data.detalheAntecedenteCriminalRelacao);
        setTestemunha1Nome(responseFormulario.data.testemunha1Nome);
        setTestemunha1Idade(responseFormulario.data.testemunha1Idade);
        setTestemunha1Naturalidade(responseFormulario.data.testemunha1Naturalidade);
        setTestemunha1NaturalEstado(responseFormulario.data.testemunha1NaturalEstado);
        setTestemunha1Cpf(responseFormulario.data.testemunha1Cpf);
        setTestemunha1Rg(responseFormulario.data.testemunha1Rg);
        setTestemunha1TempoConhece(responseFormulario.data.testemunha1TempoConhece);
        setTestemunha1Apelido(responseFormulario.data.testemunha1Apelido);
        setTestemunha1EnderecoResidencial(responseFormulario.data.testemunha1EnderecoResidencial);
        setTestemunha1Profissao(responseFormulario.data.testemunha1Profissao);
        setTestemunha1EnderecoComercial(responseFormulario.data.testemunha1EnderecoComercial);
        setTestemunha2Nome(responseFormulario.data.testemunha2Nome);
        setTestemunha2Idade(responseFormulario.data.testemunha2Idade);
        setTestemunha2Naturalidade(responseFormulario.data.testemunha2Naturalidade);
        setTestemunha2NaturalEstado(responseFormulario.data.testemunha2NaturalEstado);
        setTestemunha2Cpf(responseFormulario.data.testemunha2Cpf);
        setTestemunha2Rg(responseFormulario.data.testemunha2Rg);
        setTestemunha2TempoConhece(responseFormulario.data.testemunha2TempoConhece);
        setTestemunha2Apelido(responseFormulario.data.testemunha2Apelido);
        setTestemunha2EnderecoResidencial(responseFormulario.data.testemunha2EnderecoResidencial);
        setTestemunha2Profissao(responseFormulario.data.testemunha2Profissao);
        setTestemunha2EnderecoComercial(responseFormulario.data.testemunha2EnderecoComercial);
        setTestemunha3Nome(responseFormulario.data.testemunha3Nome);
        setTestemunha3Idade(responseFormulario.data.testemunha3Idade);
        setTestemunha3Naturalidade(responseFormulario.data.testemunha3Naturalidade);
        setTestemunha3NaturalEstado(responseFormulario.data.testemunha3NaturalEstado);
        setTestemunha3Cpf(responseFormulario.data.testemunha3Cpf);
        setTestemunha3Rg(responseFormulario.data.testemunha3Rg);
        setTestemunha3TempoConhece(responseFormulario.data.testemunha3TempoConhece);
        setTestemunha3Apelido(responseFormulario.data.testemunha3Apelido);
        setTestemunha3EnderecoResidencial(responseFormulario.data.testemunha3EnderecoResidencial);
        setTestemunha3Profissao(responseFormulario.data.testemunha3Profissao);
        setTestemunha3EnderecoComercial(responseFormulario.data.testemunha3EnderecoComercial);
        setTestemunha4Nome(responseFormulario.data.testemunha4Nome);
        setTestemunha4Idade(responseFormulario.data.testemunha4Idade);
        setTestemunha4Naturalidade(responseFormulario.data.testemunha4Naturalidade);
        setTestemunha4NaturalEstado(responseFormulario.data.testemunha4NaturalEstado);
        setTestemunha4Cpf(responseFormulario.data.testemunha4Cpf);
        setTestemunha4Rg(responseFormulario.data.testemunha4Rg);
        setTestemunha4TempoConhece(responseFormulario.data.testemunha4TempoConhece);
        setTestemunha4Apelido(responseFormulario.data.testemunha4Apelido);
        setTestemunha4EnderecoResidencial(responseFormulario.data.testemunha4EnderecoResidencial);
        setTestemunha4Profissao(responseFormulario.data.testemunha4Profissao);
        setTestemunha4EnderecoComercial(responseFormulario.data.testemunha4EnderecoComercial);
        setFaPmGmPcPtcConhecido(responseFormulario.data.faPmGmPcPtcConhecido);
        setFaPmGmPcPtc(responseFormulario.data.faPmGmPcPtc);
        setFolga(responseFormulario.data.folga);
        setCostumeLocalDiaSemana(responseFormulario.data.costumeLocalDiaSemana);
        setCostumeLocalFdsFeriado(responseFormulario.data.costumeLocalFdsFeriado);
        setSocioClube(responseFormulario.data.socioClube);
        setSocioClubes(responseFormulario.data.socioClubes);
        setSindicato(responseFormulario.data.sindicato);
        setSindicatos(responseFormulario.data.sindicatos);
        setPartidoPolitico(responseFormulario.data.partidoPolitico);
        setPartidosPoliticos(responseFormulario.data.partidosPoliticos);
        setProcessoJustica(responseFormulario.data.processoJustica);
        setProcessosJustica(responseFormulario.data.processosJustica);
        setProcessoJusticaJC(responseFormulario.data.processoJusticaJC);
        setProcessoJusticaJT(responseFormulario.data.processoJusticaJT);
        setProcessoJusticaJCR(responseFormulario.data.processoJusticaJCR);
        setProcessoJusticaJM(responseFormulario.data.processoJusticaJM);
        setProcessoJusticaVIJ(responseFormulario.data.processoJusticaVIJ);
        setProcessoJusticaJEC(responseFormulario.data.processoJusticaJEC);
        setOcorrenciaPolicial(responseFormulario.data.ocorrenciaPolicial);
        setOcorrenciasPoliciais(responseFormulario.data.ocorrenciasPoliciais);
        setIpSindIpm(responseFormulario.data.ipSindIpm);
        setListaIpSindIpm(responseFormulario.data.listaIpSindIpm);
        setPadCdCj(responseFormulario.data.padCdCj);
        setListaPadCdCj(responseFormulario.data.listaPadCdCj);
        setFamiliaOpPj(responseFormulario.data.familiaOpPj);
        setFamiliasOpPj(responseFormulario.data.familiasOpPj);
        setArmaDeFogo(responseFormulario.data.armaDeFogo);
        setDetalheArmaDeFogo(responseFormulario.data.detalheArmaDeFogo);
        setArmaDeFogoApreendida(responseFormulario.data.armaDeFogoApreendida);
        setDetalheArmaDeFogoApreendida(responseFormulario.data.detalheArmaDeFogoApreendida);
        setDetalheEmprego(responseFormulario.data.detalheEmprego);
        setTentouIngressarPm(responseFormulario.data.tentouIngressarPm);
        setTentouIngressarPmQuantidade(responseFormulario.data.tentouIngressarPmQuantidade);
        setTentouIngressarPmReprovou(responseFormulario.data.tentouIngressarPmReprovou);
        setTentouIngressarOutroEmprego(responseFormulario.data.tentouIngressarOutroEmprego);
        setTentouIngressarOutroEmpregoA(responseFormulario.data.tentouIngressarOutroEmpregoA);
        setTentouIngressarOutroEmpregoB(responseFormulario.data.tentouIngressarOutroEmpregoB);
        setTentouIngressarOutroEmpregoC(responseFormulario.data.tentouIngressarOutroEmpregoC);
        setTentouIngressarOutroEmpregoD(responseFormulario.data.tentouIngressarOutroEmpregoD);
        setTentouIngressarOutroEmpregoE(responseFormulario.data.tentouIngressarOutroEmpregoE);
        setServicoMilitar(responseFormulario.data.servicoMilitar);
        setServicoMilitarUnidade(responseFormulario.data.servicoMilitarUnidade);
        setServicoMilitarCompanhia(responseFormulario.data.servicoMilitarCompanhia);
        setServicoMilitarPelotao(responseFormulario.data.servicoMilitarPelotao);
        setServicoMilitarMesInicial(responseFormulario.data.servicoMilitarMesInicial);
        setServicoMilitarAnoInicial(responseFormulario.data.servicoMilitarAnoInicial);
        setServicoMilitarMesFinal(responseFormulario.data.servicoMilitarMesFinal);
        setServicoMilitarAnoFinal(responseFormulario.data.servicoMilitarAnoFinal);
        setServicoMilitarEndereco(responseFormulario.data.servicoMilitarEndereco);
        setServicoMilitarPontoReferencia(responseFormulario.data.servicoMilitarPontoReferencia);
        setServicoMilitarFuncao(responseFormulario.data.servicoMilitarFuncao);
        setServicoMilitarPunicao(responseFormulario.data.servicoMilitarPunicao);
        setServicoMilitarMotivoBaixa(responseFormulario.data.servicoMilitarMotivoBaixa);
        setEscolas(responseFormulario.data.escolas);
        setPunicaoEscolar(responseFormulario.data.punicaoEscolar);
        setDetalhePunicaoEscolar(responseFormulario.data.detalhePunicaoEscolar);
        setProtecaoCredito(responseFormulario.data.protecaoCredito);
        setProtecaoCreditoDivida(responseFormulario.data.protecaoCreditoDivida);
        setProtecaoCreditoDataCompra(responseFormulario.data.protecaoCreditoDataCompra);
        setProtecaoCreditoDataDeixouPagarDivida(responseFormulario.data.protecaoCreditoDataDeixouPagarDivida);
        setProtecaoCreditoInstituicaoCredora(responseFormulario.data.protecaoCreditoInstituicaoCredora);
        setProtecaoCreditoMotivoDivida(responseFormulario.data.protecaoCreditoMotivoDivida);
        setPropriedadeImovel(responseFormulario.data.propriedadeImovel);
        setPropriedadeImovelTipo(responseFormulario.data.propriedadeImovelTipo);
        setPropriedadeImovelTamanho(responseFormulario.data.propriedadeImovelTamanho);
        setPropriedadeImovelValorAvaliado(responseFormulario.data.propriedadeImovelValorAvaliado);
        setAtividadeEmpresarial(responseFormulario.data.atividadeEmpresarial);
        setAtividadeEmpresarialTipo(responseFormulario.data.atividadeEmpresarialTipo);
        setAtividadeEmpresarialNivelParticipacao(responseFormulario.data.atividadeEmpresarialNivelParticipacao);
        setAtividadeEmpresarialEmpresa(responseFormulario.data.atividadeEmpresarialEmpresa);
        setPermissaoDirigir(responseFormulario.data.permissaoDirigir);
        setCnh(responseFormulario.data.cnh);
        setNumeroRegistroCnh(responseFormulario.data.numeroRegistroCnh);
        setDataExpedicaoCnh(responseFormulario.data.dataExpedicaoCnh);
        setLocalCnh(responseFormulario.data.localCnh);
        setCategoriaCnh(responseFormulario.data.categoriaCnh);
        setCnhMulta(responseFormulario.data.cnhMulta);
        setDetalheCnhMulta(responseFormulario.data.detalheCnhMulta);
        setCnhApreendidaSuspensaCassada(responseFormulario.data.cnhApreendidaSuspensaCassada);
        setDetalheCnhApreendidaSuspensaCassada(responseFormulario.data.detalheCnhApreendidaSuspensaCassada);
        setAcidenteTransito(responseFormulario.data.acidenteTransito);
        setDetalheAcidenteTransito(responseFormulario.data.detalheAcidenteTransito);
        setVeiculoSeuNomeSuaPosse(responseFormulario.data.veiculoSeuNomeSuaPosse);
        setLVeiculoSeuNomeSuaPosse(responseFormulario.data.lVeiculoSeuNomeSuaPosse);
        setVeiculoSeuNomeNaoSuaPosse(responseFormulario.data.veiculoSeuNomeNaoSuaPosse);
        setLVeiculoSeuNomeNaoSuaPosse(responseFormulario.data.lVeiculoSeuNomeNaoSuaPosse);
        setVeiculoSuaPosseNaoSeuNome(responseFormulario.data.veiculoSuaPosseNaoSeuNome);
        setLVeiculoSuaPosseNaoSeuNome(responseFormulario.data.lVeiculoSuaPosseNaoSeuNome);
        setVeiculoNenhumaCondicaoAnterior(responseFormulario.data.veiculoNenhumaCondicaoAnterior);
        setLVeiculoNenhumaCondicaoAnterior(responseFormulario.data.lVeiculoNenhumaCondicaoAnterior);
        setPagouPrometeuPagamento(responseFormulario.data.pagouPrometeuPagamento);
        setDetalhePagouPrometeuPagamento(responseFormulario.data.detalhePagouPrometeuPagamento);
        setInformacoesComplementares(responseFormulario.data.informacoesComplementares);
    }

    const validaToken = async () => {
        document.getElementById('tokenInvalidoDiv').style.display = 'none';
        document.getElementById('conteudoDiv').style.display = 'none';

        const token = window.sessionStorage.getItem('token');
        const responseInfoToken = await axios.get(servidorBackEnd + '/getInfoTokenCandidato?token=' + token);
        if(responseInfoToken.data.cpf === undefined || responseInfoToken.data.nr_inscricao === undefined || responseInfoToken.data === '' || responseInfoToken.data.admin === true){
            document.getElementById('loadingDiv').style.display = 'none';
            document.getElementById('tokenInvalidoDiv').style.display = 'block';
            return false;
        }

        carregaCandidato(responseInfoToken.data.cpf, responseInfoToken.data.nr_inscricao);

        setIdCandidato(responseInfoToken.data.id_candidato);
        setNrInscricao(responseInfoToken.data.nr_inscricao);

        validaCarregaFoto(responseInfoToken.data.nr_inscricao);

        const responseFormulario = await axios.get(servidorBackEnd + '/getFormulario2301?nrInscricao=' + responseInfoToken.data.nr_inscricao);
        setDadosFormularioSalvo(responseFormulario.data);
        //se for diferente de null é pq já salvou então vamos carregar as variaveis para poder editar
        if(responseFormulario.data !== null){
            await preencheDadosFormulario(responseFormulario);
            await abreEscondeDivs(responseFormulario);
            
            document.getElementById('checkTermo1').checked = true;
            document.getElementById('checkTermo2').checked = true;
            document.getElementById('checkTermo3').checked = true;

            document.getElementById('botaoVoltarPreenchido').style.display = 'block';
        }
        else
            document.getElementById('botaoVoltarInstrucao').style.display = 'block';
        
        document.getElementById('loadingDiv').style.display = 'none';
        document.getElementById('conteudoDiv').style.display = 'block';
    }

    const abreEscondeDivs = async (responseFormulario) => {

        if(responseFormulario.data.separadoDivorciado === 'sim')
            document.getElementById('separadoDivorciadoDiv').style.display = 'block';

        if(responseFormulario.data.relacionamento === 'sim')
            document.getElementById('relacionamentoDiv').style.display = 'block';

        if(responseFormulario.data.processoPaternidade === 'sim')
            document.getElementById('processoPaternidadeDiv').style.display = 'block';

        if(responseFormulario.data.filhoEnteado === 'sim')
            document.getElementById('filhoEnteadoDiv').style.display = 'block';
        if(responseFormulario.data.sustentoFilhoEnteado === 'não')
            document.getElementById('sustentoFilhoEnteadoDiv').style.display = 'block';

        if(responseFormulario.data.casamentoUniaoEstavel === 'sim')
            document.getElementById('casamentoUniaoEstavelDiv').style.display = 'block';

        if(responseFormulario.data.examinadoTratadoInternado === 'sim')
            document.getElementById('examinadoTratadoInternadoDiv').style.display = 'block';

        if(responseFormulario.data.tatuagem === 'sim')
            document.getElementById('tatuagemDiv').style.display = 'block';

        if(responseFormulario.data.internadoHospital === 'sim')
            document.getElementById('internadoHospitalDiv').style.display = 'block';

        if(responseFormulario.data.desmaiou === 'sim')
            document.getElementById('desmaiouDiv').style.display = 'block';
        
        if(responseFormulario.data.bebidaAlcoolica === 'sim')
            document.getElementById('bebidaAlcoolicaDiv').style.display = 'block';

        if(responseFormulario.data.fuma === 'sim')
            document.getElementById('fumaDiv').style.display = 'block';

        if(responseFormulario.data.entorpecente === 'sim')
            document.getElementById('entorpecenteDiv').style.display = 'block';

        if(responseFormulario.data.entorpecenteFamilia === 'sim')
            document.getElementById('entorpecenteFamiliaDiv').style.display = 'block';
            
        if(responseFormulario.data.entorpecenteRelacao === 'sim')
            document.getElementById('entorpecenteRelacaoDiv').style.display = 'block';

        if(responseFormulario.data.antecedenteCriminalRelacao === 'sim')
            document.getElementById('antecedenteCriminalRelacaoDiv').style.display = 'block';

        if(responseFormulario.data.faPmGmPcPtcConhecido === 'sim')
            document.getElementById('faPmGmPcPtcConhecidoDiv').style.display = 'block';

        if(responseFormulario.data.socioClube === 'sim')
            document.getElementById('socioClubeDiv').style.display = 'block';

        if(responseFormulario.data.socioClubeNotificacaoPunicao === 'sim')
            document.getElementById('socioClubeNotificacaoPunicaoDiv').style.display = 'block';

        if(responseFormulario.data.sindicatoNotificacaoPunicao === 'sim')
            document.getElementById('sindicatoNotificacaoPunicaoDiv').style.display = 'block';

        if(responseFormulario.data.sindicato === 'sim')
            document.getElementById('sindicatoDiv').style.display = 'block';

        if(responseFormulario.data.partidoPolitico === 'sim')
            document.getElementById('partidoPoliticoDiv').style.display = 'block';

        if(responseFormulario.data.partidoPoliticoNotificacaoPunicao === 'sim')
            document.getElementById('partidoPoliticoNotificacaoPunicaoDiv').style.display = 'block';
                    
        if(responseFormulario.data.processoJustica === 'sim')
            document.getElementById('processoJusticaDiv').style.display = 'block';

        if(responseFormulario.data.ocorrenciaPolicial === 'sim')
            document.getElementById('ocorrenciaPolicialDiv').style.display = 'block';

        if(responseFormulario.data.ipSindIpm === 'sim')
            document.getElementById('ipSindIpmDiv').style.display = 'block';

        if(responseFormulario.data.padCdCj === 'sim')
            document.getElementById('padCdCjDiv').style.display = 'block';

        if(responseFormulario.data.familiaOpPj === 'sim')
            document.getElementById('familiaOpPjDiv').style.display = 'block';

        if(responseFormulario.data.armaDeFogo === 'sim')
            document.getElementById('armaDeFogoDiv').style.display = 'block';

        if(responseFormulario.data.armaDeFogoApreendida === 'sim')
            document.getElementById('armaDeFogoApreendidaDiv').style.display = 'block';

        if(responseFormulario.data.tentouIngressarPm === 'sim')
            document.getElementById('tentouIngressarPmDiv').style.display = 'block';

        if(responseFormulario.data.tentouIngressarOutroEmprego === 'sim')
            document.getElementById('tentouIngressarOutroEmpregoDiv').style.display = 'block';

        if(responseFormulario.data.servicoMilitar === 'sim')
            document.getElementById('servicoMilitarDiv').style.display = 'block';

        if(responseFormulario.data.punicaoEscolar === 'sim')
            document.getElementById('punicaoEscolarDiv').style.display = 'block';

        if(responseFormulario.data.protecaoCredito === 'sim')
            document.getElementById('protecaoCreditoDiv').style.display = 'block';

        if(responseFormulario.data.propriedadeImovel === 'sim')
            document.getElementById('propriedadeImovelDiv').style.display = 'block';
            
        if(responseFormulario.data.atividadeEmpresarial === 'sim')
            document.getElementById('atividadeEmpresarialDiv').style.display = 'block';

        if(responseFormulario.data.permissaoDirigir === 'sim')
            document.getElementById('permissaoDirigirDiv').style.display = 'block';

        if(responseFormulario.data.cnhMulta === 'sim')
            document.getElementById('cnhMultaDiv').style.display = 'block';
                    
        if(responseFormulario.data.cnhApreendidaSuspensaCassada === 'sim')
            document.getElementById('cnhApreendidaSuspensaCassadaDiv').style.display = 'block';

        if(responseFormulario.data.acidenteTransito === 'sim')
            document.getElementById('acidenteTransitoDiv').style.display = 'block';
                    
        if(responseFormulario.data.veiculoSeuNomeSuaPosse === 'sim')
            document.getElementById('veiculoSeuNomeSuaPosseDiv').style.display = 'block';

        if(responseFormulario.data.veiculoSeuNomeNaoSuaPosse === 'sim')
            document.getElementById('veiculoSeuNomeNaoSuaPosseDiv').style.display = 'block';

        if(responseFormulario.data.veiculoSuaPosseNaoSeuNome === 'sim')
            document.getElementById('veiculoSuaPosseNaoSeuNomeDiv').style.display = 'block';

        if(responseFormulario.data.veiculoNenhumaCondicaoAnterior === 'sim')
            document.getElementById('veiculoNenhumaCondicaoAnteriorDiv').style.display = 'block';
                    
        if(responseFormulario.data.pagouPrometeuPagamento === 'sim')
            document.getElementById('pagouPrometeuPagamentoDiv').style.display = 'block';
    }

    const carregaCandidato = async (cpf, numCandidato) => {
        const responseCandidato = await axios.post(servidorBackEnd + '/getCandidato2301', {
            cpf: cpf,
            nrInscricao: numCandidato
        });
        setCandidato(responseCandidato.data);
    }

    const atualizarFoto = async (e) => {
        e.preventDefault();
        
        // console.log('file: '+file)
        if(file === undefined){
            modalShow('Por favor,<br/>escolha um arquivo clicando em "Atualizar Foto 5x7"');
            return false;
        }
        const url = servidorBackEnd + '/uploadFoto2301?nrInscricao='+nrInscricao+'&idFoto='+informacaoFoto.id_foto;

        let formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', file.name);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'crossDomain': 'true',
            },
        };
        
        try{
            const responseUploadFoto = await axios.post(url, formData, config);
            if(responseUploadFoto.data !== 'erro'){
                if(responseUploadFoto.data === 'ok')
                    validaCarregaFoto(nrInscricao);
                else
                    modalShow(responseUploadFoto.data);
            }
        }
        catch(err){
            if(err.response.data !== undefined)
                modalShow(err.response.data.message);
            else
                modalShow('<b style="color:red">Arquivo INVÁLIDO</b><br/><br/>- Por favor, tente utilizar uma imagem com uma das extensões: <b>JPG, JPEG, PNG</b><br/>- Tamanho máximo permitido: <b>2 Megabytes</b><br/>- Tente novamente')
            return false;
        }
    }

    const validaCarregaFoto = async(nrInscricao) => {
        const responseInfoFoto = await axios.get(servidorBackEnd + '/getCandidatoFoto2301?nrInscricao=' + nrInscricao);
        if(responseInfoFoto.data !== null){
            setInformacaoFoto(responseInfoFoto.data);

            const responseFoto = await axios.get(servidorBackEnd + '/getCandidatoOnlyFoto2301?nrInscricao=' + nrInscricao);
            setImagemFoto(responseFoto.data);
            document.getElementById('file').value = '';
            
            // const extensao = fileName.substring(fileName.lastIndexOf('.'), fileName.length).toLowerCase();
            // if(extensao !== '.ret'){
            //     modalShow(`O arquivo <b>${fileName}</b> não parece um arquivo de retorno, por favor verifique e tente novamente`);
            //     return false;
            // }
        }
    }

    const modalClose = () => {
        setMsgErroModalDiv('');
        setShow(false);
    }

    const modalShow = (msg) => {
        setMsgErroModalDiv(msg);
        setShow(true);
    };

    const adicionaResideResidiuApartir12anos = async (e) => {
        e.preventDefault();
        let msgErro = '';
        const tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE B - INFORMAÇÕES RESIDENCIAIS</div>';
        if(resideResidiuApartir12anosMesInicial === '')
            msgErro += '<li>Selecionar o <b>Mês Inicial</b> de onde reside/residiu</li>';
        if(resideResidiuApartir12anosAnoInicial.length < 4)
            msgErro += '<li>Preencher o <b>Ano Inicial</b> de onde reside/residiu, formato aaaa</li>';
        if(resideResidiuApartir12anosMesFinal === '')
            msgErro += '<li>Selecionar o <b>Mês Final</b> de onde reside/residiu</li>';
        if(resideResidiuApartir12anosAnoFinal.length < 4)
            msgErro += '<li>Preencher o <b>Ano Final</b> de onde reside/residiu, formato aaaa</li>';
        if(resideResidiuApartir12anosEndereco.length < 3)
            msgErro += '<li>Preencher o <b>Endereço</b> de onde reside/residiu, mínimo 3 caracteres</li>';
        if(resideResidiuApartir12anosCep.length < 9)
            msgErro += '<li>Preencher o <b>CEP</b> de onde reside/residiu, mínimo 9 caracteres</li>';
        if(resideResidiuApartir12anosBairro.length < 3)
            msgErro += '<li>Preencher o <b>Bairro</b> de onde reside/residiu, mínimo 3 caracteres</li>';
        if(resideResidiuApartir12anosCidade.length < 3)
            msgErro += '<li>Preencher a <b>Cidade</b> de onde reside/residiu, mínimo 3 caracteres</li>';
        if(resideResidiuApartir12anosEstado === '')
            msgErro += '<li>Selecionar o <b>Estado</b> de onde reside/residiu</li>';
        if(resideResidiuApartir12anosPontoReferencia.length < 3)
            msgErro += '<li>Preencher o <b>Ponto de Referência (Batalhão PM da área)</b>, mínimo 3 caracteres</li>';
        if(resideResidiuApartir12anosResidiuCom.length < 3)
            msgErro += '<li>Preencher <b>Com quem residiu</b>, mínimo 3 caracteres</li>';
        if(resideResidiuApartir12anosGMaps.length < 3)
            msgErro += '<li>Preencher o <b>Link do Endereço no Google Maps</b></li>';

        if(msgErro.length > 0){
            modalShow( msgInicialErro + tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErro + '</ul>');
            return false;
        }

        let auxResideResidiuApartir12anosNumero = resideResidiuApartir12anosNumero;
        if(resideResidiuApartir12anosNumero.trim().length === 0)
            auxResideResidiuApartir12anosNumero = 'S/N';
        let auxResideResidiuApartir12anosComplemento = resideResidiuApartir12anosComplemento;
        if(resideResidiuApartir12anosComplemento.trim().length === 0)
            auxResideResidiuApartir12anosComplemento = 'Nenhum';
        let auxResideResidiuApartir12anosMoradorIndicado = resideResidiuApartir12anosMoradorIndicado;
        if(resideResidiuApartir12anosMoradorIndicado.trim().length === 0)
            auxResideResidiuApartir12anosMoradorIndicado ='Nenhum';

        resideResidiuApartir12anos.push({mêsInicial: resideResidiuApartir12anosMesInicial, anoInicial: resideResidiuApartir12anosAnoInicial, mêsFinal: resideResidiuApartir12anosMesFinal, anoFinal: resideResidiuApartir12anosAnoFinal, endereço: resideResidiuApartir12anosEndereco, número: auxResideResidiuApartir12anosNumero, complemento: auxResideResidiuApartir12anosComplemento, pontoReferência: resideResidiuApartir12anosPontoReferencia, bairro: resideResidiuApartir12anosBairro, cidade: resideResidiuApartir12anosCidade, estado: resideResidiuApartir12anosEstado, cep: resideResidiuApartir12anosCep, residiuCom: resideResidiuApartir12anosResidiuCom, moradorIndicado: auxResideResidiuApartir12anosMoradorIndicado, gMaps: resideResidiuApartir12anosGMaps });
        setResideResidiuApartir12anos([...resideResidiuApartir12anos]);

        setResideResidiuApartir12anosMesInicial('');
        setResideResidiuApartir12anosMesFinal('');
        setResideResidiuApartir12anosAnoInicial('');
        setResideResidiuApartir12anosAnoFinal('');
        setResideResidiuApartir12anosEndereco('');
        setResideResidiuApartir12anosNumero('');
        setResideResidiuApartir12anosComplemento('');
        setResideResidiuApartir12anosPontoReferencia('');
        setResideResidiuApartir12anosBairro('');
        setResideResidiuApartir12anosCidade('');
        setResideResidiuApartir12anosEstado('');
        setResideResidiuApartir12anosCep('');
        setResideResidiuApartir12anosResidiuCom('');
        setResideResidiuApartir12anosMoradorIndicado('');
        setResideResidiuApartir12anosGMaps('');
    }

    const removeResideResidiuApartir12anos = async (e, endereço, mêsInicial, anoInicial, mêsFinal, anoFinal, pontoReferência, residiuCom) => {
        e.preventDefault();
        setResideResidiuApartir12anos([...resideResidiuApartir12anos.filter(resideResidiu => ((resideResidiu.endereço != endereço) || (resideResidiu.mêsInicial != mêsInicial) || (resideResidiu.anoInicial != anoInicial) || (resideResidiu.mêsFinal != mêsFinal) || (resideResidiu.anoFinal != anoFinal) || (resideResidiu.pontoReferência != pontoReferência) || (resideResidiu.residiuCom != residiuCom) ))]);
    }

    const adicionaFamiliar = async (e) => {
        e.preventDefault();
        let msgErro = '';
        const tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE C - INFORMAÇÕES FAMILIARES</div>';
        if(familiarNome.length < 3)
            msgErro += '<li>Preencher o <b>Nome</b> do Familiar, mínimo 3 caracteres</li>';
        if(familiarParentesco.length < 3)
            msgErro += '<li>Preencher o <b>Grau de Parentesco</b> do Familiar, mínimo 3 caracteres</li>';
        if(familiarDataNascimento.length < 10)
            msgErro += '<li>Preencher a <b>Data de Nascimento</b> do Familiar, formato dd/mm/aaaa</li>';
        if(familiarSituacao === '')
            msgErro += '<li>Selecionar a <b>Situação</b> do Familiar</li>';
        if(familiarApelido.length < 2)
            msgErro += '<li>Preencher o <b>Apelido</b> do Familiar, mínimo 2 caracteres</li>';
        if(familiarIdade.length < 1)
            msgErro += '<li>Preencher a <b>Idade</b> do Familiar, mínimo 1 caracter</li>';
        if(familiarNaturalidade.length < 3)
            msgErro += '<li>Preencher a <b>Naturalidade</b> do Familiar, mínimo 3 caracteres</li>';
        if(familiarNaturalEstado === '')
            msgErro += '<li>Selecionar o <b>Estado da Naturalidade</b> do Familiar</li>';
        if(familiarCpf.length < 14)
            msgErro += '<li>Preencher o <b>CPF</b> do Familiar, mínimo 14 caracteres</li>';
        if(familiarRg.length < 3)
            msgErro += '<li>Preencher o <b>RG</b> do Familiar, mínimo 3 caracteres</li>';
        if(familiarEndereco.length < 3)
            msgErro += '<li>Preencher o <b>Endereço</b> do Familiar, mínimo 3 caracteres</li>';
        if(familiarComplemento.length < 3)
            msgErro += '<li>Preencher o <b>Complemento do Endereço</b> do Familiar, mínimo 3 caracteres</li>';
        if(familiarCep.length < 9)
            msgErro += '<li>Preencher o <b>CEP</b> do Familiar, mínimo 9 caracteres</li>';
        if(familiarBairro.length < 3)
            msgErro += '<li>Preencher o <b>Bairro</b> do Familiar, mínimo 3 caracteres</li>';
        if(familiarCidade.length < 3)
            msgErro += '<li>Preencher a <b>Cidade</b> do Familiar, mínimo 3 caracteres</li>';
        if(familiarEstado === '')
            msgErro += '<li>Selecionar o <b>Estado</b> do Familiar</li>';
        if(familiarProfissao.length < 3)
            msgErro += '<li>Preencher a <b>Profissão</b> do Familiar, mínimo 3 caracteres</li>';
        if(familiarLocalTrabalho.length < 3)
            msgErro += '<li>Preencher o <b>Local de Trabalho/Nome da Empresa</b> do Familiar, mínimo 3 caracteres</li>';

        if(msgErro.length > 0){
            modalShow( msgInicialErro + tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErro + '</ul>');
            return false;
        }

        let auxFamiliarNumero = familiarNumero;
        if(familiarNumero.trim().length === 0)
            auxFamiliarNumero = 'S/N';

        familiar.push({parentesco: familiarParentesco, idade: familiarIdade, situação: familiarSituacao, nome: familiarNome, apelido: familiarApelido, dataNascimento: familiarDataNascimento, naturalidade: familiarNaturalidade, naturalEstado: familiarNaturalEstado, cpf: familiarCpf, rg: familiarRg, endereço: familiarEndereco, número: auxFamiliarNumero, complemento: familiarComplemento, cep: familiarCep, bairro: familiarBairro, cidade: familiarCidade, estado: familiarEstado, profissão: familiarProfissao, localTrabalho: familiarLocalTrabalho });
        setFamiliar([...familiar]);

        setFamiliarParentesco('');
        setFamiliarIdade('');
        setFamiliarSituacao('');
        setFamiliarNome('');
        setFamiliarApelido('');
        setFamiliarDataNascimento('');
        setFamiliarNaturalidade('');
        setFamiliarNaturalEstado('');
        setFamiliarCpf('');
        setFamiliarRg('');
        setFamiliarEndereco('');
        setFamiliarNumero('');
        setFamiliarComplemento('');
        setFamiliarCep('');
        setFamiliarBairro('');
        setFamiliarCidade('');
        setFamiliarEstado('');
        setFamiliarProfissao('');
        setFamiliarLocalTrabalho('');
    }

    const removeFamiliar = async (e, nome, parentesco, cpf, rg) => {
        e.preventDefault();
        setFamiliar([...familiar.filter(familia => ((familia.nome != nome) || (familia.parentesco != parentesco) || (familia.cpf != cpf) || (familia.rg != rg) ))]);
    }

    const adicionaFilhosEnteados = async (e) => {
        e.preventDefault();
        let msgErro = '';
        const tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE C - FILHO(A)/ENTEADO(A)</div>';
        if(filhosEnteadosNome.length < 3)
            msgErro += '<li>Preencher o <b>Nome</b> do filho(a)/enteado(a), mínimo 3 caracteres</li>';
        if(filhosEnteadosIdade.length < 1)
            msgErro += '<li>Preencher a <b>Idade</b> do filho(a)/enteado(a), mínimo 1 caracter</li>';
        if(filhosEnteadosNaturalidade.length < 3)
            msgErro += '<li>Preencher a <b>Naturalidade</b> do filho(a)/enteado(a), mínimo 3 caracteres</li>';
        if(filhosEnteadosNaturalEstado === '')
            msgErro += '<li>Selecionar o <b>Estado da Naturalidade</b> do filho(a)/enteado(a)</li>';
        if(filhosEnteadosCpf.length < 14)
            msgErro += '<li>Preencher o <b>CPF</b> do filho(a)/enteado(a), mínimo 14 caracteres</li>';
        if(filhosEnteadosRg.length < 3)
            msgErro += '<li>Preencher o <b>RG</b> do filho(a)/enteado(a), mínimo 3 caracteres</li>';
        if(filhosEnteadosEndereco.length < 3)
            msgErro += '<li>Preencher o <b>Endereço</b> do filho(a)/enteado(a), mínimo 3 caracteres</li>';
        if(filhosEnteadosComplemento.length < 3)
            msgErro += '<li>Selecionar o <b>Complemento do Endereço</b> do filho(a)/enteado(a), mínimo 3 caracteres</li>';
        if(filhosEnteadosCep.length < 9)
            msgErro += '<li>Preencher o <b>CEP</b> do filho(a)/enteado(a), mínimo 9 caracteres</li>';
        if(filhosEnteadosBairro.length < 3)
            msgErro += '<li>Preencher o <b>Bairro</b> do filho(a)/enteado(a), mínimo 3 caracteres</li>';
        if(filhosEnteadosCidade.length < 3)
            msgErro += '<li>Preencher a <b>Cidade</b> do filho(a)/enteado(a), mínimo 3 caracteres</li>';
        if(filhosEnteadosEstado === '')
            msgErro += '<li>Selecionar o <b>Estado</b> do filho(a)/enteado(a)</li>';
        if(filhosEnteadosResideCom.length < 3)
            msgErro += '<li>Preencher <b>Com quem Reside</b>, o filho(a)/enteado(a), mínimo 3 caracteres</li>';
        if(filhosEnteadosApelido.length < 2)
            msgErro += '<li>Preencher o <b>Nome ou o apelido pelo qual é conhecido(a)</b>, o filho(a)/enteado(a), mínimo 2 caracteres</li>';
        if(filhosEnteadosProfissao.length < 3)
            msgErro += '<li>Preencher a <b>Profissão</b> do filho(a)/enteado(a), mínimo 3 caracteres</li>';
        if(filhosEnteadosTrabalhoEstudo.length < 3)
            msgErro += '<li>Preencher o <b>Local de Trabalho ou Local onde Estuda</b>, do filho(a)/enteado(a), mínimo 3 caracteres</li>';
        if(filhosEnteadosCondicao === '')
            msgErro += '<li>Selecionar a <b>Condição</b> do filho(a)/enteado(a)</li>';

        if(msgErro.length > 0){
            modalShow( msgInicialErro + tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErro + '</ul>');
            return false;
        }

        let auxFilhosEnteadosNumero = filhosEnteadosNumero;
        if(filhosEnteadosNumero.trim().length === 0)
            auxFilhosEnteadosNumero = 'S/N';

        filhosEnteados.push({ nome: filhosEnteadosNome, idade: filhosEnteadosIdade, naturalidade: filhosEnteadosNaturalidade, naturalEstado: filhosEnteadosNaturalEstado, cpf: filhosEnteadosCpf, rg: filhosEnteadosRg, endereço: filhosEnteadosEndereco,
            número: auxFilhosEnteadosNumero, complemento: filhosEnteadosComplemento, cep: filhosEnteadosCep, bairro: filhosEnteadosBairro, cidade: filhosEnteadosCidade, estado: filhosEnteadosEstado, 
            resideCom: filhosEnteadosResideCom, apelido: filhosEnteadosApelido, profissão: filhosEnteadosProfissao, trabalhoEstudo: filhosEnteadosTrabalhoEstudo, condição: filhosEnteadosCondicao });
        setFilhosEnteados([...filhosEnteados]);

        setFilhosEnteadosNome('');
        setFilhosEnteadosIdade('');
        setFilhosEnteadosNaturalidade('');
        setFilhosEnteadosNaturalEstado('');
        setFilhosEnteadosCpf('');
        setFilhosEnteadosRg('');
        setFilhosEnteadosEndereco('');
        setFilhosEnteadosNumero('');
        setFilhosEnteadosComplemento('');
        setFilhosEnteadosCep('');
        setFilhosEnteadosBairro('');
        setFilhosEnteadosCidade('');
        setFilhosEnteadosEstado('');
        setFilhosEnteadosResideCom('');
        setFilhosEnteadosApelido('');
        setFilhosEnteadosProfissao('');
        setFilhosEnteadosTrabalhoEstudo('');
        setFilhosEnteadosCondicao('');
    }

    const removeFilhosEnteados = async (e, nome, idade, cpf, rg) => {
        e.preventDefault();
        setFilhosEnteados([...filhosEnteados.filter(fe => ((fe.nome != nome) || (fe.idade != idade) || (fe.cpf != cpf) || (fe.rg != rg) ))]);
    }

    const adicionaFaPmGmPcPtc = async (e) => {
        e.preventDefault();

        let msgErro = '';
        const tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE D - PARENTE/AMIGO(A) FORÇA ARMADA/POLÍCIA MILITAR/GUARDA MUNICIPAL/POLÍCIA TÉCNICO-CIENTÍFICA</div>';
        
        if(faPmGmPcPtcNome.length < 3)
            msgErro += '<li>Preencher o <b>Nome</b> do parente/amigo(a), mínimo 3 caracteres</li>';
        if(faPmGmPcPtcGrauParentesco.length < 3)
            msgErro += '<li>Preencher o <b>Grau de Parentesco</b> do parente/amigo(a), mínimo 3 caracteres</li>';
        if(faPmGmPcPtcSituacao === '')
            msgErro += '<li>Selecionar a <b>Situação</b> do parente/amigo(a)</li>';
        if(faPmGmPcPtcCpf.length < 14)
            msgErro += '<li>Preencher o <b>CPF</b> do parente/amigo(a), mínimo 14 caracteres</li>';
        if(faPmGmPcPtcRg.length < 3)
            msgErro += '<li>Preencher o <b>RG</b> do parente/amigo(a), mínimo 3 caracteres</li>';
        if(faPmGmPcPtcTempoConhece.length < 3)
            msgErro += '<li>Preencher o <b>Tempo que se conhecem</b>, do amigo(a), mínimo 3 caracteres, preencha com --- caso seja parente</li>';
        if(faPmGmPcPtcCargoPostoGraduacao.length < 3)
            msgErro += '<li>Preencher o <b>Cargo/Posto/Graduação</b> do parente/amigo(a), mínimo 3 caracteres</li>';
        if(faPmGmPcPtcInstituicao.length < 3)
            msgErro += '<li>Preencher a <b>Instituição em que trabalha/trabalhou</b>, do parente/amigo(a), mínimo 3 caracteres</li>';
        if(faPmGmPcPtcUnidade.length < 3)
            msgErro += '<li>Preencher a <b>Unidade em que serve/serviu</b>, do parente/amigo(a), mínimo 3 caracteres</li>';
        if(faPmGmPcPtcFuncao.length < 3)
            msgErro += '<li>Preencher a <b>Função que exerce/exerceu</b>, do parente/amigo(a), mínimo 3 caracteres</li>';

        if(msgErro.length > 0){
            modalShow( msgInicialErro + tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErro + '</ul>');
            return false;
        }

        faPmGmPcPtc.push({nome: faPmGmPcPtcNome, grauParentesco: faPmGmPcPtcGrauParentesco, situação: faPmGmPcPtcSituacao, cpf: faPmGmPcPtcCpf, rg: faPmGmPcPtcRg, tempoConhece: faPmGmPcPtcTempoConhece, cargoPostoGraduação: faPmGmPcPtcCargoPostoGraduacao, instituição: faPmGmPcPtcInstituicao, unidade: faPmGmPcPtcUnidade, função: faPmGmPcPtcFuncao });
        setFaPmGmPcPtc([...faPmGmPcPtc]);

        setFaPmGmPcPtcNome('');
        setFaPmGmPcPtcGrauParentesco('');
        setFaPmGmPcPtcSituacao('');
        setFaPmGmPcPtcCpf('');
        setFaPmGmPcPtcRg('');
        setFaPmGmPcPtcTempoConhece('');
        setFaPmGmPcPtcCargoPostoGraduacao('');
        setFaPmGmPcPtcInstituicao('');
        setFaPmGmPcPtcUnidade('');
        setFaPmGmPcPtcFuncao('');
    }

    const removeFaPmGmPcPtc = async (e, nome, grauParentesco, cpf, rg, instituição ) => {
        e.preventDefault();
        setFaPmGmPcPtc([...faPmGmPcPtc.filter(parenteAmigo => ((parenteAmigo.nome != nome) || (parenteAmigo.grauParentesco != grauParentesco) || (parenteAmigo.cpf != cpf) || (parenteAmigo.rg != rg) || (parenteAmigo.instituição != instituição) ))]);
    }

    const adicionaSocioClube = async (e) => {
        e.preventDefault();

        let msgErro = '';
        const tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE D - SÓCIO DE ALGUM CLUBE/ASSOCIAÇÃO DESPORTIVA</div>';
        
        if(socioClubeNome.length < 3)
            msgErro += '<li>Preencher o <b>Nome do Clube</b>, mínimo 3 caracteres</li>';
        if(socioClubeMesInicial === '')
            msgErro += '<li>Selecionar o <b>Mês Inicial</b> do Período em que foi sócio</li>';
        if(socioClubeAnoInicial.length < 4)
            msgErro += '<li>Preencher o <b>Ano Inicial</b> do Período em que foi sócio, formato aaaa</li>';
        if(socioClubeMesFinal === '')
            msgErro += '<li>Selecionar o <b>Mês Final</b> do Período em que foi sócio</li>';
        if(socioClubeAnoFinal.length < 4)
            msgErro += '<li>Preencher o <b>Ano Final</b> do Período em que foi sócio, formato aaaa</li>';
        if(socioClubeEndereco.length < 3)
            msgErro += '<li>Preencher o <b>Endereço</b> do Clube/Associação Desportiva, mínimo 3 caracteres</li>';
        if(socioClubeComplemento.length < 3)
            msgErro += '<li>Preencher o <b>Complemento do Endereço</b> do Clube/Associação Desportiva, mínimo 3 caracteres</li>';
        if(socioClubeCep.length < 9)
            msgErro += '<li>Preencher o <b>CEP</b> do Clube/Associação Desportiva, mínimo 9 caracteres</li>';
        if(socioClubeBairro.length < 3)
            msgErro += '<li>Preencher o <b>Bairro</b> do Clube/Associação Desportiva, mínimo 3 caracteres</li>';
        if(socioClubeCidade.length < 3)
            msgErro += '<li>Preencher a <b>Cidade</b> do Clube/Associação Desportiva, mínimo 3 caracteres</li>';
        if(socioClubeEstado === '')
            msgErro += '<li>Selecionar o <b>Estado</b> do Clube/Associação Desportiva</li>';
        if(socioClubePontoReferencia.length < 3)
            msgErro += '<li>Preencher o <b>Ponto de Referência (Batalhão PM da área)</b> do Clube/Associação Desportiva, mínimo 3 caracteres</li>';
        if(socioClubeAtividade.length < 3)
            msgErro += '<li>Preencher <b>a(s) Atividade(s) que você pratica(va)</b> no Clube/Associação Desportiva, mínimo 3 caracteres</li>';
        if(socioClubeNotificacaoPunicao === '')
            msgErro += '<li>Selecionar se <b>chegou a sofrer alguma Notificação/Punição</b> no Clube/Associação Desportiva</li>';
        if(socioClubeNotificacaoPunicao === 'sim'){
            if(detalheSocioClubeNotificacaoPunicao.length < 3)
                msgErro += '<li>Preencher o <b>Detalhe da Notificação/Punição no Clube/Associação Desportiva</b>, mínimo 3 caracteres</li>';
        }

        if(msgErro.length > 0){
            modalShow( msgInicialErro + tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErro + '</ul>');
            return false;
        }

        let auxSocioClubeNumero = socioClubeNumero;
        if(socioClubeNumero.trim().length === 0)
            auxSocioClubeNumero = 'S/N';
        
        socioClubes.push({ nome: socioClubeNome, mêsInicial: socioClubeMesInicial, anoInicial: socioClubeAnoInicial, mêsFinal: socioClubeMesFinal, anoFinal: socioClubeAnoFinal, endereço: socioClubeEndereco,
            número: auxSocioClubeNumero, complemento: socioClubeComplemento, cep: socioClubeCep, bairro: socioClubeBairro, cidade: socioClubeCidade, estado: socioClubeEstado,
            pontoReferência: socioClubePontoReferencia, atividade: socioClubeAtividade, notificaçãoPunição: socioClubeNotificacaoPunicao, detalheNotificaçãoPunição: detalheSocioClubeNotificacaoPunicao });
        setSocioClubes([...socioClubes]);
        
        setSocioClubeNome('');
        setSocioClubeMesInicial('');
        setSocioClubeAnoInicial('');
        setSocioClubeMesFinal('');
        setSocioClubeAnoFinal('');
        setSocioClubeEndereco('');
        setSocioClubeNumero('');
        setSocioClubeComplemento('');
        setSocioClubeCep('');
        setSocioClubeBairro('');
        setSocioClubeCidade('');
        setSocioClubeEstado('');
        setSocioClubePontoReferencia('');
        setSocioClubeAtividade('');
        setSocioClubeNotificacaoPunicao('');
        setDetalheSocioClubeNotificacaoPunicao('');
        document.getElementById('socioClubeNotificacaoPunicaoDiv').style.display = 'none';
    }

    const removeSocioClube = async (e, nome, mêsInicial, anoInicial, mêsFinal, anoFinal, atividade ) => {
        e.preventDefault();
        setSocioClubes([...socioClubes.filter(sc => ((sc.nome != nome) || (sc.mêsInicial != mêsInicial) || (sc.anoInicial != anoInicial) || (sc.mêsFinal != mêsFinal) || (sc.anoFinal != anoFinal) || (sc.atividade != atividade) ))]);
    }

    const adicionaSindicato = async (e) => {
        e.preventDefault();

        let msgErro = '';
        const tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE D - SINDICATO/ASSOCIAÇÃO DE CLASSE</div>';
        
        if(sindicatoNome.length < 3)
            msgErro += '<li>Preencher o <b>Nome da Entidade</b> do Sindicato/Associação de Classe, mínimo 3 caracteres</li>';
        if(sindicatoMesInicial === '')
            msgErro += '<li>Selecionar o <b>Mês Inicial</b> do Período de filiação no Sindicato/Associação de Classe</li>';
        if(sindicatoAnoInicial.length < 4)
            msgErro += '<li>Preencher o <b>Ano Inicial</b> do Período de filiação no Sindicato/Associação de Classe, formato aaaa</li>';
        if(sindicatoMesFinal === '')
            msgErro += '<li>Selecionar o <b>Mês Final</b> do Período de filiação no Sindicato/Associação de Classe</li>';
        if(sindicatoAnoFinal.length < 4)
            msgErro += '<li>Preencher o <b>Ano Final</b> do Período de filiação no Sindicato/Associação de Classe, formato aaaa</li>';
        if(sindicatoEndereco.length < 3)
            msgErro += '<li>Preencher o <b>Endereço</b> do Sindicato/Associação de Classe, mínimo 3 caracteres</li>';
        if(sindicatoComplemento.length < 3)
            msgErro += '<li>Preencher o <b>Complemento do Endereço</b> do Sindicato/Associação de Classe, mínimo 3 caracteres</li>';
        if(sindicatoCep.length < 9)
            msgErro += '<li>Preencher o <b>CEP</b> do Sindicato/Associação de Classe, mínimo 9 caracteres</li>';
        if(sindicatoBairro.length < 3)
            msgErro += '<li>Preencher o <b>Bairro</b> do Sindicato/Associação de Classe, mínimo 3 caracteres</li>';
        if(sindicatoCidade.length < 3)
            msgErro += '<li>Preencher a <b>Cidade</b> do Sindicato/Associação de Classe, mínimo 3 caracteres</li>';
        if(sindicatoEstado === '')
            msgErro += '<li>Selecionar o <b>Estado</b> do Sindicato/Associação de Classe</li>';
        if(sindicatoPontoReferencia.length < 3)
            msgErro += '<li>Preencher o <b>Ponto de Referência (Batalhão PM da área)</b> do Sindicato/Associação de Classe, mínimo 3 caracteres</li>';
        if(sindicatoNotificacaoPunicao === '')
            msgErro += '<li>Selecionar se <b>chegou a sofrer alguma Notificação/Punição</b> no Sindicato/Associação de Classe</li>';
        if(sindicatoNotificacaoPunicao === 'sim'){
            if(detalheSindicatoNotificacaoPunicao.length < 3)
                msgErro += '<li>Preencher o <b>Detalhe da Notificação/Punição</b> no Sindicato/Associação de Classe, mínimo 3 caracteres</li>';
        }

        if(msgErro.length > 0){
            modalShow( msgInicialErro + tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErro + '</ul>');
            return false;
        }

        let auxSindicatoNumero = sindicatoNumero;
        if(sindicatoNumero.trim().length === 0)
            auxSindicatoNumero = 'S/N';
        
        sindicatos.push({ nome: sindicatoNome, mêsInicial: sindicatoMesInicial, anoInicial: sindicatoAnoInicial, mêsFinal: sindicatoMesFinal, anoFinal: sindicatoAnoFinal, endereço: sindicatoEndereco,
            número: auxSindicatoNumero, complemento: sindicatoComplemento, cep: sindicatoCep, bairro: sindicatoBairro, cidade: sindicatoCidade, estado: sindicatoEstado,
            pontoReferência: sindicatoPontoReferencia, notificaçãoPunição: sindicatoNotificacaoPunicao, detalheNotificaçãoPunição: detalheSindicatoNotificacaoPunicao });
        setSindicatos([...sindicatos]);
        
        setSindicatoNome('');
        setSindicatoMesInicial('');
        setSindicatoAnoInicial('');
        setSindicatoMesFinal('');
        setSindicatoAnoFinal('');
        setSindicatoEndereco('');
        setSindicatoNumero('');
        setSindicatoComplemento('');
        setSindicatoCep('');
        setSindicatoBairro('');
        setSindicatoCidade('');
        setSindicatoEstado('');
        setSindicatoPontoReferencia('');
        setSindicatoNotificacaoPunicao('');
        setDetalheSindicatoNotificacaoPunicao('');
        document.getElementById('sindicatoNotificacaoPunicaoDiv').style.display = 'none';
    }

    const removeSindicato = async (e, nome, mêsInicial, anoInicial, mêsFinal, anoFinal, pontoReferência ) => {
        e.preventDefault();
        setSindicatos([...sindicatos.filter(sind => ((sind.nome != nome) || (sind.mêsInicial != mêsInicial) || (sind.anoInicial != anoInicial) || (sind.mêsFinal != mêsFinal) || (sind.anoFinal != anoFinal) || (sind.pontoReferência != pontoReferência) ))]);
    }

    const adicionaPartidoPolitico = async (e) => {
        e.preventDefault();

        let msgErro = '';
        const tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE D - PARTIDO POLÍTICO</div>';
        
        if(partidoPoliticoNome.length < 3)
            msgErro += '<li>Preencher o <b>Nome do Partido Político</b>, mínimo 3 caracteres</li>';
        if(partidoPoliticoMesInicial === '')
            msgErro += '<li>Selecionar o <b>Mês Inicial</b> do Período de filiação no Partido Político</li>';
        if(partidoPoliticoAnoInicial.length < 4)
            msgErro += '<li>Preencher o <b>Ano Inicial</b> do Período de filiação no Partido Político, formato aaaa</li>';
        if(partidoPoliticoMesFinal === '')
            msgErro += '<li>Selecionar o <b>Mês Final</b> do Período de filiação no Partido Político</li>';
        if(partidoPoliticoAnoFinal.length < 4)
            msgErro += '<li>Preencher o <b>Ano Final</b> do Período de filiação no Partido Político, formato aaaa</li>';
        if(partidoPoliticoEndereco.length < 3)
            msgErro += '<li>Preencher o <b>Endereço</b> do Partido Político, mínimo 3 caracteres</li>';
        if(partidoPoliticoComplemento.length < 3)
            msgErro += '<li>Preencher o <b>Complemento do Endereço</b> do Partido Político, mínimo 3 caracteres</li>';
        if(partidoPoliticoCep.length < 9)
            msgErro += '<li>Preencher o <b>CEP</b> do Partido Político, mínimo 9 caracteres</li>';
        if(partidoPoliticoBairro.length < 3)
            msgErro += '<li>Preencher o <b>Bairro</b> do Partido Político, mínimo 3 caracteres</li>';
        if(partidoPoliticoCidade.length < 3)
            msgErro += '<li>Preencher a <b>Cidade</b> do Partido Político, mínimo 3 caracteres</li>';
        if(partidoPoliticoEstado === '')
            msgErro += '<li>Selecionar o <b>Estado</b> do Partido Político</li>';
        if(partidoPoliticoFiliacaoDesfiliacao.length < 3)
            msgErro += '<li>Preencher <b>Qual o motivo da filiação (e da desfiliação, se for o caso)</b> do Partido Político, mínimo 3 caracteres</li>';
        if(partidoPoliticoPontoReferencia.length < 3)
            msgErro += '<li>Preencher o <b>Ponto de Referência (Batalhão PM da área)</b> do Partido Político, mínimo 3 caracteres</li>';
        if(partidoPoliticoNotificacaoPunicao === '')
            msgErro += '<li>Selecionar se <b>chegou a sofrer alguma Notificação/Punição</b> no Partido Político</li>';
        if(partidoPoliticoNotificacaoPunicao === 'sim'){
            if(detalhePartidoPoliticoNotificacaoPunicao.length < 3)
                msgErro += '<li>Preencher o <b>Detalhe da Notificação/Punição</b> no Partido Político, mínimo 3 caracteres</li>';
        }

        if(msgErro.length > 0){
            modalShow( msgInicialErro + tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErro + '</ul>');
            return false;
        }

        let auxPartidoPoliticoNumero = partidoPoliticoNumero;
        if(partidoPoliticoNumero.trim().length === 0)
            auxPartidoPoliticoNumero = 'S/N';
        
        partidosPoliticos.push({ nome: partidoPoliticoNome, mêsInicial: partidoPoliticoMesInicial, anoInicial: partidoPoliticoAnoInicial, mêsFinal: partidoPoliticoMesFinal, anoFinal: partidoPoliticoAnoFinal, endereço: partidoPoliticoEndereco,
            número: auxPartidoPoliticoNumero, complemento: partidoPoliticoComplemento, cep: partidoPoliticoCep, bairro: partidoPoliticoBairro, cidade: partidoPoliticoCidade, estado: partidoPoliticoEstado,
            pontoReferência: partidoPoliticoPontoReferencia, filiaçãoDesfiliação: partidoPoliticoFiliacaoDesfiliacao, notificaçãoPunição: partidoPoliticoNotificacaoPunicao, detalheNotificaçãoPunição: detalhePartidoPoliticoNotificacaoPunicao });
        setPartidosPoliticos([...partidosPoliticos]);
        
        setPartidoPoliticoNome('');
        setPartidoPoliticoMesInicial('');
        setPartidoPoliticoAnoInicial('');
        setPartidoPoliticoMesFinal('');
        setPartidoPoliticoAnoFinal('');
        setPartidoPoliticoEndereco('');
        setPartidoPoliticoNumero('');
        setPartidoPoliticoComplemento('');
        setPartidoPoliticoCep('');
        setPartidoPoliticoBairro('');
        setPartidoPoliticoCidade('');
        setPartidoPoliticoEstado('');
        setPartidoPoliticoPontoReferencia('');
        setPartidoPoliticoFiliacaoDesfiliacao('');
        setPartidoPoliticoNotificacaoPunicao('');
        setDetalhePartidoPoliticoNotificacaoPunicao('');
        document.getElementById('partidoPoliticoNotificacaoPunicaoDiv').style.display = 'none';
    }

    const removePartidoPolitico = async (e, nome, mêsInicial, anoInicial, mêsFinal, anoFinal, pontoReferência ) => {
        e.preventDefault();
        setPartidosPoliticos([...partidosPoliticos.filter(pp => ((pp.nome != nome) || (pp.mêsInicial != mêsInicial) || (pp.anoInicial != anoInicial) || (pp.mêsFinal != mêsFinal) || (pp.anoFinal != anoFinal) || (pp.pontoReferência != pontoReferência) ))]);
    }

    const adicionaProcessoJustica = async (e) => {
        e.preventDefault();

        let msgErro = '';
        const tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE E - PROCESSOS NA JUSTIÇA</div>';
        
        if(processoJusticaTipo.length < 3)
            msgErro += '<li>Preencher o <b>Tipo do processo</b>, mínimo 3 caracteres</li>';
        if(processoJusticaCondicao === '')
            msgErro += '<li>Selecionar a <b>Condição</b> do processo</li>';
        if(processoJusticaDataFato.length < 10)
            msgErro += '<li>Preencher a <b>Data do Fato</b> do processo, formato dd/mm/aaaa</li>';
        if(processoJusticaNumeroAno.length < 3)
            msgErro += '<li>Preencher o <b>Número e Ano</b> do processo, mínimo 3 caracteres</li>';
        if(processoJusticaBairro.length < 3)
            msgErro += '<li>Preencher o <b>Bairro</b> do processo, mínimo 3 caracteres</li>';
        if(processoJusticaCidade.length < 3)
            msgErro += '<li>Preencher a <b>Cidade</b> do processo, mínimo 3 caracteres</li>';
        if(processoJusticaEstado === '')
            msgErro += '<li>Selecionar o <b>Estado</b> do processo</li>';
        if(processoJusticaForum.length < 3)
            msgErro += '<li>Preencher o <b>Fórum</b> do processo, mínimo 3 caracteres</li>';
        if(processoJusticaVara.length < 3)
            msgErro += '<li>Preencher a <b>Vara</b> do processo, mínimo 3 caracteres</li>';
        if(processoJusticaSolucaoPena.length < 3)
            msgErro += '<li>Preencher a <b>Solução ou Pena aplicada</b> do processo, mínimo 3 caracteres</li>';
        if(processoJusticaDetalheFato.length < 3)
            msgErro += '<li>Preencher o <b>Detalhe do fato ocorrido (motivo do Processo)</b>, mínimo 3 caracteres</li>';

        if(msgErro.length > 0){
            modalShow( msgInicialErro + tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErro + '</ul>');
            return false;
        }

        processosJustica.push({ tipo: processoJusticaTipo, condição: processoJusticaCondicao, dataFato: processoJusticaDataFato, númeroAno: processoJusticaNumeroAno, bairro: processoJusticaBairro,
            cidade: processoJusticaCidade, estado: processoJusticaEstado, fórum: processoJusticaForum, vara: processoJusticaVara, soluçãoPena: processoJusticaSolucaoPena, detalheFatoOcorrido: processoJusticaDetalheFato });
        setProcessosJustica([...processosJustica]);
        
        setProcessoJusticaTipo('');
        setProcessoJusticaCondicao('');
        setProcessoJusticaDataFato('');
        setProcessoJusticaNumeroAno('');
        setProcessoJusticaBairro('');
        setProcessoJusticaCidade('');
        setProcessoJusticaEstado('');
        setProcessoJusticaForum('');
        setProcessoJusticaVara('');
        setProcessoJusticaSolucaoPena('');
        setProcessoJusticaDetalheFato('');
    }

    const removeProcessoJustica = async (e, númeroAno, tipo, dataFato, condição ) => {
        e.preventDefault();
        setProcessosJustica([...processosJustica.filter(pj => ((pj.númeroAno != númeroAno) || (pj.tipo != tipo) || (pj.dataFato != dataFato) || (pj.condição != condição) ))]);
    }

    const adicionaOcorrenciaPolicial = async (e) => {
        e.preventDefault();

        let msgErro = '';
        const tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE E - OCORRÊNCIAS POLICIAIS</div>';
        
        if(ocorrenciaPolicialDataFato.length < 10)
            msgErro += '<li>Preencher a <b>Data do Fato</b> da ocorrência policial, formato dd/mm/aaaa</li>';
        if(ocorrenciaPolicialCondicao === '')
            msgErro += '<li>Selecionar a <b>Condição</b> em que você figurou na ocorrência policial</li>';
        if(ocorrenciaPolicialDelegaciaQuartel.length < 3)
            msgErro += '<li>Preencher a <b>Delegacia de Polícia ou Quartel para onde você foi conduzido</b> na ocorrência policial, mínimo 3 caracteres</li>';
        if(ocorrenciaPolicialRpBo.length < 3)
            msgErro += '<li>Preencher o <b>Número do registro policial, Boletim de Ocorrência ou outro documento elaborado</b> na ocorrência policial, mínimo 3 caracteres</li>';
        if(ocorrenciaPolicialSolucaoPena.length < 3)
            msgErro += '<li>Preencher a <b>Solução ou Pena aplicada</b> na ocorrência policial, mínimo 3 caracteres</li>';
        if(ocorrenciaPolicialDetalheFato.length < 3)
            msgErro += '<li>Preencher o <b>Detalhe do fato ocorrido (motivo da Ocorrência Policial)</b>, mínimo 3 caracteres</li>';

        if(msgErro.length > 0){
            modalShow( msgInicialErro + tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErro + '</ul>');
            return false;
        }

        ocorrenciasPoliciais.push({ dataFato: ocorrenciaPolicialDataFato, condição: ocorrenciaPolicialCondicao, delegaciaQuartel: ocorrenciaPolicialDelegaciaQuartel, rpBo: ocorrenciaPolicialRpBo, soluçãoPena: ocorrenciaPolicialSolucaoPena, detalheFatoOcorrido: ocorrenciaPolicialDetalheFato });
        setOcorrenciasPoliciais([...ocorrenciasPoliciais]);

        setOcorrenciaPolicialDataFato('');
        setOcorrenciaPolicialCondicao('');
        setOcorrenciaPolicialDelegaciaQuartel('');
        setOcorrenciaPolicialRpBo('');
        setOcorrenciaPolicialSolucaoPena('');
        setOcorrenciaPolicialDetalheFato('');
    }

    const removeOcorrenciaPolicial = async (e, dataFato, condição, rpBo, delegaciaQuartel ) => {
        e.preventDefault();
        setOcorrenciasPoliciais([...ocorrenciasPoliciais.filter(op => ((op.dataFato != dataFato) || (op.condição != condição) || (op.rpBo != rpBo) || (op.delegaciaQuartel != delegaciaQuartel) ))]);
    }

    const adicionaIpSindIpm = async (e) => {
        e.preventDefault();

        let msgErro = '';
        const tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE E - INVESTIGAÇÃO PRELIMINAR/SINDICÂNCIA/INQUÉRITO POLICIAL MILITAR</div>';
        
        if(ipSindIpmDataFato.length < 10)
            msgErro += '<li>Preencher a <b>Data do Fato</b> da I.P./Sindicância/I.P.M., formato dd/mm/aaaa</li>';
        if(ipSindIpmCondicao === '')
            msgErro += '<li>Selecionar <b>Qual a condição em que você figurou no Procedimento</b> da I.P./Sindicância/I.P.M.</li>';
        if(ipSindIpmNrProcedimento.length < 3)
            msgErro += '<li>Preencher o <b>Número do Procedimento instaurado</b> na I.P./Sindicância/I.P.M., mínimo 3 caracteres</li>';
        if(ipSindIpmSolucao.length < 3)
            msgErro += '<li>Preencher <b>Qual a Solução aplicada ao final do Procedimento</b> da I.P./Sindicância/I.P.M., mínimo 3 caracteres</li>';
        if(ipSindIpmDetalheFato.length < 3)
            msgErro += '<li>Preencher <b>detalhadamente o fato ocorrido (motivação do Procedimento na I.P./Sindicância/I.P.M.)</b>, mínimo 3 caracteres</li>';

        if(msgErro.length > 0){
            modalShow( msgInicialErro + tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErro + '</ul>');
            return false;
        }

        listaIpSindIpm.push({ dataFato: ipSindIpmDataFato, condição: ipSindIpmCondicao, nrProcedimento: ipSindIpmNrProcedimento, solução: ipSindIpmSolucao, detalheFatoOcorrido: ipSindIpmDetalheFato });
        setListaIpSindIpm([...listaIpSindIpm]);
        
        setIpSindIpmDataFato('');
        setIpSindIpmCondicao('');
        setIpSindIpmNrProcedimento('');
        setIpSindIpmSolucao('');
        setIpSindIpmDetalheFato('');
    }

    const removeIpSindIpm = async (e, dataFato, condição, nrProcedimento ) => {
        e.preventDefault();
        setListaIpSindIpm([...listaIpSindIpm.filter(isi => ((isi.dataFato != dataFato) || (isi.condição != condição) || (isi.nrProcedimento != nrProcedimento) ))]);
    }

    const adicionaPadCdCj = async (e) => {
        e.preventDefault();

        let msgErro = '';
        const tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE E - PROCESSO ADMINISTRATIVO DISCIPLINAR/CONSELHO DE DISCIPLINA/CONSELHO DE JUSTIFICAÇÃO</div>';
        
        if(padCdCjDataFato.length < 10)
            msgErro += '<li>Preencher a <b>Data do Fato</b> do P.A.D/C.D./C.J., formato dd/mm/aaaa</li>';
        if(padCdCjCondicao === '')
            msgErro += '<li>Selecionar <b>Qual a condição em que você figurou no Procedimento</b> do P.A.D/C.D./C.J.</li>';
        if(padCdCjNrProcedimento.length < 3)
            msgErro += '<li>Preencher o <b>Número do Procedimento instaurado</b> do P.A.D/C.D./C.J., mínimo 3 caracteres</li>';
        if(padCdCjSolucao.length < 3)
            msgErro += '<li>Preencher <b>Qual a Solução aplicada ao final do Procedimento</b> do P.A.D/C.D./C.J., mínimo 3 caracteres</li>';
        if(padCdCjDetalheFato.length < 3)
            msgErro += '<li>Preencher <b>detalhadamente o fato ocorrido (motivação do Procedimento no P.A.D/C.D./C.J.)</b>, mínimo 3 caracteres</li>';

        if(msgErro.length > 0){
            modalShow( msgInicialErro + tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErro + '</ul>');
            return false;
        }

        listaPadCdCj.push({ dataFato: padCdCjDataFato, condição: padCdCjCondicao, nrProcedimento: padCdCjNrProcedimento, solução: padCdCjSolucao, detalheFatoOcorrido: padCdCjDetalheFato });
        setListaPadCdCj([...listaPadCdCj]);
        
        setPadCdCjDataFato('');
        setPadCdCjCondicao('');
        setPadCdCjNrProcedimento('');
        setPadCdCjSolucao('');
        setPadCdCjDetalheFato('');
    }

    const removePadCdCj = async (e, dataFato, condição, nrProcedimento ) => {
        e.preventDefault();
        setListaPadCdCj([...listaPadCdCj.filter(pcc => ((pcc.dataFato != dataFato) || (pcc.condição != condição) || (pcc.nrProcedimento != nrProcedimento) ))]);
    }

    const adicionaFamiliaOpPj = async (e) => {
        e.preventDefault();

        let msgErro = '';
        const tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE D - MEMBRO DA SUA FAMÍLIA</div>';
        
        if(familiaOpPjNome.length < 3)
            msgErro += '<li>Preencher o <b>Nome</b> do membro da sua Família, mínimo 3 caracteres</li>';
        if(familiaOpPjGrauParentesco.length < 3)
            msgErro += '<li>Preencher o <b>Grau de Parentesco</b> do membro da sua Família, mínimo 3 caracteres</li>';
        if(familiaOpPjCondicao === '')
            msgErro += '<li>Selecionar a <b>Condição</b> do membro da sua Família</li>';
        if(familiaOpPjDataFato.length < 10)
            msgErro += '<li>Preencher a <b>Data do Fato</b> do membro da sua Família, formato dd/mm/aaaa</li>';
        if(familiaOpPjTipoProcesso.length < 3)
            msgErro += '<li>Preencher o <b>Tipo do Processo</b> do membro da sua Família, mínimo 3 caracteres</li>';
        if(familiaOpPjCpf.length < 14)
            msgErro += '<li>Preencher o <b>CPF</b> do membro da sua Família, mínimo 14 caracteres</li>';
        if(familiaOpPjRg.length < 3)
            msgErro += '<li>Preencher o <b>RG</b> do membro da sua Família, mínimo 3 caracteres</li>';
        if(familiaOpPjEndereco.length < 3)
            msgErro += '<li>Preencher o <b>Endereço</b> do membro da sua Família, mínimo 3 caracteres</li>';
        if(familiaOpPjBairro.length < 3)
            msgErro += '<li>Preencher o <b>Bairro</b> do membro da sua Família, mínimo 3 caracteres</li>';
        if(familiaOpPjCidade.length < 3)
            msgErro += '<li>Preencher a <b>Cidade</b> do membro da sua Família, mínimo 3 caracteres</li>';
        if(familiaOpPjEstado.length < 3)
            msgErro += '<li>Preencher o <b>Estado</b> do membro da sua Família, mínimo 3 caracteres</li>';
        if(familiaOpPjSolucaoPena.length < 3)
            msgErro += '<li>Preencher a <b>Solução ou Pena aplicada</b> ao membro da sua Família, mínimo 3 caracteres</li>';
        if(familiaOpPjFatoOcorrido.length < 3)
            msgErro += '<li>Preencher o <b>Fato ocorrido (motivo do Processo do membro da sua Família)</b>, mínimo 3 caracteres</li>';

        if(msgErro.length > 0){
            modalShow( msgInicialErro + tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErro + '</ul>');
            return false;
        }

        let auxFamiliaOpPjNumero = familiaOpPjNumero;
        if(familiaOpPjNumero.trim().length === 0)
            auxFamiliaOpPjNumero = 'S/N';

        familiasOpPj.push({ nome: familiaOpPjNome, grauParentesco: familiaOpPjGrauParentesco, condição: familiaOpPjCondicao, dataFato: familiaOpPjDataFato, tipoProcesso: familiaOpPjTipoProcesso, cpf: familiaOpPjCpf,
            rg: familiaOpPjRg, endereço: familiaOpPjEndereco, número: auxFamiliaOpPjNumero, bairro: familiaOpPjBairro, cidade: familiaOpPjCidade, estado: familiaOpPjEstado, soluçãoPena: familiaOpPjSolucaoPena, fatoOcorrido: familiaOpPjFatoOcorrido });
        setFamiliasOpPj([...familiasOpPj]);
        
        setFamiliaOpPjNome('');
        setFamiliaOpPjGrauParentesco('');
        setFamiliaOpPjCondicao('');
        setFamiliaOpPjDataFato('');
        setFamiliaOpPjTipoProcesso('');
        setFamiliaOpPjCpf('');
        setFamiliaOpPjRg('');
        setFamiliaOpPjEndereco('');
        setFamiliaOpPjNumero('');
        setFamiliaOpPjBairro('');
        setFamiliaOpPjCidade('');
        setFamiliaOpPjEstado('');
        setFamiliaOpPjSolucaoPena('');
        setFamiliaOpPjFatoOcorrido('');
    }

    const removeFamiliaOpPj = async (e, nome, grauParentesco, cpf, rg, condição ) => {
        e.preventDefault();
        setFamiliasOpPj([...familiasOpPj.filter(familiar => ((familiar.nome != nome) || (familiar.grauParentesco != grauParentesco) || (familiar.cpf != cpf) || (familiar.rg != rg) || (familiar.condição != condição) ))]);
    }

    const adicionaDetalheEmprego = (e) => {
        e.preventDefault();
        let msgErro = '';
        const tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE F - TRABALHO/ATIVIDADE DESENVOLVIDA</div>';
        
        if(detalheEmpregoEmpresa.length < 3)
            msgErro += '<li>Preencher o <b>Nome da Empresa</b>, mínimo 3 caracteres</li>';
        if(detalheEmpregoCnpj.length < 3)
            msgErro += '<li>Preencher o <b>CNPJ</b> da Empresa, mínimo 3 caracteres</li>';
        if(detalheEmpregoSede === '')
            msgErro += '<li>Selecionar a <b>Sede</b> da Empresa: Matriz ou Instalação Filial</li>';
        if(detalheEmpregoMesInicial === '')
            msgErro += '<li>Selecionar o <b>Mês Inicial</b> da atividade</li>';
        if(detalheEmpregoAnoInicial.length < 4)
            msgErro += '<li>Preencher o <b>Ano Inicial</b> da atividade, formato aaaa</li>';
        if(detalheEmpregoMesFinal === '')
            msgErro += '<li>Selecionar o <b>Mês Final</b> da atividade</li>';
        if(detalheEmpregoAnoFinal.length < 4)
            msgErro += '<li>Preencher o <b>Ano Final</b> da atividade, formato aaaa</li>';
        if(detalheEmpregoEndereco.length < 3)
            msgErro += '<li>Preencher o <b>Endereço</b> da Empresa, mínimo 3 caracteres</li>';
        if(detalheEmpregoBairro.length < 3)
            msgErro += '<li>Preencher o <b>Bairro</b> da Empresa, mínimo 3 caracteres</li>';
        if(detalheEmpregoCidade.length < 3)
            msgErro += '<li>Preencher a <b>Cidade</b> da Empresa, mínimo 3 caracteres</li>';
        if(detalheEmpregoEstado === '')
            msgErro += '<li>Selecionar o <b>Estado</b> da Empresa</li>';
        if(detalheEmpregoCep.length < 9)
            msgErro += '<li>Preencher o <b>CEP</b> da Empresa, mínimo 9 caracteres</li>';
        if(detalheEmpregoBatalhaoPm.length < 3)
            msgErro += '<li>Preencher o <b>Ponto de referência (Batalhão PM da área)</b>, mínimo 3 caracteres</li>';
        if(detalheEmpregoFuncao.length < 3)
            msgErro += '<li>Preencher o <b>Cargo, a Função ou a Atividade exercida</b>, mínimo 3 caracteres</li>';
        if(detalheEmpregoSalario.length < 3)
            msgErro += '<li>Preencher o <b>Salário (R$)</b>, mínimo 3 caracteres</li>';
        if(detalheEmpregoDepartamento.length < 3)
            msgErro += '<li>Preencher o <b>Departamento ou Seção em que trabalhava</b>, mínimo 3 caracteres</li>';
        if(detalheEmpregoChefe.length < 3)
            msgErro += '<li>Preencher o <b>Encarregado ou Chefe direto (nome)</b>, mínimo 3 caracteres</li>';
        if(detalheEmpregoPunicaoSofrida.length < 3)
            msgErro += '<li>Preencher a <b>Punição Sofrida</b>, mínimo 3 caracteres</li>';
        if(detalheEmpregoMotivoDemissao.length < 3)
            msgErro += '<li>Preencher o <b>Motivo da Demissão</b>, mínimo 3 caracteres</li>';
        if(detalheEmpregoInatividadeMesInicial === '')
            msgErro += '<li>Selecionar o <b>Mês Inicial</b> da Inatividade</li>';
        if(detalheEmpregoInatividadeAnoInicial.length < 4)
            msgErro += '<li>Preencher o <b>Ano Inicial</b> da Inatividade, formato aaaa</li>';
        if(detalheEmpregoInatividadeMesFinal === '')
            msgErro += '<li>Selecionar o <b>Mês Final</b> da Inatividade</li>';
        if(detalheEmpregoInatividadeAnoFinal.length < 4)
            msgErro += '<li>Preencher o <b>Ano Final</b> da Inatividade, formato aaaa</li>';
        if(detalheEmpregoFezDuranteDesemprego.length < 3)
            msgErro += '<li>Preencher <b>o que Fez durante este período em que esteve desempregado</b>, mínimo 3 caracteres</li>';
        

        if(msgErro.length > 0){
            modalShow(msgInicialErro + tituloAba + '<ul style="padding-left:35px; list-style-type:square">'+msgErro+'</ul>');
            return false;
        }

        detalheEmprego.push({empresa: detalheEmpregoEmpresa, cnpj: detalheEmpregoCnpj, sede: detalheEmpregoSede, mêsInicial: detalheEmpregoMesInicial, anoInicial: detalheEmpregoAnoInicial, mêsFinal: detalheEmpregoMesFinal, anoFinal: detalheEmpregoAnoFinal, endereço: detalheEmpregoEndereco, bairro: detalheEmpregoBairro, cidade: detalheEmpregoCidade, estado: detalheEmpregoEstado, cep: detalheEmpregoCep, função: detalheEmpregoFuncao, salário: detalheEmpregoSalario, departamento: detalheEmpregoDepartamento, chefe: detalheEmpregoChefe, puniçãoSofrida: detalheEmpregoPunicaoSofrida, demissão: detalheEmpregoMotivoDemissao, inatividadeMêsInicial: detalheEmpregoInatividadeMesInicial, inatividadeAnoInicial: detalheEmpregoInatividadeAnoInicial, inatividadeMêsFinal: detalheEmpregoInatividadeMesFinal, inatividadeAnoFinal: detalheEmpregoInatividadeAnoFinal, fezDuranteDesemprego: detalheEmpregoFezDuranteDesemprego, batalhaoPm: detalheEmpregoBatalhaoPm });
        setDetalheEmprego([...detalheEmprego]);

        setDetalheEmpregoEmpresa('');
        setDetalheEmpregoCnpj('');
        setDetalheEmpregoSede('');
        setDetalheEmpregoMesInicial('');
        setDetalheEmpregoAnoInicial('');
        setDetalheEmpregoMesFinal('');
        setDetalheEmpregoAnoFinal('');
        setDetalheEmpregoEndereco('');
        setDetalheEmpregoBairro('');
        setDetalheEmpregoCidade('');
        setDetalheEmpregoEstado('');
        setDetalheEmpregoCep('');
        setDetalheEmpregoFuncao('');
        setDetalheEmpregoSalario('');
        setDetalheEmpregoDepartamento('');
        setDetalheEmpregoChefe('');
        setDetalheEmpregoPunicaoSofrida('');
        setDetalheEmpregoMotivoDemissao('');
        setDetalheEmpregoInatividadeMesInicial('');
        setDetalheEmpregoInatividadeAnoInicial('');
        setDetalheEmpregoInatividadeMesFinal('');
        setDetalheEmpregoInatividadeAnoFinal('');
        setDetalheEmpregoFezDuranteDesemprego('');
        setDetalheEmpregoBatalhaoPm('');
    }

    const removeDetalheEmprego = (e, empresa, mêsInicial, anoInicial, mêsFinal, anoFinal, função) => {
        e.preventDefault();
        setDetalheEmprego([...detalheEmprego.filter(emprego => ((emprego.empresa != empresa) || (emprego.mêsInicial != mêsInicial) || (emprego.anoInicial != anoInicial) || (emprego.mêsFinal != mêsFinal) || (emprego.anoFinal != anoFinal) || (emprego.função != função)))]);
    }

    const adicionaEscola = (e) => {
        e.preventDefault();
        let msgErro = '';
        const tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE G - INFORMAÇÕES ESCOLARES</div>';
        
        if(escolaNome.length < 3)
            msgErro += '<li>Preencher o <b>Nome da Instituição</b>, mínimo 3 caracteres</li>';
        if(escolaCurso.length < 3)
            msgErro += '<li>Preencher o <b>Curso realizado</b> na Escola, mínimo 3 caracteres</li>';
        if(escolaCursou.length < 3)
            msgErro += '<li>Preencher <b>Séries/Semestres que você cursou</b>, mínimo 3 caracteres</li>';
        if(escolaTipo === '')
            msgErro += '<li>Selecionar o <b>Tipo</b> do Curso</li>';
        if(escolaRazaoEad.length < 3)
            msgErro += '<li>Preencher <b>Caso seja EAD, por qual razão você optou por este tipo de ensino/formação</b>, mínimo 3 caracteres</li>';
        if(escolaMesInicial === '')
            msgErro += '<li>Selecionar o <b>Mês Inicial</b> do período que cursou</li>';
        if(escolaAnoInicial.length < 4)
            msgErro += '<li>Preencher o <b>Ano Inicial</b> do período que cursou, formato aaaa</li>';
        if(escolaMesFinal === '')
            msgErro += '<li>Selecionar o <b>Mês Final</b> do período que cursou</li>';
        if(escolaAnoFinal.length < 4)
            msgErro += '<li>Preencher o <b>Ano Final</b> do período que cursou, formato aaaa</li>';
        if(escolaEndereco.length < 3)
            msgErro += '<li>Preencher o <b>Endereço</b> da Escola, mínimo 3 caracteres</li>';
        if(escolaComplemento.length < 3)
            msgErro += '<li>Preencher o <b>Complemento do Endereço</b> da Escola, mínimo 3 caracteres</li>';
        if(escolaCep.length < 9)
            msgErro += '<li>Preencher o <b>CEP</b> da Escola, mínimo 9 caracteres</li>';
        if(escolaBairro.length < 3)
            msgErro += '<li>Preencher o <b>Bairro</b> da Escola, mínimo 3 caracteres</li>';
        if(escolaCidade.length < 3)
            msgErro += '<li>Preencher a <b>Cidade</b> da Escola, mínimo 3 caracteres</li>';
        if(escolaEstado === '')
            msgErro += '<li>Selecionar o <b>Estado</b> da Escola</li>';
        if(escolaPontoReferencia.length < 3)
            msgErro += '<li>Preencher o <b>Ponto de referência (Batalhão PM da área)</b>, mínimo 3 caracteres</li>';

        if(msgErro.length > 0){
            modalShow(msgInicialErro + tituloAba + '<ul style="padding-left:35px; list-style-type:square">'+msgErro+'</ul>');
            return false;
        }

        let auxEscolaNumero = escolaNumero;
        if(escolaNumero.trim().length === 0)
            auxEscolaNumero = 'S/N';

        escolas.push({ nome: escolaNome, curso: escolaCurso, cursou: escolaCursou, tipo: escolaTipo, razãoEad: escolaRazaoEad, mêsInicial: escolaMesInicial, anoInicial: escolaAnoInicial,
            mêsFinal: escolaMesFinal, anoFinal: escolaAnoFinal, endereço: escolaEndereco, número: auxEscolaNumero, complemento: escolaComplemento, cep: escolaCep, bairro: escolaBairro, 
            cidade: escolaCidade, estado: escolaEstado, pontoReferência: escolaPontoReferencia });
        setEscolas([...escolas]);

        setEscolaNome('');
        setEscolaCurso('');
        setEscolaCursou('');
        setEscolaTipo('');
        setEscolaRazaoEad('');
        setEscolaMesInicial('');
        setEscolaAnoInicial('');
        setEscolaMesFinal('');
        setEscolaAnoFinal('');
        setEscolaEndereco('');
        setEscolaNumero('');
        setEscolaComplemento('');
        setEscolaCep('');
        setEscolaBairro('');
        setEscolaCidade('');
        setEscolaEstado('');
        setEscolaPontoReferencia('');
    }

    const removeEscola = (e, nome, curso, mêsInicial, anoInicial, mêsFinal, anoFinal, tipo) => {
        e.preventDefault();
        setEscolas([...escolas.filter(escola => ((escola.nome != nome) || (escola.curso != curso) || (escola.mêsInicial != mêsInicial) || (escola.anoInicial != anoInicial) || (escola.mêsFinal != mêsFinal) || (escola.anoFinal != anoFinal) || (escola.tipo != tipo)))]);
    }

    const adicionaVeiculoSeuNomeSuaPosse = (e) => {
        e.preventDefault();
        let msgErro = '';
        const tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE I - INFORMAÇÕES DE TRÂNSITO(Seu Nome, Sua Posse)</div>';
        
        if(veiculoSeuNomeSuaPossePlaca.length < 3)
            msgErro += '<li>Preencher a <b>Placa</b> do veículo, mínimo 3 caracteres</li>';
        if(veiculoSeuNomeSuaPosseMarcaAnoModelo.length < 3)
            msgErro += '<li>Preencher a <b>Marca, o Ano, o Modelo</b> do veículo, mínimo 3 caracteres</li>';
        if(veiculoSeuNomeSuaPosseCor.length < 3)
            msgErro += '<li>Preencher a <b>Cor</b> do veículo, mínimo 3 caracteres</li>';
        if(veiculoSeuNomeSuaPosseTaxaOk === '')
            msgErro += '<li>Selecionar se o <b>Seguro Obrigatório, Licenciamento Anual e o IPVA estão todos pagos</b></li>';
        if(veiculoSeuNomeSuaPosseTaxaOk === 'não'){
            if(detalheVeiculoSeuNomeSuaPosseTaxaOk.length < 3)
                msgErro += '<li>Preencher a <b>informação sobre o motivo de Não estar pago, o Seguro Obrigatório, Licenciamento Anual e/ou o IPVA</b></li>';
        }
        if(veiculoSeuNomeSuaPosseMultaPendente === '')
            msgErro += '<li>Selecionar se <b>há Multas com pagamento pendente</b></li>';
        if(veiculoSeuNomeSuaPosseMultaPendente === 'sim'){
            if(detalheVeiculoSeuNomeSuaPosseMultaPendente.length < 3)
                msgErro += '<li>Preencher o <b>motivo da Multa com pagamento pendente</b></li>';
        }
        if(veiculoSeuNomeSuaPosseVoceDirige === '')
            msgErro += '<li>Selecionar se <b>Você dirige este veículo</b></li>';
        if(veiculoSeuNomeSuaPosseVoceDirige === 'não'){
            if(detalheVeiculoSeuNomeSuaPosseVoceDirige.length < 3)
                msgErro += '<li>Preencher a <b>informação de quem dirige e o motivo</b></li>';
        }

        if(msgErro.length > 0){
            modalShow(msgInicialErro + tituloAba + '<ul style="padding-left:35px; list-style-type:square">'+msgErro+'</ul>');
            return false;
        }
        
        lVeiculoSeuNomeSuaPosse.push({ placa: veiculoSeuNomeSuaPossePlaca, marcaAnoModelo: veiculoSeuNomeSuaPosseMarcaAnoModelo, cor: veiculoSeuNomeSuaPosseCor, taxaOk: veiculoSeuNomeSuaPosseTaxaOk, detalheTaxaOk: detalheVeiculoSeuNomeSuaPosseTaxaOk, multaPendente: veiculoSeuNomeSuaPosseMultaPendente, detalheMultaPendente: detalheVeiculoSeuNomeSuaPosseMultaPendente, voceDirige: veiculoSeuNomeSuaPosseVoceDirige, detalheVoceDirige: detalheVeiculoSeuNomeSuaPosseVoceDirige });
        setLVeiculoSeuNomeSuaPosse([...lVeiculoSeuNomeSuaPosse]);

        setVeiculoSeuNomeSuaPossePlaca('');
        setVeiculoSeuNomeSuaPosseMarcaAnoModelo('');
        setVeiculoSeuNomeSuaPosseCor('');
        setVeiculoSeuNomeSuaPosseTaxaOk('');
        setVeiculoSeuNomeSuaPosseTaxaOkDiv(null);
        setVeiculoSeuNomeSuaPosseMultaPendenteDiv(null);
        setVeiculoSeuNomeSuaPosseVoceDirigeDiv(null);
    }

    const removeVeiculoSeuNomeSuaPosse = (e, marcaAnoModelo, cor, placa) => {
        e.preventDefault();
        setLVeiculoSeuNomeSuaPosse([...lVeiculoSeuNomeSuaPosse.filter(veiculo => ((veiculo.marcaAnoModelo != marcaAnoModelo) || (veiculo.cor != cor) || (veiculo.placa != placa)))]);
    }

    const adicionaVeiculoSeuNomeNaoSuaPosse = (e) => {
        e.preventDefault();
        let msgErro = '';
        const tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE I - INFORMAÇÕES DE TRÂNSITO(Seu Nome, NÃO está em sua Posse)</div>';

        if(veiculoSeuNomeNaoSuaPossePlaca.length < 3)
            msgErro += '<li>Preencher a <b>Placa</b> do veículo, mínimo 3 caracteres</li>';
        if(veiculoSeuNomeNaoSuaPosseMarcaAnoModelo.length < 3)
            msgErro += '<li>Preencher a <b>Marca, o Ano, o Modelo</b> do veículo, mínimo 3 caracteres</li>';
        if(veiculoSeuNomeNaoSuaPosseCor.length < 3)
            msgErro += '<li>Preencher a <b>Cor</b> do veículo, mínimo 3 caracteres</li>';
        if(veiculoSeuNomeNaoSuaPosseTaxaOk === '')
            msgErro += '<li>Selecionar se o <b>Seguro Obrigatório, Licenciamento Anual e o IPVA estão todos pagos</b></li>';
        if(veiculoSeuNomeNaoSuaPosseTaxaOk === 'não'){
            if(detalheVeiculoSeuNomeNaoSuaPosseTaxaOk.length < 3)
                msgErro += '<li>Preencher a <b>informação sobre o motivo de Não estar pago, o Seguro Obrigatório, Licenciamento Anual e/ou o IPVA</b></li>';
        }
        if(veiculoSeuNomeNaoSuaPosseMultaPendente === '')
            msgErro += '<li>Selecionar se <b>há Multas com pagamento pendente</b></li>';
        if(veiculoSeuNomeNaoSuaPosseMultaPendente === 'sim'){
            if(detalheVeiculoSeuNomeNaoSuaPosseMultaPendente.length < 3)
                msgErro += '<li>Preencher o <b>motivo da Multa com pagamento pendente</b></li>';
        }
        if(veiculoSeuNomeNaoSuaPosseVoceDirige === '')
            msgErro += '<li>Selecionar se <b>Você dirige este veículo</b></li>';
        if(veiculoSeuNomeNaoSuaPosseVoceDirige === 'não'){
            if(detalheVeiculoSeuNomeNaoSuaPosseVoceDirige.length < 3)
                msgErro += '<li>Preencher a <b>informação de quem dirige e o motivo</b></li>';
        }
        if(veiculoSeuNomeNaoSuaPosseProprietario.length < 3)
            msgErro += '<li>Preencher o <b>nome do Proprietário do veículo</b>, mínimo 3 caracteres</li>';
        if(veiculoSeuNomeNaoSuaPosseParentescoProprietario.length < 3)
            msgErro += '<li>Preencher qual o seu <b>Grau de Parentesco, ou amizade com o proprietário do veículo</b>, mínimo 3 caracteres</li>';

        if(msgErro.length > 0){
            modalShow(msgInicialErro + tituloAba + '<ul style="padding-left:35px; list-style-type:square">'+msgErro+'</ul>');
            return false;
        }
        
        lVeiculoSeuNomeNaoSuaPosse.push({ placa: veiculoSeuNomeNaoSuaPossePlaca, marcaAnoModelo: veiculoSeuNomeNaoSuaPosseMarcaAnoModelo, cor: veiculoSeuNomeNaoSuaPosseCor, taxaOk: veiculoSeuNomeNaoSuaPosseTaxaOk, detalheTaxaOk: detalheVeiculoSeuNomeNaoSuaPosseTaxaOk, multaPendente: veiculoSeuNomeNaoSuaPosseMultaPendente, detalheMultaPendente: detalheVeiculoSeuNomeNaoSuaPosseMultaPendente, voceDirige: veiculoSeuNomeNaoSuaPosseVoceDirige, detalheVoceDirige: detalheVeiculoSeuNomeNaoSuaPosseVoceDirige, proprietario: veiculoSeuNomeNaoSuaPosseProprietario, parentesco: veiculoSeuNomeNaoSuaPosseParentescoProprietario });
        setLVeiculoSeuNomeNaoSuaPosse([...lVeiculoSeuNomeNaoSuaPosse]);

        setVeiculoSeuNomeNaoSuaPossePlaca('');
        setVeiculoSeuNomeNaoSuaPosseMarcaAnoModelo('');
        setVeiculoSeuNomeNaoSuaPosseCor('');
        setVeiculoSeuNomeNaoSuaPosseTaxaOk('');
        setVeiculoSeuNomeNaoSuaPosseTaxaOkDiv(null);
        setVeiculoSeuNomeNaoSuaPosseMultaPendenteDiv(null);
        setVeiculoSeuNomeNaoSuaPosseVoceDirigeDiv(null);
        setVeiculoSeuNomeNaoSuaPosseProprietario('');
        setVeiculoSeuNomeNaoSuaPosseParentescoProprietario('');
    }

    const removeVeiculoSeuNomeNaoSuaPosse = (e, marcaAnoModelo, cor, placa) => {
        e.preventDefault();
        setLVeiculoSeuNomeNaoSuaPosse([...lVeiculoSeuNomeNaoSuaPosse.filter(veiculo => ((veiculo.marcaAnoModelo != marcaAnoModelo) || (veiculo.cor != cor) || (veiculo.placa != placa)))]);
    }

    const adicionaVeiculoSuaPosseNaoSeuNome = (e) => {
        e.preventDefault();
        let msgErro = '';
        const tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE I - INFORMAÇÕES DE TRÂNSITO(Posse de veículo que NÃO está em seu Nome)</div>';

        if(veiculoSuaPosseNaoSeuNomePlaca.length < 3)
            msgErro += '<li>Preencher a <b>Placa</b> do veículo, mínimo 3 caracteres</li>';
        if(veiculoSuaPosseNaoSeuNomeMarcaAnoModelo.length < 3)
            msgErro += '<li>Preencher a <b>Marca, o Ano, o Modelo</b> do veículo, mínimo 3 caracteres</li>';
        if(veiculoSuaPosseNaoSeuNomeCor.length < 3)
            msgErro += '<li>Preencher a <b>Cor</b> do veículo, mínimo 3 caracteres</li>';
        if(veiculoSuaPosseNaoSeuNomeTaxaOk === '')
            msgErro += '<li>Selecionar se o <b>Seguro Obrigatório, Licenciamento Anual e o IPVA estão todos pagos</b></li>';
        if(veiculoSuaPosseNaoSeuNomeTaxaOk === 'não'){
            if(detalheVeiculoSuaPosseNaoSeuNomeTaxaOk.length < 3)
                msgErro += '<li>Preencher a <b>informação sobre o motivo de Não estar pago, o Seguro Obrigatório, Licenciamento Anual e/ou o IPVA</b></li>';
        }
        if(veiculoSuaPosseNaoSeuNomeMultaPendente === '')
            msgErro += '<li>Selecionar se <b>há Multas com pagamento pendente</b></li>';
        if(veiculoSuaPosseNaoSeuNomeMultaPendente === 'sim'){
            if(detalheVeiculoSuaPosseNaoSeuNomeMultaPendente.length < 3)
                msgErro += '<li>Preencher o <b>motivo da Multa com pagamento pendente</b></li>';
        }
        if(veiculoSuaPosseNaoSeuNomeVoceDirige === '')
            msgErro += '<li>Selecionar se <b>Você dirige este veículo</b></li>';
        if(veiculoSuaPosseNaoSeuNomeVoceDirige === 'não'){
            if(detalheVeiculoSuaPosseNaoSeuNomeVoceDirige.length < 3)
                msgErro += '<li>Preencher a <b>informação de quem dirige e o motivo</b></li>';
        }
        if(veiculoSuaPosseNaoSeuNomeProprietario.length < 3)
            msgErro += '<li>Preencher o <b>nome do Proprietário do veículo</b>, mínimo 3 caracteres</li>';
        if(veiculoSuaPosseNaoSeuNomeParentescoProprietario.length < 3)
            msgErro += '<li>Preencher qual o seu <b>Grau de Parentesco, ou amizade com o proprietário do veículo</b>, mínimo 3 caracteres</li>';

        if(msgErro.length > 0){
            modalShow(msgInicialErro + tituloAba + '<ul style="padding-left:35px; list-style-type:square">'+msgErro+'</ul>');
            return false;
        }
        
        lVeiculoSuaPosseNaoSeuNome.push({ placa: veiculoSuaPosseNaoSeuNomePlaca, marcaAnoModelo: veiculoSuaPosseNaoSeuNomeMarcaAnoModelo, cor: veiculoSuaPosseNaoSeuNomeCor, taxaOk: veiculoSuaPosseNaoSeuNomeTaxaOk, detalheTaxaOk: detalheVeiculoSuaPosseNaoSeuNomeTaxaOk, multaPendente: veiculoSuaPosseNaoSeuNomeMultaPendente, detalheMultaPendente: detalheVeiculoSuaPosseNaoSeuNomeMultaPendente, voceDirige: veiculoSuaPosseNaoSeuNomeVoceDirige, detalheVoceDirige: detalheVeiculoSuaPosseNaoSeuNomeVoceDirige, proprietario: veiculoSuaPosseNaoSeuNomeProprietario, parentesco: veiculoSuaPosseNaoSeuNomeParentescoProprietario });
        setLVeiculoSuaPosseNaoSeuNome([...lVeiculoSuaPosseNaoSeuNome]);

        setVeiculoSuaPosseNaoSeuNomePlaca('');
        setVeiculoSuaPosseNaoSeuNomeMarcaAnoModelo('');
        setVeiculoSuaPosseNaoSeuNomeCor('');
        setVeiculoSuaPosseNaoSeuNomeTaxaOk('');
        setVeiculoSuaPosseNaoSeuNomeTaxaOkDiv(null);
        setVeiculoSuaPosseNaoSeuNomeMultaPendenteDiv(null);
        setVeiculoSuaPosseNaoSeuNomeVoceDirigeDiv(null);
        setVeiculoSuaPosseNaoSeuNomeProprietario('');
        setVeiculoSuaPosseNaoSeuNomeParentescoProprietario('');
    }

    const removeVeiculoSuaPosseNaoSeuNome = (e, marcaAnoModelo, cor, placa) => {
        e.preventDefault();
        setLVeiculoSuaPosseNaoSeuNome([...lVeiculoSuaPosseNaoSeuNome.filter(veiculo => ((veiculo.marcaAnoModelo != marcaAnoModelo) || (veiculo.cor != cor) || (veiculo.placa != placa)))]);
    }

    const adicionaVeiculoNenhumaCondicaoAnterior = (e) => {
        e.preventDefault();
        let msgErro = '';
        const tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE I - INFORMAÇÕES DE TRÂNSITO(Dirige algum veículo, NÃO enquadrado nas condições anteriores)</div>';

        if(veiculoNenhumaCondicaoAnteriorPlaca.length < 3)
            msgErro += '<li>Preencher a <b>Placa</b> do veículo, mínimo 3 caracteres</li>';
        if(veiculoNenhumaCondicaoAnteriorMarcaAnoModelo.length < 3)
            msgErro += '<li>Preencher a <b>Marca, o Ano, o Modelo</b> do veículo, mínimo 3 caracteres</li>';
        if(veiculoNenhumaCondicaoAnteriorCor.length < 3)
            msgErro += '<li>Preencher a <b>Cor</b> do veículo, mínimo 3 caracteres</li>';
        if(veiculoNenhumaCondicaoAnteriorTaxaOk === '')
            msgErro += '<li>Selecionar se o <b>Seguro Obrigatório, Licenciamento Anual e o IPVA estão todos pagos</b></li>';
        if(veiculoNenhumaCondicaoAnteriorTaxaOk === 'não'){
            if(detalheVeiculoNenhumaCondicaoAnteriorTaxaOk.length < 3)
                msgErro += '<li>Preencher a <b>informação sobre o motivo de Não estar pago, o Seguro Obrigatório, Licenciamento Anual e/ou o IPVA</b></li>';
        }
        if(veiculoNenhumaCondicaoAnteriorMultaPendente === '')
            msgErro += '<li>Selecionar se há <b>Multas com pagamento pendente</b></li>';
        if(veiculoNenhumaCondicaoAnteriorMultaPendente === 'sim'){
            if(detalheVeiculoNenhumaCondicaoAnteriorMultaPendente.length < 3)
                msgErro += '<li>Preencher o <b>motivo da Multa com pagamento pendente</b></li>';
        }

        if(msgErro.length > 0){
            modalShow(msgInicialErro + tituloAba + '<ul style="padding-left:35px; list-style-type:square">'+msgErro+'</ul>');
            return false;
        }
        
        lVeiculoNenhumaCondicaoAnterior.push({ placa: veiculoNenhumaCondicaoAnteriorPlaca, marcaAnoModelo: veiculoNenhumaCondicaoAnteriorMarcaAnoModelo, cor: veiculoNenhumaCondicaoAnteriorCor, taxaOk: veiculoNenhumaCondicaoAnteriorTaxaOk, detalheTaxaOk: detalheVeiculoNenhumaCondicaoAnteriorTaxaOk, multaPendente: veiculoNenhumaCondicaoAnteriorMultaPendente, detalheMultaPendente: detalheVeiculoNenhumaCondicaoAnteriorMultaPendente });
        setLVeiculoNenhumaCondicaoAnterior([...lVeiculoNenhumaCondicaoAnterior]);

        setVeiculoNenhumaCondicaoAnteriorPlaca('');
        setVeiculoNenhumaCondicaoAnteriorMarcaAnoModelo('');
        setVeiculoNenhumaCondicaoAnteriorCor('');
        setVeiculoNenhumaCondicaoAnteriorTaxaOk('');
        setVeiculoNenhumaCondicaoAnteriorTaxaOkDiv(null);
        setVeiculoNenhumaCondicaoAnteriorMultaPendenteDiv(null);
    }

    const removeVeiculoNenhumaCondicaoAnterior = (e, marcaAnoModelo, cor, placa) => {
        e.preventDefault();
        setLVeiculoNenhumaCondicaoAnterior([...lVeiculoNenhumaCondicaoAnterior.filter(veiculo => ((veiculo.marcaAnoModelo != marcaAnoModelo) || (veiculo.cor != cor) || (veiculo.placa != placa)))]);
    }

    const setSeparadoDivorciadoDiv = (e) => {
        e.preventDefault();
        setSeparadoDivorciado(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('separadoDivorciadoDiv').style.display = 'block';
        }
        else{
            setSeparadoDivorciadoNome('');
            setSeparadoDivorciadoCpf('');
            setSeparadoDivorciadoRg('');
            setSeparadoDivorciadoLocalTrabalho('');
            setSeparadoDivorciadoApelido('');
            setSeparadoDivorciadoEndereco('');
            setSeparadoDivorciadoProfissao('');
            setSeparadoDivorciadoRelacaoAtual('');
            setSeparadoDivorciadoNaturalidade('');
            setSeparadoDivorciadoNaturalEstado('');
            setSeparadoDivorciadoRelacionamentoMesInicio('');
            setSeparadoDivorciadoRelacionamentoAnoInicio('');
            setSeparadoDivorciadoRelacionamentoMesFim('');
            setSeparadoDivorciadoRelacionamentoAnoFim('');
            document.getElementById('separadoDivorciadoDiv').style.display = 'none';
        }
    }

    const setRelacionamentoDiv = (e) => {
        e.preventDefault();
        setRelacionamento(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('relacionamentoDiv').style.display = 'block';
        }
        else{
            setRelacionamentoNome('');
            setRelacionamentoCpf('');
            setRelacionamentoRg('');
            setRelacionamentoLocalTrabalho('');
            setRelacionamentoApelido('');
            setRelacionamentoEndereco('');
            setRelacionamentoProfissao('');
            setRelacionamentoDuracao('');
            setRelacionamentoNaturalidade('');
            setRelacionamentoNaturalEstado('');
            document.getElementById('relacionamentoDiv').style.display = 'none';
        }
    }

    const setProcessoPaternidadeDiv = (e) => {
        e.preventDefault();
        setProcessoPaternidade(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('processoPaternidadeDiv').style.display = 'block';
        }
        else{
            setDetalheProcessoPaternidade('');
            document.getElementById('processoPaternidadeDiv').style.display = 'none';
        }
    }

    const setFilhoEnteadoDiv = (e) => {
        e.preventDefault();
        setFilhoEnteado(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('filhoEnteadoDiv').style.display = 'block';
        }
        else{
            setFilhosEnteados([]);
            document.getElementById('filhoEnteadoDiv').style.display = 'none';
        }
    }
    
    const setSustentoFilhoEnteadoDiv = (e) => {
        e.preventDefault();
        setSustentoFilhoEnteado(e.target.value);
        if(e.target.value === 'não'){
            document.getElementById('sustentoFilhoEnteadoDiv').style.display = 'block';
        }
        else{
            setDetalheSustentoFilhoEnteado('');
            document.getElementById('sustentoFilhoEnteadoDiv').style.display = 'none';
        }
    }

    const setCasamentoUniaoEstavelDiv = (e) => {
        e.preventDefault();
        setCasamentoUniaoEstavel(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('casamentoUniaoEstavelDiv').style.display = 'block';
        }
        else{
            setCasamentoUniaoEstavelNome('');
            setCasamentoUniaoEstavelIdade('');
            setCasamentoUniaoEstavelNaturalidade('');
            setCasamentoUniaoEstavelNaturalEstado('');
            setCasamentoUniaoEstavelCpf('');
            setCasamentoUniaoEstavelRg('');
            setCasamentoUniaoEstavelApelido('');
            setCasamentoUniaoEstavelCondicao('');
            setCasamentoUniaoEstavelDataCasamentoRelacionamento('');
            setCasamentoUniaoEstavelLocalCasamento('');
            setCasamentoUniaoEstavelProfissao('');
            setCasamentoUniaoEstavelSalario('');
            setCasamentoUniaoEstavelEmpresa('');
            setCasamentoUniaoEstavelEmpresaEndereco('');
            setCasamentoUniaoEstavelFuncao('');
            document.getElementById('casamentoUniaoEstavelDiv').style.display = 'none';
        }
    }

    const setExaminadoTratadoInternadoDiv = (e) => {
        e.preventDefault();
        setExaminadoTratadoInternado(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('examinadoTratadoInternadoDiv').style.display = 'block';
        }
        else{
            setDetalheExaminadoTratadoInternado('');
            document.getElementById('examinadoTratadoInternadoDiv').style.display = 'none';
        }
    }

    const setTatuagemDiv = (e) => {
        e.preventDefault();
        setTatuagem(e.target.value);
        if(e.target.value === 'sim'){
            setDetalheTatuagem('a: \nb: \nc: \nd: \ne: ');
            document.getElementById('tatuagemDiv').style.display = 'block';
        }
        else{
            setDetalheTatuagem('');
            document.getElementById('tatuagemDiv').style.display = 'none';
        }
    }

    const setInternadoHospitalDiv = (e) => {
        e.preventDefault();
        setInternadoHospital(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('internadoHospitalDiv').style.display = 'block';
        }
        else{
            setDetalheInternadoHospital('');
            document.getElementById('internadoHospitalDiv').style.display = 'none';
        }
    }

    const setDesmaiouDiv = (e) => {
        e.preventDefault();
        setDesmaiou(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('desmaiouDiv').style.display = 'block';
        }
        else{
            setDetalheDesmaiou('');
            document.getElementById('desmaiouDiv').style.display = 'none';
        }
    }

    const setBebidaAlcoolicaDiv = (e) => {
        e.preventDefault();
        setBebidaAlcoolica(e.target.value);
        if(e.target.value === 'sim'){
            setDetalheBebidaAlcoolica('a: \nb: \nc: ');
            document.getElementById('bebidaAlcoolicaDiv').style.display = 'block';
        }
        else{
            setDetalheBebidaAlcoolica('');
            document.getElementById('bebidaAlcoolicaDiv').style.display = 'none';
        }
    }

    const setFumaDiv = (e) => {
        e.preventDefault();
        setFuma(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('fumaDiv').style.display = 'block';
        }
        else{
            setDetalheFuma('');
            document.getElementById('fumaDiv').style.display = 'none';
        }
    }

    const setEntorpecenteDiv = (e) => {
        e.preventDefault();
        setEntorpecente(e.target.value);
        if(e.target.value === 'sim'){
            setDetalheEntorpecente('a: \nb: \nc: \nd: \ne: \nf: \ng: \nh: \ni: \nj: ');
            document.getElementById('entorpecenteDiv').style.display = 'block';
        }
        else{
            setDetalheEntorpecente('');
            document.getElementById('entorpecenteDiv').style.display = 'none';
        }
    }

    const setEntorpecenteFamiliaDiv = (e) => {
        e.preventDefault();
        setEntorpecenteFamilia(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('entorpecenteFamiliaDiv').style.display = 'block';
        }
        else{
            setDetalheEntorpecenteFamilia('');
            document.getElementById('entorpecenteFamiliaDiv').style.display = 'none';
        }
    }

    const setEntorpecenteRelacaoDiv = (e) => {
        e.preventDefault();
        setEntorpecenteRelacao(e.target.value);
        if(e.target.value === 'sim'){
            setDetalheEntorpecenteRelacao('a: \nb: \nc: \nd: \ne: ');
            document.getElementById('entorpecenteRelacaoDiv').style.display = 'block';
        }
        else{
            setDetalheEntorpecenteRelacao('');
            document.getElementById('entorpecenteRelacaoDiv').style.display = 'none';
        }
    }

    const setAntecedenteCriminalRelacaoDiv = (e) => {
        e.preventDefault();
        setAntecedenteCriminalRelacao(e.target.value);
        if(e.target.value === 'sim'){
            setDetalheAntecedenteCriminalRelacao('a: \nb: \nc: \nd: \ne: \nf: ');
            document.getElementById('antecedenteCriminalRelacaoDiv').style.display = 'block';
        }
        else{
            setDetalheAntecedenteCriminalRelacao('');
            document.getElementById('antecedenteCriminalRelacaoDiv').style.display = 'none';
        }
    }

    const setFaPmGmPcPtcConhecidoDiv = (e) => {
        e.preventDefault();
        setFaPmGmPcPtcConhecido(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('faPmGmPcPtcConhecidoDiv').style.display = 'block';
        }
        else{
            setFaPmGmPcPtcNome('');
            document.getElementById('faPmGmPcPtcConhecidoDiv').style.display = 'none';
        }
    }

    const setSocioClubeDiv = (e) => {
        e.preventDefault();
        setSocioClube(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('socioClubeDiv').style.display = 'block';
        }
        else{
            setSocioClubes([]);
            document.getElementById('socioClubeDiv').style.display = 'none';
        }
    }

    const setSocioClubeNotificacaoPunicaoDiv = (e) => {
        e.preventDefault();
        setSocioClubeNotificacaoPunicao(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('socioClubeNotificacaoPunicaoDiv').style.display = 'block';
        }
        else{
            setDetalheSocioClubeNotificacaoPunicao('');
            document.getElementById('socioClubeNotificacaoPunicaoDiv').style.display = 'none';
        }
    }

    const setSindicatoNotificacaoPunicaoDiv = (e) => {
        e.preventDefault();
        setSindicatoNotificacaoPunicao(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('sindicatoNotificacaoPunicaoDiv').style.display = 'block';
        }
        else{
            setDetalheSindicatoNotificacaoPunicao('');
            document.getElementById('sindicatoNotificacaoPunicaoDiv').style.display = 'none';
        }
    }

    const setSindicatoDiv = (e) => {
        e.preventDefault();
        setSindicato(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('sindicatoDiv').style.display = 'block';
        }
        else{
            setSindicatos([]);
            document.getElementById('sindicatoDiv').style.display = 'none';
        }
    }

    const setPartidoPoliticoDiv = (e) => {
        e.preventDefault();
        setPartidoPolitico(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('partidoPoliticoDiv').style.display = 'block';
        }
        else{
            setPartidosPoliticos([]);
            document.getElementById('partidoPoliticoDiv').style.display = 'none';
        }
    }

    const setPartidoPoliticoNotificacaoPunicaoDiv = (e) => {
        e.preventDefault();
        setPartidoPoliticoNotificacaoPunicao(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('partidoPoliticoNotificacaoPunicaoDiv').style.display = 'block';
        }
        else{
            setDetalhePartidoPoliticoNotificacaoPunicao('');
            document.getElementById('partidoPoliticoNotificacaoPunicaoDiv').style.display = 'none';
        }
    }
    
    const setProcessoJusticaDiv = (e) => {
        e.preventDefault();
        setProcessoJustica(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('processoJusticaDiv').style.display = 'block';
        }
        else{
            setProcessoJusticaJC('');
            setProcessoJusticaJT('');
            setProcessoJusticaJCR('');
            setProcessoJusticaJM('');
            setProcessoJusticaVIJ('');
            setProcessoJusticaJEC('');
            setProcessosJustica([]);
            document.getElementById('processoJusticaDiv').style.display = 'none';
        }
    }

    const setOcorrenciaPolicialDiv = (e) => {
        e.preventDefault();
        setOcorrenciaPolicial(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('ocorrenciaPolicialDiv').style.display = 'block';
        }
        else{
            setOcorrenciasPoliciais([]);
            document.getElementById('ocorrenciaPolicialDiv').style.display = 'none';
        }
    }

    const setIpSindIpmDiv = (e) => {
        e.preventDefault();
        setIpSindIpm(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('ipSindIpmDiv').style.display = 'block';
        }
        else{
            setListaIpSindIpm([]);
            document.getElementById('ipSindIpmDiv').style.display = 'none';
        }
    }

    const setPadCdCjDiv = (e) => {
        e.preventDefault();
        setPadCdCj(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('padCdCjDiv').style.display = 'block';
        }
        else{
            setListaPadCdCj([]);
            document.getElementById('padCdCjDiv').style.display = 'none';
        }
    }

    const setFamiliaOpPjDiv = (e) => {
        e.preventDefault();
        setFamiliaOpPj(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('familiaOpPjDiv').style.display = 'block';
        }
        else{
            setFamiliasOpPj([]);
            document.getElementById('familiaOpPjDiv').style.display = 'none';
        }
    }
    
    const setArmaDeFogoDiv = (e) => {
        e.preventDefault();
        setArmaDeFogo(e.target.value);
        if(e.target.value === 'sim'){
            setDetalheArmaDeFogo('a: \nb: \nc: \nd: \ne: \nf: \ng: \nh: \ni: \nj: ');
            document.getElementById('armaDeFogoDiv').style.display = 'block';
        }
        else{
            setDetalheArmaDeFogo('');
            document.getElementById('armaDeFogoDiv').style.display = 'none';
        }
    }

    const setArmaDeFogoApreendidaDiv = (e) => {
        e.preventDefault();
        setArmaDeFogoApreendida(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('armaDeFogoApreendidaDiv').style.display = 'block';
        }
        else{
            setDetalheArmaDeFogoApreendida('');
            document.getElementById('armaDeFogoApreendidaDiv').style.display = 'none';
        }
    }

    const setTentouIngressarPmDiv = (e) => {
        e.preventDefault();
        setTentouIngressarPm(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('tentouIngressarPmDiv').style.display = 'block';
        }
        else{
            setTentouIngressarPmQuantidade('');
            setTentouIngressarPmReprovou('');
            document.getElementById('tentouIngressarPmDiv').style.display = 'none';
        }
    }

    const setTentouIngressarOutroEmpregoDiv = (e) => {
        e.preventDefault();
        setTentouIngressarOutroEmprego(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('tentouIngressarOutroEmpregoDiv').style.display = 'block';
        }
        else{
            setTentouIngressarOutroEmpregoA('');
            setTentouIngressarOutroEmpregoB('');
            setTentouIngressarOutroEmpregoC('');
            setTentouIngressarOutroEmpregoD('');
            setTentouIngressarOutroEmpregoE('');
            document.getElementById('tentouIngressarOutroEmpregoDiv').style.display = 'none';
        }
    }

    const setServicoMilitarDiv = (e) => {
        e.preventDefault();
        setServicoMilitar(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('servicoMilitarDiv').style.display = 'block';
        }
        else{
            setServicoMilitarUnidade('');
            setServicoMilitarCompanhia('');
            setServicoMilitarPelotao('');
            setServicoMilitarMesInicial('');
            setServicoMilitarAnoInicial('');
            setServicoMilitarMesFinal('');
            setServicoMilitarAnoFinal('');
            setServicoMilitarEndereco('');
            setServicoMilitarPontoReferencia('');
            setServicoMilitarFuncao('');
            setServicoMilitarPunicao('');
            setServicoMilitarMotivoBaixa('');
            document.getElementById('servicoMilitarDiv').style.display = 'none';
        }
    }
    
    const setEscolaTipoValidar = (e) => {
        e.preventDefault();
        setEscolaTipo(e.target.value);
        if(e.target.value === 'Presencial'){
            setEscolaRazaoEad('---');
        }
        else{
            setEscolaRazaoEad('');
        }
    }

    const setPunicaoEscolarDiv = (e) => {
        e.preventDefault();
        setPunicaoEscolar(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('punicaoEscolarDiv').style.display = 'block';
        }
        else{
            setDetalhePunicaoEscolar('');
            document.getElementById('punicaoEscolarDiv').style.display = 'none';
        }
    }

    const setProtecaoCreditoDiv = (e) => {
        e.preventDefault();
        setProtecaoCredito(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('protecaoCreditoDiv').style.display = 'block';
        }
        else{
            setProtecaoCreditoDivida('');
            setProtecaoCreditoDataCompra('');
            setProtecaoCreditoDataDeixouPagarDivida('');
            setProtecaoCreditoInstituicaoCredora('');
            setProtecaoCreditoMotivoDivida('');
            document.getElementById('protecaoCreditoDiv').style.display = 'none';
        }
    }

    const setPropriedadeImovelDiv = (e) => {
        e.preventDefault();
        setPropriedadeImovel(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('propriedadeImovelDiv').style.display = 'block';
        }
        else{
            setPropriedadeImovelTipo('');
            setPropriedadeImovelTamanho('');
            setPropriedadeImovelValorAvaliado('');
            document.getElementById('propriedadeImovelDiv').style.display = 'none';
        }
    }

    const setAtividadeEmpresarialDiv = (e) => {
        e.preventDefault();
        setAtividadeEmpresarial(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('atividadeEmpresarialDiv').style.display = 'block';
        }
        else{
            setAtividadeEmpresarialTipo('');
            setAtividadeEmpresarialNivelParticipacao('');
            setAtividadeEmpresarialEmpresa('');
            document.getElementById('atividadeEmpresarialDiv').style.display = 'none';
        }
    }

    const setPermissaoDirigirDiv = (e) => {
        e.preventDefault();
        setPermissaoDirigir(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('permissaoDirigirDiv').style.display = 'block';
        }
        else{
            setCnh('');
            setNumeroRegistroCnh('');
            setDataExpedicaoCnh('');
            setLocalCnh('');
            setCategoriaCnh('');
            document.getElementById('permissaoDirigirDiv').style.display = 'none';
        }
    }
    
    const setCnhMultaDiv = (e) => {
        e.preventDefault();
        setCnhMulta(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('cnhMultaDiv').style.display = 'block';
        }
        else{
            setDetalheCnhMulta('');
            document.getElementById('cnhMultaDiv').style.display = 'none';
        }
    }

    const setCnhApreendidaSuspensaCassadaDiv = (e) => {
        e.preventDefault();
        setCnhApreendidaSuspensaCassada(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('cnhApreendidaSuspensaCassadaDiv').style.display = 'block';
        }
        else{
            setDetalheCnhApreendidaSuspensaCassada('');
            document.getElementById('cnhApreendidaSuspensaCassadaDiv').style.display = 'none';
        }
    }

    const setAcidenteTransitoDiv = (e) => {
        e.preventDefault();
        setAcidenteTransito(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('acidenteTransitoDiv').style.display = 'block';
        }
        else{
            setDetalheAcidenteTransito('');
            document.getElementById('acidenteTransitoDiv').style.display = 'none';
        }
    }

    const setVeiculoSeuNomeSuaPosseDiv = (e) => {
        e.preventDefault();
        setVeiculoSeuNomeSuaPosse(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('veiculoSeuNomeSuaPosseDiv').style.display = 'block';
        }
        else{
            setVeiculoSeuNomeSuaPossePlaca('');
            setVeiculoSeuNomeSuaPosseMarcaAnoModelo('');
            setVeiculoSeuNomeSuaPosseCor('');
            setVeiculoSeuNomeSuaPosseTaxaOk('');
            setVeiculoSeuNomeSuaPosseTaxaOkDiv(null);
            setVeiculoSeuNomeSuaPosseMultaPendenteDiv(null);
            setVeiculoSeuNomeSuaPosseVoceDirigeDiv(null);
            document.getElementById('veiculoSeuNomeSuaPosseDiv').style.display = 'none';
        }
    }

    const setVeiculoSeuNomeSuaPosseTaxaOkDiv = (e) => {
        if(e === null){
            setVeiculoSeuNomeSuaPosseTaxaOk('');
            setDetalheVeiculoSeuNomeSuaPosseTaxaOk('');
            document.getElementById('veiculoSeuNomeSuaPosseTaxaOkDiv').style.display = 'none';
            return false;
        }

        e.preventDefault();
        setVeiculoSeuNomeSuaPosseTaxaOk(e.target.value);
        if(e.target.value === 'não'){
            document.getElementById('veiculoSeuNomeSuaPosseTaxaOkDiv').style.display = 'block';
        }
        else{
            setDetalheVeiculoSeuNomeSuaPosseTaxaOk('');
            document.getElementById('veiculoSeuNomeSuaPosseTaxaOkDiv').style.display = 'none';
        }
    }

    const setVeiculoSeuNomeSuaPosseMultaPendenteDiv = (e) => {
        if(e === null){
            setVeiculoSeuNomeSuaPosseMultaPendente('');
            setDetalheVeiculoSeuNomeSuaPosseMultaPendente('');
            document.getElementById('veiculoSeuNomeSuaPosseMultaPendenteDiv').style.display = 'none';
            return false;
        }

        e.preventDefault();
        setVeiculoSeuNomeSuaPosseMultaPendente(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('veiculoSeuNomeSuaPosseMultaPendenteDiv').style.display = 'block';
        }
        else{
            setDetalheVeiculoSeuNomeSuaPosseMultaPendente('');
            document.getElementById('veiculoSeuNomeSuaPosseMultaPendenteDiv').style.display = 'none';
        }
    }

    const setVeiculoSeuNomeSuaPosseVoceDirigeDiv = (e) => {
        if(e === null){
            setVeiculoSeuNomeSuaPosseVoceDirige('');
            setDetalheVeiculoSeuNomeSuaPosseVoceDirige('');
            document.getElementById('veiculoSeuNomeSuaPosseVoceDirigeDiv').style.display = 'none';
            return false;
        }

        e.preventDefault();
        setVeiculoSeuNomeSuaPosseVoceDirige(e.target.value);
        if(e.target.value === 'não'){
            document.getElementById('veiculoSeuNomeSuaPosseVoceDirigeDiv').style.display = 'block';
        }
        else{
            setDetalheVeiculoSeuNomeSuaPosseVoceDirige('');
            document.getElementById('veiculoSeuNomeSuaPosseVoceDirigeDiv').style.display = 'none';
        }
    }
    
    const setVeiculoSeuNomeNaoSuaPosseDiv = (e) => {
        e.preventDefault();
        setVeiculoSeuNomeNaoSuaPosse(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('veiculoSeuNomeNaoSuaPosseDiv').style.display = 'block';
        }
        else{
            setVeiculoSeuNomeNaoSuaPossePlaca('');
            setVeiculoSeuNomeNaoSuaPosseMarcaAnoModelo('');
            setVeiculoSeuNomeNaoSuaPosseCor('');
            setVeiculoSeuNomeNaoSuaPosseTaxaOk('');
            setVeiculoSeuNomeNaoSuaPosseTaxaOkDiv(null);
            setVeiculoSeuNomeNaoSuaPosseMultaPendenteDiv(null);
            setVeiculoSeuNomeNaoSuaPosseVoceDirigeDiv(null);
            setVeiculoSeuNomeNaoSuaPosseProprietario('');
            setVeiculoSeuNomeNaoSuaPosseParentescoProprietario('');
            document.getElementById('veiculoSeuNomeNaoSuaPosseDiv').style.display = 'none';
        }
    }

    const setVeiculoSeuNomeNaoSuaPosseTaxaOkDiv = (e) => {
        if(e === null){
            setVeiculoSeuNomeNaoSuaPosseTaxaOk('');
            setDetalheVeiculoSeuNomeNaoSuaPosseTaxaOk('');
            document.getElementById('veiculoSeuNomeNaoSuaPosseTaxaOkDiv').style.display = 'none';
            return false;
        }

        e.preventDefault();
        setVeiculoSeuNomeNaoSuaPosseTaxaOk(e.target.value);
        if(e.target.value === 'não'){
            document.getElementById('veiculoSeuNomeNaoSuaPosseTaxaOkDiv').style.display = 'block';
        }
        else{
            setDetalheVeiculoSeuNomeNaoSuaPosseTaxaOk('');
            document.getElementById('veiculoSeuNomeNaoSuaPosseTaxaOkDiv').style.display = 'none';
        }
    }

    const setVeiculoSeuNomeNaoSuaPosseMultaPendenteDiv = (e) => {
        if(e === null){
            setVeiculoSeuNomeNaoSuaPosseMultaPendente('');
            setDetalheVeiculoSeuNomeNaoSuaPosseMultaPendente('');
            document.getElementById('veiculoSeuNomeNaoSuaPosseMultaPendenteDiv').style.display = 'none';
            return false;
        }

        e.preventDefault();
        setVeiculoSeuNomeNaoSuaPosseMultaPendente(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('veiculoSeuNomeNaoSuaPosseMultaPendenteDiv').style.display = 'block';
        }
        else{
            setDetalheVeiculoSeuNomeNaoSuaPosseMultaPendente('');
            document.getElementById('veiculoSeuNomeNaoSuaPosseMultaPendenteDiv').style.display = 'none';
        }
    }

    const setVeiculoSeuNomeNaoSuaPosseVoceDirigeDiv = (e) => {
        if(e === null){
            setVeiculoSeuNomeNaoSuaPosseVoceDirige('');
            setDetalheVeiculoSeuNomeNaoSuaPosseVoceDirige('');
            document.getElementById('veiculoSeuNomeNaoSuaPosseVoceDirigeDiv').style.display = 'none';
            return false;
        }

        e.preventDefault();
        setVeiculoSeuNomeNaoSuaPosseVoceDirige(e.target.value);
        if(e.target.value === 'não'){
            document.getElementById('veiculoSeuNomeNaoSuaPosseVoceDirigeDiv').style.display = 'block';
        }
        else{
            setDetalheVeiculoSeuNomeNaoSuaPosseVoceDirige('');
            document.getElementById('veiculoSeuNomeNaoSuaPosseVoceDirigeDiv').style.display = 'none';
        }
    }

    const setVeiculoSuaPosseNaoSeuNomeDiv = (e) => {
        e.preventDefault();
        setVeiculoSuaPosseNaoSeuNome(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('veiculoSuaPosseNaoSeuNomeDiv').style.display = 'block';
        }
        else{
            setVeiculoSuaPosseNaoSeuNomePlaca('');
            setVeiculoSuaPosseNaoSeuNomeMarcaAnoModelo('');
            setVeiculoSuaPosseNaoSeuNomeCor('');
            setVeiculoSuaPosseNaoSeuNomeTaxaOk('');
            setVeiculoSuaPosseNaoSeuNomeTaxaOkDiv(null);
            setVeiculoSuaPosseNaoSeuNomeMultaPendenteDiv(null);
            setVeiculoSuaPosseNaoSeuNomeVoceDirigeDiv(null);
            setVeiculoSuaPosseNaoSeuNomeProprietario('');
            setVeiculoSuaPosseNaoSeuNomeParentescoProprietario('');
            document.getElementById('veiculoSuaPosseNaoSeuNomeDiv').style.display = 'none';
        }
    }

    const setVeiculoSuaPosseNaoSeuNomeTaxaOkDiv = (e) => {
        if(e === null){
            setVeiculoSuaPosseNaoSeuNomeTaxaOk('');
            setDetalheVeiculoSuaPosseNaoSeuNomeTaxaOk('');
            document.getElementById('veiculoSuaPosseNaoSeuNomeTaxaOkDiv').style.display = 'none';
            return false;
        }

        e.preventDefault();
        setVeiculoSuaPosseNaoSeuNomeTaxaOk(e.target.value);
        if(e.target.value === 'não'){
            document.getElementById('veiculoSuaPosseNaoSeuNomeTaxaOkDiv').style.display = 'block';
        }
        else{
            setDetalheVeiculoSuaPosseNaoSeuNomeTaxaOk('');
            document.getElementById('veiculoSuaPosseNaoSeuNomeTaxaOkDiv').style.display = 'none';
        }
    }

    const setVeiculoSuaPosseNaoSeuNomeMultaPendenteDiv = (e) => {
        if(e === null){
            setVeiculoSuaPosseNaoSeuNomeMultaPendente('');
            setDetalheVeiculoSuaPosseNaoSeuNomeMultaPendente('');
            document.getElementById('veiculoSuaPosseNaoSeuNomeMultaPendenteDiv').style.display = 'none';
            return false;
        }

        e.preventDefault();
        setVeiculoSuaPosseNaoSeuNomeMultaPendente(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('veiculoSuaPosseNaoSeuNomeMultaPendenteDiv').style.display = 'block';
        }
        else{
            setDetalheVeiculoSuaPosseNaoSeuNomeMultaPendente('');
            document.getElementById('veiculoSuaPosseNaoSeuNomeMultaPendenteDiv').style.display = 'none';
        }
    }
    
    const setVeiculoSuaPosseNaoSeuNomeVoceDirigeDiv = (e) => {
        if(e === null){
            setVeiculoSuaPosseNaoSeuNomeVoceDirige('');
            setDetalheVeiculoSuaPosseNaoSeuNomeVoceDirige('');
            document.getElementById('veiculoSuaPosseNaoSeuNomeVoceDirigeDiv').style.display = 'none';
            return false;
        }

        e.preventDefault();
        setVeiculoSuaPosseNaoSeuNomeVoceDirige(e.target.value);
        if(e.target.value === 'não'){
            document.getElementById('veiculoSuaPosseNaoSeuNomeVoceDirigeDiv').style.display = 'block';
        }
        else{
            setDetalheVeiculoSuaPosseNaoSeuNomeVoceDirige('');
            document.getElementById('veiculoSuaPosseNaoSeuNomeVoceDirigeDiv').style.display = 'none';
        }
    }

    const setVeiculoNenhumaCondicaoAnteriorDiv = (e) => {
        e.preventDefault();
        setVeiculoNenhumaCondicaoAnterior(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('veiculoNenhumaCondicaoAnteriorDiv').style.display = 'block';
        }
        else{
            setVeiculoNenhumaCondicaoAnteriorPlaca('');
            setVeiculoNenhumaCondicaoAnteriorMarcaAnoModelo('');
            setVeiculoNenhumaCondicaoAnteriorCor('');
            setVeiculoNenhumaCondicaoAnteriorTaxaOk('');
            setVeiculoNenhumaCondicaoAnteriorTaxaOkDiv(null);
            setVeiculoNenhumaCondicaoAnteriorMultaPendenteDiv(null);
            document.getElementById('veiculoNenhumaCondicaoAnteriorDiv').style.display = 'none';
        }
    }

    const setVeiculoNenhumaCondicaoAnteriorTaxaOkDiv = (e) => {
        if(e === null){
            setVeiculoNenhumaCondicaoAnteriorTaxaOk('');
            setDetalheVeiculoNenhumaCondicaoAnteriorTaxaOk('');
            document.getElementById('veiculoNenhumaCondicaoAnteriorTaxaOkDiv').style.display = 'none';
            return false;
        }

        e.preventDefault();
        setVeiculoNenhumaCondicaoAnteriorTaxaOk(e.target.value);
        if(e.target.value === 'não'){
            document.getElementById('veiculoNenhumaCondicaoAnteriorTaxaOkDiv').style.display = 'block';
        }
        else{
            setDetalheVeiculoNenhumaCondicaoAnteriorTaxaOk('');
            document.getElementById('veiculoNenhumaCondicaoAnteriorTaxaOkDiv').style.display = 'none';
        }
    }

    const setVeiculoNenhumaCondicaoAnteriorMultaPendenteDiv = (e) => {
        if(e === null){
            setVeiculoNenhumaCondicaoAnteriorMultaPendente('');
            setDetalheVeiculoNenhumaCondicaoAnteriorMultaPendente('');
            document.getElementById('veiculoNenhumaCondicaoAnteriorMultaPendenteDiv').style.display = 'none';
            return false;
        }

        e.preventDefault();
        setVeiculoNenhumaCondicaoAnteriorMultaPendente(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('veiculoNenhumaCondicaoAnteriorMultaPendenteDiv').style.display = 'block';
        }
        else{
            setDetalheVeiculoNenhumaCondicaoAnteriorMultaPendente('');
            document.getElementById('veiculoNenhumaCondicaoAnteriorMultaPendenteDiv').style.display = 'none';
        }
    }

    const setPagouPrometeuPagamentoDiv = (e) => {
        e.preventDefault();
        setPagouPrometeuPagamento(e.target.value);
        if(e.target.value === 'sim'){
            document.getElementById('pagouPrometeuPagamentoDiv').style.display = 'block';
        }
        else{
            setDetalhePagouPrometeuPagamento('');
            document.getElementById('pagouPrometeuPagamentoDiv').style.display = 'none';
        }
    }

    const setDetalheEmpregoSedeRadio = (e) => {
        setDetalheEmpregoSede('');
        
        if(e.target.value === 'matriz'){
            document.getElementById('detalheEmpregoSedeMatriz').checked = true;
            document.getElementById('detalheEmpregoSedeFilial').checked = false;
        }
        else if(e.target.value === 'filial'){
            document.getElementById('detalheEmpregoSedeMatriz').checked = false;
            document.getElementById('detalheEmpregoSedeFilial').checked = true;
        }
        setDetalheEmpregoSede(e.target.value);
    }

    const setFamiliarDataNascimentoSetaIdade = (dtNascimento) => {
        setFamiliarDataNascimento(dtNascimento);
        if(dtNascimento.length === 10){
            const dataAgora = new Date();
            const diaAgora = dataAgora.getDate();
            const mesAgora = dataAgora.getMonth() + 1;
            const anoAgora = dataAgora.getFullYear();

            const dtNascAux = dtNascimento.split('/');
            const diaNasc = Number(dtNascAux[0]);
            const mesNasc = Number(dtNascAux[1]);
            const anoNasc = Number(dtNascAux[2]);
            let idade = anoAgora - anoNasc;
            if(idade === 0)
                idade = 1;

            if(diaNasc > 31 || mesNasc > 12 || anoNasc > anoAgora || (anoAgora === anoNasc && mesAgora < mesNasc) || (anoAgora === anoNasc && mesAgora === mesNasc && diaNasc >= diaAgora)){
                setFamiliarIdade('ErroDtNasc');
                return false;
            }

            if(mesAgora < mesNasc && anoAgora < anoNasc)
                idade--;
            else {
                //Se mes do nascimento, verificar o dia
                if(mesAgora === mesNasc){
                    if(diaAgora < diaNasc && anoAgora < anoNasc){
                        //Se hoje for menor que o dia de nascimento, ele ainda nao fez aniversario
                        idade--;
                    }
                }
            }
            
            if(idade >= 1)
                setFamiliarIdade(idade);
            else
                setFamiliarIdade('ErroDtNasc');
        }
        else
            setFamiliarIdade('');
    }

    const voltarInstrucao = async (e) => {
        e.preventDefault();
        navigate('/formInstrucao');
    }

    const voltarPreenchido = async (e) => {
        e.preventDefault();
        navigate('/formPreenchido');
    }

    const finalizarCadastro = async (e) => {
        e.preventDefault();
        let msgErro = '';
        let msgErroAux = '';

        const responsePermDataHora = await axios.get(servidorBackEnd + '/getPermissaoAcessoDataHora2301?idCandidato=' + idCandidato);
        if(responsePermDataHora.data === ''){
            let date_format = {};
            date_format.year = 'numeric';
            date_format.month = '2-digit';
            date_format.day = '2-digit';
            date_format.hour = 'numeric';
            date_format.minute = 'numeric';

            var dataHoraFinalFic = new Date(candidato.form_data_final);
            dataHoraFinalFic.setMinutes(dataHoraFinalFic.getMinutes() + dataHoraFinalFic.getTimezoneOffset());

            modalShow(`<b style="color:red">Prazo para preenchimento da FIC (Ficha de Informações Confidenciais) finalizado!</b><br/>Prazo: ` + dataHoraFinalFic.toLocaleDateString('pt-BR', date_format) + 'h');
            return false;
        }

        let tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">FICHA DE INFORMAÇÕES CONFIDENCIAIS</div>';
        if(imagemFoto === '')
            msgErroAux += '<li>Adicione sua foto.<br/>Clique no botão localizado à esquerda <b>Atualizar Foto 5x7</b></li>';

        if(msgErroAux.length > 0){
            msgErro += tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErroAux + '</ul>';
            msgErroAux = '';
        }

        tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE A - INFORMAÇÕES PARTICULARES</div>';
        if(rgAnterior.length < 3)
            msgErroAux += '<li>Preencher <b>RG anterior (de outro Estado, se possuir)</b>, mínimo 3 caracteres</li>';
        if(profissao.length < 3)
            msgErroAux += '<li>Preencher a <b>Profissão</b>, mínimo 3 caracteres</li>';
        if(empregoAtual.length < 3)
            msgErroAux += '<li>Preencher o <b>Emprego atual</b>, mínimo 3 caracteres</li>';
        if(religiao.length < 3)
            msgErroAux += '<li>Preencher a <b>Religião</b>, mínimo 3 caracteres</li>';
        if(apelido.length < 3)
            msgErroAux += '<li>Preencher <b>Qualquer outro nome ou apelido pelo qual você é conhecido</b>, mínimo 3 caracteres</li>';
        if(comQuemResideAtualmente.length < 3)
            msgErroAux += '<li>Preencher <b>Com quem reside atualmente</b>, mínimo 3 caracteres</li>';
        if(todosEmail.length < 3)
            msgErroAux += '<li>Preencher <b>Correios eletrônicos (e-mail) particulares e funcionais (infome todos que você possui)</b>, mínimo 3 caracteres</li>';

        if(msgErroAux.length > 0){
            msgErro += tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErroAux + '</ul>';
            msgErroAux = '';
        }

        tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE B - INFORMAÇÕES RESIDENCIAIS</div>';
        if(JSON.stringify(resideResidiuApartir12anos) === '[]')
            msgErroAux += '<li>Nenhuma informação adicionada.<br/>Clique no botão <b>Clique Aqui para Registrar informação de residência</b></li>';
        if(motivoNResidiuPaiEsposa.length < 3)
            msgErroAux += '<li>Preencher <b>Caso você não tenha residido com os seus pais ou esposa durante algum período, explique os motivos</b>, mínimo 3 caracteres</li>';
        
        if(msgErroAux.length > 0){
            msgErro += tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErroAux + '</ul>';
            msgErroAux = '';
        }

        tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE C - INFORMAÇÕES FAMILIARES</div>';
        if(JSON.stringify(familiar) === '[]')
            msgErroAux += '<li>Nenhuma informação adicionada.<br/>Clique no botão <b>Clique Aqui para Registrar informação do familiar</b></li>';
        if(separadoDivorciado === '')
            msgErroAux += '<li>Selecione se <b>É separado (de fato ou de direito) ou divorciado</b></li>';
        else if(separadoDivorciado === 'sim'){
            if(separadoDivorciadoNome.length < 3)
                msgErroAux += '<li>Preencher <b>Nome</b> em Dados do ex-esposo(a) ou do ex-companheiro(a), mínimo 3 caracteres</li>';
            if(separadoDivorciadoCpf.length < 14)
                msgErroAux += '<li>Preencher <b>CPF</b> em Dados do ex-esposo(a) ou do ex-companheiro(a), mínimo 14 caracteres</li>';
            if(separadoDivorciadoRg.length < 3)
                msgErroAux += '<li>Preencher <b>RG</b> em Dados do ex-esposo(a) ou do ex-companheiro(a), mínimo 3 caracteres</li>';
            if(separadoDivorciadoLocalTrabalho.length < 3)
                msgErroAux += '<li>Preencher <b>Local de Trabalho</b> em Dados do ex-esposo(a) ou do ex-companheiro(a), mínimo 3 caracteres</li>';
            if(separadoDivorciadoApelido.length < 2)
                msgErroAux += '<li>Preencher <b>Qualquer outro nome ou apelido</b> em Dados do ex-esposo(a) ou do ex-companheiro(a), mínimo 2 caracteres</li>';
            if(separadoDivorciadoEndereco.length < 3)
                msgErroAux += '<li>Preencher <b>Endereço Completo</b> em Dados do ex-esposo(a) ou do ex-companheiro(a), mínimo 3 caracteres</li>';
            if(separadoDivorciadoProfissao.length < 3)
                msgErroAux += '<li>Preencher <b>Profissão</b> em Dados do ex-esposo(a) ou do ex-companheiro(a), mínimo 3 caracteres</li>';
            if(separadoDivorciadoRelacaoAtual.length < 3)
                msgErroAux += '<li>Preencher <b>Qual sua relação atual com ele(a)</b> em Dados do ex-esposo(a) ou do ex-companheiro(a), mínimo 3 caracteres</li>';
            if(separadoDivorciadoNaturalidade.length < 3)
                msgErroAux += '<li>Preencher <b>Natural de</b> em Dados do ex-esposo(a) ou do ex-companheiro(a), mínimo 3 caracteres</li>';
            if(separadoDivorciadoNaturalEstado === '')
                msgErroAux += '<li>Selecionar <b>Estado Naturalidade</b> em Dados do ex-esposo(a) ou do ex-companheiro(a)</li>';
            if(separadoDivorciadoRelacionamentoMesInicio === '')
                msgErroAux += '<li>Selecionar <b>Mês Inicial do Período do Relacionamento</b> em Dados do ex-esposo(a) ou do ex-companheiro(a)</li>';
            if(separadoDivorciadoRelacionamentoAnoInicio.length < 4)
                msgErroAux += '<li>Preencher <b>Ano Inicial do Período do Relacionamento</b> em Dados do ex-esposo(a) ou do ex-companheiro(a), formato aaaa</li>';
            if(separadoDivorciadoRelacionamentoMesFim === '')
                msgErroAux += '<li>Selecionar <b>Mês Final do Período do Relacionamento</b> em Dados do ex-esposo(a) ou do ex-companheiro(a)</li>';
            if(separadoDivorciadoRelacionamentoAnoFim.length < 4)
                msgErroAux += '<li>Preencher <b>Ano Final do Período do Relacionamento</b> em Dados do ex-esposo(a) ou do ex-companheiro(a), formato aaaa</li>';
        }
        if(relacionamento === '')
            msgErroAux += '<li>Selecione se <b>Você possui namorado ou relacionamento informal com alguma pessoa</b></li>';
        else if(relacionamento === 'sim'){
            if(relacionamentoNome.length < 3)
                msgErroAux += '<li>Preencher <b>Nome</b> em Possui namorado ou relacionamento informal com alguma pessoa, mínimo 3 caracteres</li>';
            if(relacionamentoCpf.length < 14)
                msgErroAux += '<li>Preencher <b>CPF</b> em Possui namorado ou relacionamento informal com alguma pessoa, mínimo 14 caracteres</li>';
            if(relacionamentoRg.length < 3)
                msgErroAux += '<li>Preencher <b>RG</b> em Possui namorado ou relacionamento informal com alguma pessoa, mínimo 3 caracteres</li>';
            if(relacionamentoLocalTrabalho.length < 3)
                msgErroAux += '<li>Preencher <b>Local de Trabalho</b> em Possui namorado ou relacionamento informal com alguma pessoa, mínimo 3 caracteres</li>';
            if(relacionamentoApelido.length < 2)
                msgErroAux += '<li>Preencher <b>Qualquer outro nome ou apelido</b> em Possui namorado ou relacionamento informal com alguma pessoa, mínimo 2 caracteres</li>';
            if(relacionamentoEndereco.length < 3)
                msgErroAux += '<li>Preencher <b>Endereço Completo</b> em Possui namorado ou relacionamento informal com alguma pessoa, mínimo 3 caracteres</li>';
            if(relacionamentoProfissao.length < 3)
                msgErroAux += '<li>Preencher <b>Profissão</b> em Possui namorado ou relacionamento informal com alguma pessoa, mínimo 3 caracteres</li>';
            if(relacionamentoDuracao.length < 3)
                msgErroAux += '<li>Preencher <b>Já há quanto tempo dura o relacionamento</b> em Possui namorado ou relacionamento informal com alguma pessoa, mínimo 3 caracteres</li>';
            if(relacionamentoNaturalidade.length < 3)
                msgErroAux += '<li>Preencher <b>Natural de</b> em Possui namorado ou relacionamento informal com alguma pessoa, mínimo 3 caracteres</li>';
            if(relacionamentoNaturalEstado === '')
                msgErroAux += '<li>Selecionar <b>Estado Naturalidade</b> em Possui namorado ou relacionamento informal com alguma pessoa</li>';
        }
        if(processoPaternidade === '')
            msgErroAux += '<li>Selecione se <b>Você já esteve envolvido em algum processo de paternidade</b></li>';
        else if(processoPaternidade === 'sim'){
            if(detalheProcessoPaternidade.length < 3)
                msgErroAux += '<li>Preencher <b>Informações detalhadas</b> do processo de paternidade, mínimo 3 caracteres</li>';
        }
        if(filhoEnteado === '')
            msgErroAux += '<li>Selecione se <b>Você tem filhos e/ou enteados</b></li>';
        else if(filhoEnteado === 'sim'){
            if(JSON.stringify(filhosEnteados) === '[]')
                msgErroAux += '<li>Nenhuma informação adicionada.<br/>Clique no botão <b>Clique Aqui para Registrar informação do filho(a)/enteado(a)</b></li>';
            if(sustentoFilhoEnteado === '')
                msgErroAux += '<li>Selecione se <b>Está provendo o sustento dos seus filhos e/ou enteados</b></li>';
            else if(sustentoFilhoEnteado === 'não'){
                if(detalheSustentoFilhoEnteado.length < 3)
                    msgErroAux += '<li>Preencher <b>Informações detalhadas do por que não prover o sustento dos seus filhos e/ou enteados</b> em Filhos e/ou enteados, mínimo 3 caracteres</li>';
            }
        }
        if(casamentoUniaoEstavel === '')
            msgErroAux += '<li>Selecione se <b>Você é casado ou está em uma união estável</b></li>';
        else if(casamentoUniaoEstavel === 'sim'){
            if(casamentoUniaoEstavelNome.length < 3)
                msgErroAux += '<li>Preencher <b>Nome do cônjuge/companheiro(a)</b> em Casado ou está em uma união estável, mínimo 3 caracteres</li>';
            if(casamentoUniaoEstavelIdade.length < 1)
                msgErroAux += '<li>Preencher <b>Idade</b> em Casado ou está em uma união estável, mínimo 1 caracter</li>';
            if(casamentoUniaoEstavelNaturalidade.length < 3)
                msgErroAux += '<li>Preencher <b>Natural de</b> em Casado ou está em uma união estável, mínimo 3 caracteres</li>';
            if(casamentoUniaoEstavelNaturalEstado === '')
                msgErroAux += '<li>Selecionar <b>Estado Naturalidade</b> em Casado ou está em uma união estável</li>';
            if(casamentoUniaoEstavelCpf.length < 14)
                msgErroAux += '<li>Preencher <b>CPF</b> em Casado ou está em uma união estável, mínimo 14 caracteres</li>';
            if(casamentoUniaoEstavelRg.length < 3)
                msgErroAux += '<li>Preencher <b>RG</b> em Casado ou está em uma união estável, mínimo 3 caracteres</li>';
            if(casamentoUniaoEstavelApelido.length < 2)
                msgErroAux += '<li>Preencher <b>Qualquer outro nome ou apelido</b> em Casado ou está em uma união estável, mínimo 2 caracteres</li>';
            if(casamentoUniaoEstavelCondicao === '')
                msgErroAux += '<li>Selecionar <b>Condição do relacionamento</b> em Casado ou está em uma união estável</li>';
            if(casamentoUniaoEstavelDataCasamentoRelacionamento.length < 10)
                msgErroAux += '<li>Preencher <b>Data do casamento/Início do relacionamento</b> em Casado ou está em uma união estável, formato dd/mm/aaaa</li>';
            if(casamentoUniaoEstavelLocalCasamento.length < 3)
                msgErroAux += '<li>Preencher <b>Local do casamento</b> em Casado ou está em uma união estável, mínimo 3 caracteres</li>';
            if(casamentoUniaoEstavelProfissao.length < 3)
                msgErroAux += '<li>Preencher <b>Profissão</b> em Casado ou está em uma união estável, mínimo 3 caracteres</li>';
            if(casamentoUniaoEstavelSalario.length < 3)
                msgErroAux += '<li>Preencher <b>Salário (R$)</b> em Casado ou está em uma união estável, mínimo 3 caracteres</li>';
            if(casamentoUniaoEstavelEmpresa.length < 3)
                msgErroAux += '<li>Preencher <b>Nome da empresa onde cônjuge/companheiro(a) trabalha</b> em Casado ou está em uma união estável, mínimo 3 caracteres</li>';
            if(casamentoUniaoEstavelEmpresaEndereco.length < 3)
                msgErroAux += '<li>Preencher <b>Endereço Completo da EMPRESA</b> em Casado ou está em uma união estável, mínimo 3 caracteres</li>';
            if(casamentoUniaoEstavelFuncao.length < 3)
                msgErroAux += '<li>Preencher <b>Função que cônjuge/companheiro(a) exerce</b> em Casado ou está em uma união estável, mínimo 3 caracteres</li>';
        }
            
        if(msgErroAux.length > 0){
            msgErro += tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErroAux + '</ul>';
            msgErroAux = '';
        }

        tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE D - INFORMAÇÕES DE ORDEM MÉDICA, SÓCIO-COMPORTAMENTAL E PARTIDÁRIA</div>';
        if(examinadoTratadoInternado === '')
            msgErroAux += '<li>Selecione se <b>Você ou alguém de sua família já foi examinado, tratado ou já esteve internado em razão de distúrbios nervosos, mentais ou moléstia prolongada</b></li>';
        else if(examinadoTratadoInternado === 'sim'){
            if(detalheExaminadoTratadoInternado.length < 3)
                msgErroAux += '<li>Preencher <b>Forneça informações detalhadas, relatando qual ou quais foram as moléstias, os locais e períodos de tratamento e/ou internação, bem como a situação atual do citado quadro de saúde</b>, mínimo 3 caracteres</li>';
        }
        if(tatuagem === '')
            msgErroAux += '<li>Selecione se <b>Você possui ou já possuiu tatuagem</b></li>';
        else if(tatuagem === 'sim'){
            if(detalheTatuagem.length < 20)
                msgErroAux += '<li>Preencher <b>Informações detalhadas</b> em Você possui ou já possuiu tatuagem</li>';
        }
        if(internadoHospital === '')
            msgErroAux += '<li>Selecione se <b>Você já foi internado em algum hospital</b></li>';
        else if(internadoHospital === 'sim'){
            if(detalheInternadoHospital.length < 3)
                msgErroAux += '<li>Preencher <b>Informações detalhadas</b> em Você já foi internado em algum hospital, mínimo 3 caracteres</li>';
        }
        if(desmaiou === '')
            msgErroAux += '<li>Selecione se <b>Você já desmaiou alguma vez</b></li>';
        else if(desmaiou === 'sim'){
            if(detalheDesmaiou.length < 3)
                msgErroAux += '<li>Preencher <b>Informações detalhadas</b> em Você já desmaiou alguma vez, mínimo 3 caracteres</li>';
        }
        if(bebidaAlcoolica === '')
            msgErroAux += '<li>Selecione se <b>Você já fez ou faz uso de bebidas alcóolicas</b></li>';
        else if(bebidaAlcoolica === 'sim'){
            if(detalheBebidaAlcoolica.length < 12)
                msgErroAux += '<li>Preencher <b>Informações detalhadas</b> em Você já fez ou faz uso de bebidas alcóolicas, mínimo 3 caracteres</li>';
        }
        if(fuma === '')
            msgErroAux += '<li>Selecione se <b>Você fuma ou já fumou</b></li>';
        else if(fuma === 'sim'){
            if(detalheFuma.length < 3)
                msgErroAux += '<li>Preencher <b>Informações detalhadas</b> em Você fuma ou já fumou, mínimo 3 caracteres</li>';
        }
        if(entorpecente === '')
            msgErroAux += '<li>Selecione se <b>Você já fez ou faz uso de substância entorpecente ilícita (drogas) ou já experimentou alguma vez</b></li>';
        else if(entorpecente === 'sim'){
            if(detalheEntorpecente.length < 40)
                msgErroAux += '<li>Preencher <b>Informações detalhadas</b> em Você já fez ou faz uso de substância entorpecente ilícita (drogas) ou já experimentou alguma vez</li>';
        }
        if(entorpecenteFamilia === '')
            msgErroAux += '<li>Selecione se <b>Alguém de sua família (pai, mãe, irmãos e outros parentes com quem você se relaciona com frequência) já fez ou faz uso de substância entorpecente ilícita (drogas)</b></li>';
        else if(entorpecenteFamilia === 'sim'){
            if(detalheEntorpecenteFamilia.length < 3)
                msgErroAux += '<li>Preencher <b>Informações detalhadas</b> em Alguém de sua família (pai, mãe, irmãos e outros parentes com quem você se relaciona com frequência) já fez ou faz uso de substância entorpecente ilícita (drogas), mínimo 3 caracteres</li>';
        }
        if(entorpecenteRelacao === '')
            msgErroAux += '<li>Selecione se <b>Você conhece alguém, possui/possuiu algum amigo(a), ou se relaciona/relacionou afetivamente com alguém que fez ou faz uso de substância entorpecente ilícita (drogas)</b></li>';
        else if(entorpecenteRelacao === 'sim'){
            if(detalheEntorpecenteRelacao.length < 20)
                msgErroAux += '<li>Preencher <b>Informações detalhadas</b> em Você conhece alguém, possui/possuiu algum amigo(a), ou se relaciona/relacionou afetivamente com alguém que fez ou faz uso de substância entorpecente ilícita (drogas)</li>';
        }
        if(antecedenteCriminalRelacao === '')
            msgErroAux += '<li>Selecione se <b>Você conhece alguém, possui/possuiu algum amigo(a), ou se relaciona/relacionou afetivamente com alguém que possui antecedentes criminais</b></li>';
        else if(antecedenteCriminalRelacao === 'sim'){
            if(detalheAntecedenteCriminalRelacao.length < 24)
                msgErroAux += '<li>Preencher <b>Informações detalhadas</b> em Você conhece alguém, possui/possuiu algum amigo(a), ou se relaciona/relacionou afetivamente com alguém que possui antecedentes criminais</li>';
        }
        if(testemunha1Nome.length < 3)
            msgErroAux += '<li>Preencher <b>Nome</b> em Testemunha 1, mínimo 3 caracteres</li>';
        if(testemunha1Idade.length < 1)
            msgErroAux += '<li>Preencher <b>Idade</b> em Testemunha 1, mínimo 1 caracter</li>';
        if(testemunha1Naturalidade.length < 3)
            msgErroAux += '<li>Preencher <b>Natural de</b> em Testemunha 1, mínimo 3 caracteres</li>';
        if(testemunha1NaturalEstado === '')
            msgErroAux += '<li>Selecionar <b>Estado Naturalidade</b> em Testemunha 1</li>';
        if(testemunha1Cpf.length < 14)
            msgErroAux += '<li>Preencher <b>CPF</b> em Testemunha 1, mínimo 14 caracteres</li>';
        if(testemunha1Rg.length < 3)
            msgErroAux += '<li>Preencher <b>RG</b> em Testemunha 1, mínimo 3 caracteres</li>';
        if(testemunha1TempoConhece.length < 3)
            msgErroAux += '<li>Preencher <b>Há quanto tempo se conhecem</b> em Testemunha 1, mínimo 3 caracteres</li>';
        if(testemunha1Apelido.length < 2)
            msgErroAux += '<li>Preencher <b>Qualquer outro nome ou apelido</b> em Testemunha 1, mínimo 2 caracteres</li>';
        if(testemunha1EnderecoResidencial.length < 3)
            msgErroAux += '<li>Preencher <b>Endereço Completo RESIDENCIAL</b> em Testemunha 1, mínimo 3 caracteres</li>';
        if(testemunha1Profissao.length < 3)
            msgErroAux += '<li>Preencher <b>Profissão</b> em Testemunha 1, mínimo 3 caracteres</li>';
        if(testemunha1EnderecoComercial.length < 3)
            msgErroAux += '<li>Preencher <b>Endereço Completo COMERCIAL</b> em Testemunha 1, mínimo 3 caracteres</li>';
        if(testemunha2Nome.length < 3)
            msgErroAux += '<li>Preencher <b>Nome</b> em Testemunha 2, mínimo 3 caracteres</li>';
        if(testemunha2Idade.length < 1)
            msgErroAux += '<li>Preencher <b>Idade</b> em Testemunha 2, mínimo 1 caracter</li>';
        if(testemunha2Naturalidade.length < 3)
            msgErroAux += '<li>Preencher <b>Natural de</b> em Testemunha 2, mínimo 3 caracteres</li>';
        if(testemunha2NaturalEstado === '')
            msgErroAux += '<li>Selecionar <b>Estado Naturalidade</b> em Testemunha 2</li>';
        if(testemunha2Cpf.length < 14)
            msgErroAux += '<li>Preencher <b>CPF</b> em Testemunha 2, mínimo 14 caracteres</li>';
        if(testemunha2Rg.length < 3)
            msgErroAux += '<li>Preencher <b>RG</b> em Testemunha 2, mínimo 3 caracteres</li>';
        if(testemunha2TempoConhece.length < 3)
            msgErroAux += '<li>Preencher <b>Há quanto tempo se conhecem</b> em Testemunha 2, mínimo 3 caracteres</li>';
        if(testemunha2Apelido.length < 2)
            msgErroAux += '<li>Preencher <b>Qualquer outro nome ou apelido</b> em Testemunha 2, mínimo 2 caracteres</li>';
        if(testemunha2EnderecoResidencial.length < 3)
            msgErroAux += '<li>Preencher <b>Endereço Completo RESIDENCIAL</b> em Testemunha 2, mínimo 3 caracteres</li>';
        if(testemunha2Profissao.length < 3)
            msgErroAux += '<li>Preencher <b>Profissão</b> em Testemunha 2, mínimo 3 caracteres</li>';
        if(testemunha2EnderecoComercial.length < 3)
            msgErroAux += '<li>Preencher <b>Endereço Completo COMERCIAL</b> em Testemunha 2, mínimo 3 caracteres</li>';
        if(testemunha3Nome.length < 3)
            msgErroAux += '<li>Preencher <b>Nome</b> em Testemunha 3, mínimo 3 caracteres</li>';
        if(testemunha3Idade.length < 1)
            msgErroAux += '<li>Preencher <b>Idade</b> em Testemunha 3, mínimo 1 caracter</li>';
        if(testemunha3Naturalidade.length < 3)
            msgErroAux += '<li>Preencher <b>Natural de</b> em Testemunha 3, mínimo 3 caracteres</li>';
        if(testemunha3NaturalEstado === '')
            msgErroAux += '<li>Selecionar <b>Estado Naturalidade</b> em Testemunha 3</li>';
        if(testemunha3Cpf.length < 14)
            msgErroAux += '<li>Preencher <b>CPF</b> em Testemunha 3, mínimo 14 caracteres</li>';
        if(testemunha3Rg.length < 3)
            msgErroAux += '<li>Preencher <b>RG</b> em Testemunha 3, mínimo 3 caracteres</li>';
        if(testemunha3TempoConhece.length < 3)
            msgErroAux += '<li>Preencher <b>Há quanto tempo se conhecem</b> em Testemunha 3, mínimo 3 caracteres</li>';
        if(testemunha3Apelido.length < 2)
            msgErroAux += '<li>Preencher <b>Qualquer outro nome ou apelido</b> em Testemunha 3, mínimo 2 caracteres</li>';
        if(testemunha3EnderecoResidencial.length < 3)
            msgErroAux += '<li>Preencher <b>Endereço Completo RESIDENCIAL</b> em Testemunha 3, mínimo 3 caracteres</li>';
        if(testemunha3Profissao.length < 3)
            msgErroAux += '<li>Preencher <b>Profissão</b> em Testemunha 3, mínimo 3 caracteres</li>';
        if(testemunha3EnderecoComercial.length < 3)
            msgErroAux += '<li>Preencher <b>Endereço Completo COMERCIAL</b> em Testemunha 3, mínimo 3 caracteres</li>';
        if(testemunha4Nome.length < 3)
            msgErroAux += '<li>Preencher <b>Nome</b> em Testemunha 4, mínimo 3 caracteres</li>';
        if(testemunha4Idade.length < 1)
            msgErroAux += '<li>Preencher <b>Idade</b> em Testemunha 4, mínimo 1 caracter</li>';
        if(testemunha4Naturalidade.length < 3)
            msgErroAux += '<li>Preencher <b>Natural de</b> em Testemunha 4, mínimo 3 caracteres</li>';
        if(testemunha4NaturalEstado === '')
            msgErroAux += '<li>Selecionar <b>Estado Naturalidade</b> em Testemunha 4</li>';
        if(testemunha4Cpf.length < 14)
            msgErroAux += '<li>Preencher <b>CPF</b> em Testemunha 4, mínimo 14 caracteres</li>';
        if(testemunha4Rg.length < 3)
            msgErroAux += '<li>Preencher <b>RG</b> em Testemunha 4, mínimo 3 caracteres</li>';
        if(testemunha4TempoConhece.length < 3)
            msgErroAux += '<li>Preencher <b>Há quanto tempo se conhecem</b> em Testemunha 4, mínimo 3 caracteres</li>';
        if(testemunha4Apelido.length < 2)
            msgErroAux += '<li>Preencher <b>Qualquer outro nome ou apelido</b> em Testemunha 4, mínimo 2 caracteres</li>';
        if(testemunha4EnderecoResidencial.length < 3)
            msgErroAux += '<li>Preencher <b>Endereço Completo RESIDENCIAL</b> em Testemunha 4, mínimo 3 caracteres</li>';
        if(testemunha4Profissao.length < 3)
            msgErroAux += '<li>Preencher <b>Profissão</b> em Testemunha 4, mínimo 3 caracteres</li>';
        if(testemunha4EnderecoComercial.length < 3)
            msgErroAux += '<li>Preencher <b>Endereço Completo COMERCIAL</b> em Testemunha 4, mínimo 3 caracteres</li>';
        if(faPmGmPcPtcConhecido === '')
            msgErroAux += '<li>Selecione se <b>Você possui ou já possuiu parentes e/ou amigos pessoais nas Forças Armadas, Polícia Militar, Guarda Municipal, Polícia Civil ou Polícia Técnico-Científica</b></li>';
        else if(faPmGmPcPtcConhecido === 'sim'){
            if(JSON.stringify(faPmGmPcPtc) === '[]')
                msgErroAux += '<li>Nenhuma informação adicionada.<br/>Clique no botão <b>Clique Aqui para Registrar informação do parente/amigo(a)</b></li>';
        }
        if(folga.length < 3)
            msgErroAux += '<li>Preencher <b>O que você costuma fazer ou gosta de estar fazendo em suas horas de folga</b>, mínimo 3 caracteres</li>';
        if(costumeLocalDiaSemana.length < 3)
            msgErroAux += '<li>Preencher <b>Quais são seus costumes e locais que por hábito tem frequentado: a. Durante a semana (de segunda-feira a sexta-feira)</b>, mínimo 3 caracteres</li>';
        if(costumeLocalFdsFeriado.length < 3)
            msgErroAux += '<li>Preencher <b>Quais são seus costumes e locais que por hábito tem frequentado: b. Durante o final de semana e feriados</b>, mínimo 3 caracteres</li>';
        if(socioClube === '')
            msgErroAux += '<li>Selecione se <b>Você é ou já foi sócio de algum clube/associação desportiva</b></li>';
        else if(socioClube === 'sim'){
            if(JSON.stringify(socioClubes) === '[]')
                msgErroAux += '<li>Nenhuma informação adicionada.<br/>Clique no botão <b>Clique Aqui para Registrar informação do clube/associação desportiva</b></li>';
        }
        if(sindicato === '')
            msgErroAux += '<li>Selecione se <b>Você pertence ou já pertenceu a qualquer sindicato ou outra associação de classe</b></li>';
        else if(sindicato === 'sim'){
            if(JSON.stringify(sindicatos) === '[]')
                msgErroAux += '<li>Nenhuma informação adicionada.<br/>Clique no botão <b>Clique Aqui para Registrar informação do sindicato/associação de classe</b></li>';
        }
        if(partidoPolitico === '')
            msgErroAux += '<li>Selecione se <b>Você é ou já foi filiado a algum partido político</b></li>';
        else if(partidoPolitico === 'sim'){
            if(JSON.stringify(partidosPoliticos) === '[]')
                msgErroAux += '<li>Nenhuma informação adicionada.<br/>Clique no botão <b>Clique Aqui para Registrar informação do partido político</b></li>';
        }
    
        if(msgErroAux.length > 0){
            msgErro += tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErroAux + '</ul>';
            msgErroAux = '';
        }

        tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE E - INFORMAÇÕES JUDICIAIS E POLICIAIS</div>';
        if(processoJustica === '')
            msgErroAux += '<li>Selecione se <b>Você já fez ou faz parte de algum Processo na Justiça, seja na condição de autor, indiciado, réu, testemunha ou até mesmo como vítima</b></li>';
        else if(processoJustica === 'sim'){
            if(processoJusticaJC === '')
                msgErroAux += '<li>Selecionar <b>a. Justiça Civil</b> em Informações detalhadas do Processo na Justiça</li>';
            if(processoJusticaJT === '')
                msgErroAux += '<li>Selecionar <b>b. Justiça Trabalhista</b> em Informações detalhadas do Processo na Justiça</li>';
            if(processoJusticaJCR === '')
                msgErroAux += '<li>Selecionar <b>c. Justiça Criminal</b> em Informações detalhadas do Processo na Justiça</li>';
            if(processoJusticaJM === '')
                msgErroAux += '<li>Selecionar <b>d. Justiça Militar</b> em Informações detalhadas do Processo na Justiça</li>';
            if(processoJusticaVIJ === '')
                msgErroAux += '<li>Selecionar <b>e. Vara da Infância e Juventude</b> em Informações detalhadas do Processo na Justiça</li>';
            if(processoJusticaJEC === '')
                msgErroAux += '<li>Selecionar <b>f. Juizado Especial Criminal</b> em Informações detalhadas do Processo na Justiça</li>';
            if(JSON.stringify(processosJustica) === '[]')
                msgErroAux += '<li>Nenhuma informação adicionada.<br/>Clique no botão <b>Clique Aqui para Registrar informação do processo</b></li>';
        }
        if(ocorrenciaPolicial === '')
            msgErroAux += '<li>Selecione se <b>Você já esteve envolvido em ocorrência policial</b></li>';
        else if(ocorrenciaPolicial === 'sim'){
            if(JSON.stringify(ocorrenciasPoliciais) === '[]')
                msgErroAux += '<li>Nenhuma informação adicionada.<br/>Clique no botão <b>Clique Aqui para Registrar informação da ocorrência policial</b></li>';
        }
        if(ipSindIpm === '')
            msgErroAux += '<li>Selecione se <b>Você já esteve envolvido em ou Investigação Preliminar (IP), Sindicância ou Inquérito Policial Militar (IPM)</b></li>';
        else if(ipSindIpm === 'sim'){
            if(JSON.stringify(listaIpSindIpm) === '[]')
                msgErroAux += '<li>Nenhuma informação adicionada.<br/>Clique no botão <b>Clique Aqui para Registrar informação da I.P./Sindicância/I.P.M.</b></li>';
        }
        if(padCdCj === '')
            msgErroAux += '<li>Selecione se <b>Você já esteve alguma vez envolvido em Processo Administrativo Disciplinar (PAD), Conselho de Disciplina (CD) ou Conselho de Justificação (CJ)</b></li>';
        else if(padCdCj === 'sim'){
            if(JSON.stringify(listaPadCdCj) === '[]')
                msgErroAux += '<li>Nenhuma informação adicionada.<br/>Clique no botão <b>Clique Aqui para Registrar informação do P.A.D./C.D./C.J.</b></li>';
        }
        if(familiaOpPj === '')
            msgErroAux += '<li>Selecione se <b>Algum membro de sua família já esteve ou está envolvido em ocorrência policial ou com algum Processo na Justiça</b></li>';
        else if(familiaOpPj === 'sim'){
            if(JSON.stringify(familiasOpPj) === '[]')
                msgErroAux += '<li>Nenhuma informação adicionada.<br/>Clique no botão <b>Clique Aqui para Registrar informação do membro da família</b></li>';
        }
        if(armaDeFogo === '')
            msgErroAux += '<li>Selecione se <b>Você possui ou já possuiu arma de fogo</b></li>';
        else if(armaDeFogo === 'sim'){
            if(detalheArmaDeFogo.length < 40)
                msgErroAux += '<li>Preencher <b>Informações detalhadas</b> em Você possui ou já possuiu arma de fogo</li>';
            if(armaDeFogoApreendida === '')
                msgErroAux += '<li>Selecione se <b>Você já teve arma apreendida</b></li>';
            else if(armaDeFogoApreendida === 'sim'){
                if(detalheArmaDeFogoApreendida.length < 3){
                    msgErroAux += '<li>Preencher <b>Informações detalhadas</b> em Você já teve arma apreendida, mínimo 3 caracteres</li>';
                }
            }
        }
        
        if(msgErroAux.length > 0){
            msgErro += tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErroAux + '</ul>';
            msgErroAux = '';
        }

        tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE F - INFORMAÇÕES PROFISSIONAIS</div>';
        if(JSON.stringify(detalheEmprego) === '[]')
            msgErroAux += '<li>Nenhuma informação adicionada.<br/>Clique no botão <b>Clique Aqui para Registrar informação do trabalho, da atividade desenvolvida</b></li>';
        if(tentouIngressarPm === '')
            msgErroAux += '<li>Selecione se <b>Algum membro de sua família já esteve ou está envolvido em ocorrência policial ou com algum Processo na Justiça</b></li>';
        else if(tentouIngressarPm === 'sim'){
            if(tentouIngressarPmQuantidade.length < 3)
                msgErroAux += '<li>Preencher <b>a. Quantas vezes tentou ingressar e quando</b> em Você procurou anteriormente ingressar na Polícia Militar, mínimo 3 caracteres</li>';
            if(tentouIngressarPmReprovou.length < 3)
                msgErroAux += '<li>Preencher <b>b. Qual(is) a(s) Etapa(s) em que você foi reprovado e o motivo da reprovação</b> em Você procurou anteriormente ingressar na Polícia Militar, mínimo 3 caracteres</li>';
        }
        if(tentouIngressarOutroEmprego === '')
            msgErroAux += '<li>Selecione se <b>Você procurou anteriormente ingressar em algum outro emprego público</b></li>';
        else if(tentouIngressarOutroEmprego === 'sim'){
            if(tentouIngressarOutroEmpregoA.length < 3)
                msgErroAux += '<li>Preencher <b>a. Qual foi a Instituição e o cargo pretendido</b> em Você procurou anteriormente ingressar em algum outro emprego público, mínimo 3 caracteres</li>';
            if(tentouIngressarOutroEmpregoB.length < 3)
                msgErroAux += '<li>Preencher <b>b. Quantas vezes você tentou ingressar</b> em Você procurou anteriormente ingressar em algum outro emprego público, mínimo 3 caracteres</li>';
            if(tentouIngressarOutroEmpregoC.length < 3)
                msgErroAux += '<li>Preencher <b>c. Qual foi o ano e o local do concurso (cidade/Estado)</b> em Você procurou anteriormente ingressar em algum outro emprego público, mínimo 3 caracteres</li>';
            if(tentouIngressarOutroEmpregoD.length < 3)
                msgErroAux += '<li>Preencher <b>d. Qual foi o resultado que você obteve nos exames prestados</b> em Você procurou anteriormente ingressar em algum outro emprego público, mínimo 3 caracteres</li>';
            if(tentouIngressarOutroEmpregoE.length < 3)
                msgErroAux += '<li>Preencher <b>e. Se reprovado, qual foi o motivo e a etapa do concurso em que você foi reprovado</b> em Você procurou anteriormente ingressar em algum outro emprego público, mínimo 3 caracteres</li>';
        }
        if(servicoMilitar === '')
            msgErroAux += '<li>Selecione se <b>Você serviu o Serviço Militar Obrigatório</b></li>';
        else if(servicoMilitar === 'sim'){
            if(servicoMilitarUnidade.length < 3)
                msgErroAux += '<li>Preencher <b>Unidade em que você serviu</b> em Você serviu o Serviço Militar Obrigatório, mínimo 3 caracteres</li>';
            if(servicoMilitarCompanhia.length < 3)
                msgErroAux += '<li>Preencher <b>Companhia em que você serviu</b> em Você serviu o Serviço Militar Obrigatório, mínimo 3 caracteres</li>';
            if(servicoMilitarPelotao.length < 3)
                msgErroAux += '<li>Preencher <b>Pelotão em que você serviu</b> em Você serviu o Serviço Militar Obrigatório, mínimo 3 caracteres</li>';
            if(servicoMilitarMesInicial === '')
                msgErroAux += '<li>Selecionar <b>Mês Inicial do Período em que serviu</b> em Você serviu o Serviço Militar Obrigatório</li>';
            if(servicoMilitarAnoInicial.length < 4)
                msgErroAux += '<li>Preencher <b>Ano Inicial do Período em que serviu</b> em Você serviu o Serviço Militar Obrigatório, formato aaaa</li>';
            if(servicoMilitarMesFinal === '')
                msgErroAux += '<li>Selecionar <b>Mês Final do Período em que serviu</b> em Você serviu o Serviço Militar Obrigatório</li>';
            if(servicoMilitarAnoFinal.length < 4)
                msgErroAux += '<li>Preencher <b>Ano Final do Período em que serviu</b> em Você serviu o Serviço Militar Obrigatório, formato aaaa</li>';
            if(servicoMilitarEndereco.length < 3)
                msgErroAux += '<li>Preencher <b>Endereço Completo</b> em Você serviu o Serviço Militar Obrigatório, mínimo 3 caracteres</li>';
            if(servicoMilitarPontoReferencia.length < 3)
                msgErroAux += '<li>Preencher <b>Ponto de Referência (Batalhão PM da área)</b> em Você serviu o Serviço Militar Obrigatório, mínimo 3 caracteres</li>';
            if(servicoMilitarFuncao.length < 3)
                msgErroAux += '<li>Preencher <b>Função que desempenhava</b> em Você serviu o Serviço Militar Obrigatório, mínimo 3 caracteres</li>';
            if(servicoMilitarPunicao.length < 3)
                msgErroAux += '<li>Preencher <b>Relacione TODAS as punições sofridas e os seus motivos, inclusive aquelas punições que são do seu conhecimento e que por ventura, talvez não constem registradas nos seus assentamentos de registro militar</b> em Você serviu o Serviço Militar Obrigatório, mínimo 3 caracteres</li>';
            if(servicoMilitarMotivoBaixa.length < 3)
                msgErroAux += '<li>Preencher <b>Qual foi o motivo da baixa</b> em Você serviu o Serviço Militar Obrigatório, mínimo 3 caracteres</li>';
        }
        
        if(msgErroAux.length > 0){
            msgErro += tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErroAux + '</ul>';
            msgErroAux = '';
        }

        tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE G - INFORMAÇÕES ESCOLARES</div>';
        if(JSON.stringify(escolas) === '[]')
            msgErroAux += '<li>Nenhuma informação adicionada.<br/>Clique no botão <b>Clique Aqui para Registrar informação escolar</b></li>';
        if(punicaoEscolar === '')
            msgErroAux += '<li>Selecione se <b>Você sofreu alguma punição escolar (advertência, suspensão, expulsão etc) em qualquer estabelecimento de ensino</b></li>';
        else if(punicaoEscolar === 'sim'){
            if(detalhePunicaoEscolar.length < 3)
                msgErroAux += '<li>Preencher <b>A data da punição, a sanção aplicada e o fato que causou a punição escolar</b> em Você sofreu alguma punição escolar (advertência, suspensão, expulsão etc) em qualquer estabelecimento de ensino, mínimo 3 caracteres</li>';
        }

        if(msgErroAux.length > 0){
            msgErro += tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErroAux + '</ul>';
            msgErroAux = '';
        }

        tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE H - INFORMAÇÕES ECONÔMICAS</div>';
        if(protecaoCredito === '')
            msgErroAux += '<li>Selecione se <b>Está com o nome registrado em algum órgão ou entidade de controle e proteção ao crédito financeiro (SCPC etc)? Possui cheques devolvidos, títulos protestados, prestações ou dívidas em atraso</b></li>';
        else if(protecaoCredito === 'sim'){
            if(protecaoCreditoDivida.length < 3)
                msgErroAux += '<li>Preencher <b>a. Valor da(s) dívida(s)</b> em Controle e proteção ao crédito financeiro, mínimo 3 caracteres</li>';
            if(protecaoCreditoDataCompra.length < 3)
                msgErroAux += '<li>Preencher <b>b. Data em que as compras foram realizadas</b> em Controle e proteção ao crédito financeiro, mínimo 3 caracteres</li>';
            if(protecaoCreditoDataDeixouPagarDivida.length < 3)
                msgErroAux += '<li>Preencher <b>c. Data em que você deixou de pagar a(s) dívida(s)</b> em Controle e proteção ao crédito financeiro, mínimo 3 caracteres</li>';
            if(protecaoCreditoInstituicaoCredora.length < 3)
                msgErroAux += '<li>Preencher <b>d. Nome da(s) Instituição(ões) credora(s) da(s) sua(s) dívida(s)</b> em Controle e proteção ao crédito financeiro, mínimo 3 caracteres</li>';
            if(protecaoCreditoMotivoDivida.length < 3)
                msgErroAux += '<li>Preencher <b>e. MOTIVOS pelos quais você não pagou a(s) dívida(s), tornando-o inadimplente</b> em Controle e proteção ao crédito financeiro, mínimo 3 caracteres</li>';
        }
        if(propriedadeImovel === '')
            msgErroAux += '<li>Selecione se <b>Você possui ou já possuiu em seu nome, a propriedade de algum bem imóvel</b></li>';
        else if(propriedadeImovel === 'sim'){
            if(propriedadeImovelTipo.length < 3)
                msgErroAux += '<li>Preencher <b>a. Qual tipo de imóvel</b> em Propriedade de algum bem imóvel, mínimo 3 caracteres</li>';
            if(propriedadeImovelTamanho.length < 3)
                msgErroAux += '<li>Preencher <b>b. Qual tamanho do imóvel (metragem geral)</b> em Propriedade de algum bem imóvel, mínimo 3 caracteres</li>';
            if(propriedadeImovelValorAvaliado.length < 3)
                msgErroAux += '<li>Preencher <b>c. Qual valor de mercado em que está avaliado o imóvel</b> em Propriedade de algum bem imóvel, mínimo 3 caracteres</li>';
        }
        if(atividadeEmpresarial === '')
            msgErroAux += '<li>Selecione se <b>Você possui ou já possuiu em seu nome, alguma atividade empresarial, seja na condição de proprietário ou sócio</b></li>';
        else if(atividadeEmpresarial === 'sim'){
            if(atividadeEmpresarialTipo.length < 3)
                msgErroAux += '<li>Preencher <b>a. Qual tipo de atividade empresarial realizada</b> em Atividade empresarial, mínimo 3 caracteres</li>';
            if(atividadeEmpresarialNivelParticipacao.length < 3)
                msgErroAux += '<li>Preencher <b>b. Qual nível/grau de sua participação na constituição da empresa</b> em Atividade empresarial, mínimo 3 caracteres</li>';
            if(atividadeEmpresarialEmpresa.length < 3)
                msgErroAux += '<li>Preencher <b>c. Informe os dados de identificação da empresa (Nome, CNPJ, endereço, sócios, data do contrato etc)</b> em Atividade empresarial, mínimo 3 caracteres</li>';
        }

        if(msgErroAux.length > 0){
            msgErro += tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErroAux + '</ul>';
            msgErroAux = '';
        }

        tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">PARTE I - INFORMAÇÕES DE TRÂNSITO</div>';
        if(permissaoDirigir === '')
            msgErroAux += '<li>Selecione se <b>Você possui Carteira Nacional de Habilitação (CNH) ou Permissão Para Dirigir (PPD)</b></li>';
        else if(permissaoDirigir === 'sim'){
            if(cnh.length < 3)
                msgErroAux += '<li>Preencher <b>Nº da CNH ou da PPD</b> em Você possui Carteira Nacional de Habilitação (CNH) ou Permissão Para Dirigir (PPD), mínimo 3 caracteres</li>';
            if(numeroRegistroCnh.length < 3)
                msgErroAux += '<li>Preencher <b>Nº do registro</b> em Você possui Carteira Nacional de Habilitação (CNH) ou Permissão Para Dirigir (PPD), mínimo 3 caracteres</li>';
            if(dataExpedicaoCnh.length < 10)
                msgErroAux += '<li>Preencher <b>Data de Expedição</b> em Você possui Carteira Nacional de Habilitação (CNH) ou Permissão Para Dirigir (PPD), formato dd/mm/aaaa</li>';
            if(localCnh.length < 3)
                msgErroAux += '<li>Preencher <b>Local da Expedição</b> em Você possui Carteira Nacional de Habilitação (CNH) ou Permissão Para Dirigir (PPD), mínimo 3 caracteres</li>';
            if(categoriaCnh.length < 1)
                msgErroAux += '<li>Preencher <b>Categoria</b> em Você possui Carteira Nacional de Habilitação (CNH) ou Permissão Para Dirigir (PPD), mínimo 3 caracteres</li>';
            if(cnhMulta === '')
                msgErroAux += '<li>Selecione se <b>Sua CNH ou PPD possui pontuação por multas</b> em Você possui Carteira Nacional de Habilitação (CNH) ou Permissão Para Dirigir (PPD)</li>';
            else if(cnhMulta === 'sim'){
                if(detalheCnhMulta.length < 3){
                    msgErroAux += '<li>Preencher <b>Informações detalhadas</b> em Sua CNH ou PPD possui pontuação por multas(Você possui Carteira Nacional de Habilitação (CNH) ou Permissão Para Dirigir (PPD)), mínimo 3 caracteres</li>';
                }
            }
        }
        if(cnhApreendidaSuspensaCassada === '')
            msgErroAux += '<li>Selecione se <b>Você já teve alguma vez a CNH ou PPD apreendida, suspensa ou cassada</b></li>';
        else if(cnhApreendidaSuspensaCassada === 'sim'){
            if(detalheCnhApreendidaSuspensaCassada.length < 3)
                msgErroAux += '<li>Preencher <b>Informações Detalhadas</b> em Você já teve alguma vez a CNH ou PPD apreendida, suspensa ou cassada, mínimo 3 caracteres</li>';
        }
        if(acidenteTransito === '')
            msgErroAux += '<li>Selecione se <b>Você já esteve envolvido em acidente de trânsito ao dirigir veículo</b></li>';
        else if(acidenteTransito === 'sim'){
            if(detalheAcidenteTransito.length < 3)
                msgErroAux += '<li>Preencher <b>Informações Detalhadas</b> em Você já esteve envolvido em acidente de trânsito ao dirigir veículo, mínimo 3 caracteres</li>';
        }
        if(veiculoSeuNomeSuaPosse === '')
            msgErroAux += '<li>Selecione se <b>Você possui veículo(s) em seu nome e que esteja na sua posse</b></li>';
        else if(veiculoSeuNomeSuaPosse === 'sim'){
            if(JSON.stringify(lVeiculoSeuNomeSuaPosse) === '[]')
                msgErroAux += '<li>Nenhuma informação adicionada.<br/>Clique no botão <b>Clique Aqui para Registrar informação do veículo</b> em Você possui veículo(s) em seu nome e que esteja na sua posse</li>';
        }
        if(veiculoSeuNomeNaoSuaPosse === '')
            msgErroAux += '<li>Selecione se <b>Você possui veículo(s) em seu nome e que NÃO esteja na sua posse</b></li>';
        else if(veiculoSeuNomeNaoSuaPosse === 'sim'){
            if(JSON.stringify(lVeiculoSeuNomeNaoSuaPosse) === '[]')
                msgErroAux += '<li>Nenhuma informação adicionada.<br/>Clique no botão <b>Clique Aqui para Registrar informação do veículo</b> em Você possui veículo(s) em seu nome e que NÃO esteja na sua posse</li>';
        }
        if(veiculoSuaPosseNaoSeuNome === '')
            msgErroAux += '<li>Selecione se <b>Você tem a posse de veículo(s) que não está(ão) em seu nome</b></li>';
        else if(veiculoSuaPosseNaoSeuNome === 'sim'){
            if(JSON.stringify(lVeiculoSuaPosseNaoSeuNome) === '[]')
                msgErroAux += '<li>Nenhuma informação adicionada.<br/>Clique no botão <b>Clique Aqui para Registrar informação do veículo</b> em Você tem a posse de veículo(s) que não está(ão) em seu nome</li>';
        }
        if(veiculoNenhumaCondicaoAnterior === '')
            msgErroAux += '<li>Selecione se <b>Você dirige algum veículo que NÃO esteja enquadrado em nenhuma das condições descritas nas questões anteriores</b></li>';
        else if(veiculoNenhumaCondicaoAnterior === 'sim'){
            if(JSON.stringify(lVeiculoNenhumaCondicaoAnterior) === '[]')
                msgErroAux += '<li>Nenhuma informação adicionada.<br/>Clique no botão <b>Clique Aqui para Registrar informação do veículo</b> em Você dirige algum veículo que NÃO esteja enquadrado em nenhuma das condições descritas nas questões anteriores</li>';
        }
        if(pagouPrometeuPagamento === '')
            msgErroAux += '<li>Selecione se <b>Você já pagou ou prometeu qualquer tipo de pagamento, em dinheiro ou não, para obter recomendações ou promessas de auxílio nos exames de seleção para ingresso na Polícia Militar</b></li>';
        else if(pagouPrometeuPagamento === 'sim'){
            if(detalhePagouPrometeuPagamento.length < 3)
                msgErroAux += '<li>Preencher <b>Informações Detalhadas</b> em Você já pagou ou prometeu qualquer tipo de pagamento, em dinheiro ou não, para obter recomendações ou promessas de auxílio nos exames de seleção para ingresso na Polícia Militar, mínimo 3 caracteres</li>';
        }
        if(informacoesComplementares.length < 3)
            msgErroAux += '<li>Preencher <b>Espaço reservado para esclarecimentos de lacunas no preenchimento deste formulário, bem como para prestar outras informações complementares, julgadas relevantes</b>, mínimo 3 caracteres</li>';
            
        if(msgErroAux.length > 0){
            msgErro += tituloAba + '<ul style="padding-left:35px; list-style-type:square">' + msgErroAux + '</ul>';
            msgErroAux = '';
        }

        tituloAba = '<div class="has-background-info has-text-white" style="font-weight:bold; text-align:center">TERMO, DECLARAÇÃO E AUTORIZAÇÃO</div>';
        if(document.getElementById('checkTermo1').checked === false)
            msgErroAux += '<li>Marque o <b>Primeiro Termo/Declaração/Autorização</b></li>';
        if(document.getElementById('checkTermo2').checked === false)
            msgErroAux += '<li>Marque o <b>Segundo Termo/Declaração/Autorização</b></li>';
        if(document.getElementById('checkTermo3').checked === false)
            msgErroAux += '<li>Marque o <b>Terceiro Termo/Declaração/Autorização</b></li>';

        if(msgErroAux.length > 0){
            msgErro += tituloAba + 'Todos os Termos devem estar marcados:<ul style="padding-left:35px; list-style-type:square">' + msgErroAux + '</ul>';
            msgErroAux = '';
        }

        if(msgErro.length > 0){
            modalShow( msgInicialErro + msgErro);
            return false;
        }

        let urlFormularioExecutar = '';
        if(dadosFormularioSalvo === null)
            urlFormularioExecutar = 'insertFormulario2301';
        else
            urlFormularioExecutar = 'updateFormulario2301';
        
        const responseSave = await axios.post(servidorBackEnd + '/' + urlFormularioExecutar, {
            nrInscricao:                                        nrInscricao,
            rgAnterior:                                         rgAnterior,
            profissao:                                          profissao,
            empregoAtual:                                       empregoAtual,
            religiao:                                           religiao,
            apelido:                                            apelido,
            comQuemResideAtualmente:                            comQuemResideAtualmente,
            todosEmail:                                         todosEmail,
            resideResidiuApartir12anos:                         resideResidiuApartir12anos,
            motivoNResidiuPaiEsposa:                            motivoNResidiuPaiEsposa,
            familiar:                                           familiar,
            separadoDivorciado:                                 separadoDivorciado,
            separadoDivorciadoNome:                             separadoDivorciadoNome,
            separadoDivorciadoCpf:                              separadoDivorciadoCpf,
            separadoDivorciadoRg:                               separadoDivorciadoRg,
            separadoDivorciadoLocalTrabalho:                    separadoDivorciadoLocalTrabalho,
            separadoDivorciadoApelido:                          separadoDivorciadoApelido,
            separadoDivorciadoEndereco:                         separadoDivorciadoEndereco,
            separadoDivorciadoProfissao:                        separadoDivorciadoProfissao,
            separadoDivorciadoRelacaoAtual:                     separadoDivorciadoRelacaoAtual,
            separadoDivorciadoNaturalidade:                     separadoDivorciadoNaturalidade,
            separadoDivorciadoNaturalEstado:                    separadoDivorciadoNaturalEstado,
            separadoDivorciadoRelacionamentoMesInicio:          separadoDivorciadoRelacionamentoMesInicio,
            separadoDivorciadoRelacionamentoAnoInicio:          separadoDivorciadoRelacionamentoAnoInicio,
            separadoDivorciadoRelacionamentoMesFim:             separadoDivorciadoRelacionamentoMesFim,
            separadoDivorciadoRelacionamentoAnoFim:             separadoDivorciadoRelacionamentoAnoFim,
            relacionamento:                                     relacionamento,
            relacionamentoNome:                                 relacionamentoNome,
            relacionamentoCpf:                                  relacionamentoCpf,
            relacionamentoRg:                                   relacionamentoRg,
            relacionamentoLocalTrabalho:                        relacionamentoLocalTrabalho,
            relacionamentoApelido:                              relacionamentoApelido,
            relacionamentoEndereco:                             relacionamentoEndereco,
            relacionamentoProfissao:                            relacionamentoProfissao,
            relacionamentoDuracao:                              relacionamentoDuracao,
            relacionamentoNaturalidade:                         relacionamentoNaturalidade,
            relacionamentoNaturalEstado:                        relacionamentoNaturalEstado,
            processoPaternidade:                                processoPaternidade,
            detalheProcessoPaternidade:                         detalheProcessoPaternidade,
            filhoEnteado:                                       filhoEnteado,
            filhosEnteados:                                     filhosEnteados,
            sustentoFilhoEnteado:                               sustentoFilhoEnteado,
            detalheSustentoFilhoEnteado:                        detalheSustentoFilhoEnteado,
            casamentoUniaoEstavel:                              casamentoUniaoEstavel,
            casamentoUniaoEstavelNome:                          casamentoUniaoEstavelNome,
            casamentoUniaoEstavelIdade:                         casamentoUniaoEstavelIdade,
            casamentoUniaoEstavelNaturalidade:                  casamentoUniaoEstavelNaturalidade,
            casamentoUniaoEstavelNaturalEstado:                 casamentoUniaoEstavelNaturalEstado,
            casamentoUniaoEstavelCpf:                           casamentoUniaoEstavelCpf,
            casamentoUniaoEstavelRg:                            casamentoUniaoEstavelRg,
            casamentoUniaoEstavelApelido:                       casamentoUniaoEstavelApelido,
            casamentoUniaoEstavelCondicao:                      casamentoUniaoEstavelCondicao,
            casamentoUniaoEstavelDataCasamentoRelacionamento:   casamentoUniaoEstavelDataCasamentoRelacionamento,
            casamentoUniaoEstavelLocalCasamento:                casamentoUniaoEstavelLocalCasamento,
            casamentoUniaoEstavelProfissao:                     casamentoUniaoEstavelProfissao,
            casamentoUniaoEstavelSalario:                       casamentoUniaoEstavelSalario,
            casamentoUniaoEstavelEmpresa:                       casamentoUniaoEstavelEmpresa,
            casamentoUniaoEstavelEmpresaEndereco:               casamentoUniaoEstavelEmpresaEndereco,
            casamentoUniaoEstavelFuncao:                        casamentoUniaoEstavelFuncao,
            examinadoTratadoInternado:                          examinadoTratadoInternado,
            detalheExaminadoTratadoInternado:                   detalheExaminadoTratadoInternado,
            tatuagem:                                           tatuagem,
            detalheTatuagem:                                    detalheTatuagem,
            internadoHospital:                                  internadoHospital,
            detalheInternadoHospital:                           detalheInternadoHospital,
            desmaiou:                                           desmaiou,
            detalheDesmaiou:                                    detalheDesmaiou,
            bebidaAlcoolica:                                    bebidaAlcoolica,
            detalheBebidaAlcoolica:                             detalheBebidaAlcoolica,
            fuma:                                               fuma,
            detalheFuma:                                        detalheFuma,
            entorpecente:                                       entorpecente,
            detalheEntorpecente:                                detalheEntorpecente,
            entorpecenteFamilia:                                entorpecenteFamilia,
            detalheEntorpecenteFamilia:                         detalheEntorpecenteFamilia,
            entorpecenteRelacao:                                entorpecenteRelacao,
            detalheEntorpecenteRelacao:                         detalheEntorpecenteRelacao,
            antecedenteCriminalRelacao:                         antecedenteCriminalRelacao,
            detalheAntecedenteCriminalRelacao:                  detalheAntecedenteCriminalRelacao,
            testemunha1Nome:                                    testemunha1Nome,
            testemunha1Idade:                                   testemunha1Idade,
            testemunha1Naturalidade:                            testemunha1Naturalidade,
            testemunha1NaturalEstado:                           testemunha1NaturalEstado,
            testemunha1Cpf:                                     testemunha1Cpf,
            testemunha1Rg:                                      testemunha1Rg,
            testemunha1TempoConhece:                            testemunha1TempoConhece,
            testemunha1Apelido:                                 testemunha1Apelido,
            testemunha1EnderecoResidencial:                     testemunha1EnderecoResidencial,
            testemunha1Profissao:                               testemunha1Profissao,
            testemunha1EnderecoComercial:                       testemunha1EnderecoComercial,
            testemunha2Nome:                                    testemunha2Nome,
            testemunha2Idade:                                   testemunha2Idade,
            testemunha2Naturalidade:                            testemunha2Naturalidade,
            testemunha2NaturalEstado:                           testemunha2NaturalEstado,
            testemunha2Cpf:                                     testemunha2Cpf,
            testemunha2Rg:                                      testemunha2Rg,
            testemunha2TempoConhece:                            testemunha2TempoConhece,
            testemunha2Apelido:                                 testemunha2Apelido,
            testemunha2EnderecoResidencial:                     testemunha2EnderecoResidencial,
            testemunha2Profissao:                               testemunha2Profissao,
            testemunha2EnderecoComercial:                       testemunha2EnderecoComercial,
            testemunha3Nome:                                    testemunha3Nome,
            testemunha3Idade:                                   testemunha3Idade,
            testemunha3Naturalidade:                            testemunha3Naturalidade,
            testemunha3NaturalEstado:                           testemunha3NaturalEstado,
            testemunha3Cpf:                                     testemunha3Cpf,
            testemunha3Rg:                                      testemunha3Rg,
            testemunha3TempoConhece:                            testemunha3TempoConhece,
            testemunha3Apelido:                                 testemunha3Apelido,
            testemunha3EnderecoResidencial:                     testemunha3EnderecoResidencial,
            testemunha3Profissao:                               testemunha3Profissao,
            testemunha3EnderecoComercial:                       testemunha3EnderecoComercial,
            testemunha4Nome:                                    testemunha4Nome,
            testemunha4Idade:                                   testemunha4Idade,
            testemunha4Naturalidade:                            testemunha4Naturalidade,
            testemunha4NaturalEstado:                           testemunha4NaturalEstado,
            testemunha4Cpf:                                     testemunha4Cpf,
            testemunha4Rg:                                      testemunha4Rg,
            testemunha4TempoConhece:                            testemunha4TempoConhece,
            testemunha4Apelido:                                 testemunha4Apelido,
            testemunha4EnderecoResidencial:                     testemunha4EnderecoResidencial,
            testemunha4Profissao:                               testemunha4Profissao,
            testemunha4EnderecoComercial:                       testemunha4EnderecoComercial,
            faPmGmPcPtcConhecido:                               faPmGmPcPtcConhecido,
            faPmGmPcPtc:                                        faPmGmPcPtc,
            folga:                                              folga,
            costumeLocalDiaSemana:                              costumeLocalDiaSemana,
            costumeLocalFdsFeriado:                             costumeLocalFdsFeriado,
            socioClube:                                         socioClube,
            socioClubes:                                        socioClubes,
            sindicato:                                          sindicato,
            sindicatos:                                         sindicatos,
            partidoPolitico:                                    partidoPolitico,
            partidosPoliticos:                                  partidosPoliticos,
            processoJustica:                                    processoJustica,
            processosJustica:                                   processosJustica,
            processoJusticaJC:                                  processoJusticaJC,
            processoJusticaJT:                                  processoJusticaJT,
            processoJusticaJCR:                                 processoJusticaJCR,
            processoJusticaJM:                                  processoJusticaJM,
            processoJusticaVIJ:                                 processoJusticaVIJ,
            processoJusticaJEC:                                 processoJusticaJEC,
            ocorrenciaPolicial:                                 ocorrenciaPolicial,
            ocorrenciasPoliciais:                               ocorrenciasPoliciais,
            ipSindIpm:                                          ipSindIpm,
            listaIpSindIpm:                                     listaIpSindIpm,
            padCdCj:                                            padCdCj,
            listaPadCdCj:                                       listaPadCdCj,
            familiaOpPj:                                        familiaOpPj,
            familiasOpPj:                                       familiasOpPj,
            armaDeFogo:                                         armaDeFogo,
            detalheArmaDeFogo:                                  detalheArmaDeFogo,
            armaDeFogoApreendida:                               armaDeFogoApreendida,
            detalheArmaDeFogoApreendida:                        detalheArmaDeFogoApreendida,
            detalheEmprego:                                     detalheEmprego,
            tentouIngressarPm:                                  tentouIngressarPm,
            tentouIngressarPmQuantidade:                        tentouIngressarPmQuantidade,
            tentouIngressarPmReprovou:                          tentouIngressarPmReprovou,
            tentouIngressarOutroEmprego:                        tentouIngressarOutroEmprego,
            tentouIngressarOutroEmpregoA:                       tentouIngressarOutroEmpregoA,
            tentouIngressarOutroEmpregoB:                       tentouIngressarOutroEmpregoB,
            tentouIngressarOutroEmpregoC:                       tentouIngressarOutroEmpregoC,
            tentouIngressarOutroEmpregoD:                       tentouIngressarOutroEmpregoD,
            tentouIngressarOutroEmpregoE:                       tentouIngressarOutroEmpregoE,
            servicoMilitar:                                     servicoMilitar,
            servicoMilitarUnidade:                              servicoMilitarUnidade,
            servicoMilitarCompanhia:                            servicoMilitarCompanhia,
            servicoMilitarPelotao:                              servicoMilitarPelotao,
            servicoMilitarMesInicial:                           servicoMilitarMesInicial,
            servicoMilitarAnoInicial:                           servicoMilitarAnoInicial,
            servicoMilitarMesFinal:                             servicoMilitarMesFinal,
            servicoMilitarAnoFinal:                             servicoMilitarAnoFinal,
            servicoMilitarEndereco:                             servicoMilitarEndereco,
            servicoMilitarPontoReferencia:                      servicoMilitarPontoReferencia,
            servicoMilitarFuncao:                               servicoMilitarFuncao,
            servicoMilitarPunicao:                              servicoMilitarPunicao,
            servicoMilitarMotivoBaixa:                          servicoMilitarMotivoBaixa,
            escolas:                                            escolas,
            punicaoEscolar:                                     punicaoEscolar,
            detalhePunicaoEscolar:                              detalhePunicaoEscolar,
            protecaoCredito:                                    protecaoCredito,
            protecaoCreditoDivida:                              protecaoCreditoDivida,
            protecaoCreditoDataCompra:                          protecaoCreditoDataCompra,
            protecaoCreditoDataDeixouPagarDivida:               protecaoCreditoDataDeixouPagarDivida,
            protecaoCreditoInstituicaoCredora:                  protecaoCreditoInstituicaoCredora,
            protecaoCreditoMotivoDivida:                        protecaoCreditoMotivoDivida,
            propriedadeImovel:                                  propriedadeImovel,
            propriedadeImovelTipo:                              propriedadeImovelTipo,
            propriedadeImovelTamanho:                           propriedadeImovelTamanho,
            propriedadeImovelValorAvaliado:                     propriedadeImovelValorAvaliado,
            atividadeEmpresarial:                               atividadeEmpresarial,
            atividadeEmpresarialTipo:                           atividadeEmpresarialTipo,
            atividadeEmpresarialNivelParticipacao:              atividadeEmpresarialNivelParticipacao,
            atividadeEmpresarialEmpresa:                        atividadeEmpresarialEmpresa,
            permissaoDirigir:                                   permissaoDirigir,
            cnh:                                                cnh,
            numeroRegistroCnh:                                  numeroRegistroCnh,
            dataExpedicaoCnh:                                   dataExpedicaoCnh,
            localCnh:                                           localCnh,
            categoriaCnh:                                       categoriaCnh,
            cnhMulta:                                           cnhMulta,
            detalheCnhMulta:                                    detalheCnhMulta,
            cnhApreendidaSuspensaCassada:                       cnhApreendidaSuspensaCassada,
            detalheCnhApreendidaSuspensaCassada:                detalheCnhApreendidaSuspensaCassada,
            acidenteTransito:                                   acidenteTransito,
            detalheAcidenteTransito:                            detalheAcidenteTransito,
            veiculoSeuNomeSuaPosse:                             veiculoSeuNomeSuaPosse,
            lVeiculoSeuNomeSuaPosse:                            lVeiculoSeuNomeSuaPosse,
            veiculoSeuNomeNaoSuaPosse:                          veiculoSeuNomeNaoSuaPosse,
            lVeiculoSeuNomeNaoSuaPosse:                         lVeiculoSeuNomeNaoSuaPosse,
            veiculoSuaPosseNaoSeuNome:                          veiculoSuaPosseNaoSeuNome,
            lVeiculoSuaPosseNaoSeuNome:                         lVeiculoSuaPosseNaoSeuNome,
            veiculoNenhumaCondicaoAnterior:                     veiculoNenhumaCondicaoAnterior,
            lVeiculoNenhumaCondicaoAnterior:                    lVeiculoNenhumaCondicaoAnterior,
            pagouPrometeuPagamento:                             pagouPrometeuPagamento,
            detalhePagouPrometeuPagamento:                      detalhePagouPrometeuPagamento,
            informacoesComplementares:                          informacoesComplementares,
        });
        
        if(responseSave.data.sqlMessage === undefined && responseSave.data.indexOf('ok') === 0){
            await axios.get(servidorBackEnd + '/updateFinalizarCandidatoFormulario2301?id_candidato=' + idCandidato + '&nrInscricao=' + nrInscricao + '&cpf=' + candidato.cpf);
            
            navigate('/formPreenchido');
        }
        else{
            modalShow('<b style="color:red">Erro!</b><br/>Por favor informe ao IBFC o seguinte erro:<br/>'+responseSave.data.sqlMessage);
        }
    }

    return (
        <section className="hero has-background-grey-light is-fullheight is-fullwidth">
            <div className="hero-body" id='loadingDiv' style={{ margin:'auto' }}>
                <div className="container">
                    <div className="columns is-centered box">
                        <div className="column">
                            <table border={0} width={'100%'}>
                                <tbody>
                                    <tr>
                                        <td width={'65px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnEsquerda.jpeg'/></td>
                                        <td style={{ padding:'10px', fontFamily:'Times New Roman', verticalAlign:'middle', fontWeight:'bold', fontSize:'13px', lineHeight:'14px' }}>
                                            RIO GRANDE DO NORTE<br/>
                                            SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E DA DEFESA SOCIAL<br/>
                                            POLÍCIA MILITAR<br/>
                                            AGÊNCIA CENTRAL DE INTELIGÊNCIA – ACI<br/>
                                            SUBSEÇÃO DE CONTRAINTELIGÊNCIA – SS3
                                        </td>
                                        <td width={'80px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnDireita.jpg'/></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='notification'>
                                <img src='/loading.gif'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero-body" id='tokenInvalidoDiv' style={{ display:'none', margin:'auto' }}>
                <div className="container">
                    <div className="columns is-centered box">
                        <div className="column">
                            <table border={0} width={'100%'}>
                                <tbody>
                                    <tr>
                                        <td width={'65px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnEsquerda.jpeg'/></td>
                                        <td style={{ padding:'10px', fontFamily:'Times New Roman', verticalAlign:'middle', fontWeight:'bold', fontSize:'13px', lineHeight:'14px' }}>
                                            RIO GRANDE DO NORTE<br/>
                                            SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E DA DEFESA SOCIAL<br/>
                                            POLÍCIA MILITAR<br/>
                                            AGÊNCIA CENTRAL DE INTELIGÊNCIA – ACI<br/>
                                            SUBSEÇÃO DE CONTRAINTELIGÊNCIA – SS3
                                        </td>
                                        <td width={'80px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnDireita.jpg'/></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='notification is-danger'>
                                <b>Token Inválido!</b><br/>
                                Por favor, logue novamente
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero-body" id='conteudoDiv' style={{ display:'none' }}>
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-8-desktop">
                            <form className="box" name='frm' id='frm'>
                                <article className='message is-link' id='divParteInicial'>
                                    <div className='message-header'>
                                        <div className="tags are-medium has-addons">
                                            FICHA DE INFORMAÇÕES CONFIDENCIAIS
                                        </div>
                                    </div>
                                    <div className='message-body'>
                                        <div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <b>Edital Nº 01/2023</b><br/>Concurso Público para ingresso no Curso de Formação de Praças
                                                    <div className="columns is-vcentered">
                                                        <div className="column">
                                                            <div className="control">
                                                                <input className="input" type="file" id='file' onChange={(e) => setFile(e.target.files[0])} />
                                                                <div className="notification" style={{ color:'blue', fontWeight:'bold' }}>TEXTO DE ORIENTAÇÃO DA FOTOGRAFIA<br/><span style={{ fontWeight:'normal' }}>A fotografia deve ter o seguinte padrão: 5x7, boa qualidade da imagem, individual, frontal, em fundo branco, em posição formal, com data (de no máximo 6 meses), com orelhas descobertas, sem adereços, podendo trajar qualquer roupa, desde que o pescoço esteja bem visível. Preferencialmente sem sorrisos. Maquiagem discreta é aceitável.  Uso de barba é permitido.</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="columns is-vcentered">
                                                        <div className="column is-narrow">
                                                            <div className="control">
                                                                <button type="button" className="button is-info" onClick={(e) => atualizarFoto(e)} style={{ marginTop: '0px' }}><i className="fa fa-search"></i>&nbsp;Atualizar Foto 5x7</button>
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            Antes de atualizar a Foto, <b>leia acima</b> e siga corretamente o <b>Texto de Orientação da Fotografia</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='column is-narrow' style={{ textAlign:'right' }}>
                                                    <table border='1' style={{ width:'150px', height:'190px' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ verticalAlign:'middle', textAlign:'center' }}>
                                                                    {imagemFoto === '' &&
                                                                        <div><b style={{ fontSize:'15px' }}>Foto 5x7</b><br/>Clique no botão à esquerda<br/><i>Atualizar Foto 5x7</i></div>
                                                                    }
                                                                    {imagemFoto !== '' &&
                                                                        <img src={`data:${informacaoFoto.tipo};base64,${imagemFoto}`} />
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <div className='notification is-warning' style={{ textAlign:'center' }}>
                                                        A presente FIC é constituída de dados, informações e documentos, cujo conteúdo é identificado como pertencente à categoria de <b>INFORMAÇÃO PESSOAL</b><br/>
                                                        Todo o conteúdo informado e composto em anexo à presente Ficha, possui <b>RESTRIÇÃO DE ACESSO</b>, nos termos da legislação vigente.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Nome</label>
                                                    {candidato.nome}
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Endereço</label>
                                                    {candidato.Endereco}
                                                </div>
                                                <div className='column is-narrow'>
                                                    <label className="label">Número</label>
                                                    {candidato.EnderecoNumero}
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Complemento</label>
                                                    {candidato.EnderecoComplemento}
                                                </div>
                                                <div className='column'>
                                                    <label className="label">Bairro</label>
                                                    {candidato.Bairro}
                                                </div>
                                                <div className='column'>
                                                    <label className="label">CEP</label>
                                                    {candidato.Cep}
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Cidade</label>
                                                    {candidato.Cidade}
                                                </div>
                                                <div className='column'>
                                                    <label className="label">Estado</label>
                                                    {candidato.Uf}
                                                </div>
                                                <div className='column'>
                                                    <label className="label">Telefone Celular</label>
                                                    ({candidato.Ddd}) {candidato.Telefone}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>

                                <article className='message is-link' id='divParteA'>
                                    <div className='message-header'>
                                        <div className="tags are-medium has-addons">
                                            PARTE A - INFORMAÇÕES PARTICULARES
                                        </div>
                                    </div>
                                    <div className='message-body'>
                                        <div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Nome</label>
                                                    {candidato.nome}
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="nome">Natural de</label>
                                                    {candidato.Naturalidade}
                                                </div>
                                                <div className='column'>
                                                    <label className="label">Estado</label>
                                                    {candidato.UfNaturalidade}
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="nome">Data de nascimento</label>
                                                    {dataNascimentoBanco2Html(candidato.DataNascimento)}
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="nome">CPF</label>
                                                    {candidato.cpf}
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="nome">RG</label>
                                                    {candidato.rg}
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="nome">Expedido por</label>
                                                    {candidato.OrgaoEmissor}/{candidato.UfEmissor}
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column is-two-thirds'>
                                                    <label className="label" htmlFor="rgAnterior">RG anterior (de outro Estado, se possuir)</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={50} placeholder="Número do RG anterior (de outro Estado, se possuir)" id="rgAnterior" value={rgAnterior} onChange={(e) => setRgAnterior(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="nome">Estado civil</label>
                                                    {candidato.EstadoCivil}
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="profissao">Profissão</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={70} placeholder="Profissão" id="profissao" value={profissao} onChange={(e) => setProfissao(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="empregoAtual">Emprego atual</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={191} placeholder="Emprego atual" id="empregoAtual" value={empregoAtual} onChange={(e) => setEmpregoAtual(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="religiao">Religião</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={100} placeholder="Religião" id="religiao" value={religiao} onChange={(e) => setReligiao(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column is-two-thirds'>
                                                    <label className="label" htmlFor="apelido">Qualquer outro nome ou apelido pelo qual você é conhecido</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={191} placeholder="Qualquer outro nome ou apelido pelo qual você é conhecido" id="apelido" value={apelido} onChange={(e) => setApelido(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="comQuemResideAtualmente">Com quem reside atualmente</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={50} placeholder="Com quem reside atualmente" id="comQuemResideAtualmente" value={comQuemResideAtualmente} onChange={(e) => setComQuemResideAtualmente(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="todosEmail">Correios eletrônicos (e-mail) particulares e funcionais (infome todos que você possui)</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='todosEmail' name='todosEmail' value={todosEmail} placeholder="Correios eletrônicos (e-mail) particulares e funcionais (infome todos que você possui)" onChange={(e) => setTodosEmail(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                
                                <article className='message is-link' id='divParteB'>
                                    <div className='message-header'>
                                        <div className="tags are-medium has-addons">
                                            PARTE B - INFORMAÇÕES RESIDENCIAIS
                                        </div>
                                    </div>
                                    <div className='message-body'>
                                        <div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Declarar em ordem cronológica <span style={{ fontWeight:'normal' }}>(vá adicionando clicando no botão 'Registrar informação de residência', uma residência por vez)</span> todos os lugares onde você <u>residiu e reside atualmente</u>, <u>a partir dos 15 anos de idade</u>. Incluindo alojamentos, pensões, repúblicas e outras formas de moradia. <u>ATENÇÃO:</u> Caso você tenha residido em condomínio fechado ou prédio, deverá indicar um morador do(s) local(is) que resida no endereço e que conheça você:</label>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="resideResidiuApartir12anosMesInicial">Período de Residência - De</label>
                                                            <div className='columns'>
                                                                <div className='column is-narrow'>
                                                                    <div className="select">
                                                                        <select id="resideResidiuApartir12anosMesInicial" value={resideResidiuApartir12anosMesInicial} onChange={(e) => setResideResidiuApartir12anosMesInicial(e.target.value)}>
                                                                            <option value=''>Selecione</option>
                                                                            <option value='Janeiro'>Janeiro</option>
                                                                            <option value='Fevereiro'>Fevereiro</option>
                                                                            <option value='Março'>Março</option>
                                                                            <option value='Abril'>Abril</option>
                                                                            <option value='Maio'>Maio</option>
                                                                            <option value='Junho'>Junho</option>
                                                                            <option value='Julho'>Julho</option>
                                                                            <option value='Agosto'>Agosto</option>
                                                                            <option value='Setembro'>Setembro</option>
                                                                            <option value='Outubro'>Outubro</option>
                                                                            <option value='Novembro'>Novembro</option>
                                                                            <option value='Dezembro'>Dezembro</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='column is-narrow'>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" placeholder="Ano Início" maxLength={4} id="resideResidiuApartir12anosAnoInicial" value={resideResidiuApartir12anosAnoInicial} onChange={(e) => setResideResidiuApartir12anosAnoInicial(onlyNumber(e.target.value))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="resideResidiuApartir12anosMesFinal">Período de Residência - Até</label>
                                                            <div className='columns'>
                                                                <div className='column is-narrow'>
                                                                    <div className="select">
                                                                        <select id="resideResidiuApartir12anosMesFinal" value={resideResidiuApartir12anosMesFinal} onChange={(e) => setResideResidiuApartir12anosMesFinal(e.target.value)}>
                                                                            <option value=''>Selecione</option>
                                                                            <option value='Janeiro'>Janeiro</option>
                                                                            <option value='Fevereiro'>Fevereiro</option>
                                                                            <option value='Março'>Março</option>
                                                                            <option value='Abril'>Abril</option>
                                                                            <option value='Maio'>Maio</option>
                                                                            <option value='Junho'>Junho</option>
                                                                            <option value='Julho'>Julho</option>
                                                                            <option value='Agosto'>Agosto</option>
                                                                            <option value='Setembro'>Setembro</option>
                                                                            <option value='Outubro'>Outubro</option>
                                                                            <option value='Novembro'>Novembro</option>
                                                                            <option value='Dezembro'>Dezembro</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='column is-narrow'>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" placeholder="Ano Fim" maxLength={4} id="resideResidiuApartir12anosAnoFinal" value={resideResidiuApartir12anosAnoFinal} onChange={(e) => setResideResidiuApartir12anosAnoFinal(onlyNumber(e.target.value))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="resideResidiuApartir12anosEndereco">Endereço</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={191} placeholder="Endereço" id="resideResidiuApartir12anosEndereco" value={resideResidiuApartir12anosEndereco} onChange={(e) => setResideResidiuApartir12anosEndereco(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='column is-narrow'>
                                                            <label className="label" htmlFor="resideResidiuApartir12anosNumero">Número</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={50} placeholder="Número" id="resideResidiuApartir12anosNumero" value={resideResidiuApartir12anosNumero} onChange={(e) => setResideResidiuApartir12anosNumero(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="resideResidiuApartir12anosComplemento">Complemento</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={50} placeholder="Complemento" id="resideResidiuApartir12anosComplemento" value={resideResidiuApartir12anosComplemento} onChange={(e) => setResideResidiuApartir12anosComplemento(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="resideResidiuApartir12anosCep">CEP</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={9} placeholder="CEP" id="resideResidiuApartir12anosCep" value={resideResidiuApartir12anosCep} onChange={(e) => setResideResidiuApartir12anosCep(nrCepMask(e.target.value))} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="resideResidiuApartir12anosBairro">Bairro</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={70} placeholder="Bairro" id="resideResidiuApartir12anosBairro" value={resideResidiuApartir12anosBairro} onChange={(e) => setResideResidiuApartir12anosBairro(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="resideResidiuApartir12anosCidade">Cidade</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={70} placeholder="Cidade" id="resideResidiuApartir12anosCidade" value={resideResidiuApartir12anosCidade} onChange={(e) => setResideResidiuApartir12anosCidade(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="resideResidiuApartir12anosEstado">Estado</label>
                                                            <div className="controls">
                                                                <div className="select is-fullwidth">
                                                                    <select id="resideResidiuApartir12anosEstado" value={resideResidiuApartir12anosEstado} onChange={(e) => setResideResidiuApartir12anosEstado(e.target.value)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='Rio Grande do Norte'>Rio Grande do Norte</option>
                                                                        <option value='Acre'>Acre</option>
                                                                        <option value='Alagoas'>Alagoas</option>
                                                                        <option value='Amapá'>Amapá</option>
                                                                        <option value='Amazonas'>Amazonas</option>
                                                                        <option value='Bahia'>Bahia</option>
                                                                        <option value='Ceará'>Ceará</option>
                                                                        <option value='Distrito Federal'>Distrito Federal</option>
                                                                        <option value='Espírito Santo'>Espírito Santo</option>
                                                                        <option value='Goiás'>Goiás</option>
                                                                        <option value='Maranhão'>Maranhão</option>
                                                                        <option value='Mato Grosso'>Mato Grosso</option>
                                                                        <option value='Mato Grosso do Sul'>Mato Grosso do Sul</option>
                                                                        <option value='Minas Gerais'>Minas Gerais</option>
                                                                        <option value='Pará'>Pará</option>
                                                                        <option value='Paraíba'>Paraíba</option>
                                                                        <option value='Paraná'>Paraná</option>
                                                                        <option value='Pernambuco'>Pernambuco</option>
                                                                        <option value='Piauí'>Piauí</option>
                                                                        <option value='Rio de Janeiro'>Rio de Janeiro</option>
                                                                        <option value='Rio Grande do Sul'>Rio Grande do Sul</option>
                                                                        <option value='Rondônia'>Rondônia</option>
                                                                        <option value='Roraima'>Roraima</option>
                                                                        <option value='Santa Catarina'>Santa Catarina</option>
                                                                        <option value='São Paulo'>São Paulo</option>
                                                                        <option value='Sergipe'>Sergipe</option>
                                                                        <option value='Tocantins'>Tocantins</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="resideResidiuApartir12anosPontoReferencia">Ponto de Referência (Batalhão PM da área)</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={191} placeholder="Ponto de Referência" id="resideResidiuApartir12anosPontoReferencia" value={resideResidiuApartir12anosPontoReferencia} onChange={(e) => setResideResidiuApartir12anosPontoReferencia(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="resideResidiuApartir12anosResidiuCom">Com quem residiu</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={70} placeholder="Com quem residiu" id="resideResidiuApartir12anosResidiuCom" value={resideResidiuApartir12anosResidiuCom} onChange={(e) => setResideResidiuApartir12anosResidiuCom(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="resideResidiuApartir12anosMoradorIndicado">Morador indicado</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={70} placeholder="Morador indicado" id="resideResidiuApartir12anosMoradorIndicado" value={resideResidiuApartir12anosMoradorIndicado} onChange={(e) => setResideResidiuApartir12anosMoradorIndicado(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="resideResidiuApartir12anosGMaps">Copie e cole o link do mapa (Google Maps), indicando a localização exata do endereço</label>
                                                            {/* <a href='https://www.ibfc.org.br' target='_blank' style={{ cursor:'pointer' }}>Clique aqui</a>, para a visualização de um exemplo */}
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={400} placeholder="Link do Endereço no Google Maps" id="resideResidiuApartir12anosGMaps" value={resideResidiuApartir12anosGMaps} onChange={(e) => setResideResidiuApartir12anosGMaps(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <button type="button" className="button is-info" onClick={(e) => adicionaResideResidiuApartir12anos(e)}><i className="fa fa-plus-circle"></i>&nbsp;Clique Aqui para Registrar informação de residência</button>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            {resideResidiuApartir12anos.map((resideResidiu, index) => (
                                                                <div key={index}>
                                                                    <article className='message'>
                                                                        <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                            <p>{index+1}. Residiu com: {resideResidiu.residiuCom} ({resideResidiu.mêsInicial}/{resideResidiu.anoInicial} ~ {resideResidiu.mêsFinal}/{resideResidiu.anoFinal})</p>
                                                                            <button type="button" className='delete' aria-label='delete' onClick={(e) => removeResideResidiuApartir12anos(e, resideResidiu.endereço, resideResidiu.mêsInicial, resideResidiu.anoInicial, resideResidiu.mêsFinal, resideResidiu.anoFinal, resideResidiu.pontoReferência, resideResidiu.residiuCom)}></button>
                                                                        </div>
                                                                        <div className='message-body has-background-white-ter has-text-black'>
                                                                            <p>
                                                                                Endereço: {resideResidiu.endereço} {resideResidiu.número}
                                                                            </p>
                                                                            <p>
                                                                                Complemento: {resideResidiu.complemento};
                                                                                Morador Indicado: {resideResidiu.moradorIndicado}
                                                                            </p>
                                                                            <p>
                                                                                Cidade: {resideResidiu.cidade};
                                                                                Estado: {resideResidiu.estado};
                                                                                Bairro: {resideResidiu.bairro}
                                                                            </p>
                                                                            <p>
                                                                                CEP: {resideResidiu.cep};
                                                                                Ponto de Referência: {resideResidiu.pontoReferência}
                                                                            </p>
                                                                            <p style={{ wordWrap:'break-word', width:'720px' }}>
                                                                                Link do Endereço no Google Maps: <a href={resideResidiu.gMaps} target='_blank' style={{ cursor:'pointer' }}>{resideResidiu.gMaps}</a>
                                                                            </p>
                                                                        </div>
                                                                    </article>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="motivoNResidiuPaiEsposa">Caso você não tenha residido com os seus pais ou esposa durante algum período, explique os motivos:</label>
                                                            <div className="controls">
                                                                <textarea maxLength={1500} rows='4' className="textarea" id='motivoNResidiuPaiEsposa' name='motivoNResidiuPaiEsposa' value={motivoNResidiuPaiEsposa} placeholder="Caso você não tenha residido com os seus pais ou esposa durante algum período, explique os motivos" onChange={(e) => setMotivoNResidiuPaiEsposa(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                
                                <article className='message is-link' id='divParteC'>
                                    <div className='message-header'>
                                        <div className="tags are-medium has-addons">
                                            PARTE C - INFORMAÇÕES FAMILIARES
                                        </div>
                                    </div>
                                    <div className='message-body'>
                                        <div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Dê informações completas <span style={{ fontWeight:'normal' }}>(vá adicionando clicando no botão 'Registrar informação do familiar', um familiar por vez)</span> sobre seus <u>pais</u> e <u>irmãos</u>. Se você foi <u>criado por padrastos</u>, <u>tutores legais</u> ou <u>outras pessoas que não sejam seus pais biológicos</u>, também devem constar nas informações abaixo solicitadas:</label>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiarNome">Nome</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={191} placeholder="Nome" id="familiarNome" value={familiarNome} onChange={(e) => setFamiliarNome(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiarParentesco">Grau de parentesco</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={50} placeholder="Grau de parentesco" id="familiarParentesco" value={familiarParentesco} onChange={(e) => setFamiliarParentesco(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiarDataNascimento">Data de nascimento</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={10} placeholder="Data de nascimento" id="familiarDataNascimento" value={familiarDataNascimento} onChange={(e) => setFamiliarDataNascimentoSetaIdade(dataBr(e.target.value))} />
                                                            </div>
                                                        </div>
                                                        <div className='column is-narrow'>
                                                            <label className="label" htmlFor="familiarSituacao">Situação</label>
                                                            <div className="controls">
                                                                <div className="select">
                                                                    <select id="familiarSituacao" value={familiarSituacao} onChange={(e) => setFamiliarSituacao(e.target.value)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='Vivo'>Vivo</option>
                                                                        <option value='Falecido'>Falecido</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiarApelido">Qualquer outro nome ou apelido pelo qual ele(a) é conhecido(a)</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={191} placeholder="Nome ou apelido pelo qual ele(a) é conhecido(a)" id="familiarApelido" value={familiarApelido} onChange={(e) => setFamiliarApelido(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='column is-narrow'>
                                                            <label className="label" htmlFor="familiarIdade">Idade</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={10} placeholder="Idade" id="familiarIdade" value={familiarIdade} onChange={(e) => setFamiliarIdade(onlyNumber(e.target.value))} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiarNaturalidade">Natural de</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={80} placeholder="Natural de" id="familiarNaturalidade" value={familiarNaturalidade} onChange={(e) => setFamiliarNaturalidade(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiarNaturalEstado">Estado</label>
                                                            <div className="controls">
                                                                <div className="select is-fullwidth">
                                                                    <select id="familiarNaturalEstado" value={familiarNaturalEstado} onChange={(e) => setFamiliarNaturalEstado(e.target.value)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='Rio Grande do Norte'>Rio Grande do Norte</option>
                                                                        <option value='Acre'>Acre</option>
                                                                        <option value='Alagoas'>Alagoas</option>
                                                                        <option value='Amapá'>Amapá</option>
                                                                        <option value='Amazonas'>Amazonas</option>
                                                                        <option value='Bahia'>Bahia</option>
                                                                        <option value='Ceará'>Ceará</option>
                                                                        <option value='Distrito Federal'>Distrito Federal</option>
                                                                        <option value='Espírito Santo'>Espírito Santo</option>
                                                                        <option value='Goiás'>Goiás</option>
                                                                        <option value='Maranhão'>Maranhão</option>
                                                                        <option value='Mato Grosso'>Mato Grosso</option>
                                                                        <option value='Mato Grosso do Sul'>Mato Grosso do Sul</option>
                                                                        <option value='Minas Gerais'>Minas Gerais</option>
                                                                        <option value='Pará'>Pará</option>
                                                                        <option value='Paraíba'>Paraíba</option>
                                                                        <option value='Paraná'>Paraná</option>
                                                                        <option value='Pernambuco'>Pernambuco</option>
                                                                        <option value='Piauí'>Piauí</option>
                                                                        <option value='Rio de Janeiro'>Rio de Janeiro</option>
                                                                        <option value='Rio Grande do Sul'>Rio Grande do Sul</option>
                                                                        <option value='Rondônia'>Rondônia</option>
                                                                        <option value='Roraima'>Roraima</option>
                                                                        <option value='Santa Catarina'>Santa Catarina</option>
                                                                        <option value='São Paulo'>São Paulo</option>
                                                                        <option value='Sergipe'>Sergipe</option>
                                                                        <option value='Tocantins'>Tocantins</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiarCpf">CPF</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={14} placeholder="Número do CPF" id="familiarCpf" value={familiarCpf} onChange={(e) => setFamiliarCpf(cpfMask(e.target.value))} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiarRg">RG</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={50} placeholder="Número do RG" id="familiarRg" value={familiarRg} onChange={(e) => setFamiliarRg(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiarEndereco">Endereço</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={191} placeholder="Endereço" id="familiarEndereco" value={familiarEndereco} onChange={(e) => setFamiliarEndereco(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='column is-narrow'>
                                                            <label className="label" htmlFor="familiarNumero">Número</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={50} placeholder="Número" id="familiarNumero" value={familiarNumero} onChange={(e) => setFamiliarNumero(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiarComplemento">Complemento</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={50} placeholder="Complemento" id="familiarComplemento" value={familiarComplemento} onChange={(e) => setFamiliarComplemento(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiarCep">CEP</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={9} placeholder="CEP" id="familiarCep" value={familiarCep} onChange={(e) => setFamiliarCep(nrCepMask(e.target.value))} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiarBairro">Bairro</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={70} placeholder="Bairro" id="familiarBairro" value={familiarBairro} onChange={(e) => setFamiliarBairro(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiarCidade">Cidade</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={70} placeholder="Cidade" id="familiarCidade" value={familiarCidade} onChange={(e) => setFamiliarCidade(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiarEstado">Estado</label>
                                                            <div className="controls">
                                                                <div className="select is-fullwidth">
                                                                    <select id="familiarEstado" value={familiarEstado} onChange={(e) => setFamiliarEstado(e.target.value)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='Rio Grande do Norte'>Rio Grande do Norte</option>
                                                                        <option value='Acre'>Acre</option>
                                                                        <option value='Alagoas'>Alagoas</option>
                                                                        <option value='Amapá'>Amapá</option>
                                                                        <option value='Amazonas'>Amazonas</option>
                                                                        <option value='Bahia'>Bahia</option>
                                                                        <option value='Ceará'>Ceará</option>
                                                                        <option value='Distrito Federal'>Distrito Federal</option>
                                                                        <option value='Espírito Santo'>Espírito Santo</option>
                                                                        <option value='Goiás'>Goiás</option>
                                                                        <option value='Maranhão'>Maranhão</option>
                                                                        <option value='Mato Grosso'>Mato Grosso</option>
                                                                        <option value='Mato Grosso do Sul'>Mato Grosso do Sul</option>
                                                                        <option value='Minas Gerais'>Minas Gerais</option>
                                                                        <option value='Pará'>Pará</option>
                                                                        <option value='Paraíba'>Paraíba</option>
                                                                        <option value='Paraná'>Paraná</option>
                                                                        <option value='Pernambuco'>Pernambuco</option>
                                                                        <option value='Piauí'>Piauí</option>
                                                                        <option value='Rio de Janeiro'>Rio de Janeiro</option>
                                                                        <option value='Rio Grande do Sul'>Rio Grande do Sul</option>
                                                                        <option value='Rondônia'>Rondônia</option>
                                                                        <option value='Roraima'>Roraima</option>
                                                                        <option value='Santa Catarina'>Santa Catarina</option>
                                                                        <option value='São Paulo'>São Paulo</option>
                                                                        <option value='Sergipe'>Sergipe</option>
                                                                        <option value='Tocantins'>Tocantins</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiarProfissao">Profissão</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={70} placeholder="Profissão" id="familiarProfissao" value={familiarProfissao} onChange={(e) => setFamiliarProfissao(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiarLocalTrabalho">Local de trabalho/Nome da Empresa</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={191} placeholder="Local de trabalho/Nome da Empresa" id="familiarLocalTrabalho" value={familiarLocalTrabalho} onChange={(e) => setFamiliarLocalTrabalho(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <button type="button" className="button is-info" onClick={(e) => adicionaFamiliar(e)}><i className="fa fa-plus-circle"></i>&nbsp;Clique Aqui para Registrar informação do familiar</button>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            {familiar.map((familia, index) => (
                                                                <div key={index}>
                                                                    <article className='message is-dark'>
                                                                        <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                            <p>{index+1}. {familia.nome} ({familia.parentesco})</p>
                                                                            <button type="button" className='delete' aria-label='delete' onClick={(e) => removeFamiliar(e, familia.nome, familia.parentesco, familia.cpf, familia.rg)}></button>
                                                                        </div>
                                                                        <div className='message-body has-background-white-ter has-text-black'>
                                                                            <p>
                                                                                CPF: {familia.cpf};
                                                                                RG: {familia.rg}
                                                                            </p>
                                                                            <p>
                                                                                Apelido: {familia.apelido};
                                                                                Idade: {familia.idade};
                                                                                Situação: {familia.situação}
                                                                            </p>
                                                                            <p>
                                                                                DataNascimento: {familia.dataNascimento};
                                                                                Naturalidade: {familia.naturalidade};
                                                                                Estado: {familia.naturalEstado}
                                                                            </p>
                                                                            <p>
                                                                                Endereço: {familia.endereço} {familia.número}
                                                                            </p>
                                                                            <p>
                                                                                Complemento: {familia.complemento};
                                                                                CEP: {familia.cep}
                                                                            </p>
                                                                            <p>
                                                                                Cidade: {familia.cidade};
                                                                                Estado: {familia.estado};
                                                                                Bairro: {familia.bairro}
                                                                            </p>
                                                                            <p>
                                                                                Profissão: {familia.profissão};
                                                                                Local Trabalho: {familia.localTrabalho}
                                                                            </p>
                                                                        </div>
                                                                    </article>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>

                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="separadoDivorciado">É separado (de fato ou de direito) ou divorciado?</label>
                                                            <div className="select">
                                                                <select id="separadoDivorciado" value={separadoDivorciado} onChange={(e) => setSeparadoDivorciadoDiv(e)}>
                                                                    <option value=''>Selecione</option>
                                                                    <option value='sim'>Sim</option>
                                                                    <option value='não'>Não</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id='separadoDivorciadoDiv' className='box' style={{ display:'none' }}>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label">Cite os dados do <u>ex-esposo(a)</u> ou do <u>ex-companheiro(a)</u>:</label>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoNome">Nome</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={191} placeholder="Nome" id="separadoDivorciadoNome" value={separadoDivorciadoNome} onChange={(e) => setSeparadoDivorciadoNome(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoCpf">CPF</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={14} placeholder="Número do CPF" id="separadoDivorciadoCpf" value={separadoDivorciadoCpf} onChange={(e) => setSeparadoDivorciadoCpf(cpfMask(e.target.value))} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoRg">RG</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={50} placeholder="Número do RG" id="separadoDivorciadoRg" value={separadoDivorciadoRg} onChange={(e) => setSeparadoDivorciadoRg(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoLocalTrabalho">Local de Trabalho</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={191} placeholder="Local de Trabalho" id="separadoDivorciadoLocalTrabalho" value={separadoDivorciadoLocalTrabalho} onChange={(e) => setSeparadoDivorciadoLocalTrabalho(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoApelido">Qualquer outro nome ou apelido</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={191} placeholder="Nome ou apelido pelo qual é conhecido" id="separadoDivorciadoApelido" value={separadoDivorciadoApelido} onChange={(e) => setSeparadoDivorciadoApelido(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="controls">
                                                                    <b>Endereço Completo(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</b>
                                                                    <textarea maxLength={1500} rows='4' className="textarea" id='separadoDivorciadoEndereco' name='separadoDivorciadoEndereco' value={separadoDivorciadoEndereco} placeholder="Endereço Completo(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)" onChange={(e) => setSeparadoDivorciadoEndereco(e.target.value)} />
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoProfissao">Profissão</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={100} placeholder="Profissão" id="separadoDivorciadoProfissao" value={separadoDivorciadoProfissao} onChange={(e) => setSeparadoDivorciadoProfissao(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoRelacaoAtual">Qual sua relação atual com ele(a)?</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={50} placeholder="Qual sua relação atual com ele(a)" id="separadoDivorciadoRelacaoAtual" value={separadoDivorciadoRelacaoAtual} onChange={(e) => setSeparadoDivorciadoRelacaoAtual(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoNaturalidade">Natural de</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={80} placeholder="Natural de" id="separadoDivorciadoNaturalidade" value={separadoDivorciadoNaturalidade} onChange={(e) => setSeparadoDivorciadoNaturalidade(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoNaturalEstado">Estado Naturalidade</label>
                                                                        <div className="controls">
                                                                            <div className="select is-fullwidth">
                                                                                <select id="separadoDivorciadoNaturalEstado" value={separadoDivorciadoNaturalEstado} onChange={(e) => setSeparadoDivorciadoNaturalEstado(e.target.value)}>
                                                                                    <option value=''>Selecione</option>
                                                                                    <option value='Rio Grande do Norte'>Rio Grande do Norte</option>
                                                                                    <option value='Acre'>Acre</option>
                                                                                    <option value='Alagoas'>Alagoas</option>
                                                                                    <option value='Amapá'>Amapá</option>
                                                                                    <option value='Amazonas'>Amazonas</option>
                                                                                    <option value='Bahia'>Bahia</option>
                                                                                    <option value='Ceará'>Ceará</option>
                                                                                    <option value='Distrito Federal'>Distrito Federal</option>
                                                                                    <option value='Espírito Santo'>Espírito Santo</option>
                                                                                    <option value='Goiás'>Goiás</option>
                                                                                    <option value='Maranhão'>Maranhão</option>
                                                                                    <option value='Mato Grosso'>Mato Grosso</option>
                                                                                    <option value='Mato Grosso do Sul'>Mato Grosso do Sul</option>
                                                                                    <option value='Minas Gerais'>Minas Gerais</option>
                                                                                    <option value='Pará'>Pará</option>
                                                                                    <option value='Paraíba'>Paraíba</option>
                                                                                    <option value='Paraná'>Paraná</option>
                                                                                    <option value='Pernambuco'>Pernambuco</option>
                                                                                    <option value='Piauí'>Piauí</option>
                                                                                    <option value='Rio de Janeiro'>Rio de Janeiro</option>
                                                                                    <option value='Rio Grande do Sul'>Rio Grande do Sul</option>
                                                                                    <option value='Rondônia'>Rondônia</option>
                                                                                    <option value='Roraima'>Roraima</option>
                                                                                    <option value='Santa Catarina'>Santa Catarina</option>
                                                                                    <option value='São Paulo'>São Paulo</option>
                                                                                    <option value='Sergipe'>Sergipe</option>
                                                                                    <option value='Tocantins'>Tocantins</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoRelacionamentoMesInicio">Período do Relacionamento - De</label>
                                                                        <div className='columns'>
                                                                            <div className='column is-narrow'>
                                                                                <div className="select">
                                                                                    <select id="separadoDivorciadoRelacionamentoMesInicio" value={separadoDivorciadoRelacionamentoMesInicio} onChange={(e) => setSeparadoDivorciadoRelacionamentoMesInicio(e.target.value)}>
                                                                                        <option value=''>Selecione</option>
                                                                                        <option value='Janeiro'>Janeiro</option>
                                                                                        <option value='Fevereiro'>Fevereiro</option>
                                                                                        <option value='Março'>Março</option>
                                                                                        <option value='Abril'>Abril</option>
                                                                                        <option value='Maio'>Maio</option>
                                                                                        <option value='Junho'>Junho</option>
                                                                                        <option value='Julho'>Julho</option>
                                                                                        <option value='Agosto'>Agosto</option>
                                                                                        <option value='Setembro'>Setembro</option>
                                                                                        <option value='Outubro'>Outubro</option>
                                                                                        <option value='Novembro'>Novembro</option>
                                                                                        <option value='Dezembro'>Dezembro</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className='column is-narrow'>
                                                                                <div className="controls">
                                                                                    <input type="text" className="input" placeholder="Ano Início" maxLength={4} id="separadoDivorciadoRelacionamentoAnoInicio" value={separadoDivorciadoRelacionamentoAnoInicio} onChange={(e) => setSeparadoDivorciadoRelacionamentoAnoInicio(onlyNumber(e.target.value))} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="separadoDivorciadoRelacionamentoMesFim">Período do Relacionamento - Até</label>
                                                                        <div className='columns'>
                                                                            <div className='column is-narrow'>
                                                                                <div className="select">
                                                                                    <select id="separadoDivorciadoRelacionamentoMesFim" value={separadoDivorciadoRelacionamentoMesFim} onChange={(e) => setSeparadoDivorciadoRelacionamentoMesFim(e.target.value)}>
                                                                                        <option value=''>Selecione</option>
                                                                                        <option value='Janeiro'>Janeiro</option>
                                                                                        <option value='Fevereiro'>Fevereiro</option>
                                                                                        <option value='Março'>Março</option>
                                                                                        <option value='Abril'>Abril</option>
                                                                                        <option value='Maio'>Maio</option>
                                                                                        <option value='Junho'>Junho</option>
                                                                                        <option value='Julho'>Julho</option>
                                                                                        <option value='Agosto'>Agosto</option>
                                                                                        <option value='Setembro'>Setembro</option>
                                                                                        <option value='Outubro'>Outubro</option>
                                                                                        <option value='Novembro'>Novembro</option>
                                                                                        <option value='Dezembro'>Dezembro</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className='column is-narrow'>
                                                                                <div className="controls">
                                                                                    <input type="text" className="input" placeholder="Ano Fim" maxLength={4} id="separadoDivorciadoRelacionamentoAnoFim" value={separadoDivorciadoRelacionamentoAnoFim} onChange={(e) => setSeparadoDivorciadoRelacionamentoAnoFim(onlyNumber(e.target.value))} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="relacionamento">Você possui <u>namorado</u> ou <u>relacionamento informal</u> com alguma pessoa?</label>
                                                            <div className="select">
                                                                <select id="relacionamento" value={relacionamento} onChange={(e) => setRelacionamentoDiv(e)}>
                                                                    <option value=''>Selecione</option>
                                                                    <option value='sim'>Sim</option>
                                                                    <option value='não'>Não</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id='relacionamentoDiv' className='box' style={{ display:'none' }}>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label">Forneça as seguintes informações:</label>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="relacionamentoNome">Nome</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={191} placeholder="Nome" id="relacionamentoNome" value={relacionamentoNome} onChange={(e) => setRelacionamentoNome(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="relacionamentoCpf">CPF</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={14} placeholder="Número do CPF" id="relacionamentoCpf" value={relacionamentoCpf} onChange={(e) => setRelacionamentoCpf(cpfMask(e.target.value))} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="relacionamentoRg">RG</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={50} placeholder="Número do RG" id="relacionamentoRg" value={relacionamentoRg} onChange={(e) => setRelacionamentoRg(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="relacionamentoLocalTrabalho">Local de Trabalho</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={191} placeholder="Local de Trabalho" id="relacionamentoLocalTrabalho" value={relacionamentoLocalTrabalho} onChange={(e) => setRelacionamentoLocalTrabalho(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="relacionamentoApelido">Qualquer outro nome ou apelido</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={191} placeholder="Nome ou apelido pelo qual é conhecido(a)" id="relacionamentoApelido" value={relacionamentoApelido} onChange={(e) => setRelacionamentoApelido(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="controls">
                                                                    <b>Endereço Completo(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</b>
                                                                    <textarea maxLength={1500} rows='4' className="textarea" id='relacionamentoEndereco' name='relacionamentoEndereco' value={relacionamentoEndereco} placeholder="Endereço Completo(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)" onChange={(e) => setRelacionamentoEndereco(e.target.value)} />
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="relacionamentoProfissao">Profissão</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={100} placeholder="Profissão" id="relacionamentoProfissao" value={relacionamentoProfissao} onChange={(e) => setRelacionamentoProfissao(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="relacionamentoDuracao">Já há quanto tempo dura o relacionamento?</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={50} placeholder="Qual sua relação atual com ele(a)" id="relacionamentoDuracao" value={relacionamentoDuracao} onChange={(e) => setRelacionamentoDuracao(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="relacionamentoNaturalidade">Natural de</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={80} placeholder="Natural de" id="relacionamentoNaturalidade" value={relacionamentoNaturalidade} onChange={(e) => setRelacionamentoNaturalidade(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="relacionamentoNaturalEstado">Estado Naturalidade</label>
                                                                        <div className="controls">
                                                                            <div className="select is-fullwidth">
                                                                                <select id="relacionamentoNaturalEstado" value={relacionamentoNaturalEstado} onChange={(e) => setRelacionamentoNaturalEstado(e.target.value)}>
                                                                                    <option value=''>Selecione</option>
                                                                                    <option value='Rio Grande do Norte'>Rio Grande do Norte</option>
                                                                                    <option value='Acre'>Acre</option>
                                                                                    <option value='Alagoas'>Alagoas</option>
                                                                                    <option value='Amapá'>Amapá</option>
                                                                                    <option value='Amazonas'>Amazonas</option>
                                                                                    <option value='Bahia'>Bahia</option>
                                                                                    <option value='Ceará'>Ceará</option>
                                                                                    <option value='Distrito Federal'>Distrito Federal</option>
                                                                                    <option value='Espírito Santo'>Espírito Santo</option>
                                                                                    <option value='Goiás'>Goiás</option>
                                                                                    <option value='Maranhão'>Maranhão</option>
                                                                                    <option value='Mato Grosso'>Mato Grosso</option>
                                                                                    <option value='Mato Grosso do Sul'>Mato Grosso do Sul</option>
                                                                                    <option value='Minas Gerais'>Minas Gerais</option>
                                                                                    <option value='Pará'>Pará</option>
                                                                                    <option value='Paraíba'>Paraíba</option>
                                                                                    <option value='Paraná'>Paraná</option>
                                                                                    <option value='Pernambuco'>Pernambuco</option>
                                                                                    <option value='Piauí'>Piauí</option>
                                                                                    <option value='Rio de Janeiro'>Rio de Janeiro</option>
                                                                                    <option value='Rio Grande do Sul'>Rio Grande do Sul</option>
                                                                                    <option value='Rondônia'>Rondônia</option>
                                                                                    <option value='Roraima'>Roraima</option>
                                                                                    <option value='Santa Catarina'>Santa Catarina</option>
                                                                                    <option value='São Paulo'>São Paulo</option>
                                                                                    <option value='Sergipe'>Sergipe</option>
                                                                                    <option value='Tocantins'>Tocantins</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="processoPaternidade">Você já esteve envolvido em algum <u>processo de paternidade</u>?</label>
                                                            <div className="select">
                                                                <select id="processoPaternidade" value={processoPaternidade} onChange={(e) => setProcessoPaternidadeDiv(e)}>
                                                                    <option value=''>Selecione</option>
                                                                    <option value='sim'>Sim</option>
                                                                    <option value='não'>Não</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id='processoPaternidadeDiv' className='box' style={{ display:'none' }}>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="detalheProcessoPaternidade">Forneça informações detalhadas:</label>
                                                                <div className="controls">
                                                                    <textarea maxLength={1500} rows='4' className="textarea" id='detalheProcessoPaternidade' name='detalheProcessoPaternidade' value={detalheProcessoPaternidade} placeholder="" onChange={(e) => setDetalheProcessoPaternidade(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="filhoEnteado">Você tem <u>filhos</u> e/ou <u>enteados</u>?</label>
                                                            <div className="select">
                                                                <select id="filhoEnteado" value={filhoEnteado} onChange={(e) => setFilhoEnteadoDiv(e)}>
                                                                    <option value=''>Selecione</option>
                                                                    <option value='sim'>Sim</option>
                                                                    <option value='não'>Não</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id='filhoEnteadoDiv' className='box' style={{ display:'none' }}>
                                                        <b>Dê informações completas</b> <span style={{ fontWeight:'normal' }}>(vá adicionando clicando no botão 'Registrar informação do filho(a)/enteado(a)', um filho(a)/enteado(a) por vez</span><b>:</b>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="filhosEnteadosNome">Nome</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={191} placeholder="Nome" id="filhosEnteadosNome" value={filhosEnteadosNome} onChange={(e) => setFilhosEnteadosNome(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='column is-narrow'>
                                                                <label className="label" htmlFor="filhosEnteadosIdade">Idade</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={10} placeholder="Idade" id="filhosEnteadosIdade" value={filhosEnteadosIdade} onChange={(e) => setFilhosEnteadosIdade(onlyNumber(e.target.value))} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="filhosEnteadosNaturalidade">Natural de</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={80} placeholder="Natural de" id="filhosEnteadosNaturalidade" value={filhosEnteadosNaturalidade} onChange={(e) => setFilhosEnteadosNaturalidade(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="filhosEnteadosNaturalEstado">Estado Naturalidade</label>
                                                                <div className="controls">
                                                                    <div className="select is-fullwidth">
                                                                        <select id="filhosEnteadosNaturalEstado" value={filhosEnteadosNaturalEstado} onChange={(e) => setFilhosEnteadosNaturalEstado(e.target.value)}>
                                                                            <option value=''>Selecione</option>
                                                                            <option value='Rio Grande do Norte'>Rio Grande do Norte</option>
                                                                            <option value='Acre'>Acre</option>
                                                                            <option value='Alagoas'>Alagoas</option>
                                                                            <option value='Amapá'>Amapá</option>
                                                                            <option value='Amazonas'>Amazonas</option>
                                                                            <option value='Bahia'>Bahia</option>
                                                                            <option value='Ceará'>Ceará</option>
                                                                            <option value='Distrito Federal'>Distrito Federal</option>
                                                                            <option value='Espírito Santo'>Espírito Santo</option>
                                                                            <option value='Goiás'>Goiás</option>
                                                                            <option value='Maranhão'>Maranhão</option>
                                                                            <option value='Mato Grosso'>Mato Grosso</option>
                                                                            <option value='Mato Grosso do Sul'>Mato Grosso do Sul</option>
                                                                            <option value='Minas Gerais'>Minas Gerais</option>
                                                                            <option value='Pará'>Pará</option>
                                                                            <option value='Paraíba'>Paraíba</option>
                                                                            <option value='Paraná'>Paraná</option>
                                                                            <option value='Pernambuco'>Pernambuco</option>
                                                                            <option value='Piauí'>Piauí</option>
                                                                            <option value='Rio de Janeiro'>Rio de Janeiro</option>
                                                                            <option value='Rio Grande do Sul'>Rio Grande do Sul</option>
                                                                            <option value='Rondônia'>Rondônia</option>
                                                                            <option value='Roraima'>Roraima</option>
                                                                            <option value='Santa Catarina'>Santa Catarina</option>
                                                                            <option value='São Paulo'>São Paulo</option>
                                                                            <option value='Sergipe'>Sergipe</option>
                                                                            <option value='Tocantins'>Tocantins</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="filhosEnteadosCpf">CPF</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={14} placeholder="Número do CPF" id="filhosEnteadosCpf" value={filhosEnteadosCpf} onChange={(e) => setFilhosEnteadosCpf(cpfMask(e.target.value))} />
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="filhosEnteadosRg">RG</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={50} placeholder="Número do RG" id="filhosEnteadosRg" value={filhosEnteadosRg} onChange={(e) => setFilhosEnteadosRg(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="filhosEnteadosEndereco">Endereço</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={191} placeholder="Endereço" id="filhosEnteadosEndereco" value={filhosEnteadosEndereco} onChange={(e) => setFilhosEnteadosEndereco(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='column is-narrow'>
                                                                <label className="label" htmlFor="filhosEnteadosNumero">Número</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={50} placeholder="Número" id="filhosEnteadosNumero" value={filhosEnteadosNumero} onChange={(e) => setFilhosEnteadosNumero(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="filhosEnteadosComplemento">Complemento</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={50} placeholder="Complemento" id="filhosEnteadosComplemento" value={filhosEnteadosComplemento} onChange={(e) => setFilhosEnteadosComplemento(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="filhosEnteadosCep">CEP</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={9} placeholder="CEP" id="filhosEnteadosCep" value={filhosEnteadosCep} onChange={(e) => setFilhosEnteadosCep(nrCepMask(e.target.value))} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="filhosEnteadosBairro">Bairro</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={70} placeholder="Bairro" id="filhosEnteadosBairro" value={filhosEnteadosBairro} onChange={(e) => setFilhosEnteadosBairro(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="filhosEnteadosCidade">Cidade</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={70} placeholder="Cidade" id="filhosEnteadosCidade" value={filhosEnteadosCidade} onChange={(e) => setFilhosEnteadosCidade(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="filhosEnteadosEstado">Estado</label>
                                                                <div className="controls">
                                                                    <div className="select is-fullwidth">
                                                                        <select id="filhosEnteadosEstado" value={filhosEnteadosEstado} onChange={(e) => setFilhosEnteadosEstado(e.target.value)}>
                                                                            <option value=''>Selecione</option>
                                                                            <option value='Rio Grande do Norte'>Rio Grande do Norte</option>
                                                                            <option value='Acre'>Acre</option>
                                                                            <option value='Alagoas'>Alagoas</option>
                                                                            <option value='Amapá'>Amapá</option>
                                                                            <option value='Amazonas'>Amazonas</option>
                                                                            <option value='Bahia'>Bahia</option>
                                                                            <option value='Ceará'>Ceará</option>
                                                                            <option value='Distrito Federal'>Distrito Federal</option>
                                                                            <option value='Espírito Santo'>Espírito Santo</option>
                                                                            <option value='Goiás'>Goiás</option>
                                                                            <option value='Maranhão'>Maranhão</option>
                                                                            <option value='Mato Grosso'>Mato Grosso</option>
                                                                            <option value='Mato Grosso do Sul'>Mato Grosso do Sul</option>
                                                                            <option value='Minas Gerais'>Minas Gerais</option>
                                                                            <option value='Pará'>Pará</option>
                                                                            <option value='Paraíba'>Paraíba</option>
                                                                            <option value='Paraná'>Paraná</option>
                                                                            <option value='Pernambuco'>Pernambuco</option>
                                                                            <option value='Piauí'>Piauí</option>
                                                                            <option value='Rio de Janeiro'>Rio de Janeiro</option>
                                                                            <option value='Rio Grande do Sul'>Rio Grande do Sul</option>
                                                                            <option value='Rondônia'>Rondônia</option>
                                                                            <option value='Roraima'>Roraima</option>
                                                                            <option value='Santa Catarina'>Santa Catarina</option>
                                                                            <option value='São Paulo'>São Paulo</option>
                                                                            <option value='Sergipe'>Sergipe</option>
                                                                            <option value='Tocantins'>Tocantins</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="filhosEnteadosResideCom">Com quem reside</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={70} placeholder="Com quem reside" id="filhosEnteadosResideCom" value={filhosEnteadosResideCom} onChange={(e) => setFilhosEnteadosResideCom(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="filhosEnteadosApelido">Qualquer outro nome ou apelido</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={191} placeholder="Nome ou apelido pelo qual é conhecido(a)" id="filhosEnteadosApelido" value={filhosEnteadosApelido} onChange={(e) => setFilhosEnteadosApelido(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="filhosEnteadosProfissao">Profissão</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={100} placeholder="Profissão" id="filhosEnteadosProfissao" value={filhosEnteadosProfissao} onChange={(e) => setFilhosEnteadosProfissao(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="filhosEnteadosTrabalhoEstudo">Local de trabalho (ou local onde estuda, caso não trabalhe)</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={191} placeholder="Local de trabalho (ou local onde estuda, caso não trabalhe)" id="filhosEnteadosTrabalhoEstudo" value={filhosEnteadosTrabalhoEstudo} onChange={(e) => setFilhosEnteadosTrabalhoEstudo(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='column is-narrow'>
                                                                <label className="label" htmlFor="filhosEnteadosCondicao">Condição</label>
                                                                <div className="select is-fullwidth">
                                                                    <select id="filhosEnteadosCondicao" value={filhosEnteadosCondicao} onChange={(e) => setFilhosEnteadosCondicao(e.target.value)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='Enteado'>Enteado</option>
                                                                        <option value='Filho biológico'>Filho biológico</option>
                                                                        <option value='Adotado'>Adotado</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <button type="button" className="button is-info" onClick={(e) => adicionaFilhosEnteados(e)}><i className="fa fa-plus-circle"></i>&nbsp;Clique Aqui para Registrar informação do filho(a)/enteado(a)</button>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                {filhosEnteados.map((fe, index) => (
                                                                    <div key={index}>
                                                                        <article className='message'>
                                                                            <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                                <p>{index+1}. {fe.nome} ({fe.condição})</p>
                                                                                <button type="button" className='delete' aria-label='delete' onClick={(e) => removeFilhosEnteados(e, fe.nome, fe.idade, fe.cpf, fe.rg)}></button>
                                                                            </div>
                                                                            <div className='message-body has-background-white-ter has-text-black'>
                                                                                <p>
                                                                                    CPF: {fe.cpf}; RG: {fe.rg}; Idade: {fe.idade}
                                                                                </p>
                                                                                <p>
                                                                                    Natural de: {fe.naturalidade}; Estado Naturalidade: {fe.naturalEstado}
                                                                                </p>
                                                                                <p>
                                                                                    Endereço: {fe.endereço} {fe.número}
                                                                                </p>
                                                                                <p>
                                                                                    Cidade: {fe.cidade};
                                                                                    Estado: {fe.estado};
                                                                                    Bairro: {fe.bairro}
                                                                                </p>
                                                                                <p>
                                                                                    Complemento: {fe.complemento};
                                                                                    CEP: {fe.cep}
                                                                                </p>
                                                                                <p>
                                                                                    Reside com: {fe.resideCom};
                                                                                    Apelido: {fe.apelido};
                                                                                    profissão: {fe.profissão}
                                                                                </p>
                                                                                <p>
                                                                                    Trabalha/Estuda: {fe.trabalhoEstudo}
                                                                                </p>
                                                                            </div>
                                                                        </article>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>

                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="sustentoFilhoEnteado">Está provendo o <u>sustento</u> dos seus filhos e/ou enteados?</label>
                                                                <div className="select">
                                                                    <select id="sustentoFilhoEnteado" value={sustentoFilhoEnteado} onChange={(e) => setSustentoFilhoEnteadoDiv(e)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='sim'>Sim</option>
                                                                        <option value='não'>Não</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id='sustentoFilhoEnteadoDiv' className='box' style={{ display:'none' }}>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="detalheSustentoFilhoEnteado">Forneça informações detalhadas, por que não:</label>
                                                                    <div className="controls">
                                                                        <textarea maxLength={1500} rows='4' className="textarea" id='detalheSustentoFilhoEnteado' name='detalheSustentoFilhoEnteado' value={detalheSustentoFilhoEnteado} placeholder="" onChange={(e) => setDetalheSustentoFilhoEnteado(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="casamentoUniaoEstavel">Você é <u>casado</u> ou está em uma <u>união estável</u>?</label>
                                                            <div className="select">
                                                                <select id="casamentoUniaoEstavel" value={casamentoUniaoEstavel} onChange={(e) => setCasamentoUniaoEstavelDiv(e)}>
                                                                    <option value=''>Selecione</option>
                                                                    <option value='sim'>Sim</option>
                                                                    <option value='não'>Não</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id='casamentoUniaoEstavelDiv' className='box' style={{ display:'none' }}>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                            <label className="label">Forneça as seguintes informações:</label>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelNome">Nome do cônjuge/companheiro(a)</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={191} placeholder="Nome do cônjuge/companheiro(a)" id="casamentoUniaoEstavelNome" value={casamentoUniaoEstavelNome} onChange={(e) => setCasamentoUniaoEstavelNome(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='column is-narrow'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelIdade">Idade</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={10} placeholder="Idade" id="casamentoUniaoEstavelIdade" value={casamentoUniaoEstavelIdade} onChange={(e) => setCasamentoUniaoEstavelIdade(onlyNumber(e.target.value))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelNaturalidade">Natural de</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={80} placeholder="Natural de" id="casamentoUniaoEstavelNaturalidade" value={casamentoUniaoEstavelNaturalidade} onChange={(e) => setCasamentoUniaoEstavelNaturalidade(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelNaturalEstado">Estado Naturalidade</label>
                                                                        <div className="controls">
                                                                            <div className="select is-fullwidth">
                                                                                <select id="casamentoUniaoEstavelNaturalEstado" value={casamentoUniaoEstavelNaturalEstado} onChange={(e) => setCasamentoUniaoEstavelNaturalEstado(e.target.value)}>
                                                                                    <option value=''>Selecione</option>
                                                                                    <option value='Rio Grande do Norte'>Rio Grande do Norte</option>
                                                                                    <option value='Acre'>Acre</option>
                                                                                    <option value='Alagoas'>Alagoas</option>
                                                                                    <option value='Amapá'>Amapá</option>
                                                                                    <option value='Amazonas'>Amazonas</option>
                                                                                    <option value='Bahia'>Bahia</option>
                                                                                    <option value='Ceará'>Ceará</option>
                                                                                    <option value='Distrito Federal'>Distrito Federal</option>
                                                                                    <option value='Espírito Santo'>Espírito Santo</option>
                                                                                    <option value='Goiás'>Goiás</option>
                                                                                    <option value='Maranhão'>Maranhão</option>
                                                                                    <option value='Mato Grosso'>Mato Grosso</option>
                                                                                    <option value='Mato Grosso do Sul'>Mato Grosso do Sul</option>
                                                                                    <option value='Minas Gerais'>Minas Gerais</option>
                                                                                    <option value='Pará'>Pará</option>
                                                                                    <option value='Paraíba'>Paraíba</option>
                                                                                    <option value='Paraná'>Paraná</option>
                                                                                    <option value='Pernambuco'>Pernambuco</option>
                                                                                    <option value='Piauí'>Piauí</option>
                                                                                    <option value='Rio de Janeiro'>Rio de Janeiro</option>
                                                                                    <option value='Rio Grande do Sul'>Rio Grande do Sul</option>
                                                                                    <option value='Rondônia'>Rondônia</option>
                                                                                    <option value='Roraima'>Roraima</option>
                                                                                    <option value='Santa Catarina'>Santa Catarina</option>
                                                                                    <option value='São Paulo'>São Paulo</option>
                                                                                    <option value='Sergipe'>Sergipe</option>
                                                                                    <option value='Tocantins'>Tocantins</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelCpf">CPF</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={14} placeholder="Número do CPF" id="casamentoUniaoEstavelCpf" value={casamentoUniaoEstavelCpf} onChange={(e) => setCasamentoUniaoEstavelCpf(cpfMask(e.target.value))} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelRg">RG</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={50} placeholder="Número do RG" id="casamentoUniaoEstavelRg" value={casamentoUniaoEstavelRg} onChange={(e) => setCasamentoUniaoEstavelRg(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelApelido">Qualquer outro nome ou apelido</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={191} placeholder="Nome ou apelido pelo qual ele é conhecido(a)" id="casamentoUniaoEstavelApelido" value={casamentoUniaoEstavelApelido} onChange={(e) => setCasamentoUniaoEstavelApelido(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelCondicao">Condição do relacionamento</label>
                                                                        <div className="controls">
                                                                            <div className="select is-fullwidth">
                                                                                <select id="casamentoUniaoEstavelCondicao" value={casamentoUniaoEstavelCondicao} onChange={(e) => setCasamentoUniaoEstavelCondicao(e.target.value)}>
                                                                                    <option value=''>Selecione</option>
                                                                                    <option value='Casamento'>Casamento</option>
                                                                                    <option value='União estável'>União estável</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelDataCasamentoRelacionamento">Data do casamento/Início do relacionamento</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={10} placeholder="Data do casamento/Início do relacionamento" id="casamentoUniaoEstavelDataCasamentoRelacionamento" value={casamentoUniaoEstavelDataCasamentoRelacionamento} onChange={(e) => setCasamentoUniaoEstavelDataCasamentoRelacionamento(dataBr(e.target.value))} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelLocalCasamento">Local do casamento</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={191} placeholder="Local do casamento" id="casamentoUniaoEstavelLocalCasamento" value={casamentoUniaoEstavelLocalCasamento} onChange={(e) => setCasamentoUniaoEstavelLocalCasamento(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelProfissao">Profissão</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={100} placeholder="Profissão" id="casamentoUniaoEstavelProfissao" value={casamentoUniaoEstavelProfissao} onChange={(e) => setCasamentoUniaoEstavelProfissao(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelSalario">Salário (R$)</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={50} placeholder="Salário (R$)" id="casamentoUniaoEstavelSalario" value={casamentoUniaoEstavelSalario} onChange={(e) => setCasamentoUniaoEstavelSalario(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelEmpresa">Nome da empresa onde cônjuge/companheiro(a) trabalha</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={191} placeholder="Nome da empresa onde cônjuge/companheiro(a) trabalha" id="casamentoUniaoEstavelEmpresa" value={casamentoUniaoEstavelEmpresa} onChange={(e) => setCasamentoUniaoEstavelEmpresa(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="controls">
                                                                    <b>Endereço Completo da EMPRESA(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</b>
                                                                    <textarea maxLength={1500} rows='4' className="textarea" id='casamentoUniaoEstavelEmpresaEndereco' name='casamentoUniaoEstavelEmpresaEndereco' value={casamentoUniaoEstavelEmpresaEndereco} placeholder="Endereço Completo da EMPRESA(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)" onChange={(e) => setCasamentoUniaoEstavelEmpresaEndereco(e.target.value)} />
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="casamentoUniaoEstavelFuncao">Função que cônjuge/companheiro(a) exerce</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={70} placeholder="Função que cônjuge/companheiro(a) exerce" id="casamentoUniaoEstavelFuncao" value={casamentoUniaoEstavelFuncao} onChange={(e) => setCasamentoUniaoEstavelFuncao(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                
                                <article className='message is-link' id='dadosConcursoDiv'>
                                    <div className='message-header'>
                                        <div className="tags are-medium has-addons">
                                            PARTE D - INFORMAÇÕES DE ORDEM MÉDICA, SÓCIO-COMPORTAMENTAL E PARTIDÁRIA
                                        </div>
                                    </div>
                                    <div className='message-body'>
                                        <div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="examinadoTratadoInternado">Você ou alguém de sua família já foi <u>examinado</u>, <u>tratado</u> ou <u>já esteve internado</u> em razão de distúrbios nervosos, mentais ou moléstia prolongada?</label>
                                                    <div className="select">
                                                        <select id="examinadoTratadoInternado" value={examinadoTratadoInternado} onChange={(e) => setExaminadoTratadoInternadoDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='examinadoTratadoInternadoDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheExaminadoTratadoInternado">Forneça informações detalhadas, relatando qual ou quais foram as moléstias, os locais e períodos de tratamento e/ou internação, bem como a situação atual do citado quadro de saúde:</label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='detalheExaminadoTratadoInternado' name='detalheExaminadoTratadoInternado' value={detalheExaminadoTratadoInternado} placeholder="" onChange={(e) => setDetalheExaminadoTratadoInternado(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="tatuagem">Você <u>possui</u> ou <u>já possuiu tatuagem</u>?</label>
                                                    <div className="select">
                                                        <select id="tatuagem" value={tatuagem} onChange={(e) => setTatuagemDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='tatuagemDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheTatuagem">Forneça informações detalhadas, relatando sobre os seguintes quesitos:
                                                        <span style={{ fontWeight:'normal' }}><br/>a. Quais partes do seu corpo são/eram tatuadas?<br/>b. Quais desenhos, símbolos ou dizeres você possui/possuía tatuado? 
                                                        <br/>c. Qual o significado de suas tatuagens?<br/>d. O que lhe motivou fazer e/ou remover as tatuagens?<br/>e. Em qual estabelecimento você realizou e/ou removeu as tatuagens (nome e localização)?</span></label>
                                                        <div className="controls">
                                                            <textarea maxLength={3000} rows='5' className="textarea" id='detalheTatuagem' name='detalheTatuagem' value={detalheTatuagem} placeholder="" onChange={(e) => setDetalheTatuagem(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="internadoHospital">Você já foi <u>internado</u> em algum hospital?</label>
                                                    <div className="select">
                                                        <select id="internadoHospital" value={internadoHospital} onChange={(e) => setInternadoHospitalDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='internadoHospitalDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheInternadoHospital">Forneça informações detalhadas, relatando qual foi o motivo (acidentes, doenças, etc.), a data e período de internação, bem como o local (hospital) de internação e/ou tratamento:</label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='detalheInternadoHospital' name='detalheInternadoHospital' value={detalheInternadoHospital} placeholder="" onChange={(e) => setDetalheInternadoHospital(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="desmaiou">Você já <u>desmaiou</u> alguma vez?</label>
                                                    <div className="select">
                                                        <select id="desmaiou" value={desmaiou} onChange={(e) => setDesmaiouDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='desmaiouDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheDesmaiou">Forneça informações detalhadas, relatando sobre o que aconteceu:</label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='detalheDesmaiou' name='detalheDesmaiou' value={detalheDesmaiou} placeholder="" onChange={(e) => setDetalheDesmaiou(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="bebidaAlcoolica">Você já fez ou faz <u>uso de bebidas alcóolicas</u>?</label>
                                                    <div className="select">
                                                        <select id="bebidaAlcoolica" value={bebidaAlcoolica} onChange={(e) => setBebidaAlcoolicaDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='bebidaAlcoolicaDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheBebidaAlcoolica">Forneça informações detalhadas, relatando sobre os seguintes quesitos:<span style={{ fontWeight:'normal' }}><br/>a. Quais tipos de bebidas alcoólicas você consome?<br/>b. Com que frequência você bebe?<br/>c. Quais locais você costuma beber e com quem?</span></label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='detalheBebidaAlcoolica' name='detalheBebidaAlcoolica' value={detalheBebidaAlcoolica} placeholder="" onChange={(e) => setDetalheBebidaAlcoolica(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="fuma">Você <u>fuma</u> ou já fumou?</label>
                                                    <div className="select">
                                                        <select id="fuma" value={fuma} onChange={(e) => setFumaDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='fumaDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheFuma">Forneça informações detalhadas, relatando sobre o seu uso de cigarro:</label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='detalheFuma' name='detalheFuma' value={detalheFuma} placeholder="" onChange={(e) => setDetalheFuma(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="entorpecente"><u>Você</u> já fez ou faz uso de substância entorpecente ilícita (<u>drogas</u>) ou já experimentou alguma vez?</label>
                                                    <div className="select">
                                                        <select id="entorpecente" value={entorpecente} onChange={(e) => setEntorpecenteDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='entorpecenteDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEntorpecente">Forneça informações detalhadas, relatando sobre os seguintes quesitos:<span style={{ fontWeight:'normal' }}><br/>a. Qual(is) tipo(s) de droga(s) que você utilizou/utiliza?<br/>b. Já há quanto tempo você faz uso ou por quanto tempo você utilizou droga?<br/>c. Qual(is) a(s) maneiras(s) que você fez/faz uso de droga?<br/>d. Você fazia uso ou somente experimentou drogas?<br/>e. Quando você experimentou ou fez uso de drogas?<br/>f. Qual local (estabelecimento) que você usou droga?<br/>g. Com quem você fez uso de droga? (cite nome, se possível completo, apelido e seu endereço)<br/>h. De quem, onde e quando você adquiriu/comprou a droga?<br/>i. Quanto você pagou pela droga?<br/>j. Quais os efeitos que você sentiu com o uso da droga?</span></label>
                                                        <div className="controls">
                                                            <textarea maxLength={3000} rows='10' className="textarea" id='detalheEntorpecente' name='detalheEntorpecente' value={detalheEntorpecente} placeholder="" onChange={(e) => setDetalheEntorpecente(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="entorpecenteFamilia"><u>Alguém de sua família</u> (pai, mãe, irmãos e outros parentes com quem você se relaciona com frequência) já fez ou faz uso de substância entorpecente ilícita (<u>drogas</u>)?</label>
                                                    <div className="select">
                                                        <select id="entorpecenteFamilia" value={entorpecenteFamilia} onChange={(e) => setEntorpecenteFamiliaDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='entorpecenteFamiliaDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEntorpecenteFamilia">Forneça informações detalhadas:</label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='detalheEntorpecenteFamilia' name='detalheEntorpecenteFamilia' value={detalheEntorpecenteFamilia} placeholder="" onChange={(e) => setDetalheEntorpecenteFamilia(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="entorpecenteRelacao">Você conhece alguém, possui/possuiu algum <u>amigo(a)</u>, ou se relaciona/relacionou afetivamente com alguém que fez ou faz uso de substância entorpecente ilícita (<u>drogas</u>)?</label>
                                                    <div className="select">
                                                        <select id="entorpecenteRelacao" value={entorpecenteRelacao} onChange={(e) => setEntorpecenteRelacaoDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='entorpecenteRelacaoDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEntorpecenteRelacao">Forneça informações detalhadas, relatando sobre os seguintes quesitos:<span style={{ fontWeight:'normal' }}><br/>a. Nome da(s) pessoa(s)<br/>b. Grau de amizade ou de relacionamento com aquela(s) pessoa(s)<br/>c. Período de amizade ou de relacionamento com aquela(s) pessoa(s)<br/>d. Endereço completo de moradia da(s) pessoa(s)<br/>e. Qualquer outro nome e/ou apelido pelo qual a pessoa é ou era conhecida</span></label>
                                                        <div className="controls">
                                                            <textarea maxLength={3000} rows='5' className="textarea" id='detalheEntorpecenteRelacao' name='detalheEntorpecenteRelacao' value={detalheEntorpecenteRelacao} placeholder="" onChange={(e) => setDetalheEntorpecenteRelacao(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="antecedenteCriminalRelacao">Você conhece alguém, possui/possuiu algum <u>amigo(a)</u>, ou se relaciona/relacionou afetivamente com alguém que possui <u>antecedentes criminais</u>?</label>
                                                    <div className="select">
                                                        <select id="antecedenteCriminalRelacao" value={antecedenteCriminalRelacao} onChange={(e) => setAntecedenteCriminalRelacaoDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='antecedenteCriminalRelacaoDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheAntecedenteCriminalRelacao">Forneça informações detalhadas, relatando sobre os seguintes quesitos:<span style={{ fontWeight:'normal' }}><br/>a. Nome da(s) pessoa(s)<br/>b. Grau de amizade ou de relacionamento com aquela(s) pessoa(s)<br/>c. Período de amizade ou de relacionamento com aquela(s) pessoa(s)<br/>d. Endereço completo de moradia da(s) pessoa(s)<br/>e. Qualquer outro nome e/ou apelido pelo qual a pessoa é ou era conhecida<br/>f. Motivos que levaram o referido amigo(a) a ser registrado criminalmente</span></label>
                                                        <div className="controls">
                                                            <textarea maxLength={3000} rows='6' className="textarea" id='detalheAntecedenteCriminalRelacao' name='detalheAntecedenteCriminalRelacao' value={detalheAntecedenteCriminalRelacao} placeholder="" onChange={(e) => setDetalheAntecedenteCriminalRelacao(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Forneça as seguintes informações sobre 4 (quatro) pessoas, que NÃO sejam parentes, ex-empregadores, dos quadros das Forças Armadas, Polícia Militar, Guarda Municipal, Polícia Civil, Polícia Técnico-Científica ou Polícia Penal, e que possam <u>testemunhar</u> a seu favor:</label>
                                                    <article className='message is-info' id='testemunhaDiv'>
                                                        <div className='message-header'>
                                                            <div className="tags are-medium has-addons">
                                                                TESTEMUNHA 1
                                                            </div>
                                                        </div>
                                                        <div className='message-body' style={{ backgroundColor:'white' }}>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha1Nome">1. Nome</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={191} placeholder="Nome" id="testemunha1Nome" value={testemunha1Nome} onChange={(e) => setTestemunha1Nome(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className='column is-narrow'>
                                                                    <label className="label" htmlFor="testemunha1Idade">1. Idade</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={10} placeholder="Idade" id="testemunha1Idade" value={testemunha1Idade} onChange={(e) => setTestemunha1Idade(onlyNumber(e.target.value))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha1Naturalidade">1. Natural de</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={80} placeholder="Natural de" id="testemunha1Naturalidade" value={testemunha1Naturalidade} onChange={(e) => setTestemunha1Naturalidade(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha1NaturalEstado">1. Estado Naturalidade</label>
                                                                    <div className="controls">
                                                                        <div className="select is-fullwidth">
                                                                            <select id="testemunha1NaturalEstado" value={testemunha1NaturalEstado} onChange={(e) => setTestemunha1NaturalEstado(e.target.value)}>
                                                                                <option value=''>Selecione</option>
                                                                                <option value='Rio Grande do Norte'>Rio Grande do Norte</option>
                                                                                <option value='Acre'>Acre</option>
                                                                                <option value='Alagoas'>Alagoas</option>
                                                                                <option value='Amapá'>Amapá</option>
                                                                                <option value='Amazonas'>Amazonas</option>
                                                                                <option value='Bahia'>Bahia</option>
                                                                                <option value='Ceará'>Ceará</option>
                                                                                <option value='Distrito Federal'>Distrito Federal</option>
                                                                                <option value='Espírito Santo'>Espírito Santo</option>
                                                                                <option value='Goiás'>Goiás</option>
                                                                                <option value='Maranhão'>Maranhão</option>
                                                                                <option value='Mato Grosso'>Mato Grosso</option>
                                                                                <option value='Mato Grosso do Sul'>Mato Grosso do Sul</option>
                                                                                <option value='Minas Gerais'>Minas Gerais</option>
                                                                                <option value='Pará'>Pará</option>
                                                                                <option value='Paraíba'>Paraíba</option>
                                                                                <option value='Paraná'>Paraná</option>
                                                                                <option value='Pernambuco'>Pernambuco</option>
                                                                                <option value='Piauí'>Piauí</option>
                                                                                <option value='Rio de Janeiro'>Rio de Janeiro</option>
                                                                                <option value='Rio Grande do Sul'>Rio Grande do Sul</option>
                                                                                <option value='Rondônia'>Rondônia</option>
                                                                                <option value='Roraima'>Roraima</option>
                                                                                <option value='Santa Catarina'>Santa Catarina</option>
                                                                                <option value='São Paulo'>São Paulo</option>
                                                                                <option value='Sergipe'>Sergipe</option>
                                                                                <option value='Tocantins'>Tocantins</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha1Cpf">1. CPF</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={14} placeholder="Número do CPF" id="testemunha1Cpf" value={testemunha1Cpf} onChange={(e) => setTestemunha1Cpf(cpfMask(e.target.value))} />
                                                                    </div>
                                                                </div>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha1Rg">1. RG</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={50} placeholder="Número do RG" id="testemunha1Rg" value={testemunha1Rg} onChange={(e) => setTestemunha1Rg(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha1TempoConhece">1. Há quanto tempo se conhecem?</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={30} placeholder="Há quanto tempo se conhecem" id="testemunha1TempoConhece" value={testemunha1TempoConhece} onChange={(e) => setTestemunha1TempoConhece(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha1Apelido">1. Qualquer outro nome ou apelido</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={191} placeholder="Nome ou apelido pelo qual ele é conhecido(a)" id="testemunha1Apelido" value={testemunha1Apelido} onChange={(e) => setTestemunha1Apelido(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="controls">
                                                                <label className="label" htmlFor="testemunha1EnderecoResidencial">1. Endereço Completo RESIDENCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                <textarea maxLength={1500} rows='4' className="textarea" id='testemunha1EnderecoResidencial' name='testemunha1EnderecoResidencial' value={testemunha1EnderecoResidencial} placeholder="Endereço Completo RESIDENCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)" onChange={(e) => setTestemunha1EnderecoResidencial(e.target.value)} />
                                                            </div>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha1Profissao">1. Profissão</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={100} placeholder="Profissão" id="testemunha1Profissao" value={testemunha1Profissao} onChange={(e) => setTestemunha1Profissao(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="controls">
                                                                <label className="label" htmlFor="testemunha1EnderecoComercial">1. Endereço Completo COMERCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                <textarea maxLength={1500} rows='4' className="textarea" id='testemunha1EnderecoComercial' name='testemunha1EnderecoComercial' value={testemunha1EnderecoComercial} placeholder="Endereço Completo COMERCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)" onChange={(e) => setTestemunha1EnderecoComercial(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </article>
                                                    <article className='message is-info' id='dadosPessoaisDiv'>
                                                        <div className='message-header'>
                                                            <div className="tags are-medium has-addons">
                                                                TESTEMUNHA 2
                                                            </div>
                                                        </div>
                                                        <div className='message-body' style={{ backgroundColor:'white' }}>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha2Nome">2. Nome</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={191} placeholder="Nome" id="testemunha2Nome" value={testemunha2Nome} onChange={(e) => setTestemunha2Nome(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className='column is-narrow'>
                                                                    <label className="label" htmlFor="testemunha2Idade">2. Idade</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={10} placeholder="Idade" id="testemunha2Idade" value={testemunha2Idade} onChange={(e) => setTestemunha2Idade(onlyNumber(e.target.value))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha2Naturalidade">2. Natural de</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={80} placeholder="Natural de" id="testemunha2Naturalidade" value={testemunha2Naturalidade} onChange={(e) => setTestemunha2Naturalidade(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha2NaturalEstado">2. Estado Naturalidade</label>
                                                                    <div className="controls">
                                                                        <div className="select is-fullwidth">
                                                                            <select id="testemunha2NaturalEstado" value={testemunha2NaturalEstado} onChange={(e) => setTestemunha2NaturalEstado(e.target.value)}>
                                                                                <option value=''>Selecione</option>
                                                                                <option value='Rio Grande do Norte'>Rio Grande do Norte</option>
                                                                                <option value='Acre'>Acre</option>
                                                                                <option value='Alagoas'>Alagoas</option>
                                                                                <option value='Amapá'>Amapá</option>
                                                                                <option value='Amazonas'>Amazonas</option>
                                                                                <option value='Bahia'>Bahia</option>
                                                                                <option value='Ceará'>Ceará</option>
                                                                                <option value='Distrito Federal'>Distrito Federal</option>
                                                                                <option value='Espírito Santo'>Espírito Santo</option>
                                                                                <option value='Goiás'>Goiás</option>
                                                                                <option value='Maranhão'>Maranhão</option>
                                                                                <option value='Mato Grosso'>Mato Grosso</option>
                                                                                <option value='Mato Grosso do Sul'>Mato Grosso do Sul</option>
                                                                                <option value='Minas Gerais'>Minas Gerais</option>
                                                                                <option value='Pará'>Pará</option>
                                                                                <option value='Paraíba'>Paraíba</option>
                                                                                <option value='Paraná'>Paraná</option>
                                                                                <option value='Pernambuco'>Pernambuco</option>
                                                                                <option value='Piauí'>Piauí</option>
                                                                                <option value='Rio de Janeiro'>Rio de Janeiro</option>
                                                                                <option value='Rio Grande do Sul'>Rio Grande do Sul</option>
                                                                                <option value='Rondônia'>Rondônia</option>
                                                                                <option value='Roraima'>Roraima</option>
                                                                                <option value='Santa Catarina'>Santa Catarina</option>
                                                                                <option value='São Paulo'>São Paulo</option>
                                                                                <option value='Sergipe'>Sergipe</option>
                                                                                <option value='Tocantins'>Tocantins</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha2Cpf">2. CPF</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={14} placeholder="Número do CPF" id="testemunha2Cpf" value={testemunha2Cpf} onChange={(e) => setTestemunha2Cpf(cpfMask(e.target.value))} />
                                                                    </div>
                                                                </div>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha2Rg">2. RG</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={50} placeholder="Número do RG" id="testemunha2Rg" value={testemunha2Rg} onChange={(e) => setTestemunha2Rg(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha2TempoConhece">2. Há quanto tempo se conhecem?</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={30} placeholder="Há quanto tempo se conhecem" id="testemunha2TempoConhece" value={testemunha2TempoConhece} onChange={(e) => setTestemunha2TempoConhece(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha2Apelido">2. Qualquer outro nome ou apelido</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={191} placeholder="Nome ou apelido pelo qual ele é conhecido(a)" id="testemunha2Apelido" value={testemunha2Apelido} onChange={(e) => setTestemunha2Apelido(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="controls">
                                                                <label className="label" htmlFor="testemunha2EnderecoResidencial">2. Endereço Completo RESIDENCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                <textarea maxLength={1500} rows='4' className="textarea" id='testemunha2EnderecoResidencial' name='testemunha2EnderecoResidencial' value={testemunha2EnderecoResidencial} placeholder="Endereço Completo RESIDENCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)" onChange={(e) => setTestemunha2EnderecoResidencial(e.target.value)} />
                                                            </div>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha2Profissao">2. Profissão</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={100} placeholder="Profissão" id="testemunha2Profissao" value={testemunha2Profissao} onChange={(e) => setTestemunha2Profissao(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="controls">
                                                                <label className="label" htmlFor="testemunha2EnderecoComercial">2. Endereço Completo COMERCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                <textarea maxLength={1500} rows='4' className="textarea" id='testemunha2EnderecoComercial' name='testemunha2EnderecoComercial' value={testemunha2EnderecoComercial} placeholder="Endereço Completo COMERCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)" onChange={(e) => setTestemunha2EnderecoComercial(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </article>
                                                    <article className='message is-info' id='dadosPessoaisDiv'>
                                                        <div className='message-header'>
                                                            <div className="tags are-medium has-addons">
                                                                TESTEMUNHA 3
                                                            </div>
                                                        </div>
                                                        <div className='message-body' style={{ backgroundColor:'white' }}>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha3Nome">3. Nome</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={191} placeholder="Nome" id="testemunha3Nome" value={testemunha3Nome} onChange={(e) => setTestemunha3Nome(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className='column is-narrow'>
                                                                    <label className="label" htmlFor="testemunha3Idade">3. Idade</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={10} placeholder="Idade" id="testemunha3Idade" value={testemunha3Idade} onChange={(e) => setTestemunha3Idade(onlyNumber(e.target.value))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha3Naturalidade">3. Natural de</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={80} placeholder="Natural de" id="testemunha3Naturalidade" value={testemunha3Naturalidade} onChange={(e) => setTestemunha3Naturalidade(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha3NaturalEstado">3. Estado Naturalidade</label>
                                                                    <div className="controls">
                                                                        <div className="select is-fullwidth">
                                                                            <select id="testemunha3NaturalEstado" value={testemunha3NaturalEstado} onChange={(e) => setTestemunha3NaturalEstado(e.target.value)}>
                                                                                <option value=''>Selecione</option>
                                                                                <option value='Rio Grande do Norte'>Rio Grande do Norte</option>
                                                                                <option value='Acre'>Acre</option>
                                                                                <option value='Alagoas'>Alagoas</option>
                                                                                <option value='Amapá'>Amapá</option>
                                                                                <option value='Amazonas'>Amazonas</option>
                                                                                <option value='Bahia'>Bahia</option>
                                                                                <option value='Ceará'>Ceará</option>
                                                                                <option value='Distrito Federal'>Distrito Federal</option>
                                                                                <option value='Espírito Santo'>Espírito Santo</option>
                                                                                <option value='Goiás'>Goiás</option>
                                                                                <option value='Maranhão'>Maranhão</option>
                                                                                <option value='Mato Grosso'>Mato Grosso</option>
                                                                                <option value='Mato Grosso do Sul'>Mato Grosso do Sul</option>
                                                                                <option value='Minas Gerais'>Minas Gerais</option>
                                                                                <option value='Pará'>Pará</option>
                                                                                <option value='Paraíba'>Paraíba</option>
                                                                                <option value='Paraná'>Paraná</option>
                                                                                <option value='Pernambuco'>Pernambuco</option>
                                                                                <option value='Piauí'>Piauí</option>
                                                                                <option value='Rio de Janeiro'>Rio de Janeiro</option>
                                                                                <option value='Rio Grande do Sul'>Rio Grande do Sul</option>
                                                                                <option value='Rondônia'>Rondônia</option>
                                                                                <option value='Roraima'>Roraima</option>
                                                                                <option value='Santa Catarina'>Santa Catarina</option>
                                                                                <option value='São Paulo'>São Paulo</option>
                                                                                <option value='Sergipe'>Sergipe</option>
                                                                                <option value='Tocantins'>Tocantins</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha3Cpf">3. CPF</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={14} placeholder="Número do CPF" id="testemunha3Cpf" value={testemunha3Cpf} onChange={(e) => setTestemunha3Cpf(cpfMask(e.target.value))} />
                                                                    </div>
                                                                </div>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha3Rg">3. RG</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={50} placeholder="Número do RG" id="testemunha3Rg" value={testemunha3Rg} onChange={(e) => setTestemunha3Rg(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha3TempoConhece">3. Há quanto tempo se conhecem?</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={30} placeholder="Há quanto tempo se conhecem" id="testemunha3TempoConhece" value={testemunha3TempoConhece} onChange={(e) => setTestemunha3TempoConhece(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha3Apelido">3. Qualquer outro nome ou apelido</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={191} placeholder="Nome ou apelido pelo qual ele é conhecido(a)" id="testemunha3Apelido" value={testemunha3Apelido} onChange={(e) => setTestemunha3Apelido(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="controls">
                                                                <label className="label" htmlFor="testemunha3EnderecoResidencial">3. Endereço Completo RESIDENCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                <textarea maxLength={1500} rows='4' className="textarea" id='testemunha3EnderecoResidencial' name='testemunha3EnderecoResidencial' value={testemunha3EnderecoResidencial} placeholder="Endereço Completo RESIDENCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)" onChange={(e) => setTestemunha3EnderecoResidencial(e.target.value)} />
                                                            </div>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha3Profissao">3. Profissão</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={100} placeholder="Profissão" id="testemunha3Profissao" value={testemunha3Profissao} onChange={(e) => setTestemunha3Profissao(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="controls">
                                                                <label className="label" htmlFor="testemunha3EnderecoComercial">3. Endereço Completo COMERCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                <textarea maxLength={1500} rows='4' className="textarea" id='testemunha3EnderecoComercial' name='testemunha3EnderecoComercial' value={testemunha3EnderecoComercial} placeholder="Endereço Completo COMERCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)" onChange={(e) => setTestemunha3EnderecoComercial(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </article>
                                                    <article className='message is-info' id='dadosPessoaisDiv'>
                                                        <div className='message-header'>
                                                            <div className="tags are-medium has-addons">
                                                                TESTEMUNHA 4
                                                            </div>
                                                        </div>
                                                        <div className='message-body' style={{ backgroundColor:'white' }}>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha4Nome">4. Nome</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={191} placeholder="Nome" id="testemunha4Nome" value={testemunha4Nome} onChange={(e) => setTestemunha4Nome(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className='column is-narrow'>
                                                                    <label className="label" htmlFor="testemunha4Idade">4. Idade</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={10} placeholder="Idade" id="testemunha4Idade" value={testemunha4Idade} onChange={(e) => setTestemunha4Idade(onlyNumber(e.target.value))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha4Naturalidade">4. Natural de</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={80} placeholder="Natural de" id="testemunha4Naturalidade" value={testemunha4Naturalidade} onChange={(e) => setTestemunha4Naturalidade(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha4NaturalEstado">4. Estado Naturalidade</label>
                                                                    <div className="controls">
                                                                        <div className="select is-fullwidth">
                                                                            <select id="testemunha4NaturalEstado" value={testemunha4NaturalEstado} onChange={(e) => setTestemunha4NaturalEstado(e.target.value)}>
                                                                                <option value=''>Selecione</option>
                                                                                <option value='Rio Grande do Norte'>Rio Grande do Norte</option>
                                                                                <option value='Acre'>Acre</option>
                                                                                <option value='Alagoas'>Alagoas</option>
                                                                                <option value='Amapá'>Amapá</option>
                                                                                <option value='Amazonas'>Amazonas</option>
                                                                                <option value='Bahia'>Bahia</option>
                                                                                <option value='Ceará'>Ceará</option>
                                                                                <option value='Distrito Federal'>Distrito Federal</option>
                                                                                <option value='Espírito Santo'>Espírito Santo</option>
                                                                                <option value='Goiás'>Goiás</option>
                                                                                <option value='Maranhão'>Maranhão</option>
                                                                                <option value='Mato Grosso'>Mato Grosso</option>
                                                                                <option value='Mato Grosso do Sul'>Mato Grosso do Sul</option>
                                                                                <option value='Minas Gerais'>Minas Gerais</option>
                                                                                <option value='Pará'>Pará</option>
                                                                                <option value='Paraíba'>Paraíba</option>
                                                                                <option value='Paraná'>Paraná</option>
                                                                                <option value='Pernambuco'>Pernambuco</option>
                                                                                <option value='Piauí'>Piauí</option>
                                                                                <option value='Rio de Janeiro'>Rio de Janeiro</option>
                                                                                <option value='Rio Grande do Sul'>Rio Grande do Sul</option>
                                                                                <option value='Rondônia'>Rondônia</option>
                                                                                <option value='Roraima'>Roraima</option>
                                                                                <option value='Santa Catarina'>Santa Catarina</option>
                                                                                <option value='São Paulo'>São Paulo</option>
                                                                                <option value='Sergipe'>Sergipe</option>
                                                                                <option value='Tocantins'>Tocantins</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha4Cpf">4. CPF</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={14} placeholder="Número do CPF" id="testemunha4Cpf" value={testemunha4Cpf} onChange={(e) => setTestemunha4Cpf(cpfMask(e.target.value))} />
                                                                    </div>
                                                                </div>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha4Rg">4. RG</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={50} placeholder="Número do RG" id="testemunha4Rg" value={testemunha4Rg} onChange={(e) => setTestemunha4Rg(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha4TempoConhece">4. Há quanto tempo se conhecem?</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={30} placeholder="Há quanto tempo se conhecem" id="testemunha4TempoConhece" value={testemunha4TempoConhece} onChange={(e) => setTestemunha4TempoConhece(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha4Apelido">4. Qualquer outro nome ou apelido</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={191} placeholder="Nome ou apelido pelo qual ele é conhecido(a)" id="testemunha4Apelido" value={testemunha4Apelido} onChange={(e) => setTestemunha4Apelido(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="controls">
                                                                <label className="label" htmlFor="testemunha4EnderecoResidencial">4. Endereço Completo RESIDENCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                <textarea maxLength={1500} rows='4' className="textarea" id='testemunha4EnderecoResidencial' name='testemunha4EnderecoResidencial' value={testemunha4EnderecoResidencial} placeholder="Endereço Completo RESIDENCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)" onChange={(e) => setTestemunha4EnderecoResidencial(e.target.value)} />
                                                            </div>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="testemunha4Profissao">4. Profissão</label>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" maxLength={100} placeholder="Profissão" id="testemunha4Profissao" value={testemunha4Profissao} onChange={(e) => setTestemunha4Profissao(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="controls">
                                                                <label className="label" htmlFor="testemunha4EnderecoComercial">4. Endereço Completo COMERCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                <textarea maxLength={1500} rows='4' className="textarea" id='testemunha4EnderecoComercial' name='testemunha4EnderecoComercial' value={testemunha4EnderecoComercial} placeholder="Endereço Completo COMERCIAL(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)" onChange={(e) => setTestemunha4EnderecoComercial(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </div>
                                            
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="faPmGmPcPtcConhecido">Você possui ou já possuiu <u>parentes</u> e/ou <u>amigos pessoais</u> nas Forças Armadas, Polícia Militar, Guarda Municipal, Polícia Civil ou Polícia Técnico-Científica?</label>
                                                    <div className="select">
                                                        <select id="faPmGmPcPtcConhecido" value={faPmGmPcPtcConhecido} onChange={(e) => setFaPmGmPcPtcConhecidoDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='faPmGmPcPtcConhecidoDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label">Adicione as seguintes informações sobre eles <span style={{ fontWeight:'normal' }}>(vá adicionando clicando no botão 'Registrar informação do parente/amigo(a)', um parente/amigo(a) por vez)</span>:</label>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="faPmGmPcPtcNome">Nome</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={191} placeholder="Nome" id="faPmGmPcPtcNome" value={faPmGmPcPtcNome} onChange={(e) => setFaPmGmPcPtcNome(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="faPmGmPcPtcGrauParentesco">Grau de parentesco</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={50} placeholder="Grau de parentesco" id="faPmGmPcPtcGrauParentesco" value={faPmGmPcPtcGrauParentesco} onChange={(e) => setFaPmGmPcPtcGrauParentesco(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="faPmGmPcPtcSituacao">Situação</label>
                                                                <div className="controls">
                                                                    <div className="select is-fullwidth">
                                                                        <select id="faPmGmPcPtcSituacao" value={faPmGmPcPtcSituacao} onChange={(e) => setFaPmGmPcPtcSituacao(e.target.value)}>
                                                                            <option value=''>Selecione</option>
                                                                            <option value='Ativo'>Ativo</option>
                                                                            <option value='Aposentado'>Aposentado</option>
                                                                            <option value='Exonerado'>Exonerado</option>
                                                                            <option value='Demitido'>Demitido</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="faPmGmPcPtcCpf">CPF</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={14} placeholder="Número do CPF" id="faPmGmPcPtcCpf" value={faPmGmPcPtcCpf} onChange={(e) => setFaPmGmPcPtcCpf(cpfMask(e.target.value))} />
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="faPmGmPcPtcRg">RG</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={50} placeholder="Número do RG" id="faPmGmPcPtcRg" value={faPmGmPcPtcRg} onChange={(e) => setFaPmGmPcPtcRg(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="faPmGmPcPtcTempoConhece">Se amigo(a), há quanto tempo se conhecem?</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={191} placeholder="Quanto tempo se conhecem" id="faPmGmPcPtcTempoConhece" value={faPmGmPcPtcTempoConhece} onChange={(e) => setFaPmGmPcPtcTempoConhece(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="faPmGmPcPtcCargoPostoGraduacao">Cargo, Posto ou Graduação</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={80} placeholder="Cargo, Posto ou Graduação" id="faPmGmPcPtcCargoPostoGraduacao" value={faPmGmPcPtcCargoPostoGraduacao} onChange={(e) => setFaPmGmPcPtcCargoPostoGraduacao(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="faPmGmPcPtcInstituicao">Instituição em que trabalha/trabalhou</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={80} placeholder="Instituição em que trabalha/trabalhou" id="faPmGmPcPtcInstituicao" value={faPmGmPcPtcInstituicao} onChange={(e) => setFaPmGmPcPtcInstituicao(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="faPmGmPcPtcUnidade">Unidade em que serve/serviu</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={80} placeholder="Unidade em que serve/serviu" id="faPmGmPcPtcUnidade" value={faPmGmPcPtcUnidade} onChange={(e) => setFaPmGmPcPtcUnidade(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="faPmGmPcPtcFuncao">Função que exerce/exerceu</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={80} placeholder="Função que exerce/exerceu" id="faPmGmPcPtcFuncao" value={faPmGmPcPtcFuncao} onChange={(e) => setFaPmGmPcPtcFuncao(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <button type="button" className="button is-info" onClick={(e) => adicionaFaPmGmPcPtc(e)}><i className="fa fa-plus-circle"></i>&nbsp;Clique Aqui para Registrar informação do parente/amigo(a)</button>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                {faPmGmPcPtc.map((parenteAmigo, index) => (
                                                                    <div key={index}>
                                                                        <article className='message is-dark'>
                                                                            <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                                <p>{index+1}. {parenteAmigo.nome} ({parenteAmigo.grauParentesco})</p>
                                                                                <button type="button" className='delete' aria-label='delete' onClick={(e) => removeFaPmGmPcPtc(e, parenteAmigo.nome, parenteAmigo.grauParentesco, parenteAmigo.cpf, parenteAmigo.rg, parenteAmigo.instituição )}></button>
                                                                            </div>
                                                                            <div className='message-body has-background-white-ter has-text-black'>
                                                                                <p>
                                                                                    Situação: {parenteAmigo.situação};
                                                                                    Tempo que se conhecem: {parenteAmigo.tempoConhece}
                                                                                </p>
                                                                                <p>
                                                                                    CPF: {parenteAmigo.cpf};
                                                                                    RG: {parenteAmigo.rg}
                                                                                </p>
                                                                                <p>
                                                                                    Instituição: {parenteAmigo.instituição}
                                                                                </p>
                                                                                <p>
                                                                                    Cargo/Posto/Graduação: {parenteAmigo.cargoPostoGraduação}
                                                                                </p>
                                                                                <p>
                                                                                    Unidade: {parenteAmigo.unidade}
                                                                                </p>
                                                                                <p>
                                                                                    Função: {parenteAmigo.função}
                                                                                </p>
                                                                            </div>
                                                                        </article>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="folga">O que você costuma fazer ou gosta de estar fazendo em suas <u>horas de folga</u>?</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='folga' name='folga' value={folga} placeholder="O que você costuma fazer ou gosta de estar fazendo em suas horas de folga?" onChange={(e) => setFolga(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Quais são seus <u>costumes</u> e <u>locais</u> que por hábito tem frequentado?</label>
                                                    <div className='box'>
                                                        <label className="label" htmlFor="costumeLocalDiaSemana">a. Durante a semana (de segunda-feira a sexta-feira)</label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='costumeLocalDiaSemana' name='costumeLocalDiaSemana' value={costumeLocalDiaSemana} placeholder="Costumes e locais que por hábito tem frequentado durante a semana (de segunda-feira a sexta-feira)" onChange={(e) => setCostumeLocalDiaSemana(e.target.value)} />
                                                        </div>
                                                        <label className="label" htmlFor="costumeLocalFdsFeriado">b. Durante o final de semana e feriados</label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='costumeLocalFdsFeriado' name='costumeLocalFdsFeriado' value={costumeLocalFdsFeriado} placeholder="Costumes e locais que por hábito tem frequentado durante o final de semana e feriados" onChange={(e) => setCostumeLocalFdsFeriado(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="socioClube">Você é ou já foi sócio de algum <u>clube/associação desportiva</u>?</label>
                                                    <div className="select">
                                                        <select id="socioClube" value={socioClube} onChange={(e) => setSocioClubeDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='socioClubeDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label">Forneça informações detalhadas <span style={{ fontWeight:'normal' }}>(vá adicionando clicando no botão 'Registrar informação do clube/associação desportiva', um clube/associação por vez)</span>:</label>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="socioClubeNome">Nome do Clube</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={191} placeholder="Nome do Clube" id="socioClubeNome" value={socioClubeNome} onChange={(e) => setSocioClubeNome(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="socioClubeMesInicial">Período em que foi sócio - De</label>
                                                                <div className='columns'>
                                                                    <div className='column is-narrow'>
                                                                        <div className="select">
                                                                            <select id="socioClubeMesInicial" value={socioClubeMesInicial} onChange={(e) => setSocioClubeMesInicial(e.target.value)}>
                                                                                <option value=''>Selecione</option>
                                                                                <option value='Janeiro'>Janeiro</option>
                                                                                <option value='Fevereiro'>Fevereiro</option>
                                                                                <option value='Março'>Março</option>
                                                                                <option value='Abril'>Abril</option>
                                                                                <option value='Maio'>Maio</option>
                                                                                <option value='Junho'>Junho</option>
                                                                                <option value='Julho'>Julho</option>
                                                                                <option value='Agosto'>Agosto</option>
                                                                                <option value='Setembro'>Setembro</option>
                                                                                <option value='Outubro'>Outubro</option>
                                                                                <option value='Novembro'>Novembro</option>
                                                                                <option value='Dezembro'>Dezembro</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className='column is-narrow'>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" placeholder="Ano Início" maxLength={4} id="socioClubeAnoInicial" value={socioClubeAnoInicial} onChange={(e) => setSocioClubeAnoInicial(onlyNumber(e.target.value))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="socioClubeMesFinal">Período em que foi sócio - Até</label>
                                                                <div className='columns'>
                                                                    <div className='column is-narrow'>
                                                                        <div className="select">
                                                                            <select id="socioClubeMesFinal" value={socioClubeMesFinal} onChange={(e) => setSocioClubeMesFinal(e.target.value)}>
                                                                                <option value=''>Selecione</option>
                                                                                <option value='Janeiro'>Janeiro</option>
                                                                                <option value='Fevereiro'>Fevereiro</option>
                                                                                <option value='Março'>Março</option>
                                                                                <option value='Abril'>Abril</option>
                                                                                <option value='Maio'>Maio</option>
                                                                                <option value='Junho'>Junho</option>
                                                                                <option value='Julho'>Julho</option>
                                                                                <option value='Agosto'>Agosto</option>
                                                                                <option value='Setembro'>Setembro</option>
                                                                                <option value='Outubro'>Outubro</option>
                                                                                <option value='Novembro'>Novembro</option>
                                                                                <option value='Dezembro'>Dezembro</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className='column is-narrow'>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" placeholder="Ano Fim" maxLength={4} id="socioClubeAnoFinal" value={socioClubeAnoFinal} onChange={(e) => setSocioClubeAnoFinal(onlyNumber(e.target.value))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="socioClubeEndereco">Endereço</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={191} placeholder="Endereço" id="socioClubeEndereco" value={socioClubeEndereco} onChange={(e) => setSocioClubeEndereco(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='column is-narrow'>
                                                                <label className="label" htmlFor="socioClubeNumero">Número</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={50} placeholder="Número" id="socioClubeNumero" value={socioClubeNumero} onChange={(e) => setSocioClubeNumero(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="socioClubeComplemento">Complemento</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={50} placeholder="Complemento" id="socioClubeComplemento" value={socioClubeComplemento} onChange={(e) => setSocioClubeComplemento(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="socioClubeCep">CEP</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={9} placeholder="CEP" id="socioClubeCep" value={socioClubeCep} onChange={(e) => setSocioClubeCep(nrCepMask(e.target.value))} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="socioClubeBairro">Bairro</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={70} placeholder="Bairro" id="socioClubeBairro" value={socioClubeBairro} onChange={(e) => setSocioClubeBairro(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="socioClubeCidade">Cidade</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={70} placeholder="Cidade" id="socioClubeCidade" value={socioClubeCidade} onChange={(e) => setSocioClubeCidade(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="socioClubeEstado">Estado</label>
                                                                <div className="controls">
                                                                    <div className="select is-fullwidth">
                                                                        <select id="socioClubeEstado" value={socioClubeEstado} onChange={(e) => setSocioClubeEstado(e.target.value)}>
                                                                            <option value=''>Selecione</option>
                                                                            <option value='Rio Grande do Norte'>Rio Grande do Norte</option>
                                                                            <option value='Acre'>Acre</option>
                                                                            <option value='Alagoas'>Alagoas</option>
                                                                            <option value='Amapá'>Amapá</option>
                                                                            <option value='Amazonas'>Amazonas</option>
                                                                            <option value='Bahia'>Bahia</option>
                                                                            <option value='Ceará'>Ceará</option>
                                                                            <option value='Distrito Federal'>Distrito Federal</option>
                                                                            <option value='Espírito Santo'>Espírito Santo</option>
                                                                            <option value='Goiás'>Goiás</option>
                                                                            <option value='Maranhão'>Maranhão</option>
                                                                            <option value='Mato Grosso'>Mato Grosso</option>
                                                                            <option value='Mato Grosso do Sul'>Mato Grosso do Sul</option>
                                                                            <option value='Minas Gerais'>Minas Gerais</option>
                                                                            <option value='Pará'>Pará</option>
                                                                            <option value='Paraíba'>Paraíba</option>
                                                                            <option value='Paraná'>Paraná</option>
                                                                            <option value='Pernambuco'>Pernambuco</option>
                                                                            <option value='Piauí'>Piauí</option>
                                                                            <option value='Rio de Janeiro'>Rio de Janeiro</option>
                                                                            <option value='Rio Grande do Sul'>Rio Grande do Sul</option>
                                                                            <option value='Rondônia'>Rondônia</option>
                                                                            <option value='Roraima'>Roraima</option>
                                                                            <option value='Santa Catarina'>Santa Catarina</option>
                                                                            <option value='São Paulo'>São Paulo</option>
                                                                            <option value='Sergipe'>Sergipe</option>
                                                                            <option value='Tocantins'>Tocantins</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="socioClubePontoReferencia">Ponto de Referência (Batalhão PM da área)</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={191} placeholder="Ponto de Referência" id="socioClubePontoReferencia" value={socioClubePontoReferencia} onChange={(e) => setSocioClubePontoReferencia(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="socioClubeAtividade">Quais as atividades que você pratica(va) no Clube?</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={191} placeholder="Quais as atividades que você pratica(va) no Clube?" id="socioClubeAtividade" value={socioClubeAtividade} onChange={(e) => setSocioClubeAtividade(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="socioClubeNotificacaoPunicao">Chegou a sofrer alguma notificação ou punição pela Diretoria do Clube em razão do cometimento de alguma conduta de indisciplina?</label>
                                                                <div className="select">
                                                                    <select id="socioClubeNotificacaoPunicao" value={socioClubeNotificacaoPunicao} onChange={(e) => setSocioClubeNotificacaoPunicaoDiv(e)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='sim'>Sim</option>
                                                                        <option value='não'>Não</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id='socioClubeNotificacaoPunicaoDiv' className='box' style={{ display:'none' }}>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="detalheSocioClubeNotificacaoPunicao">Forneça informações detalhadas:</label>
                                                                    <div className="controls">
                                                                        <textarea maxLength={1500} rows='4' className="textarea" id='detalheSocioClubeNotificacaoPunicao' name='detalheSocioClubeNotificacaoPunicao' value={detalheSocioClubeNotificacaoPunicao} placeholder="Forneça informações detalhadas" onChange={(e) => setDetalheSocioClubeNotificacaoPunicao(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <button type="button" className="button is-info" onClick={(e) => adicionaSocioClube(e)}><i className="fa fa-plus-circle"></i>&nbsp;Clique Aqui para Registrar informação do clube/associação desportiva</button>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                {socioClubes.map((sc, index) => (
                                                                    <div key={index}>
                                                                        <article className='message'>
                                                                            <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                                <p>{index+1}. {sc.nome} ({sc.mêsInicial}/{sc.anoInicial} ~ {sc.mêsFinal}/{sc.anoFinal})</p>
                                                                                <button type="button" className='delete' aria-label='delete' onClick={(e) => removeSocioClube(e, sc.nome, sc.mêsInicial, sc.anoInicial, sc.mêsFinal, sc.anoFinal, sc.atividade )}></button>
                                                                            </div>
                                                                            <div className='message-body has-background-white-ter has-text-black'>
                                                                                <p>
                                                                                    Endereço: {sc.endereço} {sc.número}
                                                                                </p>
                                                                                <p>
                                                                                    Complemento: {sc.complemento};
                                                                                    CEP: {sc.cep}
                                                                                </p>
                                                                                <p>
                                                                                    Bairro: {sc.bairro};
                                                                                    Cidade: {sc.cidade};
                                                                                    Estado: {sc.estado}
                                                                                </p>
                                                                                <p>
                                                                                    Ponto de Referência: {sc.pontoReferência}
                                                                                </p>
                                                                                <p>
                                                                                    Atividade(s): {sc.atividade}
                                                                                </p>
                                                                                <p>
                                                                                    Notificação/Punição: {sc.notificaçãoPunição}
                                                                                </p>
                                                                                {sc.notificaçãoPunição === 'sim' &&
                                                                                    <div dangerouslySetInnerHTML={{ __html: sc.detalheNotificaçãoPunição.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                                }
                                                                            </div>
                                                                        </article>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="sindicato">Você pertence ou já pertenceu a qualquer <u>sindicato</u> ou outra <u>associação de classe</u>?</label>
                                                    <div className="select">
                                                        <select id="sindicato" value={sindicato} onChange={(e) => setSindicatoDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='sindicatoDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label">Forneça informações detalhadas <span style={{ fontWeight:'normal' }}>(vá adicionando clicando no botão 'Registrar informação do sindicato/associação de classe', um sindicato/associação por vez)</span>:</label>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="sindicatoNome">Nome da Entidade</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={191} placeholder="Nome da Entidade" id="sindicatoNome" value={sindicatoNome} onChange={(e) => setSindicatoNome(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="sindicatoMesInicial">Período de filiação - De</label>
                                                                <div className='columns'>
                                                                    <div className='column is-narrow'>
                                                                        <div className="select">
                                                                            <select id="sindicatoMesInicial" value={sindicatoMesInicial} onChange={(e) => setSindicatoMesInicial(e.target.value)}>
                                                                                <option value=''>Selecione</option>
                                                                                <option value='Janeiro'>Janeiro</option>
                                                                                <option value='Fevereiro'>Fevereiro</option>
                                                                                <option value='Março'>Março</option>
                                                                                <option value='Abril'>Abril</option>
                                                                                <option value='Maio'>Maio</option>
                                                                                <option value='Junho'>Junho</option>
                                                                                <option value='Julho'>Julho</option>
                                                                                <option value='Agosto'>Agosto</option>
                                                                                <option value='Setembro'>Setembro</option>
                                                                                <option value='Outubro'>Outubro</option>
                                                                                <option value='Novembro'>Novembro</option>
                                                                                <option value='Dezembro'>Dezembro</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className='column is-narrow'>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" placeholder="Ano Início" maxLength={4} id="sindicatoAnoInicial" value={sindicatoAnoInicial} onChange={(e) => setSindicatoAnoInicial(onlyNumber(e.target.value))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="sindicatoMesFinal">Período de filiação - Até</label>
                                                                <div className='columns'>
                                                                    <div className='column is-narrow'>
                                                                        <div className="select">
                                                                            <select id="sindicatoMesFinal" value={sindicatoMesFinal} onChange={(e) => setSindicatoMesFinal(e.target.value)}>
                                                                                <option value=''>Selecione</option>
                                                                                <option value='Janeiro'>Janeiro</option>
                                                                                <option value='Fevereiro'>Fevereiro</option>
                                                                                <option value='Março'>Março</option>
                                                                                <option value='Abril'>Abril</option>
                                                                                <option value='Maio'>Maio</option>
                                                                                <option value='Junho'>Junho</option>
                                                                                <option value='Julho'>Julho</option>
                                                                                <option value='Agosto'>Agosto</option>
                                                                                <option value='Setembro'>Setembro</option>
                                                                                <option value='Outubro'>Outubro</option>
                                                                                <option value='Novembro'>Novembro</option>
                                                                                <option value='Dezembro'>Dezembro</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className='column is-narrow'>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" placeholder="Ano Fim" maxLength={4} id="sindicatoAnoFinal" value={sindicatoAnoFinal} onChange={(e) => setSindicatoAnoFinal(onlyNumber(e.target.value))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="sindicatoEndereco">Endereço</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={191} placeholder="Endereço" id="sindicatoEndereco" value={sindicatoEndereco} onChange={(e) => setSindicatoEndereco(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='column is-narrow'>
                                                                <label className="label" htmlFor="sindicatoNumero">Número</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={50} placeholder="Número" id="sindicatoNumero" value={sindicatoNumero} onChange={(e) => setSindicatoNumero(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="sindicatoComplemento">Complemento</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={50} placeholder="Complemento" id="sindicatoComplemento" value={sindicatoComplemento} onChange={(e) => setSindicatoComplemento(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="sindicatoCep">CEP</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={9} placeholder="CEP" id="sindicatoCep" value={sindicatoCep} onChange={(e) => setSindicatoCep(nrCepMask(e.target.value))} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="sindicatoBairro">Bairro</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={70} placeholder="Bairro" id="sindicatoBairro" value={sindicatoBairro} onChange={(e) => setSindicatoBairro(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="sindicatoCidade">Cidade</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={70} placeholder="Cidade" id="sindicatoCidade" value={sindicatoCidade} onChange={(e) => setSindicatoCidade(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="sindicatoEstado">Estado</label>
                                                                <div className="controls">
                                                                    <div className="select is-fullwidth">
                                                                        <select id="sindicatoEstado" value={sindicatoEstado} onChange={(e) => setSindicatoEstado(e.target.value)}>
                                                                            <option value=''>Selecione</option>
                                                                            <option value='Rio Grande do Norte'>Rio Grande do Norte</option>
                                                                            <option value='Acre'>Acre</option>
                                                                            <option value='Alagoas'>Alagoas</option>
                                                                            <option value='Amapá'>Amapá</option>
                                                                            <option value='Amazonas'>Amazonas</option>
                                                                            <option value='Bahia'>Bahia</option>
                                                                            <option value='Ceará'>Ceará</option>
                                                                            <option value='Distrito Federal'>Distrito Federal</option>
                                                                            <option value='Espírito Santo'>Espírito Santo</option>
                                                                            <option value='Goiás'>Goiás</option>
                                                                            <option value='Maranhão'>Maranhão</option>
                                                                            <option value='Mato Grosso'>Mato Grosso</option>
                                                                            <option value='Mato Grosso do Sul'>Mato Grosso do Sul</option>
                                                                            <option value='Minas Gerais'>Minas Gerais</option>
                                                                            <option value='Pará'>Pará</option>
                                                                            <option value='Paraíba'>Paraíba</option>
                                                                            <option value='Paraná'>Paraná</option>
                                                                            <option value='Pernambuco'>Pernambuco</option>
                                                                            <option value='Piauí'>Piauí</option>
                                                                            <option value='Rio de Janeiro'>Rio de Janeiro</option>
                                                                            <option value='Rio Grande do Sul'>Rio Grande do Sul</option>
                                                                            <option value='Rondônia'>Rondônia</option>
                                                                            <option value='Roraima'>Roraima</option>
                                                                            <option value='Santa Catarina'>Santa Catarina</option>
                                                                            <option value='São Paulo'>São Paulo</option>
                                                                            <option value='Sergipe'>Sergipe</option>
                                                                            <option value='Tocantins'>Tocantins</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="sindicatoPontoReferencia">Ponto de Referência (Batalhão PM da área)</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={191} placeholder="Ponto de Referência" id="sindicatoPontoReferencia" value={sindicatoPontoReferencia} onChange={(e) => setSindicatoPontoReferencia(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="sindicatoNotificacaoPunicao">Você desempenhava alguma atividade na Entidade ou chegou a sofrer alguma notificação ou punição pela Diretoria em razão do cometimento de alguma conduta de indisciplina?</label>
                                                                <div className="select">
                                                                    <select id="sindicatoNotificacaoPunicao" value={sindicatoNotificacaoPunicao} onChange={(e) => setSindicatoNotificacaoPunicaoDiv(e)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='sim'>Sim</option>
                                                                        <option value='não'>Não</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id='sindicatoNotificacaoPunicaoDiv' className='box' style={{ display:'none' }}>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="detalheSindicatoNotificacaoPunicao">Forneça informações detalhadas:</label>
                                                                    <div className="controls">
                                                                        <textarea maxLength={1500} rows='4' className="textarea" id='detalheSindicatoNotificacaoPunicao' name='detalheSindicatoNotificacaoPunicao' value={detalheSindicatoNotificacaoPunicao} placeholder="Forneça informações detalhadas" onChange={(e) => setDetalheSindicatoNotificacaoPunicao(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <button type="button" className="button is-info" onClick={(e) => adicionaSindicato(e)}><i className="fa fa-plus-circle"></i>&nbsp;Clique Aqui para Registrar informação do sindicato/associação de classe</button>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                {sindicatos.map((sind, index) => (
                                                                    <div key={index}>
                                                                        <article className='message'>
                                                                            <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                                <p>{index+1}. {sind.nome} ({sind.mêsInicial}/{sind.anoInicial} ~ {sind.mêsFinal}/{sind.anoFinal})</p>
                                                                                <button type="button" className='delete' aria-label='delete' onClick={(e) => removeSindicato(e, sind.nome, sind.mêsInicial, sind.anoInicial, sind.mêsFinal, sind.anoFinal, sind.pontoReferência )}></button>
                                                                            </div>
                                                                            <div className='message-body has-background-white-ter has-text-black'>
                                                                                <p>
                                                                                    Endereço: {sind.endereço} {sind.número}
                                                                                </p>
                                                                                <p>
                                                                                    Complemento: {sind.complemento};
                                                                                    CEP: {sind.cep}
                                                                                </p>
                                                                                <p>
                                                                                    Bairro: {sind.bairro};
                                                                                    Cidade: {sind.cidade};
                                                                                    Estado: {sind.estado}
                                                                                </p>
                                                                                <p>
                                                                                    Ponto de Referência: {sind.pontoReferência}
                                                                                </p>
                                                                                <p>
                                                                                    Notificação/Punição: {sind.notificaçãoPunição}
                                                                                </p>
                                                                                {sind.notificaçãoPunição === 'sim' &&
                                                                                    <div dangerouslySetInnerHTML={{ __html: sind.detalheNotificaçãoPunição.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                                }
                                                                            </div>
                                                                        </article>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="partidoPolitico">Você é ou já foi filiado a algum <u>partido político</u>?</label>
                                                    <div className="select">
                                                        <select id="partidoPolitico" value={partidoPolitico} onChange={(e) => setPartidoPoliticoDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='partidoPoliticoDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label">Forneça informações detalhadas <span style={{ fontWeight:'normal' }}>(vá adicionando clicando no botão 'Registrar informação do partido político', um partido por vez)</span>:</label>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="partidoPoliticoNome">Nome do Partido</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={191} placeholder="Nome do Partido" id="partidoPoliticoNome" value={partidoPoliticoNome} onChange={(e) => setPartidoPoliticoNome(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="partidoPoliticoMesInicial">Período de filiação - De</label>
                                                                <div className='columns'>
                                                                    <div className='column is-narrow'>
                                                                        <div className="select">
                                                                            <select id="partidoPoliticoMesInicial" value={partidoPoliticoMesInicial} onChange={(e) => setPartidoPoliticoMesInicial(e.target.value)}>
                                                                                <option value=''>Selecione</option>
                                                                                <option value='Janeiro'>Janeiro</option>
                                                                                <option value='Fevereiro'>Fevereiro</option>
                                                                                <option value='Março'>Março</option>
                                                                                <option value='Abril'>Abril</option>
                                                                                <option value='Maio'>Maio</option>
                                                                                <option value='Junho'>Junho</option>
                                                                                <option value='Julho'>Julho</option>
                                                                                <option value='Agosto'>Agosto</option>
                                                                                <option value='Setembro'>Setembro</option>
                                                                                <option value='Outubro'>Outubro</option>
                                                                                <option value='Novembro'>Novembro</option>
                                                                                <option value='Dezembro'>Dezembro</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className='column is-narrow'>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" placeholder="Ano Início" maxLength={4} id="partidoPoliticoAnoInicial" value={partidoPoliticoAnoInicial} onChange={(e) => setPartidoPoliticoAnoInicial(onlyNumber(e.target.value))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="partidoPoliticoMesFinal">Período de filiação - Até</label>
                                                                <div className='columns'>
                                                                    <div className='column is-narrow'>
                                                                        <div className="select">
                                                                            <select id="partidoPoliticoMesFinal" value={partidoPoliticoMesFinal} onChange={(e) => setPartidoPoliticoMesFinal(e.target.value)}>
                                                                                <option value=''>Selecione</option>
                                                                                <option value='Janeiro'>Janeiro</option>
                                                                                <option value='Fevereiro'>Fevereiro</option>
                                                                                <option value='Março'>Março</option>
                                                                                <option value='Abril'>Abril</option>
                                                                                <option value='Maio'>Maio</option>
                                                                                <option value='Junho'>Junho</option>
                                                                                <option value='Julho'>Julho</option>
                                                                                <option value='Agosto'>Agosto</option>
                                                                                <option value='Setembro'>Setembro</option>
                                                                                <option value='Outubro'>Outubro</option>
                                                                                <option value='Novembro'>Novembro</option>
                                                                                <option value='Dezembro'>Dezembro</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className='column is-narrow'>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" placeholder="Ano Fim" maxLength={4} id="partidoPoliticoAnoFinal" value={partidoPoliticoAnoFinal} onChange={(e) => setPartidoPoliticoAnoFinal(onlyNumber(e.target.value))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="partidoPoliticoEndereco">Endereço</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={191} placeholder="Endereço" id="partidoPoliticoEndereco" value={partidoPoliticoEndereco} onChange={(e) => setPartidoPoliticoEndereco(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='column is-narrow'>
                                                                <label className="label" htmlFor="partidoPoliticoNumero">Número</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={50} placeholder="Número" id="partidoPoliticoNumero" value={partidoPoliticoNumero} onChange={(e) => setPartidoPoliticoNumero(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="partidoPoliticoComplemento">Complemento</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={50} placeholder="Complemento" id="partidoPoliticoComplemento" value={partidoPoliticoComplemento} onChange={(e) => setPartidoPoliticoComplemento(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="partidoPoliticoCep">CEP</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={9} placeholder="CEP" id="partidoPoliticoCep" value={partidoPoliticoCep} onChange={(e) => setPartidoPoliticoCep(nrCepMask(e.target.value))} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="partidoPoliticoBairro">Bairro</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={70} placeholder="Bairro" id="partidoPoliticoBairro" value={partidoPoliticoBairro} onChange={(e) => setPartidoPoliticoBairro(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="partidoPoliticoCidade">Cidade</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={70} placeholder="Cidade" id="partidoPoliticoCidade" value={partidoPoliticoCidade} onChange={(e) => setPartidoPoliticoCidade(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="partidoPoliticoEstado">Estado</label>
                                                                <div className="controls">
                                                                    <div className="select is-fullwidth">
                                                                        <select id="partidoPoliticoEstado" value={partidoPoliticoEstado} onChange={(e) => setPartidoPoliticoEstado(e.target.value)}>
                                                                            <option value=''>Selecione</option>
                                                                            <option value='Rio Grande do Norte'>Rio Grande do Norte</option>
                                                                            <option value='Acre'>Acre</option>
                                                                            <option value='Alagoas'>Alagoas</option>
                                                                            <option value='Amapá'>Amapá</option>
                                                                            <option value='Amazonas'>Amazonas</option>
                                                                            <option value='Bahia'>Bahia</option>
                                                                            <option value='Ceará'>Ceará</option>
                                                                            <option value='Distrito Federal'>Distrito Federal</option>
                                                                            <option value='Espírito Santo'>Espírito Santo</option>
                                                                            <option value='Goiás'>Goiás</option>
                                                                            <option value='Maranhão'>Maranhão</option>
                                                                            <option value='Mato Grosso'>Mato Grosso</option>
                                                                            <option value='Mato Grosso do Sul'>Mato Grosso do Sul</option>
                                                                            <option value='Minas Gerais'>Minas Gerais</option>
                                                                            <option value='Pará'>Pará</option>
                                                                            <option value='Paraíba'>Paraíba</option>
                                                                            <option value='Paraná'>Paraná</option>
                                                                            <option value='Pernambuco'>Pernambuco</option>
                                                                            <option value='Piauí'>Piauí</option>
                                                                            <option value='Rio de Janeiro'>Rio de Janeiro</option>
                                                                            <option value='Rio Grande do Sul'>Rio Grande do Sul</option>
                                                                            <option value='Rondônia'>Rondônia</option>
                                                                            <option value='Roraima'>Roraima</option>
                                                                            <option value='Santa Catarina'>Santa Catarina</option>
                                                                            <option value='São Paulo'>São Paulo</option>
                                                                            <option value='Sergipe'>Sergipe</option>
                                                                            <option value='Tocantins'>Tocantins</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="partidoPoliticoPontoReferencia">Ponto de Referência (Batalhão PM da área)</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={191} placeholder="Ponto de Referência" id="partidoPoliticoPontoReferencia" value={partidoPoliticoPontoReferencia} onChange={(e) => setPartidoPoliticoPontoReferencia(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="partidoPoliticoFiliacaoDesfiliacao">Qual o motivo da filiação (e da desfiliação, se for o caso) partidária?</label>
                                                                <div className="controls">
                                                                    <input type="text" className="input" maxLength={191} placeholder="Qual o motivo da filiação (e da desfiliação, se for o caso) partidária?" id="partidoPoliticoFiliacaoDesfiliacao" value={partidoPoliticoFiliacaoDesfiliacao} onChange={(e) => setPartidoPoliticoFiliacaoDesfiliacao(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label" htmlFor="partidoPoliticoNotificacaoPunicao">Você desempenhava alguma atividade no Partido ou chegou a sofrer alguma notificação ou punição pela Diretoria em razão do cometimento de alguma conduta de indisciplina?</label>
                                                                <div className="select">
                                                                    <select id="partidoPoliticoNotificacaoPunicao" value={partidoPoliticoNotificacaoPunicao} onChange={(e) => setPartidoPoliticoNotificacaoPunicaoDiv(e)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='sim'>Sim</option>
                                                                        <option value='não'>Não</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id='partidoPoliticoNotificacaoPunicaoDiv' className='box' style={{ display:'none' }}>
                                                            <div className='columns'>
                                                                <div className='column'>
                                                                    <label className="label" htmlFor="detalhePartidoPoliticoNotificacaoPunicao">Forneça informações detalhadas:</label>
                                                                    <div className="controls">
                                                                        <textarea maxLength={1500} rows='4' className="textarea" id='detalhePartidoPoliticoNotificacaoPunicao' name='detalhePartidoPoliticoNotificacaoPunicao' value={detalhePartidoPoliticoNotificacaoPunicao} placeholder="Forneça informações detalhadas" onChange={(e) => setDetalhePartidoPoliticoNotificacaoPunicao(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <button type="button" className="button is-info" onClick={(e) => adicionaPartidoPolitico(e)}><i className="fa fa-plus-circle"></i>&nbsp;Clique Aqui para Registrar informação do partido político</button>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                {partidosPoliticos.map((pp, index) => (
                                                                    <div key={index}>
                                                                        <article className='message'>
                                                                            <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                                <p>{index+1}. {pp.nome} ({pp.mêsInicial}/{pp.anoInicial} ~ {pp.mêsFinal}/{pp.anoFinal})</p>
                                                                                <button type="button" className='delete' aria-label='delete' onClick={(e) => removePartidoPolitico(e, pp.nome, pp.mêsInicial, pp.anoInicial, pp.mêsFinal, pp.anoFinal, pp.pontoReferência )}></button>
                                                                            </div>
                                                                            <div className='message-body has-background-white-ter has-text-black'>
                                                                                <p>
                                                                                    Endereço: {pp.endereço} {pp.número}
                                                                                </p>
                                                                                <p>
                                                                                    Complemento: {pp.complemento};
                                                                                    CEP: {pp.cep}
                                                                                </p>
                                                                                <p>
                                                                                    Bairro: {pp.bairro};
                                                                                    Cidade: {pp.cidade};
                                                                                    Estado: {pp.estado}
                                                                                </p>
                                                                                <p>
                                                                                    Ponto de Referência: {pp.pontoReferência}
                                                                                </p>
                                                                                <p>
                                                                                    Filiação/Desfiliação: {pp.filiaçãoDesfiliação}
                                                                                </p>
                                                                                <p>
                                                                                    Notificação/Punição: {pp.notificaçãoPunição}
                                                                                </p>
                                                                                {pp.notificaçãoPunição === 'sim' &&
                                                                                    <div dangerouslySetInnerHTML={{ __html: pp.detalheNotificaçãoPunição.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                                }
                                                                            </div>
                                                                        </article>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                
                                <article className='message is-link' id='dadosConcursoDiv'>
                                    <div className='message-header'>
                                        <div className="tags are-medium has-addons">
                                            PARTE E - INFORMAÇÕES JUDICIAIS E POLICIAIS
                                        </div>
                                    </div>
                                    <div className='message-body'>
                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="processoJustica">Você já fez ou faz parte de algum <u>Processo na Justiça</u>, seja na condição de autor, indiciado, réu, testemunha ou até mesmo como vítima?</label>
                                                <div className="select">
                                                    <select id="processoJustica" value={processoJustica} onChange={(e) => setProcessoJusticaDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='processoJusticaDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Forneça informações detalhadas:</label>
                                                    <div className="controls">
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>a. Justiça Civil?</td>
                                                                            <td style={{ paddingLeft:'5px', paddingRight:'15px' }}>
                                                                                <select id="processoJusticaJC" value={processoJusticaJC} onChange={(e) => setProcessoJusticaJC(e.target.value)}>
                                                                                    <option value=''>Selecione</option>
                                                                                    <option value='sim'>Sim</option>
                                                                                    <option value='não'>Não</option>
                                                                                </select>
                                                                            </td>
                                                                            <td>d. Justiça Militar?</td>
                                                                            <td style={{ paddingLeft:'5px', paddingRight:'15px' }}>
                                                                                <select id="processoJusticaJM" value={processoJusticaJM} onChange={(e) => setProcessoJusticaJM(e.target.value)}>
                                                                                    <option value=''>Selecione</option>
                                                                                    <option value='sim'>Sim</option>
                                                                                    <option value='não'>Não</option>
                                                                                </select>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>b. Justiça Trabalhista?</td>
                                                                            <td style={{ paddingLeft:'5px', paddingRight:'15px' }}>
                                                                                <select id="processoJusticaJT" value={processoJusticaJT} onChange={(e) => setProcessoJusticaJT(e.target.value)}>
                                                                                    <option value=''>Selecione</option>
                                                                                    <option value='sim'>Sim</option>
                                                                                    <option value='não'>Não</option>
                                                                                </select>
                                                                            </td>
                                                                            <td>e. Vara da Infância e Juventude?</td>
                                                                            <td style={{ paddingLeft:'5px', paddingRight:'15px' }}>
                                                                                <select id="processoJusticaVIJ" value={processoJusticaVIJ} onChange={(e) => setProcessoJusticaVIJ(e.target.value)}>
                                                                                    <option value=''>Selecione</option>
                                                                                    <option value='sim'>Sim</option>
                                                                                    <option value='não'>Não</option>
                                                                                </select>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>c. Justiça Criminal?</td>
                                                                            <td style={{ paddingLeft:'5px', paddingRight:'15px' }}>
                                                                                <select id="processoJusticaJCR" value={processoJusticaJCR} onChange={(e) => setProcessoJusticaJCR(e.target.value)}>
                                                                                    <option value=''>Selecione</option>
                                                                                    <option value='sim'>Sim</option>
                                                                                    <option value='não'>Não</option>
                                                                                </select>
                                                                            </td>
                                                                            <td>f. Juizado Especial Criminal?</td>
                                                                            <td style={{ paddingLeft:'5px', paddingRight:'15px' }}>
                                                                                <select id="processoJusticaJEC" value={processoJusticaJEC} onChange={(e) => setProcessoJusticaJEC(e.target.value)}>
                                                                                    <option value=''>Selecione</option>
                                                                                    <option value='sim'>Sim</option>
                                                                                    <option value='não'>Não</option>
                                                                                </select>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className='columns'>
                                                            <div className='column'>
                                                                <label className="label">Adicione as seguintes informações sobre os processos <span style={{ fontWeight:'normal' }}>(vá adicionando clicando no botão 'Registrar informação do processo', um processo por vez)</span>:</label>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="processoJusticaTipo">Tipo do Processo</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={50} placeholder="Tipo do Processo" id="processoJusticaTipo" value={processoJusticaTipo} onChange={(e) => setProcessoJusticaTipo(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="processoJusticaCondicao">Condição</label>
                                                                        <div className="controls">
                                                                            <div className="select is-fullwidth">
                                                                                <select id="processoJusticaCondicao" value={processoJusticaCondicao} onChange={(e) => setProcessoJusticaCondicao(e.target.value)}>
                                                                                    <option value=''>Selecione</option>
                                                                                    <option value='Autor'>Autor</option>
                                                                                    <option value='Réu'>Réu</option>
                                                                                    <option value='Testemunha'>Testemunha</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="processoJusticaDataFato">Data do fato</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={10} placeholder="Data do fato" id="processoJusticaDataFato" value={processoJusticaDataFato} onChange={(e) => setProcessoJusticaDataFato(dataBr(e.target.value))} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="processoJusticaNumeroAno">Número e ano do Processo</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={50} placeholder="Número e ano do Processo" id="processoJusticaNumeroAno" value={processoJusticaNumeroAno} onChange={(e) => setProcessoJusticaNumeroAno(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="processoJusticaBairro">Bairro</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={50} placeholder="Bairro" id="processoJusticaBairro" value={processoJusticaBairro} onChange={(e) => setProcessoJusticaBairro(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="processoJusticaCidade">Cidade</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={70} placeholder="Cidade" id="processoJusticaCidade" value={processoJusticaCidade} onChange={(e) => setProcessoJusticaCidade(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="processoJusticaEstado">Estado</label>
                                                                        <div className="controls">
                                                                            <div className="select is-fullwidth">
                                                                                <select id="processoJusticaEstado" value={processoJusticaEstado} onChange={(e) => setProcessoJusticaEstado(e.target.value)}>
                                                                                    <option value=''>Selecione</option>
                                                                                    <option value='Rio Grande do Norte'>Rio Grande do Norte</option>
                                                                                    <option value='Acre'>Acre</option>
                                                                                    <option value='Alagoas'>Alagoas</option>
                                                                                    <option value='Amapá'>Amapá</option>
                                                                                    <option value='Amazonas'>Amazonas</option>
                                                                                    <option value='Bahia'>Bahia</option>
                                                                                    <option value='Ceará'>Ceará</option>
                                                                                    <option value='Distrito Federal'>Distrito Federal</option>
                                                                                    <option value='Espírito Santo'>Espírito Santo</option>
                                                                                    <option value='Goiás'>Goiás</option>
                                                                                    <option value='Maranhão'>Maranhão</option>
                                                                                    <option value='Mato Grosso'>Mato Grosso</option>
                                                                                    <option value='Mato Grosso do Sul'>Mato Grosso do Sul</option>
                                                                                    <option value='Minas Gerais'>Minas Gerais</option>
                                                                                    <option value='Pará'>Pará</option>
                                                                                    <option value='Paraíba'>Paraíba</option>
                                                                                    <option value='Paraná'>Paraná</option>
                                                                                    <option value='Pernambuco'>Pernambuco</option>
                                                                                    <option value='Piauí'>Piauí</option>
                                                                                    <option value='Rio de Janeiro'>Rio de Janeiro</option>
                                                                                    <option value='Rio Grande do Sul'>Rio Grande do Sul</option>
                                                                                    <option value='Rondônia'>Rondônia</option>
                                                                                    <option value='Roraima'>Roraima</option>
                                                                                    <option value='Santa Catarina'>Santa Catarina</option>
                                                                                    <option value='São Paulo'>São Paulo</option>
                                                                                    <option value='Sergipe'>Sergipe</option>
                                                                                    <option value='Tocantins'>Tocantins</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="processoJusticaForum">Fórum</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={100} placeholder="Fórum" id="processoJusticaForum" value={processoJusticaForum} onChange={(e) => setProcessoJusticaForum(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="processoJusticaVara">Vara</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={100} placeholder="Vara" id="processoJusticaVara" value={processoJusticaVara} onChange={(e) => setProcessoJusticaVara(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="processoJusticaSolucaoPena">Solução ou pena aplicada</label>
                                                                        <div className="controls">
                                                                            <input type="text" className="input" maxLength={191} placeholder="Solução ou pena aplicada" id="processoJusticaSolucaoPena" value={processoJusticaSolucaoPena} onChange={(e) => setProcessoJusticaSolucaoPena(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <label className="label" htmlFor="processoJusticaDetalheFato">Relate detalhadamente o fato ocorrido (motivo do Processo)</label>
                                                                        <div className="controls">
                                                                            <textarea maxLength={1500} rows='3' className="textarea" id='processoJusticaDetalheFato' name='processoJusticaDetalheFato' value={processoJusticaDetalheFato} placeholder="Relate detalhadamente o fato ocorrido (motivo do Processo)" onChange={(e) => setProcessoJusticaDetalheFato(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        <button type="button" className="button is-info" onClick={(e) => adicionaProcessoJustica(e)}><i className="fa fa-plus-circle"></i>&nbsp;Clique Aqui para Registrar informação do processo</button>
                                                                    </div>
                                                                </div>
                                                                <div className='columns'>
                                                                    <div className='column'>
                                                                        {processosJustica.map((pj, index) => (
                                                                            <div key={index}>
                                                                                <article className='message is-dark'>
                                                                                    <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                                        <p>{index+1}. [{pj.númeroAno}] {pj.tipo} ({pj.condição})</p>
                                                                                        <button type="button" className='delete' aria-label='delete' onClick={(e) => removeProcessoJustica(e, pj.númeroAno, pj.tipo, pj.dataFato, pj.condição )}></button>
                                                                                    </div>
                                                                                    <div className='message-body has-background-white-ter has-text-black'>
                                                                                        <p>
                                                                                            Data do Fato: {pj.dataFato}
                                                                                        </p>
                                                                                        <p>
                                                                                            Bairro: {pj.bairro};
                                                                                            Cidade: {pj.cidade};
                                                                                            Estado: {pj.estado}
                                                                                        </p>
                                                                                        <p>
                                                                                            Fórum: {pj.fórum}
                                                                                        </p>
                                                                                        <p>
                                                                                            Vara: {pj.vara}
                                                                                        </p>
                                                                                        <p>
                                                                                            Solução/Pena: {pj.soluçãoPena}
                                                                                        </p>
                                                                                        Detalhe do fato ocorrido:
                                                                                            <div dangerouslySetInnerHTML={{ __html: pj.detalheFatoOcorrido.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                                    </div>
                                                                                </article>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="ocorrenciaPolicial">Você já esteve envolvido em <u>ocorrência policial</u>? (Informe qualquer tipo de ocorrência, seja quando você era criança, adolescente ou adulto, seja na condição de <u>autor</u>, <u>indiciado</u>, <u>réu</u>, <u>testemunha</u> ou até mesmo como <u>vítima</u>)</label>
                                                <div className="select">
                                                    <select id="ocorrenciaPolicial" value={ocorrenciaPolicial} onChange={(e) => setOcorrenciaPolicialDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='ocorrenciaPolicialDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Adicione as seguintes informações sobre a ocorrência policial <span style={{ fontWeight:'normal' }}>(vá adicionando clicando no botão 'Registrar informação da ocorrência policial', uma ocorrência por vez)</span>:</label>
                                                    <div className='columns'>
                                                        <div className='column is-narrow'>
                                                            <label className="label" htmlFor="ocorrenciaPolicialDataFato">Data do fato</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={10} placeholder="Data do fato" id="ocorrenciaPolicialDataFato" value={ocorrenciaPolicialDataFato} onChange={(e) => setOcorrenciaPolicialDataFato(dataBr(e.target.value))} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="ocorrenciaPolicialCondicao">Qual a condição em que você figurou na ocorrência</label>
                                                            <div className="controls">
                                                                <div className="select is-fullwidth">
                                                                    <select id="ocorrenciaPolicialCondicao" value={ocorrenciaPolicialCondicao} onChange={(e) => setOcorrenciaPolicialCondicao(e.target.value)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='Indiciado'>Indiciado</option>
                                                                        <option value='Réu'>Réu</option>
                                                                        <option value='Vítima'>Vítima</option>
                                                                        <option value='Testemunha'>Testemunha</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="ocorrenciaPolicialDelegaciaQuartel">Delegacia de Polícia ou Quartel para onde você foi conduzido</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={70} placeholder="Delegacia de Polícia ou Quartel para onde você foi conduzido" id="ocorrenciaPolicialDelegaciaQuartel" value={ocorrenciaPolicialDelegaciaQuartel} onChange={(e) => setOcorrenciaPolicialDelegaciaQuartel(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="ocorrenciaPolicialRpBo">Número do registro policial (Boletim de Ocorrência ou outro documento) elaborado</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={70} placeholder="Número do registro policial (Boletim de Ocorrência ou outro documento) elaborado" id="ocorrenciaPolicialRpBo" value={ocorrenciaPolicialRpBo} onChange={(e) => setOcorrenciaPolicialRpBo(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="ocorrenciaPolicialSolucaoPena">Solução ou pena aplicada</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={191} placeholder="Solução ou pena aplicada" id="ocorrenciaPolicialSolucaoPena" value={ocorrenciaPolicialSolucaoPena} onChange={(e) => setOcorrenciaPolicialSolucaoPena(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="ocorrenciaPolicialDetalheFato">Relate detalhadamente o fato ocorrido (motivo da Ocorrência)</label>
                                                            <div className="controls">
                                                                <textarea maxLength={1500} rows='3' className="textarea" id='ocorrenciaPolicialDetalheFato' name='ocorrenciaPolicialDetalheFato' value={ocorrenciaPolicialDetalheFato} placeholder="Relate detalhadamente o fato ocorrido (motivo da Ocorrência)" onChange={(e) => setOcorrenciaPolicialDetalheFato(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <button type="button" className="button is-info" onClick={(e) => adicionaOcorrenciaPolicial(e)}><i className="fa fa-plus-circle"></i>&nbsp;Clique Aqui para Registrar informação da ocorrência policial</button>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            {ocorrenciasPoliciais.map((op, index) => (
                                                                <div key={index}>
                                                                    <article className='message is-dark'>
                                                                        <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                            <p>{index+1}. {op.rpBo} ({op.condição})</p>
                                                                            <button type="button" className='delete' aria-label='delete' onClick={(e) => removeOcorrenciaPolicial(e, op.dataFato, op.condição, op.rpBo, op.delegaciaQuartel )}></button>
                                                                        </div>
                                                                        <div className='message-body has-background-white-ter has-text-black'>
                                                                            <p>
                                                                                Data do Fato: {op.dataFato}
                                                                            </p>
                                                                            <p>
                                                                                Registro Policial/Boletim Ocorrência: {op.rpBo}
                                                                            </p>
                                                                            <p>
                                                                                Delegacia/Quartel: {op.delegaciaQuartel}
                                                                            </p>
                                                                            <p>
                                                                                Solução/Pena: {op.soluçãoPena}
                                                                            </p>
                                                                            Detalhe do fato ocorrido:
                                                                                <div dangerouslySetInnerHTML={{ __html: op.detalheFatoOcorrido.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                        </div>
                                                                    </article>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="ipSindIpm">Você já esteve envolvido em ou <u>Investigação Preliminar (IP)</u>, <u>Sindicância</u> ou <u>Inquérito Policial Militar (IPM)</u>?</label>
                                                <div className="select">
                                                    <select id="ipSindIpm" value={ipSindIpm} onChange={(e) => setIpSindIpmDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='ipSindIpmDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Adicione as seguintes informações sobre a Investigação Preliminar (IP), Sindicância ou Inquérito Policial Militar (IPM) <span style={{ fontWeight:'normal' }}>(vá adicionando clicando no botão 'Registrar informação da I.P./Sindicância/I.P.M.', uma por vez)</span>:</label>
                                                    <div className='columns'>
                                                        <div className='column is-narrow'>
                                                            <label className="label" htmlFor="ipSindIpmDataFato">Data do fato</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={10} placeholder="Data do fato" id="ipSindIpmDataFato" value={ipSindIpmDataFato} onChange={(e) => setIpSindIpmDataFato(dataBr(e.target.value))} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="ipSindIpmCondicao">Qual a condição em que você figurou no Procedimento</label>
                                                            <div className="controls">
                                                                <div className="select is-fullwidth">
                                                                    <select id="ipSindIpmCondicao" value={ipSindIpmCondicao} onChange={(e) => setIpSindIpmCondicao(e.target.value)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='Comunicante'>Comunicante</option>
                                                                        <option value='Vítima'>Vítima</option>
                                                                        <option value='Autor/infrator'>Autor/infrator</option>
                                                                        <option value='Testemunha'>Testemunha</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="ipSindIpmNrProcedimento">Número do Procedimento instaurado</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={70} placeholder="Número do Procedimento instaurado" id="ipSindIpmNrProcedimento" value={ipSindIpmNrProcedimento} onChange={(e) => setIpSindIpmNrProcedimento(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="ipSindIpmSolucao">Qual a Solução aplicada ao final do Procedimento</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={191} placeholder="Qual a Solução aplicada ao final do Procedimento" id="ipSindIpmSolucao" value={ipSindIpmSolucao} onChange={(e) => setIpSindIpmSolucao(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="ipSindIpmDetalheFato">Relate detalhadamente o fato ocorrido (motivação do Procedimento)</label>
                                                            <div className="controls">
                                                                <textarea maxLength={1500} rows='3' className="textarea" id='ipSindIpmDetalheFato' name='ipSindIpmDetalheFato' value={ipSindIpmDetalheFato} placeholder="Relate detalhadamente o fato ocorrido (motivação do Procedimento)" onChange={(e) => setIpSindIpmDetalheFato(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <button type="button" className="button is-info" onClick={(e) => adicionaIpSindIpm(e)}><i className="fa fa-plus-circle"></i>&nbsp;Clique Aqui para Registrar informação da I.P./Sindicância/I.P.M.</button>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            {listaIpSindIpm.map((isi, index) => (
                                                                <div key={index}>
                                                                    <article className='message is-dark'>
                                                                        <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                            <p>{index+1}. {isi.nrProcedimento} ({isi.condição})</p>
                                                                            <button type="button" className='delete' aria-label='delete' onClick={(e) => removeIpSindIpm(e, isi.dataFato, isi.condição, isi.nrProcedimento )}></button>
                                                                        </div>
                                                                        <div className='message-body has-background-white-ter has-text-black'>
                                                                            <p>
                                                                                Data do Fato: {isi.dataFato}
                                                                            </p>
                                                                            <p>
                                                                                Solução: {isi.solução}
                                                                            </p>
                                                                            Detalhe do fato ocorrido:
                                                                                <div dangerouslySetInnerHTML={{ __html: isi.detalheFatoOcorrido.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                        </div>
                                                                    </article>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="padCdCj">Você já esteve alguma vez envolvido em <u>Processo Administrativo Disciplinar (PAD)</u>, <u>Conselho de Disciplina (CD)</u> ou <u>Conselho de Justificação (CJ)</u>?</label>
                                                <div className="select">
                                                    <select id="padCdCj" value={padCdCj} onChange={(e) => setPadCdCjDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='padCdCjDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Adicione as seguintes informações sobre o Processo Administrativo Disciplinar (PAD), Conselho de Disciplina (CD) ou Conselho de Justificação (CJ) <span style={{ fontWeight:'normal' }}>(vá adicionando clicando no botão 'Registrar informação do P.A.D./C.D./C.J.', um por vez)</span>:</label>
                                                    <div className='columns'>
                                                        <div className='column is-narrow'>
                                                            <label className="label" htmlFor="padCdCjDataFato">Data do fato</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={10} placeholder="Data do fato" id="padCdCjDataFato" value={padCdCjDataFato} onChange={(e) => setPadCdCjDataFato(dataBr(e.target.value))} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="padCdCjCondicao">Qual a condição em que você figurou no Procedimento</label>
                                                            <div className="controls">
                                                                <div className="select is-fullwidth">
                                                                    <select id="padCdCjCondicao" value={padCdCjCondicao} onChange={(e) => setPadCdCjCondicao(e.target.value)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='Indiciado'>Indiciado</option>
                                                                        <option value='Réu'>Réu</option>
                                                                        <option value='Vítima'>Vítima</option>
                                                                        <option value='Testemunha'>Testemunha</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="padCdCjNrProcedimento">Número do Procedimento instaurado</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={70} placeholder="Número do Procedimento instaurado" id="padCdCjNrProcedimento" value={padCdCjNrProcedimento} onChange={(e) => setPadCdCjNrProcedimento(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="padCdCjSolucao">Qual a Solução aplicada ao final do Procedimento</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={191} placeholder="Qual a Solução aplicada ao final do Procedimento" id="padCdCjSolucao" value={padCdCjSolucao} onChange={(e) => setPadCdCjSolucao(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="padCdCjDetalheFato">Relate detalhadamente o fato ocorrido (motivação do Procedimento)</label>
                                                            <div className="controls">
                                                                <textarea maxLength={1500} rows='3' className="textarea" id='padCdCjDetalheFato' name='padCdCjDetalheFato' value={padCdCjDetalheFato} placeholder="Relate detalhadamente o fato ocorrido (motivação do Procedimento)" onChange={(e) => setPadCdCjDetalheFato(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <button type="button" className="button is-info" onClick={(e) => adicionaPadCdCj(e)}><i className="fa fa-plus-circle"></i>&nbsp;Clique Aqui para Registrar informação do P.A.D./C.D./C.J.</button>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            {listaPadCdCj.map((pcc, index) => (
                                                                <div key={index}>
                                                                    <article className='message is-dark'>
                                                                        <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                            <p>{index+1}. {pcc.nrProcedimento} ({pcc.condição})</p>
                                                                            <button type="button" className='delete' aria-label='delete' onClick={(e) => removePadCdCj(e, pcc.dataFato, pcc.condição, pcc.nrProcedimento )}></button>
                                                                        </div>
                                                                        <div className='message-body has-background-white-ter has-text-black'>
                                                                            <p>
                                                                                Data do Fato: {pcc.dataFato}
                                                                            </p>
                                                                            <p>
                                                                                Solução: {pcc.solução}
                                                                            </p>
                                                                            Detalhe do fato ocorrido:
                                                                                <div dangerouslySetInnerHTML={{ __html: pcc.detalheFatoOcorrido.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                        </div>
                                                                    </article>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="familiaOpPj">Algum <u>membro de sua família</u> já esteve ou está envolvido em ocorrência policial ou com algum Processo na Justiça?</label>
                                                <div className="select">
                                                    <select id="familiaOpPj" value={familiaOpPj} onChange={(e) => setFamiliaOpPjDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='familiaOpPjDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Adicione as seguintes informações <span style={{ fontWeight:'normal' }}>(vá adicionando clicando no botão 'Registrar informação do membro da família', um membro familiar por vez)</span>:</label>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiaOpPjNome">Nome</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={191} placeholder="Nome" id="familiaOpPjNome" value={familiaOpPjNome} onChange={(e) => setFamiliaOpPjNome(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiaOpPjGrauParentesco">Grau de parentesco</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={20} placeholder="Grau de parentesco" id="familiaOpPjGrauParentesco" value={familiaOpPjGrauParentesco} onChange={(e) => setFamiliaOpPjGrauParentesco(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiaOpPjCondicao">Condição</label>
                                                            <div className="controls">
                                                                <div className="select is-fullwidth">
                                                                    <select id="familiaOpPjCondicao" value={familiaOpPjCondicao} onChange={(e) => setFamiliaOpPjCondicao(e.target.value)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='Indiciado'>Indiciado</option>
                                                                        <option value='Réu'>Réu</option>
                                                                        <option value='Vítima'>Vítima</option>
                                                                        <option value='Testemunha'>Testemunha</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiaOpPjDataFato">Data do fato</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={10} placeholder="Data do fato" id="familiaOpPjDataFato" value={familiaOpPjDataFato} onChange={(e) => setFamiliaOpPjDataFato(dataBr(e.target.value))} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiaOpPjTipoProcesso">Tipo do Processo</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={70} placeholder="Tipo do Processo" id="familiaOpPjTipoProcesso" value={familiaOpPjTipoProcesso} onChange={(e) => setFamiliaOpPjTipoProcesso(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiaOpPjCpf">CPF</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={14} placeholder="Número do CPF" id="familiaOpPjCpf" value={familiaOpPjCpf} onChange={(e) => setFamiliaOpPjCpf(cpfMask(e.target.value))} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiaOpPjRg">RG</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={50} placeholder="Número do RG" id="familiaOpPjRg" value={familiaOpPjRg} onChange={(e) => setFamiliaOpPjRg(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiaOpPjEndereco">Endereço do familiar</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={191} placeholder="Endereço do familiar" id="familiaOpPjEndereco" value={familiaOpPjEndereco} onChange={(e) => setFamiliaOpPjEndereco(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='column is-narrow'>
                                                            <label className="label" htmlFor="familiaOpPjNumero">Número</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={20} placeholder="Número" id="familiaOpPjNumero" value={familiaOpPjNumero} onChange={(e) => setFamiliaOpPjNumero(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiaOpPjBairro">Bairro</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={50} placeholder="Bairro" id="familiaOpPjBairro" value={familiaOpPjBairro} onChange={(e) => setFamiliaOpPjBairro(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiaOpPjCidade">Cidade</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={70} placeholder="Cidade" id="familiaOpPjCidade" value={familiaOpPjCidade} onChange={(e) => setFamiliaOpPjCidade(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiaOpPjEstado">Estado</label>
                                                            <div className="controls">
                                                                <div className="select is-fullwidth">
                                                                    <select id="familiaOpPjEstado" value={familiaOpPjEstado} onChange={(e) => setFamiliaOpPjEstado(e.target.value)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='Rio Grande do Norte'>Rio Grande do Norte</option>
                                                                        <option value='Acre'>Acre</option>
                                                                        <option value='Alagoas'>Alagoas</option>
                                                                        <option value='Amapá'>Amapá</option>
                                                                        <option value='Amazonas'>Amazonas</option>
                                                                        <option value='Bahia'>Bahia</option>
                                                                        <option value='Ceará'>Ceará</option>
                                                                        <option value='Distrito Federal'>Distrito Federal</option>
                                                                        <option value='Espírito Santo'>Espírito Santo</option>
                                                                        <option value='Goiás'>Goiás</option>
                                                                        <option value='Maranhão'>Maranhão</option>
                                                                        <option value='Mato Grosso'>Mato Grosso</option>
                                                                        <option value='Mato Grosso do Sul'>Mato Grosso do Sul</option>
                                                                        <option value='Minas Gerais'>Minas Gerais</option>
                                                                        <option value='Pará'>Pará</option>
                                                                        <option value='Paraíba'>Paraíba</option>
                                                                        <option value='Paraná'>Paraná</option>
                                                                        <option value='Pernambuco'>Pernambuco</option>
                                                                        <option value='Piauí'>Piauí</option>
                                                                        <option value='Rio de Janeiro'>Rio de Janeiro</option>
                                                                        <option value='Rio Grande do Sul'>Rio Grande do Sul</option>
                                                                        <option value='Rondônia'>Rondônia</option>
                                                                        <option value='Roraima'>Roraima</option>
                                                                        <option value='Santa Catarina'>Santa Catarina</option>
                                                                        <option value='São Paulo'>São Paulo</option>
                                                                        <option value='Sergipe'>Sergipe</option>
                                                                        <option value='Tocantins'>Tocantins</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiaOpPjSolucaoPena">Solução ou Pena aplicada</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={191} placeholder="Solução ou Pena aplicada" id="familiaOpPjSolucaoPena" value={familiaOpPjSolucaoPena} onChange={(e) => setFamiliaOpPjSolucaoPena(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="familiaOpPjFatoOcorrido">Relate o fato ocorrido (motivo do Processo)</label>
                                                            <div className="controls">
                                                                <textarea maxLength={1500} rows='3' className="textarea" id='familiaOpPjFatoOcorrido' name='familiaOpPjFatoOcorrido' value={familiaOpPjFatoOcorrido} placeholder="Relate o fato ocorrido (motivo do Processo)" onChange={(e) => setFamiliaOpPjFatoOcorrido(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <button type="button" className="button is-info" onClick={(e) => adicionaFamiliaOpPj(e)}><i className="fa fa-plus-circle"></i>&nbsp;Clique Aqui para Registrar informação do membro da família</button>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            {familiasOpPj.map((familiar, index) => (
                                                                <div key={index}>
                                                                    <article className='message is-dark'>
                                                                        <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                            <p>{index+1}. {familiar.nome} ({familiar.condição})</p>
                                                                            <button type="button" className='delete' aria-label='delete' onClick={(e) => removeFamiliaOpPj(e, familiar.nome, familiar.grauParentesco, familiar.cpf, familiar.rg, familiar.condição )}></button>
                                                                        </div>
                                                                        <div className='message-body has-background-white-ter has-text-black'>
                                                                            <p>
                                                                                Grau de Parentesco: {familiar.grauParentesco}
                                                                            </p>
                                                                            <p>
                                                                                Data do Fato: {familiar.dataFato};
                                                                                Tipo do Processo: {familiar.tipoProcesso}
                                                                            </p>
                                                                            <p>
                                                                                CPF: {familiar.cpf};
                                                                                RG: {familiar.rg}
                                                                            </p>
                                                                            <p>
                                                                                Endereço do familiar: {familiar.endereço} {familiar.número}
                                                                            </p>
                                                                            <p>
                                                                                Bairro: {familiar.bairro};
                                                                                Cidade: {familiar.cidade};
                                                                                Estado: {familiar.estado}
                                                                            </p>
                                                                            <p>
                                                                                Solução/Pena: {familiar.soluçãoPena}
                                                                            </p>
                                                                            Fato ocorrido:
                                                                                <div dangerouslySetInnerHTML={{ __html: familiar.fatoOcorrido.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                        </div>
                                                                    </article>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="armaDeFogo">Você possui ou já possuiu <u>arma de fogo</u>?</label>
                                                <div className="select">
                                                    <select id="armaDeFogo" value={armaDeFogo} onChange={(e) => setArmaDeFogoDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='armaDeFogoDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="detalheArmaDeFogo">Forneça informações detalhadas, relatando sobre os seguintes quesitos:
                                                    <span style={{ fontWeight:'normal' }}><br/>a. Espécie do armamento<br/>b. Marca, modelo e calibre da arma<br/>c. Número do armamento<br/>d. Número do registro e data de validade<br/>e. Número do documento de porte e validade<br/>f. De quem você adquiriu a arma ? De um parente, amigo(a) ou conhecido?<br/>g. Nome<br/>h. Número do CPF<br/>i. Número do RG<br/>j. Endereço (Rua, número, bairro, cidade, estado)</span></label>
                                                    <div className="controls">
                                                        <textarea maxLength={4500} rows='10' className="textarea" id='detalheArmaDeFogo' name='detalheArmaDeFogo' value={detalheArmaDeFogo} placeholder="" onChange={(e) => setDetalheArmaDeFogo(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="armaDeFogoApreendida">Você já teve <u>arma apreendida</u>?</label>
                                                    <div className="select">
                                                        <select id="armaDeFogoApreendida" value={armaDeFogoApreendida} onChange={(e) => setArmaDeFogoApreendidaDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='armaDeFogoApreendidaDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheArmaDeFogoApreendida">Forneça informações detalhadas</label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='3' className="textarea" id='detalheArmaDeFogoApreendida' name='detalheArmaDeFogoApreendida' value={detalheArmaDeFogoApreendida} placeholder="Forneça informações detalhadas sobre a arma apreendida" onChange={(e) => setDetalheArmaDeFogoApreendida(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                
                                <article className='message is-link' id='dadosConcursoDiv'>
                                    <div className='message-header'>
                                        <div className="tags are-medium has-addons">
                                            PARTE F - INFORMAÇÕES PROFISSIONAIS
                                        </div>
                                    </div>
                                    <div className='message-body'>
                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label">Relacione, a partir das datas mais antigas, todos os <u>lugares em que você esteve empregado, até o atual</u>, registrado ou não, bem como os por conta própria e serviços informais ("bicos"). NÃO DEIXE DE DECLARAR NENHUM EMPREGO <span style={{ fontWeight:'normal' }}>(vá adicionando clicando no botão 'Registrar informação do trabalho, da atividade desenvolvida', um trabalho/atividade por vez)</span>. Informe os endereços por completo. Informe também os períodos (datas) de inatividade entre cada emprego. <u>ATENÇÃO</u>: Caso você tenha trabalhado em empresa terceirizada, informe o <u>endereço do escritório</u> da empresa e também o <u>endereço do local onde você prestou serviços</u> para a empresa que o contratou:</label>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEmpregoEmpresa">Empresa</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={191} placeholder="Empresa" id="detalheEmpregoEmpresa" value={detalheEmpregoEmpresa} onChange={(e) => setDetalheEmpregoEmpresa(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEmpregoCnpj">CNPJ</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={50} placeholder="CNPJ" id="detalheEmpregoCnpj" value={detalheEmpregoCnpj} onChange={(e) => setDetalheEmpregoCnpj(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <div className="controls">
                                                            <label className="label" htmlFor="detalheEmpregoSedeMatriz"><input type="radio" id="detalheEmpregoSedeMatriz" value={'matriz'} onChange={(e) => setDetalheEmpregoSedeRadio(e)} /> Sede Matriz</label>
                                                        </div>
                                                    </div>
                                                    <div className='column'>
                                                        <div className="controls">
                                                            <label className="label" htmlFor="detalheEmpregoSedeFilial"><input type="radio" id="detalheEmpregoSedeFilial" value={'filial'} onChange={(e) => setDetalheEmpregoSedeRadio(e)} /> Instalação Filial</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEmpregoMesInicial">Período em que Trabalhou - De</label>
                                                        <div className='columns'>
                                                            <div className='column is-narrow'>
                                                                <div className="select">
                                                                    <select id="detalheEmpregoMesInicial" value={detalheEmpregoMesInicial} onChange={(e) => setDetalheEmpregoMesInicial(e.target.value)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='Janeiro'>Janeiro</option>
                                                                        <option value='Fevereiro'>Fevereiro</option>
                                                                        <option value='Março'>Março</option>
                                                                        <option value='Abril'>Abril</option>
                                                                        <option value='Maio'>Maio</option>
                                                                        <option value='Junho'>Junho</option>
                                                                        <option value='Julho'>Julho</option>
                                                                        <option value='Agosto'>Agosto</option>
                                                                        <option value='Setembro'>Setembro</option>
                                                                        <option value='Outubro'>Outubro</option>
                                                                        <option value='Novembro'>Novembro</option>
                                                                        <option value='Dezembro'>Dezembro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='column is-narrow'>
                                                                <div className="controls">
                                                                    <input type="text" className="input" placeholder="Ano Início" maxLength={4} id="detalheEmpregoAnoInicial" value={detalheEmpregoAnoInicial} onChange={(e) => setDetalheEmpregoAnoInicial(onlyNumber(e.target.value))} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEmpregoMesFinal">Período em que Trabalhou - Até</label>
                                                        <div className='columns'>
                                                            <div className='column is-narrow'>
                                                                <div className="select">
                                                                    <select id="detalheEmpregoMesFinal" value={detalheEmpregoMesFinal} onChange={(e) => setDetalheEmpregoMesFinal(e.target.value)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='Janeiro'>Janeiro</option>
                                                                        <option value='Fevereiro'>Fevereiro</option>
                                                                        <option value='Março'>Março</option>
                                                                        <option value='Abril'>Abril</option>
                                                                        <option value='Maio'>Maio</option>
                                                                        <option value='Junho'>Junho</option>
                                                                        <option value='Julho'>Julho</option>
                                                                        <option value='Agosto'>Agosto</option>
                                                                        <option value='Setembro'>Setembro</option>
                                                                        <option value='Outubro'>Outubro</option>
                                                                        <option value='Novembro'>Novembro</option>
                                                                        <option value='Dezembro'>Dezembro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='column is-narrow'>
                                                                <div className="controls">
                                                                    <input type="text" className="input" placeholder="Ano Fim" maxLength={4} id="detalheEmpregoAnoFinal" value={detalheEmpregoAnoFinal} onChange={(e) => setDetalheEmpregoAnoFinal(onlyNumber(e.target.value))} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEmpregoEndereco">Endereço (Rua, Número, Complemento)</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={191} placeholder="Endereço (Rua, Número, Complemento)" id="detalheEmpregoEndereco" value={detalheEmpregoEndereco} onChange={(e) => setDetalheEmpregoEndereco(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEmpregoBairro">Bairro</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={70} placeholder="Bairro" id="detalheEmpregoBairro" value={detalheEmpregoBairro} onChange={(e) => setDetalheEmpregoBairro(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEmpregoCidade">Cidade</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={70} placeholder="Cidade" id="detalheEmpregoCidade" value={detalheEmpregoCidade} onChange={(e) => setDetalheEmpregoCidade(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEmpregoEstado">Estado</label>
                                                        <div className="controls">
                                                            <div className="select is-fullwidth">
                                                                <select id="detalheEmpregoEstado" value={detalheEmpregoEstado} onChange={(e) => setDetalheEmpregoEstado(e.target.value)}>
                                                                    <option value=''>Selecione</option>
                                                                    <option value='Rio Grande do Norte'>Rio Grande do Norte</option>
                                                                    <option value='Acre'>Acre</option>
                                                                    <option value='Alagoas'>Alagoas</option>
                                                                    <option value='Amapá'>Amapá</option>
                                                                    <option value='Amazonas'>Amazonas</option>
                                                                    <option value='Bahia'>Bahia</option>
                                                                    <option value='Ceará'>Ceará</option>
                                                                    <option value='Distrito Federal'>Distrito Federal</option>
                                                                    <option value='Espírito Santo'>Espírito Santo</option>
                                                                    <option value='Goiás'>Goiás</option>
                                                                    <option value='Maranhão'>Maranhão</option>
                                                                    <option value='Mato Grosso'>Mato Grosso</option>
                                                                    <option value='Mato Grosso do Sul'>Mato Grosso do Sul</option>
                                                                    <option value='Minas Gerais'>Minas Gerais</option>
                                                                    <option value='Pará'>Pará</option>
                                                                    <option value='Paraíba'>Paraíba</option>
                                                                    <option value='Paraná'>Paraná</option>
                                                                    <option value='Pernambuco'>Pernambuco</option>
                                                                    <option value='Piauí'>Piauí</option>
                                                                    <option value='Rio de Janeiro'>Rio de Janeiro</option>
                                                                    <option value='Rio Grande do Sul'>Rio Grande do Sul</option>
                                                                    <option value='Rondônia'>Rondônia</option>
                                                                    <option value='Roraima'>Roraima</option>
                                                                    <option value='Santa Catarina'>Santa Catarina</option>
                                                                    <option value='São Paulo'>São Paulo</option>
                                                                    <option value='Sergipe'>Sergipe</option>
                                                                    <option value='Tocantins'>Tocantins</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEmpregoCep">CEP</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={9} placeholder="CEP" id="detalheEmpregoCep" value={detalheEmpregoCep} onChange={(e) => setDetalheEmpregoCep(nrCepMask(e.target.value))} />
                                                        </div>
                                                    </div>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEmpregoBatalhaoPm">Ponto de referência (Batalhão PM da área)</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={191} placeholder="Ponto de referência (Batalhão PM da área)" id="detalheEmpregoBatalhaoPm" value={detalheEmpregoBatalhaoPm} onChange={(e) => setDetalheEmpregoBatalhaoPm(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEmpregoFuncao">Cargo / Função / Atividade</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={191} placeholder="Cargo / Função / Atividade" id="detalheEmpregoFuncao" value={detalheEmpregoFuncao} onChange={(e) => setDetalheEmpregoFuncao(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEmpregoSalario">Salário (R$)</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={50} placeholder="Salário (R$)" id="detalheEmpregoSalario" value={detalheEmpregoSalario} onChange={(e) => setDetalheEmpregoSalario(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEmpregoDepartamento">Departamento ou Seção em que trabalhava</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={191} placeholder="Departamento ou Seção em que trabalhava" id="detalheEmpregoDepartamento" value={detalheEmpregoDepartamento} onChange={(e) => setDetalheEmpregoDepartamento(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEmpregoChefe">Encarregado ou Chefe direto (nome)</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={191} placeholder="Encarregado ou Chefe direto (nome)" id="detalheEmpregoChefe" value={detalheEmpregoChefe} onChange={(e) => setDetalheEmpregoChefe(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEmpregoPunicaoSofrida">Punições sofridas</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={191} placeholder="Punições sofridas" id="detalheEmpregoPunicaoSofrida" value={detalheEmpregoPunicaoSofrida} onChange={(e) => setDetalheEmpregoPunicaoSofrida(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEmpregoMotivoDemissao">Motivo da demissão</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={191} placeholder="Motivo da demissão" id="detalheEmpregoMotivoDemissao" value={detalheEmpregoMotivoDemissao} onChange={(e) => setDetalheEmpregoMotivoDemissao(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEmpregoInatividadeMesInicial">Período da inatividade - De</label>
                                                        <div className='columns'>
                                                            <div className='column is-narrow'>
                                                                <div className="select">
                                                                    <select id="detalheEmpregoInatividadeMesInicial" value={detalheEmpregoInatividadeMesInicial} onChange={(e) => setDetalheEmpregoInatividadeMesInicial(e.target.value)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='Janeiro'>Janeiro</option>
                                                                        <option value='Fevereiro'>Fevereiro</option>
                                                                        <option value='Março'>Março</option>
                                                                        <option value='Abril'>Abril</option>
                                                                        <option value='Maio'>Maio</option>
                                                                        <option value='Junho'>Junho</option>
                                                                        <option value='Julho'>Julho</option>
                                                                        <option value='Agosto'>Agosto</option>
                                                                        <option value='Setembro'>Setembro</option>
                                                                        <option value='Outubro'>Outubro</option>
                                                                        <option value='Novembro'>Novembro</option>
                                                                        <option value='Dezembro'>Dezembro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='column is-narrow'>
                                                                <div className="controls">
                                                                    <input type="text" className="input" placeholder="Ano Início" maxLength={4} id="detalheEmpregoInatividadeAnoInicial" value={detalheEmpregoInatividadeAnoInicial} onChange={(e) => setDetalheEmpregoInatividadeAnoInicial(onlyNumber(e.target.value))} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEmpregoInatividadeMesFinal">Período da inatividade - Até</label>
                                                        <div className='columns'>
                                                            <div className='column is-narrow'>
                                                                <div className="select">
                                                                    <select id="detalheEmpregoInatividadeMesFinal" value={detalheEmpregoInatividadeMesFinal} onChange={(e) => setDetalheEmpregoInatividadeMesFinal(e.target.value)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='Janeiro'>Janeiro</option>
                                                                        <option value='Fevereiro'>Fevereiro</option>
                                                                        <option value='Março'>Março</option>
                                                                        <option value='Abril'>Abril</option>
                                                                        <option value='Maio'>Maio</option>
                                                                        <option value='Junho'>Junho</option>
                                                                        <option value='Julho'>Julho</option>
                                                                        <option value='Agosto'>Agosto</option>
                                                                        <option value='Setembro'>Setembro</option>
                                                                        <option value='Outubro'>Outubro</option>
                                                                        <option value='Novembro'>Novembro</option>
                                                                        <option value='Dezembro'>Dezembro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='column is-narrow'>
                                                                <div className="controls">
                                                                    <input type="text" className="input" placeholder="Ano Fim" maxLength={4} id="detalheEmpregoInatividadeAnoFinal" value={detalheEmpregoInatividadeAnoFinal} onChange={(e) => setDetalheEmpregoInatividadeAnoFinal(onlyNumber(e.target.value))} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheEmpregoFezDuranteDesemprego">O que fez durante este período em que esteve desempregado?</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={191} placeholder="O que fez durante este período em que esteve desempregado" id="detalheEmpregoFezDuranteDesemprego" value={detalheEmpregoFezDuranteDesemprego} onChange={(e) => setDetalheEmpregoFezDuranteDesemprego(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <button type="button" className="button is-info" onClick={(e) => adicionaDetalheEmprego(e)}><i className="fa fa-plus-circle"></i>&nbsp;Clique Aqui para Registrar informação do trabalho, da atividade desenvolvida</button>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        {detalheEmprego.map((emprego, index) => (
                                                            <div key={index}>
                                                                <article className='message'>
                                                                    <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                        <p>{index+1}. {emprego.empresa} ({emprego.mêsInicial}/{emprego.anoInicial} ~ {emprego.mêsFinal}/{emprego.anoFinal})</p>
                                                                        <button type="button" className='delete' aria-label='delete' onClick={(e) => removeDetalheEmprego(e, emprego.empresa, emprego.mêsInicial, emprego.anoInicial, emprego.mêsFinal, emprego.anoFinal, emprego.função)}></button>
                                                                    </div>
                                                                    <div className='message-body has-background-white-ter has-text-black'>
                                                                        <p>
                                                                            CNPJ: {emprego.cnpj} (Sede: {emprego.sede})
                                                                        </p>
                                                                        <p>
                                                                            Endereço: {emprego.endereço}
                                                                        </p>
                                                                        <p>
                                                                            Bairro: {emprego.bairro}; Cidade: {emprego.cidade}
                                                                        </p>
                                                                        <p>
                                                                            Estado: {emprego.estado}; CEP: {emprego.cep}
                                                                        </p>
                                                                        <p>
                                                                            Batalhão PM: {emprego.batalhaoPm}
                                                                        </p>
                                                                        <p>
                                                                            Função: {emprego.função}; Salário: {emprego.salário}
                                                                        </p>
                                                                        <p>
                                                                            Departamento: {emprego.departamento}; Chefe: {emprego.chefe}
                                                                        </p>
                                                                        <p>
                                                                            Punição: {emprego.puniçãoSofrida}
                                                                        </p>
                                                                        <p>
                                                                            Motivo Demissão: {emprego.demissão}
                                                                        </p>
                                                                        <p>
                                                                            Inatividade: ({emprego.inatividadeMêsInicial}/{emprego.inatividadeAnoInicial} ~ {emprego.inatividadeMêsFinal}/{emprego.inatividadeAnoFinal})
                                                                        </p>
                                                                        <p>
                                                                            fezDuranteDesemprego: {emprego.fezDuranteDesemprego}
                                                                        </p>
                                                                    </div>
                                                                </article>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="tentouIngressarPm">Você procurou <u>anteriormente ingressar</u> na Polícia Militar?</label>
                                                <div className="select">
                                                    <select id="tentouIngressarPm" value={tentouIngressarPm} onChange={(e) => setTentouIngressarPmDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='tentouIngressarPmDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="tentouIngressarPmQuantidade">a. Quantas vezes tentou ingressar e quando</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='tentouIngressarPmQuantidade' name='tentouIngressarPmQuantidade' value={tentouIngressarPmQuantidade} placeholder="Quantas vezes tentou ingressar e quando" onChange={(e) => setTentouIngressarPmQuantidade(e.target.value)} />
                                                    </div>
                                                    <label className="label" htmlFor="tentouIngressarPmReprovou">b. Qual(is) a(s) Etapa(s) em que você foi reprovado e o motivo da reprovação</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='tentouIngressarPmReprovou' name='tentouIngressarPmReprovou' value={tentouIngressarPmReprovou} placeholder="Qual(is) a(s) Etapa(s) em que você foi reprovado e o motivo da reprovação" onChange={(e) => setTentouIngressarPmReprovou(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="tentouIngressarOutroEmprego">Você procurou anteriormente <u>ingressar em algum outro emprego</u> público?</label>
                                                <div className="select">
                                                    <select id="tentouIngressarOutroEmprego" value={tentouIngressarOutroEmprego} onChange={(e) => setTentouIngressarOutroEmpregoDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='tentouIngressarOutroEmpregoDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="tentouIngressarOutroEmpregoA">a. Qual foi a Instituição e o cargo pretendido?</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='tentouIngressarOutroEmpregoA' name='tentouIngressarOutroEmpregoA' value={tentouIngressarOutroEmpregoA} placeholder="Instituição e o cargo pretendido" onChange={(e) => setTentouIngressarOutroEmpregoA(e.target.value)} />
                                                    </div>
                                                    <label className="label" htmlFor="tentouIngressarOutroEmpregoB">b. Quantas vezes você tentou ingressar?</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='tentouIngressarOutroEmpregoB' name='tentouIngressarOutroEmpregoB' value={tentouIngressarOutroEmpregoB} placeholder="Quantas vezes você tentou ingressar" onChange={(e) => setTentouIngressarOutroEmpregoB(e.target.value)} />
                                                    </div>
                                                    <label className="label" htmlFor="tentouIngressarOutroEmpregoC">c. Qual foi o ano e o local do concurso (cidade/Estado)?</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='tentouIngressarOutroEmpregoC' name='tentouIngressarOutroEmpregoC' value={tentouIngressarOutroEmpregoC} placeholder="Ano e o local do concurso (cidade/Estado)" onChange={(e) => setTentouIngressarOutroEmpregoC(e.target.value)} />
                                                    </div>
                                                    <label className="label" htmlFor="tentouIngressarOutroEmpregoD">d. Qual foi o resultado que você obteve nos exames prestados?</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='tentouIngressarOutroEmpregoD' name='tentouIngressarOutroEmpregoD' value={tentouIngressarOutroEmpregoD} placeholder="Resultado que você obteve nos exames prestados" onChange={(e) => setTentouIngressarOutroEmpregoD(e.target.value)} />
                                                    </div>
                                                    <label className="label" htmlFor="tentouIngressarOutroEmpregoE">e. Se reprovado, qual foi o motivo e a etapa do concurso em que você foi reprovado?</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='tentouIngressarOutroEmpregoE' name='tentouIngressarOutroEmpregoE' value={tentouIngressarOutroEmpregoE} placeholder="Se reprovado, qual foi o motivo e a etapa do concurso em que você foi reprovado" onChange={(e) => setTentouIngressarOutroEmpregoE(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="servicoMilitar">Você serviu o <u>Serviço Militar Obrigatório</u>?</label>
                                                <div className="select">
                                                    <select id="servicoMilitar" value={servicoMilitar} onChange={(e) => setServicoMilitarDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='servicoMilitarDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label">Preste as seguintes informações a respeito de seu <u>Serviço Militar Obrigatório</u>:</label>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="servicoMilitarUnidade">Unidade em que você serviu</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={191} placeholder="Unidade em que você serviu" id="servicoMilitarUnidade" value={servicoMilitarUnidade} onChange={(e) => setServicoMilitarUnidade(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="servicoMilitarCompanhia">Companhia</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={191} placeholder="Cia em que você serviu" id="servicoMilitarCompanhia" value={servicoMilitarCompanhia} onChange={(e) => setServicoMilitarCompanhia(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="servicoMilitarPelotao">Pelotão</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={191} placeholder="Pel. em que você serviu" id="servicoMilitarPelotao" value={servicoMilitarPelotao} onChange={(e) => setServicoMilitarPelotao(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="servicoMilitarMesInicial">Período em que serviu - De</label>
                                                            <div className='columns'>
                                                                <div className='column is-narrow'>
                                                                    <div className="select">
                                                                        <select id="servicoMilitarMesInicial" value={servicoMilitarMesInicial} onChange={(e) => setServicoMilitarMesInicial(e.target.value)}>
                                                                            <option value=''>Selecione</option>
                                                                            <option value='Janeiro'>Janeiro</option>
                                                                            <option value='Fevereiro'>Fevereiro</option>
                                                                            <option value='Março'>Março</option>
                                                                            <option value='Abril'>Abril</option>
                                                                            <option value='Maio'>Maio</option>
                                                                            <option value='Junho'>Junho</option>
                                                                            <option value='Julho'>Julho</option>
                                                                            <option value='Agosto'>Agosto</option>
                                                                            <option value='Setembro'>Setembro</option>
                                                                            <option value='Outubro'>Outubro</option>
                                                                            <option value='Novembro'>Novembro</option>
                                                                            <option value='Dezembro'>Dezembro</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='column is-narrow'>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" placeholder="Ano Início" maxLength={4} id="servicoMilitarAnoInicial" value={servicoMilitarAnoInicial} onChange={(e) => setServicoMilitarAnoInicial(onlyNumber(e.target.value))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="servicoMilitarMesFinal">Período em que serviu - Até</label>
                                                            <div className='columns'>
                                                                <div className='column is-narrow'>
                                                                    <div className="select">
                                                                        <select id="servicoMilitarMesFinal" value={servicoMilitarMesFinal} onChange={(e) => setServicoMilitarMesFinal(e.target.value)}>
                                                                            <option value=''>Selecione</option>
                                                                            <option value='Janeiro'>Janeiro</option>
                                                                            <option value='Fevereiro'>Fevereiro</option>
                                                                            <option value='Março'>Março</option>
                                                                            <option value='Abril'>Abril</option>
                                                                            <option value='Maio'>Maio</option>
                                                                            <option value='Junho'>Junho</option>
                                                                            <option value='Julho'>Julho</option>
                                                                            <option value='Agosto'>Agosto</option>
                                                                            <option value='Setembro'>Setembro</option>
                                                                            <option value='Outubro'>Outubro</option>
                                                                            <option value='Novembro'>Novembro</option>
                                                                            <option value='Dezembro'>Dezembro</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='column is-narrow'>
                                                                    <div className="controls">
                                                                        <input type="text" className="input" placeholder="Ano Fim" maxLength={4} id="servicoMilitarAnoFinal" value={servicoMilitarAnoFinal} onChange={(e) => setServicoMilitarAnoFinal(onlyNumber(e.target.value))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <div className="controls">
                                                                <label className="label" htmlFor="servicoMilitarEndereco">Endereço(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)</label>
                                                                <textarea maxLength={1500} rows='4' className="textarea" id='servicoMilitarEndereco' name='servicoMilitarEndereco' value={servicoMilitarEndereco} placeholder="Endereço Completo(Rua, Número, Complemento, CEP, Bairro, Cidade, Estado)" onChange={(e) => setServicoMilitarEndereco(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="servicoMilitarPontoReferencia">Ponto de Referência (Batalhão PM da área)</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={191} placeholder="Ponto de Referência" id="servicoMilitarPontoReferencia" value={servicoMilitarPontoReferencia} onChange={(e) => setServicoMilitarPontoReferencia(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="servicoMilitarFuncao">Função que desempenhava</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={191} placeholder="Função que desempenhava" id="servicoMilitarFuncao" value={servicoMilitarFuncao} onChange={(e) => setServicoMilitarFuncao(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <div className="controls">
                                                                <label className="label" htmlFor="servicoMilitarPunicao">Relacione TODAS as punições sofridas e os seus motivos, inclusive aquelas punições que são do seu conhecimento e que por ventura, <u>talvez não constem registradas nos seus assentamentos de registro militar</u></label>
                                                                <textarea maxLength={1500} rows='4' className="textarea" id='servicoMilitarPunicao' name='servicoMilitarPunicao' value={servicoMilitarPunicao} placeholder="Relacione TODAS as punições sofridas e os seus motivos, inclusive aquelas punições que são do seu conhecimento e que por ventura, talvez não constem registradas nos seus assentamentos de registro militar" onChange={(e) => setServicoMilitarPunicao(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='columns'>
                                                        <div className='column'>
                                                            <label className="label" htmlFor="servicoMilitarMotivoBaixa">Qual foi o motivo da baixa?</label>
                                                            <div className="controls">
                                                                <input type="text" className="input" maxLength={191} placeholder="Qual foi o motivo da baixa?" id="servicoMilitarMotivoBaixa" value={servicoMilitarMotivoBaixa} onChange={(e) => setServicoMilitarMotivoBaixa(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                
                                <article className='message is-link' id='dadosConcursoDiv'>
                                    <div className='message-header'>
                                        <div className="tags are-medium has-addons">
                                            PARTE G - INFORMAÇÕES ESCOLARES
                                        </div>
                                    </div>
                                    <div className='message-body'>
                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label">Informe a seguir<span style={{ fontWeight:'normal' }}>(vá adicionando clicando no botão 'Registrar informação escolar', um curso por vez)</span>, os estabelecimentos de ensino onde você cursou os ensinos médio, técnico, superior e de pós-graduação (se houver):</label>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="escolaNome">Nome da Instituição</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={191} placeholder="Nome da Instituição" id="escolaNome" value={escolaNome} onChange={(e) => setEscolaNome(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="escolaCurso">Curso realizado</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={100} placeholder="Curso realizado" id="escolaCurso" value={escolaCurso} onChange={(e) => setEscolaCurso(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="escolaCursou">Séries/Semestres que você cursou</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={70} placeholder="Séries/Semestres que você cursou" id="escolaCursou" value={escolaCursou} onChange={(e) => setEscolaCursou(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="escolaTipo">Tipo</label>
                                                        <div className="controls">
                                                            <div className="select is-fullwidth">
                                                                <select id="escolaTipo" value={escolaTipo} onChange={(e) => setEscolaTipoValidar(e)}>
                                                                    <option value=''>Selecione</option>
                                                                    <option value='Presencial'>Presencial</option>
                                                                    <option value='EAD'>EAD</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="escolaRazaoEad">Caso seja EAD, por qual razão você optou por este tipo de ensino/formação?</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={191} placeholder="Caso seja EAD, por qual razão você optou por este tipo de ensino/formação?" id="escolaRazaoEad" value={escolaRazaoEad} onChange={(e) => setEscolaRazaoEad(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="escolaMesInicial">Período que você cursou - De</label>
                                                        <div className='columns'>
                                                            <div className='column is-narrow'>
                                                                <div className="select">
                                                                    <select id="escolaMesInicial" value={escolaMesInicial} onChange={(e) => setEscolaMesInicial(e.target.value)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='Janeiro'>Janeiro</option>
                                                                        <option value='Fevereiro'>Fevereiro</option>
                                                                        <option value='Março'>Março</option>
                                                                        <option value='Abril'>Abril</option>
                                                                        <option value='Maio'>Maio</option>
                                                                        <option value='Junho'>Junho</option>
                                                                        <option value='Julho'>Julho</option>
                                                                        <option value='Agosto'>Agosto</option>
                                                                        <option value='Setembro'>Setembro</option>
                                                                        <option value='Outubro'>Outubro</option>
                                                                        <option value='Novembro'>Novembro</option>
                                                                        <option value='Dezembro'>Dezembro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='column is-narrow'>
                                                                <div className="controls">
                                                                    <input type="text" className="input" placeholder="Ano Início" maxLength={4} id="escolaAnoInicial" value={escolaAnoInicial} onChange={(e) => setEscolaAnoInicial(onlyNumber(e.target.value))} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="escolaMesFinal">Período que você cursou - Até</label>
                                                        <div className='columns'>
                                                            <div className='column is-narrow'>
                                                                <div className="select">
                                                                    <select id="escolaMesFinal" value={escolaMesFinal} onChange={(e) => setEscolaMesFinal(e.target.value)}>
                                                                        <option value=''>Selecione</option>
                                                                        <option value='Janeiro'>Janeiro</option>
                                                                        <option value='Fevereiro'>Fevereiro</option>
                                                                        <option value='Março'>Março</option>
                                                                        <option value='Abril'>Abril</option>
                                                                        <option value='Maio'>Maio</option>
                                                                        <option value='Junho'>Junho</option>
                                                                        <option value='Julho'>Julho</option>
                                                                        <option value='Agosto'>Agosto</option>
                                                                        <option value='Setembro'>Setembro</option>
                                                                        <option value='Outubro'>Outubro</option>
                                                                        <option value='Novembro'>Novembro</option>
                                                                        <option value='Dezembro'>Dezembro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='column is-narrow'>
                                                                <div className="controls">
                                                                    <input type="text" className="input" placeholder="Ano Fim" maxLength={4} id="escolaAnoFinal" value={escolaAnoFinal} onChange={(e) => setEscolaAnoFinal(onlyNumber(e.target.value))} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="escolaEndereco">Endereço</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={191} placeholder="Endereço" id="escolaEndereco" value={escolaEndereco} onChange={(e) => setEscolaEndereco(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='column is-narrow'>
                                                        <label className="label" htmlFor="escolaNumero">Número</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={50} placeholder="Número" id="escolaNumero" value={escolaNumero} onChange={(e) => setEscolaNumero(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="escolaComplemento">Complemento</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={50} placeholder="Complemento" id="escolaComplemento" value={escolaComplemento} onChange={(e) => setEscolaComplemento(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="escolaCep">CEP</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={9} placeholder="CEP" id="escolaCep" value={escolaCep} onChange={(e) => setEscolaCep(nrCepMask(e.target.value))} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="escolaBairro">Bairro</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={70} placeholder="Bairro" id="escolaBairro" value={escolaBairro} onChange={(e) => setEscolaBairro(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="escolaCidade">Cidade</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={70} placeholder="Cidade" id="escolaCidade" value={escolaCidade} onChange={(e) => setEscolaCidade(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="escolaEstado">Estado</label>
                                                        <div className="controls">
                                                            <div className="select is-fullwidth">
                                                                <select id="escolaEstado" value={escolaEstado} onChange={(e) => setEscolaEstado(e.target.value)}>
                                                                    <option value=''>Selecione</option>
                                                                    <option value='Rio Grande do Norte'>Rio Grande do Norte</option>
                                                                    <option value='Acre'>Acre</option>
                                                                    <option value='Alagoas'>Alagoas</option>
                                                                    <option value='Amapá'>Amapá</option>
                                                                    <option value='Amazonas'>Amazonas</option>
                                                                    <option value='Bahia'>Bahia</option>
                                                                    <option value='Ceará'>Ceará</option>
                                                                    <option value='Distrito Federal'>Distrito Federal</option>
                                                                    <option value='Espírito Santo'>Espírito Santo</option>
                                                                    <option value='Goiás'>Goiás</option>
                                                                    <option value='Maranhão'>Maranhão</option>
                                                                    <option value='Mato Grosso'>Mato Grosso</option>
                                                                    <option value='Mato Grosso do Sul'>Mato Grosso do Sul</option>
                                                                    <option value='Minas Gerais'>Minas Gerais</option>
                                                                    <option value='Pará'>Pará</option>
                                                                    <option value='Paraíba'>Paraíba</option>
                                                                    <option value='Paraná'>Paraná</option>
                                                                    <option value='Pernambuco'>Pernambuco</option>
                                                                    <option value='Piauí'>Piauí</option>
                                                                    <option value='Rio de Janeiro'>Rio de Janeiro</option>
                                                                    <option value='Rio Grande do Sul'>Rio Grande do Sul</option>
                                                                    <option value='Rondônia'>Rondônia</option>
                                                                    <option value='Roraima'>Roraima</option>
                                                                    <option value='Santa Catarina'>Santa Catarina</option>
                                                                    <option value='São Paulo'>São Paulo</option>
                                                                    <option value='Sergipe'>Sergipe</option>
                                                                    <option value='Tocantins'>Tocantins</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="escolaPontoReferencia">Ponto de Referência (Batalhão PM da área)</label>
                                                        <div className="controls">
                                                            <input type="text" className="input" maxLength={191} placeholder="Ponto de Referência" id="escolaPontoReferencia" value={escolaPontoReferencia} onChange={(e) => setEscolaPontoReferencia(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <button type="button" className="button is-info" onClick={(e) => adicionaEscola(e)}><i className="fa fa-plus-circle"></i>&nbsp;Clique Aqui para Registrar informação escolar</button>
                                                    </div>
                                                </div>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        {escolas.map((escola, index) => (
                                                            <div key={index}>
                                                                <article className='message'>
                                                                    <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                        <p>{index+1}. {escola.curso} ({escola.mêsInicial}/{escola.anoInicial} ~ {escola.mêsFinal}/{escola.anoFinal})</p>
                                                                        <button type="button" className='delete' aria-label='delete' onClick={(e) => removeEscola(e, escola.nome, escola.curso, escola.mêsInicial, escola.anoInicial, escola.mêsFinal, escola.anoFinal, escola.tipo)}></button>
                                                                    </div>
                                                                    <div className='message-body has-background-white-ter has-text-black'>
                                                                        <p>
                                                                            Escola: {escola.nome}
                                                                        </p>
                                                                        <p>
                                                                            Séries/Semestres que você cursou: {escola.cursou}; 
                                                                            Tipo: {escola.tipo}
                                                                        </p>
                                                                        <p>
                                                                            Razão EAD: {escola.razãoEad}
                                                                        </p>
                                                                        <p>
                                                                            Endereço: {escola.endereço} {escola.número}
                                                                        </p>
                                                                        <p>
                                                                            Complemento: {escola.complemento};
                                                                            CEP: {escola.cep}
                                                                        </p>
                                                                        <p>
                                                                            Cidade: {escola.cidade};
                                                                            Estado: {escola.estado};
                                                                            Bairro: {escola.bairro}
                                                                        </p>
                                                                        <p>
                                                                            Ponto de Referência: {escola.pontoReferência}
                                                                        </p>
                                                                    </div>
                                                                </article>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="punicaoEscolar">Você sofreu alguma <u>punição escolar</u> (advertência, suspensão, expulsão etc) em qualquer estabelecimento de ensino?</label>
                                                <div className="select">
                                                    <select id="punicaoEscolar" value={punicaoEscolar} onChange={(e) => setPunicaoEscolarDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='punicaoEscolarDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="detalhePunicaoEscolar">Descreva com detalhes: A data da punição, a sanção aplicada e o fato que causou a punição escolar:</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='detalhePunicaoEscolar' name='detalhePunicaoEscolar' value={detalhePunicaoEscolar} placeholder="" onChange={(e) => setDetalhePunicaoEscolar(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                
                                <article className='message is-link' id='dadosConcursoDiv'>
                                    <div className='message-header'>
                                        <div className="tags are-medium has-addons">
                                            PARTE H - INFORMAÇÕES ECONÔMICAS
                                        </div>
                                    </div>
                                    <div className='message-body'>
                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="protecaoCredito">Está com o nome registrado em algum órgão ou entidade de <u>controle e proteção ao crédito</u> financeiro (SCPC etc)? Possui <u>cheques devolvidos</u>, títulos protestados, prestações ou dívidas em atraso?</label>
                                                <div className="select">
                                                    <select id="protecaoCredito" value={protecaoCredito} onChange={(e) => setProtecaoCreditoDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='protecaoCreditoDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Forneça informações detalhadas, relatando sobre os seguintes quesitos:</label>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="protecaoCreditoDivida">a. Valor da(s) dívida(s)</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='protecaoCreditoDivida' name='protecaoCreditoDivida' value={protecaoCreditoDivida} placeholder="Valor da(s) dívida(s)" onChange={(e) => setProtecaoCreditoDivida(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="protecaoCreditoDataCompra">b. Data em que as compras foram realizadas</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='protecaoCreditoDataCompra' name='protecaoCreditoDataCompra' value={protecaoCreditoDataCompra} placeholder="Data em que as compras foram realizadas" onChange={(e) => setProtecaoCreditoDataCompra(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="protecaoCreditoDataDeixouPagarDivida">c. Data em que você deixou de pagar a(s) dívida(s)</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='protecaoCreditoDataDeixouPagarDivida' name='protecaoCreditoDataDeixouPagarDivida' value={protecaoCreditoDataDeixouPagarDivida} placeholder="Data em que você deixou de pagar a(s) dívida(s)" onChange={(e) => setProtecaoCreditoDataDeixouPagarDivida(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="protecaoCreditoInstituicaoCredora">d. Nome da(s) Instituição(ões) credora(s) da(s) sua(s) dívida(s)</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='protecaoCreditoInstituicaoCredora' name='protecaoCreditoInstituicaoCredora' value={protecaoCreditoInstituicaoCredora} placeholder="Nome da(s) Instituição(ões) credora(s) da(s) sua(s) dívida(s)" onChange={(e) => setProtecaoCreditoInstituicaoCredora(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="protecaoCreditoMotivoDivida">e. MOTIVOS pelos quais você não pagou a(s) dívida(s), tornando-o inadimplente</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='protecaoCreditoMotivoDivida' name='protecaoCreditoMotivoDivida' value={protecaoCreditoMotivoDivida} placeholder="MOTIVOS pelos quais você não pagou a(s) dívida(s), tornando-o inadimplente" onChange={(e) => setProtecaoCreditoMotivoDivida(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="propriedadeImovel">Você possui ou já possuiu em seu nome, a propriedade de algum bem <u>imóvel</u>?</label>
                                                <div className="select">
                                                    <select id="propriedadeImovel" value={propriedadeImovel} onChange={(e) => setPropriedadeImovelDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='propriedadeImovelDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Forneça informações detalhadas, relatando sobre os seguintes quesitos:</label>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="propriedadeImovelTipo">a. Qual tipo de imóvel</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='propriedadeImovelTipo' name='propriedadeImovelTipo' value={propriedadeImovelTipo} placeholder="Qual tipo de imóvel" onChange={(e) => setPropriedadeImovelTipo(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="propriedadeImovelTamanho">b. Qual tamanho do imóvel (metragem geral)</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='propriedadeImovelTamanho' name='propriedadeImovelTamanho' value={propriedadeImovelTamanho} placeholder="Qual tamanho do imóvel (metragem geral)" onChange={(e) => setPropriedadeImovelTamanho(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="propriedadeImovelValorAvaliado">c. Qual valor de mercado em que está avaliado o imóvel</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='propriedadeImovelValorAvaliado' name='propriedadeImovelValorAvaliado' value={propriedadeImovelValorAvaliado} placeholder="Qual valor de mercado em que está avaliado o imóvel" onChange={(e) => setPropriedadeImovelValorAvaliado(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="atividadeEmpresarial">Você possui ou já possuiu em seu nome, alguma <u>atividade empresarial</u>, seja na condição de <u>proprietário ou sócio</u>?</label>
                                                <div className="select">
                                                    <select id="atividadeEmpresarial" value={atividadeEmpresarial} onChange={(e) => setAtividadeEmpresarialDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='atividadeEmpresarialDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Forneça informações detalhadas, relatando sobre os seguintes quesitos:</label>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="atividadeEmpresarialTipo">a. Qual tipo de atividade empresarial realizada</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='atividadeEmpresarialTipo' name='atividadeEmpresarialTipo' value={atividadeEmpresarialTipo} placeholder="Qual tipo de atividade empresarial realizada" onChange={(e) => setAtividadeEmpresarialTipo(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="atividadeEmpresarialNivelParticipacao">b. Qual nível/grau de sua participação na constituição da empresa</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='atividadeEmpresarialNivelParticipacao' name='atividadeEmpresarialNivelParticipacao' value={atividadeEmpresarialNivelParticipacao} placeholder="Qual nível/grau de sua participação na constituição da empresa" onChange={(e) => setAtividadeEmpresarialNivelParticipacao(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="atividadeEmpresarialEmpresa">c. Informe os dados de identificação da empresa (Nome, CNPJ, endereço, sócios, data do contrato etc)</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='atividadeEmpresarialEmpresa' name='atividadeEmpresarialEmpresa' value={atividadeEmpresarialEmpresa} placeholder="Informe os dados de identificação da empresa (Nome, CNPJ, endereço, sócios, data do contrato etc)" onChange={(e) => setAtividadeEmpresarialEmpresa(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </article>
                                
                                <article className='message is-link' id='dadosConcursoDiv'>
                                    <div className='message-header'>
                                        <div className="tags are-medium has-addons">
                                            PARTE I - INFORMAÇÕES DE TRÂNSITO
                                        </div>
                                    </div>
                                    <div className='message-body'>
                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="permissaoDirigir">Você possui <u>Carteira Nacional de Habilitação (CNH)</u> ou <u>Permissão Para Dirigir (PPD)</u>?</label>
                                                <div className="select">
                                                    <select id="permissaoDirigir" value={permissaoDirigir} onChange={(e) => setPermissaoDirigirDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='permissaoDirigirDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className='label'>Forneça informações detalhadas, relatando sobre os seguintes quesitos:</label>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="cnh">Nº da CNH ou da PPD</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={50} placeholder="Número da CNH ou da PPD" id="cnh" value={cnh} onChange={(e) => setCnh(onlyNumber(e.target.value))} />
                                                    </div>
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="numeroRegistroCnh">Nº do registro</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={50} placeholder="Número do registro" id="numeroRegistroCnh" value={numeroRegistroCnh} onChange={(e) => setNumeroRegistroCnh(onlyNumber(e.target.value))} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="dataExpedicaoCnh">Data de Expedição</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={10} placeholder="Data de Expedição" id="dataExpedicaoCnh" value={dataExpedicaoCnh} onChange={(e) => setDataExpedicaoCnh(dataBr(e.target.value))} />
                                                    </div>
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="localCnh">Local da Expedição</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={80} placeholder="Local da Expedição" id="localCnh" value={localCnh} onChange={(e) => setLocalCnh(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="categoriaCnh">Categoria</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={50} placeholder="Categoria" id="categoriaCnh" value={categoriaCnh} onChange={(e) => setCategoriaCnh(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="cnhMulta">Sua CNH ou PPD possui pontuação por multas?</label>
                                                    <div className="select">
                                                        <select id="cnhMulta" value={cnhMulta} onChange={(e) => setCnhMultaDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='cnhMultaDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className='label' htmlFor='detalheCnhMulta'>Forneça informações detalhadas sobre as infrações cometidas, bem como aquelas que foram registradas em seu nome, ainda que você não estivesse conduzindo o veículo, porém, trata-se de um carro (ou moto) registrado em seu nome:</label>
                                                        <div className='controls'>
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='detalheCnhMulta' name='detalheCnhMulta' value={detalheCnhMulta} placeholder="Forneça informações detalhadas sobre as infrações cometidas, bem como aquelas que foram registradas em seu nome, ainda que você não estivesse conduzindo o veículo, porém, trata-se de um carro (ou moto) registrado em seu nome" onChange={(e) => setDetalheCnhMulta(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="cnhApreendidaSuspensaCassada">Você já teve alguma vez a CNH ou PPD <u>apreendida</u>, <u>suspensa</u> ou <u>cassada</u>?</label>
                                                <div className="select">
                                                    <select id="cnhApreendidaSuspensaCassada" value={cnhApreendidaSuspensaCassada} onChange={(e) => setCnhApreendidaSuspensaCassadaDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='cnhApreendidaSuspensaCassadaDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="detalheCnhApreendidaSuspensaCassada">Forneça informações detalhadas sobre os motivos:</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='detalheCnhApreendidaSuspensaCassada' name='detalheCnhApreendidaSuspensaCassada' value={detalheCnhApreendidaSuspensaCassada} placeholder="Forneça informações detalhadas sobre os motivos" onChange={(e) => setDetalheCnhApreendidaSuspensaCassada(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="acidenteTransito">Você já esteve envolvido em <u>acidente de trânsito</u> ao dirigir veículo?</label>
                                                <div className="select">
                                                    <select id="acidenteTransito" value={acidenteTransito} onChange={(e) => setAcidenteTransitoDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='acidenteTransitoDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="detalheAcidenteTransito">Forneça informações detalhadas sobre o(s) acidente(s):</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='detalheAcidenteTransito' name='detalheAcidenteTransito' value={detalheAcidenteTransito} placeholder="Forneça informações detalhadas sobre o(s) acidente(s)" onChange={(e) => setDetalheAcidenteTransito(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="veiculoSeuNomeSuaPosse">Você possui <u>veículo(s) em seu nome</u> e que esteja na <u>sua posse</u>?</label>
                                                <div className="select">
                                                    <select id="veiculoSeuNomeSuaPosse" value={veiculoSeuNomeSuaPosse} onChange={(e) => setVeiculoSeuNomeSuaPosseDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='veiculoSeuNomeSuaPosseDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Forneça informações sobre cada veículo <span style={{ fontWeight:'normal' }}>(vá adicionando clicando no botão 'Registrar informação do veículo', um veículo por vez)</span>, relatando os seguintes quesitos:</label>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSeuNomeSuaPossePlaca">a. Placa</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={20} placeholder="Placa" id="veiculoSeuNomeSuaPossePlaca" value={veiculoSeuNomeSuaPossePlaca} onChange={(e) => setVeiculoSeuNomeSuaPossePlaca(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSeuNomeSuaPosseMarcaAnoModelo">b. Marca / Ano / Modelo</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={70} placeholder="Marca / Ano / Modelo" id="veiculoSeuNomeSuaPosseMarcaAnoModelo" value={veiculoSeuNomeSuaPosseMarcaAnoModelo} onChange={(e) => setVeiculoSeuNomeSuaPosseMarcaAnoModelo(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSeuNomeSuaPosseCor">c. Cor</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={20} placeholder="Cor" id="veiculoSeuNomeSuaPosseCor" value={veiculoSeuNomeSuaPosseCor} onChange={(e) => setVeiculoSeuNomeSuaPosseCor(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSeuNomeSuaPosseTaxaOk">d. Seguro Obrigatório, Licenciamento Anual e o IPVA estão todos pagos?</label>
                                                    <div className="select">
                                                        <select id="veiculoSeuNomeSuaPosseTaxaOk" value={veiculoSeuNomeSuaPosseTaxaOk} onChange={(e) => setVeiculoSeuNomeSuaPosseTaxaOkDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='veiculoSeuNomeSuaPosseTaxaOkDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheVeiculoSeuNomeSuaPosseTaxaOk">Em caso negativo, forneça informações sobre os motivos:</label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='detalheVeiculoSeuNomeSuaPosseTaxaOk' name='detalheVeiculoSeuNomeSuaPosseTaxaOk' value={detalheVeiculoSeuNomeSuaPosseTaxaOk} placeholder="Forneça informações sobre os motivos" onChange={(e) => setDetalheVeiculoSeuNomeSuaPosseTaxaOk(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSeuNomeSuaPosseMultaPendente">e. Neste veículo, há multas com pagamento pendente?</label>
                                                    <div className="select">
                                                        <select id="veiculoSeuNomeSuaPosseMultaPendente" value={veiculoSeuNomeSuaPosseMultaPendente} onChange={(e) => setVeiculoSeuNomeSuaPosseMultaPendenteDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='veiculoSeuNomeSuaPosseMultaPendenteDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheVeiculoSeuNomeSuaPosseMultaPendente">Em caso positivo, informe os motivos:</label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='detalheVeiculoSeuNomeSuaPosseMultaPendente' name='detalheVeiculoSeuNomeSuaPosseMultaPendente' value={detalheVeiculoSeuNomeSuaPosseMultaPendente} placeholder="Informe os motivos" onChange={(e) => setDetalheVeiculoSeuNomeSuaPosseMultaPendente(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSeuNomeSuaPosseVoceDirige">f. Você dirige este veículo?</label>
                                                    <div className="select">
                                                        <select id="veiculoSeuNomeSuaPosseVoceDirige" value={veiculoSeuNomeSuaPosseVoceDirige} onChange={(e) => setVeiculoSeuNomeSuaPosseVoceDirigeDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='veiculoSeuNomeSuaPosseVoceDirigeDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheVeiculoSeuNomeSuaPosseVoceDirige">Em caso negativo, informe quem dirige e o motivo:</label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='detalheVeiculoSeuNomeSuaPosseVoceDirige' name='detalheVeiculoSeuNomeSuaPosseVoceDirige' value={detalheVeiculoSeuNomeSuaPosseVoceDirige} placeholder="Informe quem dirige e o motivo" onChange={(e) => setDetalheVeiculoSeuNomeSuaPosseVoceDirige(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <button type="button" className="button is-info" onClick={(e) => adicionaVeiculoSeuNomeSuaPosse(e)}><i className="fa fa-plus-circle"></i>&nbsp;Clique Aqui para Registrar informação do veículo</button>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    {lVeiculoSeuNomeSuaPosse.map((veiculo, index) => (
                                                        <div key={index}>
                                                            <article className='message'>
                                                                <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                    <p>{index+1}. [{veiculo.placa}] {veiculo.marcaAnoModelo} ({veiculo.cor})</p>
                                                                    <button type="button" className='delete' aria-label='delete' onClick={(e) => removeVeiculoSeuNomeSuaPosse(e, veiculo.marcaAnoModelo, veiculo.cor, veiculo.placa)}></button>
                                                                </div>
                                                                <div className='message-body has-background-white-ter has-text-black'>
                                                                    <p>
                                                                        Seguro, Licenciamento, IPVA pago: {veiculo.taxaOk}
                                                                    </p>
                                                                    {veiculo.taxaOk === 'não' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheTaxaOk.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                    <p>
                                                                        Multa pendente: {veiculo.multaPendente}
                                                                    </p>
                                                                    {veiculo.multaPendente === 'sim' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheMultaPendente.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                    <p>
                                                                        Dirige este veículo: {veiculo.voceDirige}
                                                                    </p>
                                                                    {veiculo.voceDirige === 'não' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheVoceDirige.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                </div>
                                                            </article>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="veiculoSeuNomeNaoSuaPosse">Você possui <u>veículo(s) em seu nome</u> e que <u>NÃO esteja na sua posse</u>?</label>
                                                <div className="select">
                                                    <select id="veiculoSeuNomeNaoSuaPosse" value={veiculoSeuNomeNaoSuaPosse} onChange={(e) => setVeiculoSeuNomeNaoSuaPosseDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='veiculoSeuNomeNaoSuaPosseDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Forneça informações sobre cada veículo <span style={{ fontWeight:'normal' }}>(vá adicionando clicando no botão 'Registrar informação do veículo', um veículo por vez)</span>, relatando os seguintes quesitos:</label>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSeuNomeNaoSuaPossePlaca">a. Placa</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={20} placeholder="Placa" id="veiculoSeuNomeNaoSuaPossePlaca" value={veiculoSeuNomeNaoSuaPossePlaca} onChange={(e) => setVeiculoSeuNomeNaoSuaPossePlaca(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSeuNomeNaoSuaPosseMarcaAnoModelo">b. Marca / Ano / Modelo</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={70} placeholder="Marca / Ano / Modelo" id="veiculoSeuNomeNaoSuaPosseMarcaAnoModelo" value={veiculoSeuNomeNaoSuaPosseMarcaAnoModelo} onChange={(e) => setVeiculoSeuNomeNaoSuaPosseMarcaAnoModelo(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSeuNomeNaoSuaPosseCor">c. Cor</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={20} placeholder="Cor" id="veiculoSeuNomeNaoSuaPosseCor" value={veiculoSeuNomeNaoSuaPosseCor} onChange={(e) => setVeiculoSeuNomeNaoSuaPosseCor(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSeuNomeNaoSuaPosseTaxaOk">d. Seguro Obrigatório, Licenciamento Anual e o IPVA estão todos pagos?</label>
                                                    <div className="select">
                                                        <select id="veiculoSeuNomeNaoSuaPosseTaxaOk" value={veiculoSeuNomeNaoSuaPosseTaxaOk} onChange={(e) => setVeiculoSeuNomeNaoSuaPosseTaxaOkDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='veiculoSeuNomeNaoSuaPosseTaxaOkDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheVeiculoSeuNomeNaoSuaPosseTaxaOk">Em caso negativo, forneça informações sobre os motivos:</label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='detalheVeiculoSeuNomeNaoSuaPosseTaxaOk' name='detalheVeiculoSeuNomeNaoSuaPosseTaxaOk' value={detalheVeiculoSeuNomeNaoSuaPosseTaxaOk} placeholder="Forneça informações sobre os motivos" onChange={(e) => setDetalheVeiculoSeuNomeNaoSuaPosseTaxaOk(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSeuNomeNaoSuaPosseMultaPendente">e. Neste veículo, há multas com pagamento pendente?</label>
                                                    <div className="select">
                                                        <select id="veiculoSeuNomeNaoSuaPosseMultaPendente" value={veiculoSeuNomeNaoSuaPosseMultaPendente} onChange={(e) => setVeiculoSeuNomeNaoSuaPosseMultaPendenteDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='veiculoSeuNomeNaoSuaPosseMultaPendenteDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheVeiculoSeuNomeNaoSuaPosseMultaPendente">Em caso positivo, informe os motivos:</label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='detalheVeiculoSeuNomeNaoSuaPosseMultaPendente' name='detalheVeiculoSeuNomeNaoSuaPosseMultaPendente' value={detalheVeiculoSeuNomeNaoSuaPosseMultaPendente} placeholder="Informe os motivos" onChange={(e) => setDetalheVeiculoSeuNomeNaoSuaPosseMultaPendente(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSeuNomeNaoSuaPosseVoceDirige">f. Você dirige este veículo?</label>
                                                    <div className="select">
                                                        <select id="veiculoSeuNomeNaoSuaPosseVoceDirige" value={veiculoSeuNomeNaoSuaPosseVoceDirige} onChange={(e) => setVeiculoSeuNomeNaoSuaPosseVoceDirigeDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='veiculoSeuNomeNaoSuaPosseVoceDirigeDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheVeiculoSeuNomeNaoSuaPosseVoceDirige">Em caso negativo, informe quem dirige e o motivo:</label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='detalheVeiculoSeuNomeNaoSuaPosseVoceDirige' name='detalheVeiculoSeuNomeNaoSuaPosseVoceDirige' value={detalheVeiculoSeuNomeNaoSuaPosseVoceDirige} placeholder="Informe quem dirige e o motivo" onChange={(e) => setDetalheVeiculoSeuNomeNaoSuaPosseVoceDirige(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSeuNomeNaoSuaPosseProprietario">g. Qual o nome do proprietário?</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={191} placeholder="Informe o nome do proprietário" id="veiculoSeuNomeNaoSuaPosseProprietario" value={veiculoSeuNomeNaoSuaPosseProprietario} onChange={(e) => setVeiculoSeuNomeNaoSuaPosseProprietario(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSeuNomeNaoSuaPosseParentescoProprietario">h. Qual o seu grau de parentesco ou amizade?</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={20} placeholder="Informe o seu grau de parentesco ou amizade" id="veiculoSeuNomeNaoSuaPosseParentescoProprietario" value={veiculoSeuNomeNaoSuaPosseParentescoProprietario} onChange={(e) => setVeiculoSeuNomeNaoSuaPosseParentescoProprietario(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <button type="button" className="button is-info" onClick={(e) => adicionaVeiculoSeuNomeNaoSuaPosse(e)}><i className="fa fa-plus-circle"></i>&nbsp;Clique Aqui para Registrar informação do veículo</button>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    {lVeiculoSeuNomeNaoSuaPosse.map((veiculo, index) => (
                                                        <div key={index}>
                                                            <article className='message'>
                                                                <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                    <p>{index+1}. [{veiculo.placa}] {veiculo.marcaAnoModelo} ({veiculo.cor})</p>
                                                                    <button type="button" className='delete' aria-label='delete' onClick={(e) => removeVeiculoSeuNomeNaoSuaPosse(e, veiculo.marcaAnoModelo, veiculo.cor, veiculo.placa)}></button>
                                                                </div>
                                                                <div className='message-body has-background-white-ter has-text-black'>
                                                                    <p>
                                                                        Seguro, Licenciamento, IPVA pago: {veiculo.taxaOk}
                                                                    </p>
                                                                    {veiculo.taxaOk === 'não' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheTaxaOk.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                    <p>
                                                                        Multa pendente: {veiculo.multaPendente}
                                                                    </p>
                                                                    {veiculo.multaPendente === 'sim' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheMultaPendente.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                    <p>
                                                                        Dirige este veículo: {veiculo.voceDirige}
                                                                    </p>
                                                                    {veiculo.voceDirige === 'não' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheVoceDirige.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                    <p>
                                                                        Proprietário: {veiculo.proprietario};
                                                                        Parentesco: {veiculo.parentesco}
                                                                    </p>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="veiculoSuaPosseNaoSeuNome">Você <u>tem a posse de veículo(s) que não está(ão) em seu nome</u>?</label>
                                                <div className="select">
                                                    <select id="veiculoSuaPosseNaoSeuNome" value={veiculoSuaPosseNaoSeuNome} onChange={(e) => setVeiculoSuaPosseNaoSeuNomeDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='veiculoSuaPosseNaoSeuNomeDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Forneça informações sobre cada veículo <span style={{ fontWeight:'normal' }}>(vá adicionando clicando no botão 'Registrar informação do veículo', um veículo por vez)</span>, relatando os seguintes quesitos:</label>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSuaPosseNaoSeuNomePlaca">a. Placa</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={20} placeholder="Placa" id="veiculoSuaPosseNaoSeuNomePlaca" value={veiculoSuaPosseNaoSeuNomePlaca} onChange={(e) => setVeiculoSuaPosseNaoSeuNomePlaca(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSuaPosseNaoSeuNomeMarcaAnoModelo">b. Marca / Ano / Modelo</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={70} placeholder="Marca / Ano / Modelo" id="veiculoSuaPosseNaoSeuNomeMarcaAnoModelo" value={veiculoSuaPosseNaoSeuNomeMarcaAnoModelo} onChange={(e) => setVeiculoSuaPosseNaoSeuNomeMarcaAnoModelo(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSuaPosseNaoSeuNomeCor">c. Cor</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={20} placeholder="Cor" id="veiculoSuaPosseNaoSeuNomeCor" value={veiculoSuaPosseNaoSeuNomeCor} onChange={(e) => setVeiculoSuaPosseNaoSeuNomeCor(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSuaPosseNaoSeuNomeTaxaOk">d. Seguro Obrigatório, Licenciamento Anual e o IPVA estão todos pagos?</label>
                                                    <div className="select">
                                                        <select id="veiculoSuaPosseNaoSeuNomeTaxaOk" value={veiculoSuaPosseNaoSeuNomeTaxaOk} onChange={(e) => setVeiculoSuaPosseNaoSeuNomeTaxaOkDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='veiculoSuaPosseNaoSeuNomeTaxaOkDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheVeiculoSuaPosseNaoSeuNomeTaxaOk">Em caso negativo, forneça informações sobre os motivos:</label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='detalheVeiculoSuaPosseNaoSeuNomeTaxaOk' name='detalheVeiculoSuaPosseNaoSeuNomeTaxaOk' value={detalheVeiculoSuaPosseNaoSeuNomeTaxaOk} placeholder="Forneça informações sobre os motivos" onChange={(e) => setDetalheVeiculoSuaPosseNaoSeuNomeTaxaOk(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSuaPosseNaoSeuNomeMultaPendente">e. Neste veículo, há multas com pagamento pendente?</label>
                                                    <div className="select">
                                                        <select id="veiculoSuaPosseNaoSeuNomeMultaPendente" value={veiculoSuaPosseNaoSeuNomeMultaPendente} onChange={(e) => setVeiculoSuaPosseNaoSeuNomeMultaPendenteDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='veiculoSuaPosseNaoSeuNomeMultaPendenteDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheVeiculoSuaPosseNaoSeuNomeMultaPendente">Em caso positivo, informe os motivos:</label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='detalheVeiculoSuaPosseNaoSeuNomeMultaPendente' name='detalheVeiculoSuaPosseNaoSeuNomeMultaPendente' value={detalheVeiculoSuaPosseNaoSeuNomeMultaPendente} placeholder="Informe os motivos" onChange={(e) => setDetalheVeiculoSuaPosseNaoSeuNomeMultaPendente(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSuaPosseNaoSeuNomeVoceDirige">f. Você dirige este veículo?</label>
                                                    <div className="select">
                                                        <select id="veiculoSuaPosseNaoSeuNomeVoceDirige" value={veiculoSuaPosseNaoSeuNomeVoceDirige} onChange={(e) => setVeiculoSuaPosseNaoSeuNomeVoceDirigeDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='veiculoSuaPosseNaoSeuNomeVoceDirigeDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheVeiculoSuaPosseNaoSeuNomeVoceDirige">Em caso negativo, informe quem dirige e o motivo:</label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='detalheVeiculoSuaPosseNaoSeuNomeVoceDirige' name='detalheVeiculoSuaPosseNaoSeuNomeVoceDirige' value={detalheVeiculoSuaPosseNaoSeuNomeVoceDirige} placeholder="Informe quem dirige e o motivo" onChange={(e) => setDetalheVeiculoSuaPosseNaoSeuNomeVoceDirige(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSuaPosseNaoSeuNomeProprietario">g. Qual o nome do proprietário?</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={191} placeholder="Informe o nome do proprietário" id="veiculoSuaPosseNaoSeuNomeProprietario" value={veiculoSuaPosseNaoSeuNomeProprietario} onChange={(e) => setVeiculoSuaPosseNaoSeuNomeProprietario(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoSuaPosseNaoSeuNomeParentescoProprietario">h. Qual o seu grau de parentesco ou amizade?</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={20} placeholder="Informe o seu grau de parentesco ou amizade" id="veiculoSuaPosseNaoSeuNomeParentescoProprietario" value={veiculoSuaPosseNaoSeuNomeParentescoProprietario} onChange={(e) => setVeiculoSuaPosseNaoSeuNomeParentescoProprietario(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <button type="button" className="button is-info" onClick={(e) => adicionaVeiculoSuaPosseNaoSeuNome(e)}><i className="fa fa-plus-circle"></i>&nbsp;Clique Aqui para Registrar informação do veículo</button>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    {lVeiculoSuaPosseNaoSeuNome.map((veiculo, index) => (
                                                        <div key={index}>
                                                            <article className='message'>
                                                                <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                    <p>{index+1}. [{veiculo.placa}] {veiculo.marcaAnoModelo} ({veiculo.cor})</p>
                                                                    <button type="button" className='delete' aria-label='delete' onClick={(e) => removeVeiculoSuaPosseNaoSeuNome(e, veiculo.marcaAnoModelo, veiculo.cor, veiculo.placa)}></button>
                                                                </div>
                                                                <div className='message-body has-background-white-ter has-text-black'>
                                                                    <p>
                                                                        Seguro, Licenciamento, IPVA pago: {veiculo.taxaOk}
                                                                    </p>
                                                                    {veiculo.taxaOk === 'não' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheTaxaOk.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                    <p>
                                                                        Multa pendente: {veiculo.multaPendente}
                                                                    </p>
                                                                    {veiculo.multaPendente === 'sim' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheMultaPendente.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                    <p>
                                                                        Dirige este veículo: {veiculo.voceDirige}
                                                                    </p>
                                                                    {veiculo.voceDirige === 'não' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheVoceDirige.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                    <p>
                                                                        Proprietário: {veiculo.proprietario};
                                                                        Parentesco: {veiculo.parentesco}
                                                                    </p>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>


                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="veiculoNenhumaCondicaoAnterior"><u>Você dirige algum veículo</u> que NÃO esteja enquadrado em nenhuma das condições descritas nas questões anteriores?</label>
                                                <div className="select">
                                                    <select id="veiculoNenhumaCondicaoAnterior" value={veiculoNenhumaCondicaoAnterior} onChange={(e) => setVeiculoNenhumaCondicaoAnteriorDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='veiculoNenhumaCondicaoAnteriorDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label">Forneça informações sobre cada veículo <span style={{ fontWeight:'normal' }}>(vá adicionando clicando no botão 'Registrar informação do veículo', um veículo por vez)</span>, relatando os seguintes quesitos:</label>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoNenhumaCondicaoAnteriorPlaca">a. Placa</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={20} placeholder="Placa" id="veiculoNenhumaCondicaoAnteriorPlaca" value={veiculoNenhumaCondicaoAnteriorPlaca} onChange={(e) => setVeiculoNenhumaCondicaoAnteriorPlaca(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoNenhumaCondicaoAnteriorMarcaAnoModelo">b. Marca / Ano / Modelo</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={70} placeholder="Marca / Ano / Modelo" id="veiculoNenhumaCondicaoAnteriorMarcaAnoModelo" value={veiculoNenhumaCondicaoAnteriorMarcaAnoModelo} onChange={(e) => setVeiculoNenhumaCondicaoAnteriorMarcaAnoModelo(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoNenhumaCondicaoAnteriorCor">c. Cor</label>
                                                    <div className="controls">
                                                        <input type="text" className="input" maxLength={20} placeholder="Cor" id="veiculoNenhumaCondicaoAnteriorCor" value={veiculoNenhumaCondicaoAnteriorCor} onChange={(e) => setVeiculoNenhumaCondicaoAnteriorCor(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoNenhumaCondicaoAnteriorTaxaOk">d. Seguro Obrigatório, Licenciamento Anual e o IPVA estão todos pagos?</label>
                                                    <div className="select">
                                                        <select id="veiculoNenhumaCondicaoAnteriorTaxaOk" value={veiculoNenhumaCondicaoAnteriorTaxaOk} onChange={(e) => setVeiculoNenhumaCondicaoAnteriorTaxaOkDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='veiculoNenhumaCondicaoAnteriorTaxaOkDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheVeiculoNenhumaCondicaoAnteriorTaxaOk">Em caso negativo, forneça informações sobre os motivos:</label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='detalheVeiculoNenhumaCondicaoAnteriorTaxaOk' name='detalheVeiculoNenhumaCondicaoAnteriorTaxaOk' value={detalheVeiculoNenhumaCondicaoAnteriorTaxaOk} placeholder="Forneça informações sobre os motivos" onChange={(e) => setDetalheVeiculoNenhumaCondicaoAnteriorTaxaOk(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="veiculoNenhumaCondicaoAnteriorMultaPendente">e. Neste veículo, há multas com pagamento pendente?</label>
                                                    <div className="select">
                                                        <select id="veiculoNenhumaCondicaoAnteriorMultaPendente" value={veiculoNenhumaCondicaoAnteriorMultaPendente} onChange={(e) => setVeiculoNenhumaCondicaoAnteriorMultaPendenteDiv(e)}>
                                                            <option value=''>Selecione</option>
                                                            <option value='sim'>Sim</option>
                                                            <option value='não'>Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='veiculoNenhumaCondicaoAnteriorMultaPendenteDiv' className='box' style={{ display:'none' }}>
                                                <div className='columns'>
                                                    <div className='column'>
                                                        <label className="label" htmlFor="detalheVeiculoNenhumaCondicaoAnteriorMultaPendente">Em caso positivo, informe os motivos:</label>
                                                        <div className="controls">
                                                            <textarea maxLength={1500} rows='4' className="textarea" id='detalheVeiculoNenhumaCondicaoAnteriorMultaPendente' name='detalheVeiculoNenhumaCondicaoAnteriorMultaPendente' value={detalheVeiculoNenhumaCondicaoAnteriorMultaPendente} placeholder="Informe os motivos" onChange={(e) => setDetalheVeiculoNenhumaCondicaoAnteriorMultaPendente(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <button type="button" className="button is-info" onClick={(e) => adicionaVeiculoNenhumaCondicaoAnterior(e)}><i className="fa fa-plus-circle"></i>&nbsp;Clique Aqui para Registrar informação do veículo</button>
                                                </div>
                                            </div>
                                            <div className='columns'>
                                                <div className='column'>
                                                    {lVeiculoNenhumaCondicaoAnterior.map((veiculo, index) => (
                                                        <div key={index}>
                                                            <article className='message'>
                                                                <div className='message-header' style={{ backgroundColor:'black' }}>
                                                                    <p>{index+1}. [{veiculo.placa}] {veiculo.marcaAnoModelo} ({veiculo.cor})</p>
                                                                    <button type="button" className='delete' aria-label='delete' onClick={(e) => removeVeiculoNenhumaCondicaoAnterior(e, veiculo.marcaAnoModelo, veiculo.cor, veiculo.placa)}></button>
                                                                </div>
                                                                <div className='message-body has-background-white-ter has-text-black'>
                                                                    <p>
                                                                        Seguro, Licenciamento, IPVA pago: {veiculo.taxaOk}
                                                                    </p>
                                                                    {veiculo.taxaOk === 'não' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheTaxaOk.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                    <p>
                                                                        Multa pendente: {veiculo.multaPendente}
                                                                    </p>
                                                                    {veiculo.multaPendente === 'sim' &&
                                                                        <div dangerouslySetInnerHTML={{ __html: veiculo.detalheMultaPendente.replaceAll('\n', '<br/>'), }} style={{ marginLeft:'10px', color:'grey', padding:'0px' }} />
                                                                    }
                                                                </div>
                                                            </article>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="pagouPrometeuPagamento">Você já <u>pagou ou prometeu qualquer tipo de pagamento</u>, em dinheiro ou não, para obter recomendações ou promessas de auxílio nos exames de seleção para ingresso na Polícia Militar?</label>
                                                <div className="select">
                                                    <select id="pagouPrometeuPagamento" value={pagouPrometeuPagamento} onChange={(e) => setPagouPrometeuPagamentoDiv(e)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='sim'>Sim</option>
                                                        <option value='não'>Não</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='pagouPrometeuPagamentoDiv' className='box' style={{ display:'none' }}>
                                            <div className='columns'>
                                                <div className='column'>
                                                    <label className="label" htmlFor="detalhePagouPrometeuPagamento">Forneça informações detalhadas, relatando sobre quem recebeu seu pagamento (Nome), qual foi a <u>recompensa prometida</u> e outros dados e informações importantes:</label>
                                                    <div className="controls">
                                                        <textarea maxLength={1500} rows='4' className="textarea" id='detalhePagouPrometeuPagamento' name='detalhePagouPrometeuPagamento' value={detalhePagouPrometeuPagamento} placeholder="Forneça informações detalhadas, relatando sobre quem recebeu seu pagamento (Nome), qual foi a recompensa prometida e outros dados e informações importantes" onChange={(e) => setDetalhePagouPrometeuPagamento(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='columns'>
                                            <div className='column'>
                                                <label className="label" htmlFor="informacoesComplementares">Espaço reservado para esclarecimentos de lacunas no preenchimento deste formulário, bem como para prestar outras informações complementares, julgadas relevantes</label>
                                                <div className="controls">
                                                    <textarea maxLength={7000} rows='10' className="textarea" id='informacoesComplementares' name='informacoesComplementares' value={informacoesComplementares} placeholder="Espaço reservado para esclarecimentos de lacunas no preenchimento deste formulário, bem como para prestar outras informações complementares, julgadas relevantes" onChange={(e) => setInformacoesComplementares(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </article>
                                
                                <article className='message is-link' id='dadosConcursoDiv'>
                                    <div className='message-header'>
                                        <div className="tags are-medium has-addons">
                                            PARTE J - JUNTADA DE DOCUMENTAÇÃO
                                        </div>
                                    </div>
                                    <div className='message-body'>
                                        {/* <div className='columns'>
                                            <div className='column'>
                                                <label className="label">Anexe o mapa ou desenho ilustrativo de todos os endereços de suas residências (antigas e atual), assinalando os pontos de referência e ruas principais. Se necessário, anexe folhas suplementares.</label>
                                            </div>
                                        </div> */}
                                        <div className='columns' style={{ textAlign:'center' }}>
                                            <div className='column'>
                                                <label className='label'>DOCUMENTAÇÃO NECESSÁRIA PARA A AVALIAÇÃO DA CONDUTA SOCIAL</label>
                                                <b>ATENÇÃO</b>: Deve ser apresentada toda a documentação original para conferência.
                                            </div>
                                        </div>
                                        <div className='columns'>
                                            <div className='column'>
                                                <ol style={{ listStyleType:'lower-alpha', marginLeft:'18px', marginRight:'18px' }}>
                                                    <li>01 (uma) foto 5X7 cm colada na capa de cada via do Formulário da Avaliação de Conduta Social, datada e recente em até, no máximo, 6 (seis)  meses. ATENÇÃO: Não será aceita fotografia trajando uniformes militares, escolares, empresariais ou similares;</li>
                                                    <li style={{ marginTop:'5px' }}>01 (uma) cópia reprográfica da Certidão de Nascimento ou Casamento;</li>
                                                    <li style={{ marginTop:'5px' }}>01 (uma) cópia reprográfica da Cédula de Identidade (RG) ou Registro de Identidade Civil (RIC);</li>
                                                    <li style={{ marginTop:'5px' }}>01 (uma) cópia reprográfica da Certidão de Distribuição Criminal, emitida pela Justiça Estadual, das comarcas dos municípios em que residiu a partir dos 18 anos de idade;</li>
                                                    <li style={{ marginTop:'5px' }}>01 (uma) cópia do Atestado de Antecedentes Criminais, com emissão de forma imediata e gratuita por meio da rede Mundial de Computadores – Intenet;</li>
                                                    <li style={{ marginTop:'5px' }}>01 (uma) cópia reprográfica da Certidão Negativa do Serviço Central de Proteção ao Crédito ou, caso exista débito, 01 (uma) cópia reprográfica do Extrato de Consulta;</li>
                                                    <li style={{ marginTop:'5px' }}>01 (uma) cópia reprográfica da Certidão, expedida pelo órgão público em que estiver servindo, informando sua atual situação disciplinar ou comportamento; se responde ou se já respondeu a algum procedimento/processo administrativo; se responde ou se já respondeu a algum procedimento/ processo disciplinar, bem como punições sofridas, se houver. Esta documentação deve ser atendida somente por quem é servidor público ou militar;</li>
                                                    <li style={{ marginTop:'5px' }}>01 (uma) cópia reprográfica do Certificado de Dispensa de Incorporação (CDI). Esta documentação deve ser atendida somente para candidatos do sexo masculino;</li>
                                                    <li style={{ marginTop:'5px' }}>01 (uma) cópia reprográfica do Certificado de Conclusão do Ensino Superior ou grau equivalente, expedido por estabelecimento de ensino público ou particular, devidamente reconhecido pela legislação vigente;</li>
                                                    <li style={{ marginTop:'5px' }}>01 (uma) cópia reprográfica do Histórico Escolar do Ensino Superior.</li>
                                                </ol>
                                            </div>
                                        </div>

                                        <div className='columns' style={{ textAlign:'center' }}>
                                            <div className='column'>
                                                <label className='label'>DOCUMENTAÇÃO NECESSÁRIA PARA QUEM SERVIU AS FORÇAS ARMADAS</label>
                                            </div>
                                        </div>
                                        <div className='columns'>
                                            <div className='column'>
                                                <ol style={{ listStyleType:'lower-alpha', marginLeft:'18px', marginRight:'18px' }}>
                                                    <li>01 (uma) cópia reprográfica do Certificado de Reservista;</li>
                                                    <li style={{ marginTop:'5px' }}>01 (uma) cópia reprográfica da Declaração de Comportamento ou do Diploma de Honra ao Mérito.</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                
                                <article className='message is-link' id='dadosConcursoDiv'>
                                    <div className='message-header'>
                                        <div className="tags are-medium has-addons">
                                            TERMO, DECLARAÇÃO E AUTORIZAÇÃO
                                        </div>
                                    </div>
                                    <div className='message-body'>
                                        <label className='label' style={{ textAlign:'center' }}>TERMO DE CIÊNCIA, DECLARAÇÃO E AUTORIZAÇÃO SOBRE AVALIAÇÃO DA CONDUTA SOCIAL, DA REPUTAÇÃO E DA IDONEIDADE </label>
                                        <div className='notification is-warning'>
                                            <div className="control">
                                                <label className="checkbox" style={{ textAlign:'justify', fontWeight:'bold' }}>
                                                    <input type="checkbox" id='checkTermo1'/>&nbsp;DECLARO, na condição de candidato a ingresso na Polícia Militar do Estado do Rio Grande do Norte,       ESTAR CIENTE que a presente documentação é constituída de dados e informações sigilosas, cuja preservação e restrição de acesso devem ser rigorosamente cumpridas pela própria Administração deste Concurso, uma vez que além do sigilo instituído, os dados, informações e documentos apresentados estão identificados como INFORMAÇÕES PESSOAIS, conforme define o inciso IV, do artigo 4º da Lei Federal 12.527, de 18NOV11; Inciso III, do Art. 4° da Lei Estadual n°9.963, de 27JUL2015, regulamentada pelo Decreto Estadual n° 25.399, de 31JUL2015, dispositivos legais instituídos para a devida proteção da intimidade, vida privada, honra e imagem deste próprio candidato.
                                                </label>
                                            </div>
                                        </div>

                                        <div className='notification is-warning'>
                                            <div className="control">
                                                <label className="checkbox" style={{ textAlign:'justify', fontWeight:'bold' }}>
                                                    <input type="checkbox" id='checkTermo2'/>&nbsp;ESTOU CIENTE sobre todas as orientações descritas, bem como li e respondi pessoalmente todas as questões contidas no presente Formulário, bem como AUTORIZO que seja procedida a necessária averiguação sobre a minha vida pregressa e atual, nos aspectos de minha conduta escolar, profissional, ético-social e moral, isentando de qualquer responsabilidade as pessoas, empresas e estabelecimentos de ensino que prestarem informações sobre minha pessoa à Instituição (PMRN), a quem caberá resguardar o SIGILO DA FONTE dos dados, informações e documentos que instruírem a pesquisa sobre meu histórico de conduta e perfil ético-social e moral durante a Etapa da <i>Avaliação da Conduta Social, da Reputação e da Idoneidade</i>, que compõe o processo seletivo do presente certame concursal, em cumprimento ao que está determinado, principalmente, nos incisos X e XXXIII do artigo 5º e inciso II, do parágrafo 3.º, do artigo 37, tudo da Constituição Federal do Brasil.
                                                </label>
                                            </div>
                                        </div>

                                        <div className='notification is-warning'>
                                            <div className="control">
                                                <label className="checkbox" style={{ textAlign:'justify', fontWeight:'bold' }}>
                                                    <input type="checkbox" id='checkTermo3'/>&nbsp;ESTOU CIENTE que após a entrega da minha Ficha de Informações Confidenciais para a Comissão do Concurso, caso ocorra QUALQUER alteração do meu <u>endereço de moradia</u>, envolvimento de minha pessoa em <u>ocorrência policial</u> de qualquer natureza (seja na condição de autor, indiciado, réu, testemunha ou até mesmo como vítima), <u>tratamento de saúde</u> grave que eu seja submetido, <u>internação</u> hospitalar, <u>punição</u> disciplinar no trabalho e/ou em ambiente escolar, <u>acidente</u> de trânsito, suspensão ou cassação de minha <u>CNH</u>, inclusão de um nome no <u>SCPC e/ou SERASA</u>, DEVEREI INFORMAR por escrito à Investigação Social da Polícia Militar e anexar uma cópia, nos casos em que houver, do registro policial elaborado na ocorrência da qual venha participar. Tenho o conhecimento de que o não atendimento a qualquer uma das exigências descritas, poderá determinar a minha eliminação no concurso, tudo nos termos do § 6º; artigo 2º, da Lei Complementar n.º 613, de 03JAN18 – “Lei de Ingresso da PMRN”, que modificou o Art. 11 da Lei Estadual n° 4.630, de 16DEZ1976 – "<i>Estatuto dos Militares Estaduais do Rio Grande do Norte</i>" e também conforme especificado no edital do concurso.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </article>

                                <div style={{textAlign:'center'}}>
                                    <table style={{ margin:'auto' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ paddingRight:'10px' }}>
                                                    <button type="button" style={{ display:'none' }} id='botaoVoltarPreenchido' onClick={(e) => voltarPreenchido(e)} className="button is-info"><i className="fa fa-reply"></i>&nbsp;VOLTAR (NÃO Salva)</button>
                                                    <button type="button" style={{ display:'none' }} id='botaoVoltarInstrucao' onClick={(e) => voltarInstrucao(e)} className="button is-info"><i className="fa fa-reply"></i>&nbsp;VOLTAR (NÃO Salva)</button>
                                                </td>
                                                <td>
                                                    <button type="button" onClick={(e) => finalizarCadastro(e)} className="button is-link"><i className="fa fa-save"></i>&nbsp;SALVAR FIC (Ficha de Informação Confidencial)</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <Modal show={show} animation={true} onHide={modalClose} style={{ padding:'20px', backgroundColor: 'rgba(52, 52, 52, 0.8)' }}>
                    <Modal.Body className="has-background-link-light" style={{ textAlign:'center', marginRight:'25px' }}>
                        <div dangerouslySetInnerHTML={{ __html: msgErroModalDiv, }} style={{ paddingTop:'10px', paddingLeft:'20px', paddingRight:'20px', textAlign:'left' }}/>
                    </Modal.Body>
                    <Modal.Footer className="has-background-link-light" style={{ textAlign:'center', marginRight:'25px' }}>
                        <Button type="button" className="button is-info" onClick={modalClose} style={{margin:'10px', opacity:'1'}}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </section>
    )
}
 
export default Formulario2301