import React, { useState, useEffect } from 'react';
 
const Footer2219 = () => {

    return (
        // <footer class="footer" style={{ verticalAlign:'middle', paddingBottom:'0px', marginBottom:'0px' }}>
        //     <div class="content has-text-centered has-background-info-dark has-text-white" style={{ verticalAlign:'middle', height:'50px' }}>
        //         Rio Grande do Norte - Secretaria de Estado da Segurança Pública e da Defesa Social Militar - Polícia Militar - 2022
        //     </div>
        // </footer>
        <nav className="navbar has-background-info-dark has-text-white" role="navigation" aria-label="main navigation">
            <div className="container">
                <div className="navbar-menu">
                    <table width={'100%'}>
                        <tbody>
                            <tr style={{ textAlign:'center', fontWeight:'bold', color:'white' }}>
                                <td style={{ paddingTop:'12px' }}>Rio Grande do Norte - Secretaria de Estado da Segurança Pública e da Defesa Social Militar - Polícia Militar - 2022</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </nav>
    )
}
    
export default Footer2219