export const cpfMask = value => {
    return value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') //captura 2 grupos de numero o primeiro de 3 números (\d{3})e o segundo de 1 número (\d). Após capturar o primeiro grupo de numero $1. ele adiciona um ponto antes do segundo grupo de numero $2.
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}

export const nrInscricaoMask = value => {
    return value
    .replace(/\D/g, '')
    .replace(/(\d{7})(\d)/, '$1-$2')
}

export const nrCepMask = value => {
    return value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
}

export const nrNisMask = value => {
    return value
    .replace(/\D/g, '')
}

export const onlyNumber = value => {
    return value
    .replace(/\D/g, '')
}

export const dataBr = value => {
    return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
}

export const horaCompleta = value => {
    return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1:$2')
    .replace(/(\d{2})(\d)/, '$1:$2')
    .replace(/(\d{2})(\d)/, '$1:$2')
}

export const dataNascimentoBanco2Html = value => {
    if(value === undefined || value.length < 10)
        return '';

    return value.substring(8, 10) + '/' + value.substring(5, 7) + '/' + value.substring(0, 4);
}