import { BrowserRouter, Route, Routes } from "react-router-dom";

/****************************** 2219 **************************** */
import PaginaInicialAdmin2219 from "../components/2219/pagina_inicial_admin";
import Admin2219Busca from "../components/2219/admin/busca";
import NavbarAdmin2219 from "../components/2219/admin/navbar";

import FormularioImpressao2219 from "../components/2219/impressao";

import PaginaInicialCandidato2219 from "../components/2219/pagina_inicial_candidato";
import Formulario2219 from "../components/2219/formulario";
import Formulario2219Instrucao from "../components/2219/formulario_instrucao";
import Formulario2219Preenchido from "../components/2219/formulario_preenchido";
import Navbar2219 from "../components/2219/navbar";
import Footer2219 from "../components/2219/footer";


/****************************** 2301 **************************** */
import PaginaInicialAdmin2301 from "../components/2301/pagina_inicial_admin";
import Admin2301Busca from "../components/2301/admin/busca";
import NavbarAdmin2301 from "../components/2301/admin/navbar";

import FormularioImpressao2301 from "../components/2301/impressao";

import PaginaInicialCandidato2301 from "../components/2301/pagina_inicial_candidato";
import Formulario2301 from "../components/2301/formulario";
import Formulario2301Instrucao from "../components/2301/formulario_instrucao";
import Formulario2301Preenchido from "../components/2301/formulario_preenchido";
import Navbar2301 from "../components/2301/navbar";
import Footer2301 from "../components/2301/footer";

const Rotas = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="" element={<PaginaInicialCandidato2301/>} />
                <Route exact path="/" element={<PaginaInicialCandidato2301/>} />

                <Route path="/impressao2219/:autenticacao" element={<FormularioImpressao2219/>} />
                <Route path="/pmrnAcessoAdministrativo2219" element={<PaginaInicialAdmin2219/>} />
                <Route path="/adminBusca2219" element={<><NavbarAdmin2219/><Admin2219Busca/><Footer2219/></>} />
                <Route path="/form2219" element={<><Navbar2219/><Formulario2219/><Footer2219/></>} />
                <Route path="/formInstrucao2219" element={<><Navbar2219/><Formulario2219Instrucao/><Footer2219/></>} />
                <Route path="/formPreenchido2219" element={<><Navbar2219/><Formulario2219Preenchido/><Footer2219/></>} />

                <Route path="/impressao/:autenticacao" element={<FormularioImpressao2301/>} />
                <Route path="/pmrnAcessoAdministrativo" element={<PaginaInicialAdmin2301/>} />
                <Route path="/adminBusca" element={<><NavbarAdmin2301/><Admin2301Busca/><Footer2301/></>} />
                <Route path="/form" element={<><Navbar2301/><Formulario2301/><Footer2301/></>} />
                <Route path="/formInstrucao" element={<><Navbar2301/><Formulario2301Instrucao/><Footer2301/></>} />
                <Route path="/formPreenchido" element={<><Navbar2301/><Formulario2301Preenchido/><Footer2301/></>} />
            </Routes>
        </BrowserRouter>
    )
}

export default Rotas;