import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Parser from 'html-react-parser';
import axios from 'axios';
// import '../../utils/padrao1.css';
import { servidorBackEnd, diminuirAlturaBoxFooter } from '../../../utils/constantes_em_comum.js';

const setaTituloSistema = () => {
    document.getElementById('nomeSistemaInfoDiv').innerHTML = 'Acompanhamento dos Candidatos';

    document.getElementById('boxDiv').style.minHeight = (window.innerHeight - diminuirAlturaBoxFooter)+'px';
}

const Admin2219Busca = () => {
    const navigate = useNavigate();
    const [lCandidato, setLCandidato] = useState([]);
    const [nomeCandidato, setNomeCandidato] = useState('');
    const [cpfCandidato, setCpfCandidato] = useState('');
    const [nrInscricao, setNrInscricao] = useState('');
    const [codigoAutenticacao, setCodigoAutenticacao] = useState('');
    const [quantidadeFormularioTotal, setQuantidadeFormularioTotal] = useState(0);
    const [quantidadeFormularioPreenchido, setQuantidadeFormularioPreenchido] = useState(0);

    useEffect(() => {
        document.title = '[SCIC] Admin';
        setaTituloSistema();
        validaToken();
    }, []);

    const validaToken = async () => {
        document.getElementById('tokenInvalidoDiv').style.display = 'none';
        document.getElementById('conteudoDiv').style.display = 'none';

        const token = window.sessionStorage.getItem('token');
        const responseInfoToken = await axios.get(servidorBackEnd + '/getInfoTokenAdmin?token=' + token);
        if(responseInfoToken.data === '' || responseInfoToken.data.admin === false){
            document.getElementById('tokenInvalidoDiv').style.display = 'block';
            return false;
        }

        document.getElementById('conteudoDiv').style.display = 'block';
        carregaQuantidadeFormularioPreenchido();
    }

    const carregaQuantidadeFormularioPreenchido = async () => {
        const responseQuantidadeFormularioPreenchido = await axios.get(servidorBackEnd + '/getFormulario2219Preenchido');
        if(responseQuantidadeFormularioPreenchido.data._all === undefined){
            document.getElementById('conteudoDiv').style.display = 'none';
            document.getElementById('tokenInvalidoDiv').style.display = 'block';
            return false;
        }
        setQuantidadeFormularioTotal(responseQuantidadeFormularioPreenchido.data._all);
        setQuantidadeFormularioPreenchido(responseQuantidadeFormularioPreenchido.data.form_finalizou);
    }

    const limpaFormulario = (e) => {
        e.preventDefault();
        //navigate('/candidato2219');
        setLCandidato([]);

        setNomeCandidato('');
        setCpfCandidato('');
        setNrInscricao('');
        setCodigoAutenticacao('');
    }

    const searchCandidato = async (e) => {
        carregaQuantidadeFormularioPreenchido();
        e.preventDefault();
        document.getElementById('nenhumResultadoBuscaDiv').style.display = 'none';
        document.getElementById('buscaLoadingDiv').style.display = 'block';
        try {
            const response = await axios.post(servidorBackEnd + '/searchCandidatoPreencheuFormularioByFiltro2219', {
                nomeCandidato: nomeCandidato,
                cpfCandidato: cpfCandidato,
                nrInscricao: nrInscricao,
                codigoAutenticacao: codigoAutenticacao
            });
            setLCandidato(response.data);
            document.getElementById('buscaLoadingDiv').style.display = 'none';

            if(response.data.length === 0){
                document.getElementById('nenhumResultadoBuscaDiv').style.display = 'block';
                return false;
            }            
        } catch (error) {
            if (error.response) {
                console.log(error);
            }
        }
    }

    const gerarPdf = async (e, codAutenticacao) => {
        e.preventDefault();
        window.open('/impressao2219/' + codAutenticacao, 'ficImpressao');
    }

    return (
        <div className="container mt-5 box" id='boxDiv'>
            <div className="hero-body" id='tokenInvalidoDiv' style={{ display:'none', margin:'auto' }}>
                <div className="container">
                    <div className="columns is-centered box">
                        <div className="column">
                            <table border={0} style={{ margin:'auto' }}>
                                <tbody>
                                    <tr>
                                        <td width={'65px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnEsquerda.jpeg'/></td>
                                        <td style={{ padding:'10px', fontFamily:'Times New Roman', verticalAlign:'middle', fontWeight:'bold', fontSize:'13px', lineHeight:'14px' }}>
                                            RIO GRANDE DO NORTE<br/>
                                            SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E DA DEFESA SOCIAL<br/>
                                            POLÍCIA MILITAR<br/>
                                            AGÊNCIA CENTRAL DE INTELIGÊNCIA – ACI<br/>
                                            SUBSEÇÃO DE CONTRAINTELIGÊNCIA – SS3
                                        </td>
                                        <td width={'80px'} style={{ verticalAlign:'middle', textAlign:'center' }}><img src='/pmrnDireita.jpg'/></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3}>
                                            <div className='notification is-danger'>
                                                <b>Token Inválido!</b><br/>
                                                Por favor, logue novamente
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div id='conteudoDiv' style={{ display:'none' }}>
                <form>
                    <div className="columns is-vcentered">
                        <div className="column">
                            <label className='label' htmlFor='nomeCandidato'>Nome</label>
                            <div className="control has-icons-left">
                                <div className="controls">
                                    <input className="input" id="nomeCandidato" type="text" placeholder="Nome do candidato" value={nomeCandidato} onChange={(e) => setNomeCandidato(e.target.value)}/>
                                </div>
                                <span className="icon is-small is-left">
                                    <i className="fa fa-user"></i>
                                </span>
                            </div>
                        </div>
                        <div className="column">
                            <label className='label' htmlFor='cpfCandidato'>CPF</label>
                            <div className="control has-icons-left">
                                <div className="control">
                                    <input className="input" id="cpfCandidato" type="text" placeholder="CPF do candidato" value={cpfCandidato} onChange={(e) => setCpfCandidato(e.target.value)}/>
                                </div>
                                <span className="icon is-small is-left">
                                    <i className="fa fa-vcard-o"></i>
                                </span>
                            </div>
                        </div>
                        <div className="column">
                            <label className='label' htmlFor='nrInscricao'>N° Inscrição</label>
                            <div className="control has-icons-left">
                                <div className="control">
                                    <input className="input" id="nrInscricao" type="text" placeholder="Número da Inscrição" value={nrInscricao} onChange={(e) => setNrInscricao(e.target.value)}/>
                                </div>
                                <span className="icon is-small is-left">
                                    <i className="fa fa-id-card-o"></i>
                                </span>
                            </div>
                        </div>
                        <div className="column">
                            <label className='label' htmlFor='codigoAutenticacao'>Cód. Autenticação</label>
                            <div className="control has-icons-left">
                                <div className="control">
                                    <input className="input" id="codigoAutenticacao" type="text" placeholder="Código de Autenticação" value={codigoAutenticacao} onChange={(e) => setCodigoAutenticacao(e.target.value)}/>
                                </div>
                                <span className="icon is-small is-left">
                                    <i className="fa fa-user-circle-o"></i>
                                </span>
                            </div>
                        </div>
                        <div className="column is-narrow">
                            <label className='label' htmlFor='codigoAutenticacao'>Fichas Preenchidas: <b style={{ color:'blue' }}>{quantidadeFormularioPreenchido}</b>/<b style={{ color:'black ' }}>{quantidadeFormularioTotal}</b></label>
                            <div className="control">
                                <button className="button is-info" onClick={searchCandidato} style={{ marginTop: '0px', marginLeft:'0px', width: '50px' }} title='Buscar/Listar candidatos'><i className="fa fa-search"></i>&nbsp;</button>
                                <button className="button has-background-grey-lighter" onClick={limpaFormulario} style={{ marginTop: '0px', marginLeft:'15px', width: '100px' }} title="Limpa somente os campos do formulário para a busca">Limpar</button>
                            </div>
                        </div>
                    </div>
                </form>
                <div id='buscaLoadingDiv' style={{ marginTop:'30px', display:'none' }}><img src='/loading.gif'/></div>
                <div id='nenhumResultadoBuscaDiv' style={{ marginTop:'30px', display:'none', textAlign:'center' }}><div className='has-text-white has-background-danger' style={{ padding:'10px' }}>Nenhum candidato encontrado</div></div>
                {(JSON.stringify(lCandidato) !== '[]' && lCandidato !== '') &&
                    <table className='table is-hoverable is-fullwidth' style={{ marginTop:'20px' }}>
                        <thead>
                            <tr className='has-background-info'>
                                <th>&nbsp;</th>
                                <th className='has-text-white'>Nome</th>
                                <th className='has-text-white'>CPF</th>
                                <th className='has-text-white'>N° Inscrição</th>
                                <th className='has-text-white'>Autenticação</th>
                                <th className='has-text-white'><i className="fa fa-download"></i></th>
                            </tr>
                        </thead>
                        <tbody>
                            {lCandidato.map((candidato, index) => (
                                <tr key={index}>
                                    <td style={{ borderBottom:'1px solid lightgrey', textAlign:'right' }}>{index + 1}.</td>
                                    <td style={{ borderBottom:'1px solid lightgrey' }}><i className="fa fa-user"></i> {candidato.nome}</td>
                                    <td style={{ borderBottom:'1px solid lightgrey' }}><i className="fa fa-vcard-o"></i> {candidato.cpf}</td>
                                    <td style={{ borderBottom:'1px solid lightgrey' }}><i className="fa fa-id-card-o"></i> {candidato.nr_inscricao}</td>
                                    <td style={{ borderBottom:'1px solid lightgrey' }}>
                                        {candidato.form_finalizou === 1 &&
                                            <div><i className="fa fa-user-circle-o"></i> {candidato.form_autenticacao}</div>
                                        }
                                        {candidato.form_finalizou === 0 &&
                                            <div><i className="fa fa-user-circle-o"></i> Não preencheu / Não disponível</div>
                                        }
                                    </td>
                                    <td style={{ borderBottom:'1px solid lightgrey' }}>
                                        {candidato.form_finalizou === 1 &&
                                            <a href='#' target='_blank' onClick={(e) => gerarPdf(e, candidato.form_autenticacao)}><i className="fa fa-file-pdf-o" style={{ lineHeight:'0px' }}></i> FIC</a>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
}
 
export default Admin2219Busca